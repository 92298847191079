import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Config from "../../../config"; 
import {useAuth} from '../../../components/Common/InitialGears/AuthContext'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import {  faMagicWandSparkles, faStar, faChartLine, faPlusCircle, faTachometerAlt,faSearch,faCheck,faPlus, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Color } from "../../../GlobalStyles";
import shootingStarImage from '../../../assets/images/shooting-star.png';
import CategoryItem from "./CategoryItem";
import MyWishesModal from "./MyWishesModal"; 
import TrendingDetailsModal from './TrendingDetailsModal';
import trendingImage from '../../../assets/images/trending.png';
import newWishAIRequestImage from '../../../assets/images/think-out-of-the-box.png';
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";

library.add(faStar, faChartLine, faPlusCircle, faTachometerAlt, faSearch, faCheck, faPlus, faArrowLeft);

const WishesPage = () => { 
  const navigate = useNavigate(); 
  const { queryLoginParam, idTokenLoggedIn, logOutRequested } = useAuth(); 
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);  
  const [modalVisible, setModalVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  const [wishlistData, setWishlistData] = useState([]); 
  const [trendsData, setTrendsData] = useState([]);
  const [callAPIs, setCallAPIs] = useState(false);
  const [existingWishes, setExistingWishes] = useState([]);
  const [wishesLoaded, setWishesLoaded] = useState(false);
  const [lastAddedCategory, setLastAddedCategory] = useState(null);
  const [categoriesList, setCategoriesList] = useState([]); 
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [showStarFor, setShowStarFor] = useState(null);
  const [showWishTrends, setShowWishTrends] = useState(false); 
  const [showMyWishesModal, setShowMyWishesModal] = useState(false);

  useEffect(() => {     
    setModalVisible(false);
    if (!logOutRequested) {
      setCallAPIs(true);
    }
  }, [navigate, queryLoginParam]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchWishListData();
        await fetchWishTrends();
        await imageCall();
      } catch (error) {
        setErrorMessage("Currently not all items are loaded");
      }
    };
    if (callAPIs) {
      fetchData();
    }
  }, [callAPIs]);

  const addtoWishList = async (category) => {
    setErrorMessage('');
    let wishURL = `${Config.apiUrl}addToMyWish?${queryLoginParam}`;

    const requestBody = {
      category: category,
      manufacturer_name: '',
      seller_name: '',
      brand_name: '',
      product_name: '',
      wait_period: '',
      personal_use_or_professional: 'personal',
      additional_comments: '',
    };
    const headers = { 'Content-Type': 'application/json', 'Authorization': idTokenLoggedIn }; 
    const response = await fetch(wishURL, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestBody),
        mode: 'cors',  
      credentials: 'omit' 
      });
  };

  const handleAddWish = async (title) => {
    setLastAddedCategory(title);
    setShowStarFor(title);

    try {
        await addtoWishList(title); 
      } catch (error) {
        console.error('Failed to add wish:', error);
        setErrorMessage('Failed to add wish. Please try again.');
      }

    setTimeout(() => {
      setShowStarFor(null);
      setLastAddedCategory(null);
      setExistingWishes(prev => [...prev, title]);
    }, 10000);
  };

  const fetchWishListData = async () => {
    let wishURL = `${Config.apiUrl}getMyWishes?${queryLoginParam}&limit=5`; 
    const headers = { 'Content-Type': 'application/json', 'Authorization': idTokenLoggedIn };
    try {
      const wishResponse = await fetch(wishURL, {headers});
      let responseData = "";
      try {
        responseData = await wishResponse.json();
      } catch (error) {
        responseData = wishResponse;
      }
      if (responseData.wish_lists) {
        setWishlistData(responseData.wish_lists);
        setExistingWishes(responseData.categories || []);
        setWishesLoaded(true);
      } else {
        setWishlistData({});
        setWishesLoaded(true);
        setExistingWishes([]);
      }
    } catch (error) {
      setErrorMessage('Oops, please re-try');
      setExistingWishes([]);
    } finally {
      setIsLoading(false);
      setWishesLoaded(true);
    }
  };

  const fetchWishTrends = async () => {
    try{
    let wishTrendsURL = buildUrl('getWishTrends', `${queryLoginParam}&limit=3`);     
    const wishResponse = await getApi(wishTrendsURL);
     
    if (Array.isArray(wishResponse.data)) {
      const trendsArray = wishResponse.data.map(item => ({
        name: item.name,
        count: item.count
      }));
      setTrendsData(trendsArray);
    } else { 
      setTrendsData([]);
    }
  } catch (error) { 
    setTrendsData([]);
  }
  };

  const imageCall = async () => {
    let categoriesUrl = `${Config.apiUrl}getCategories?${queryLoginParam}`;
    const headers = { 'Authorization': idTokenLoggedIn, 'Content-Type': 'application/json' };
    try {
      const response = await fetch(categoriesUrl, {headers});
      let data;
      try {
        data = await response.json();
      } catch (error) {
        data = response;
      }

      if (data && data.length > 0) {
        const newcategoriesList = data.map((category) => ({
          image: { uri: category.image_url },
          title: category.name,
        }));
        setCategoriesList(newcategoriesList);
        setFilteredCategories(newcategoriesList);
        setCategories(data.map((category) => category.name));
      }
    } catch (error) {
      // Handle error
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filteredData = categoriesList.filter(category =>
      category.title.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCategories(filteredData);
  };


const handleWishAdded = (title) => {
    setExistingWishes(prev => [...prev, title]);
  };

const handleMyWishesClick = () => { 
    fetchWishListData(); 
    setShowMyWishesModal(true);
  };

  const handleWishTrendsClick = () => { 
    fetchWishTrends(); 
    setShowWishTrends(true); 
  };
  return (
    <div style={styles.container}>
      <div style={styles.leftSection}> 
        <h2 style={styles.leftSectionTitle}>Wish Menu</h2>
        <div style={styles.menuItems}>
          <MenuItem icon={shootingStarImage} title="My Wishes" onClick={handleMyWishesClick} isImage={true}/>
          <MenuItem icon={trendingImage} title="Wish Trends" onClick={handleWishTrendsClick} isImage={true}/>           
          <MenuItem icon={newWishAIRequestImage} title="I have a new Wish" onClick={() => navigate('/addWish')} isImage={true}/>
          <MenuItem icon={faTachometerAlt} title="Wishes Dashboard" onClick={() => navigate('/wishes-dashboard')}/>
        </div>
      </div>
      <div style={styles.rightSection}>
        <div style={styles.header}>
            <button onClick={() => navigate(-1)} style={styles.backButton}>
            <FontAwesomeIcon icon={faArrowLeft} /> Back
            </button> 
        <div style={styles.placeholder}></div>
      </div>
        {isLoading ? (
          <div style={styles.loadingContainer}>
            <div style={styles.spinner}></div>
          </div>
        ) : (
          <>
            <div style={styles.searchBox}>
              <input type="text" placeholder="Search" value={searchQuery} onChange={(e) => handleSearch(e.target.value)} style={styles.searchInput} />
              <button style={styles.searchButton}>
                <FontAwesomeIcon icon="search" color={Color.colorBaseBeige}/>
              </button>
            </div>
            <div style={styles.categoriesContainer}>
              {filteredCategories.map((item, index) => (
                <CategoryItem key={index} item={item} onAddWish={handleAddWish} existingWishes={existingWishes} onWishAdded={handleWishAdded}/>
              ))}
            </div>
          </>
        )}
      </div>
      {showMyWishesModal && (
        <MyWishesModal wishlistData={wishlistData}  onClose={() => setShowMyWishesModal(false)}/>
      )}
      {showWishTrends && (
        <TrendingDetailsModal trendsData={trendsData}  onClose={() => setShowWishTrends(false)}/>
      )}

      
    </div>
  );
};

const MenuItem = ({ icon, title, onClick , isImage = false}) => (
  <button onClick={onClick} style={styles.menuItem}>
    {isImage ? (
      <img src={icon} alt={title} style={styles.menuIcon} />
    ) : (
      <FontAwesomeIcon icon={icon} style={styles.menuIcon} />
    )}
    <span style={styles.menuItemText}>{title}</span>
  </button>
);

const styles = {
  container: {
    display: 'flex',
    height: '100vh',
    backgroundColor: Color.colorLightBeige,
  }, 
  leftSection: {
    width: '10%',
    padding: '30px 20px',
    background: Color.colorWhite,
    borderRadius:'10px',
    margin:'10px', 
    boxShadow: '2px 0 10px rgba(0,0,0,0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  leftSectionTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: Color.colorRoyalBlueText,
    marginBottom: '30px',
    textAlign: 'center',
  },
  rightSection: {
    width: '95%', 
    overflowY: 'auto',
    backgroundColor: Color.colorLightBeige,
  },
  backButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    marginBottom: '20px',
    color: Color.colorDarkGrey,
    zIndex: 1,
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    backgroundColor: Color.colorLightBeige,
    position: 'relative',
    marginBottom: '20px',
  }, 
  title: {
    fontSize: '24px',
    marginBottom: '30px',
    color: Color.colorRoyalBlueText,
  },
  menuItems: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
    textAlign: 'left',
    marginBottom: '10%',
  },
  menuIcon: {
    fontSize: '20px',
    width: '24px',
    height: '24px',
    objectFit: 'contain',
  },
  menuItemText: {
    color: Color.colorDarkGrey,
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  spinner: {
    border: '4px solid #f3f3f3',
    borderTop: '4px solid #3498db',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 1s linear infinite',
  },
  searchBox: {
    display: 'flex',
    marginBottom: '20px',
  },
  searchInput: {
    flex: 1,
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ddd',
    borderRadius: '4px 0 0 4px',
  },
  searchButton: {
    padding: '10px 15px',
    backgroundColor: Color.colorCoolBeige,
    color: Color.colorCoolBeige,
    border: 'none',
    borderRadius: '0 4px 4px 0',
    cursor: 'pointer',
  },
  categoriesContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    gap: '20px',
  },
  categoryItem: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '15px',
    textAlign: 'center',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    position: 'relative',
  },
  categoryImage: {
    width: '100%',
    height: '150px',
    objectFit: 'cover',
    borderRadius: '4px',
    marginBottom: '10px',
  },
  categoryTitle: {
    fontSize: '18px',
    marginBottom: '10px',
  },
  checkIcon: {
    color: Color.colorBaseBeige,
    fontSize: '20px',
    position: 'absolute',
    bottom: '10px',
    right: '10px',
  },
  addWishButton: {
    backgroundColor: Color.colorBaseBeige,
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    padding: '5px 10px',
    cursor: 'pointer',
    position: 'absolute',
    bottom: '10px',
    right: '10px',
  },
  animationContainer: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    width: '30px',
    height: '30px',
  },
};

export default WishesPage;
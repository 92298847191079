// validation-utils.js
const US_STATES = {
    'AL': 'Alabama', 'AK': 'Alaska', 'AZ': 'Arizona', 'AR': 'Arkansas', 'CA': 'California',
    'CO': 'Colorado', 'CT': 'Connecticut', 'DE': 'Delaware', 'FL': 'Florida', 'GA': 'Georgia',
    'HI': 'Hawaii', 'ID': 'Idaho', 'IL': 'Illinois', 'IN': 'Indiana', 'IA': 'Iowa',
    'KS': 'Kansas', 'KY': 'Kentucky', 'LA': 'Louisiana', 'ME': 'Maine', 'MD': 'Maryland',
    'MA': 'Massachusetts', 'MI': 'Michigan', 'MN': 'Minnesota', 'MS': 'Mississippi', 'MO': 'Missouri',
    'MT': 'Montana', 'NE': 'Nebraska', 'NV': 'Nevada', 'NH': 'New Hampshire', 'NJ': 'New Jersey',
    'NM': 'New Mexico', 'NY': 'New York', 'NC': 'North Carolina', 'ND': 'North Dakota', 'OH': 'Ohio',
    'OK': 'Oklahoma', 'OR': 'Oregon', 'PA': 'Pennsylvania', 'RI': 'Rhode Island', 'SC': 'South Carolina',
    'SD': 'South Dakota', 'TN': 'Tennessee', 'TX': 'Texas', 'UT': 'Utah', 'VT': 'Vermont',
    'VA': 'Virginia', 'WA': 'Washington', 'WV': 'West Virginia', 'WI': 'Wisconsin', 'WY': 'Wyoming'
  };
  
  const CANADA_PROVINCES = {
    'AB': 'Alberta', 'BC': 'British Columbia', 'MB': 'Manitoba', 'NB': 'New Brunswick',
    'NL': 'Newfoundland and Labrador', 'NS': 'Nova Scotia', 'ON': 'Ontario',
    'PE': 'Prince Edward Island', 'QC': 'Quebec', 'SK': 'Saskatchewan',
    'NT': 'Northwest Territories', 'NU': 'Nunavut', 'YT': 'Yukon'
  };
  
  export const ALL_STATES = { ...US_STATES, ...CANADA_PROVINCES };
  
  export const validateForm = {
    name: (value) => {
      if (!value) return 'Name is required';
      if (/\d/.test(value)) return 'Name cannot contain numbers';
      if (value.length < 2) return 'Name must be at least 2 characters long';
      return '';
    },
  
    email: (value) => {
      if (!value) return 'Email is required';
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) return 'Invalid email format';
      return '';
    },
  
    cardNumber: (value) => {
      if (!value) return 'Card number is required';
      const cleaned = value.replace(/\D/g, '');
      if (cleaned.length !== 16) return 'Card number must be 16 digits';
      return '';
    },
  
    state: (value) => {
      if (!value) return 'State/Province is required';
      if (!ALL_STATES[value.toUpperCase()]) return 'Invalid state/province';
      return '';
    },
  
    postalCode: (value, countryCode) => {
      if (!value) return 'Postal code is required';
      if (countryCode === 'US') {
        const usZipRegex = /^\d{5}(-\d{4})?$/;
        if (!usZipRegex.test(value)) return 'Invalid US ZIP code';
      } else if (countryCode === 'CA') {
        const caPostalRegex = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;
        if (!caPostalRegex.test(value)) return 'Invalid Canadian postal code';
      }
      return '';
    }
  };
  
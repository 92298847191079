import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ApprovalModalStyles from './ApprovalModalStyles';

const ApprovalModal = ({ isOpen, onClose, onApprove }) => {
  const [isVerified, setIsVerified] = useState(false);
  const [verificationScore, setVerificationScore] = useState('');
  const [error, setError] = useState('');

  useEffect(() => { 
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = ApprovalModalStyles;
    document.head.appendChild(styleSheet); 
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  const handleApprove = () => {
    if (verificationScore === '' || isNaN(verificationScore) || verificationScore < 0 || verificationScore > 100) {
      setError('Please enter a valid verification score between 0 and 100.');
      return;
    }
    setError('');
    onApprove(isVerified, parseInt(verificationScore));
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="modal-title">Approve Group Wish</h2>
          <button onClick={onClose} className="close-button">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="approval-form">
          <div className="form-group">
            <label htmlFor="verified">Is the group wish verified?</label>
            <select 
              id="verified"
              value={isVerified ? 'yes' : 'no'} 
              onChange={(e) => setIsVerified(e.target.value === 'yes')}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="score">Verification Score (0-100):</label>
            <input id="score" type="number" min="0" max="100" value={verificationScore} onChange={(e) => setVerificationScore(e.target.value)}/>
          </div>
          {error && <div className="error">{error}</div>}
          <button onClick={handleApprove} className="approve-button">
            Approve
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApprovalModal;
// config/faqConfig.js
export const FAQ_CATEGORIES = {    
        benefits: {
        "title": "Benefits",
        "questions": [
          {
            "q": "What are my benefits?", 
            "a": "Best rates for products, secure and transparent, guaranteeing cost savings through our group buying model."
          },
          {
            "q": "What do I need to do to gain the cost advantage?",
            "a": "Simply join our buying pool and wait for the right time to reap the benefits when enough buyers come together."
          },
          {
            "q": "Is there any risk?",
            "a": "No -- we work exclusively with reputable, verified, and thoroughly vetted manufacturers and sellers."
          },
          {
            "q": "How can I fast track my product purchase?",
            "a": "Refer friends and bring buddies interested in similar products. You'll gain additional benefits through our referral bonus program."
          },
          {
            "q": "I have a contact of a seller who can supply at bulk rates.",
            "a": "We welcome business referrals too! You can earn rewards by connecting us with quality suppliers when they qualify."
          }          
        ]
       },
    ordering: {
      title: "Ordering Process",
      questions: [
        {
          q: "Why do I need to wait for my order to be processed?",
          a: "BacksApp uses group buying power to secure better prices. We combine multiple orders to reach wholesale quantity requirements, allowing everyone to benefit from bulk pricing typically reserved for resellers."
        },
        {
          q: "Why is advance payment required?",
          a: "Pre--payment enables us to process bulk orders efficiently and secure the best wholesale prices. Orders are carefully handled through our warehouse system to ensure quality and accurate distribution."
        },
        {
          q: "Can I modify my order quantity later?",
          a: "You can increase your quantity at any time until orders are closed. Once a deal is activated and confirmed, quantity decreases are not permitted as they affect the group's negotiated wholesale pricing. This ensures we maintain our committed volume to secure the best prices for everyone."
        },
        {
          q: "How long do orders typically take to process?",
          a: "Processing times vary based on group completion and supplier schedules. Estimated timelines are provided with each product listing."
        },
        {
          q: "Can I purchase multiple different products at once?",
          a: "Yes, you can participate in multiple group purchases simultaneously, each with its own completion timeline."
        }
      ]
    },
    verification: {
      title: "Verification & Security",
      questions: [
        {
          q: "Why do I need to get verified?",
          a: "Verification ensures the legitimacy of all users and orders, maintaining the integrity of our group buying platform."
        },
        {
          q: "Why does an order need minimum verified users?",
          a: "This requirement helps ensure order authenticity and maintains the quality of our group buying process."
        },
        {
          q: "What verification documents are needed?",
          a: "Required documentation varies based on purchase type and value. Specific requirements are provided during the verification process."
        }
      ]
    },
    support: {
      title: "Product Support",
      questions: [
        {
          q: "What if my product arrives damaged or missing parts?",
          a: "Submit a claim with your package opening video through our support system. Our team will assist with replacing verified damaged or missing components."   
        },
        {
          q: "Can I return my product?",
          a: "Our wholesale group buying model doesn't support returns. All sales are final, so please review product details carefully before purchasing."
        },
        {
            "q": "What if I don't find a product that I am looking for?",
            "a": "Let us know what deals you're looking for, and we will try to meet your needs."
          },
          {
            "q": "How do I know if someone else is looking for a similar product?",
            "a": "You can monitor the trends of other users' wishes to see what products are in demand."
          }        
      ]
    },
    delivery: {
        title: "Product Delivery",
        questions: [          
          {
            q: "How do I track my order?",
            a: "Once your group order is confirmed, you'll receive tracking information through your account dashboard."
          },
          {
              q: "What if I need to change my delivery address?",
              a: "Address changes can be made before the deal is activated. Once activated, address modifications may incur additional shipping costs based on the new location. Contact support immediately for assistance with address changes."
            },
            {
              q: "How are shipping costs calculated?",
              a: "Shipping costs vary based on three key factors: the delivery location, package size, and weight. Use our shipping calculator or check out to see exact costs."
            },
            {
              q: "How can I track my shipping status?",
              a: "Once your order is shipped from our warehouse, you'll receive a tracking number via email and it will be available in your account dashboard. You can track the package's journey from our warehouse to your delivery address."
            }
        ]
      },
    payment: {
      title: "Payment & Pricing",
      questions: [
        {
          q: "What payment methods are accepted?",
          a: "We accept major credit cards and bank transfers. Specific payment options are displayed at checkout."
        },
        {
          q: "How are group discounts calculated?",
          a: "Discounts are based on total group order volume. The final price is displayed before payment confirmation."
        },
        {
          q: "Are there any additional fees?",
          a: "Shipping and handling fees are calculated based on your location and displayed at checkout. No hidden fees are added."
        }
      ]
    }
  };
  
  export default FAQ_CATEGORIES;
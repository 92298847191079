import React, { useState ,useEffect} from 'react';
import { useAuth } from '../../Common/InitialGears/AuthContext';
import * as style from "../../../GlobalStyles";
import {postApi, buildUrl} from "../../Common/BackendCalls/apiUtils";
import { validateForm, ALL_STATES } from './VerificationUtils';
import './VerificationModal.css'; 

const VerificationModal = ({ isOpen, onClose, onVerificationSuccess }) => {
  const { queryLoginParam, idTokenLoggedIn } = useAuth();
  const [step, setStep] = useState(1);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [cvv, setCvv] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [countryCode, setCountryCode] = useState('US');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  
  useEffect(() => {
    if (isOpen) {
      setStep(1);
      setError(null);
    }
  }, [isOpen]);

  const validateField = (name, value) => {
    let error = '';
    switch (name) {
      case 'name':
        error = validateForm.name(value);
        break;
      case 'email':
        error = validateForm.email(value);
        break;
      case 'cardNumber':
        error = validateForm.cardNumber(value);
        break;
      case 'state':
        error = validateForm.state(value);
        break;
      case 'postalCode':
        error = validateForm.postalCode(value, countryCode);
        break;
      default:
        break;
    }
    setFormErrors(prev => ({
      ...prev,
      [name]: error
    }));
    return !error;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    switch (name) {
      case 'cardNumber':
        const cleaned = value.replace(/\D/g, '');
        if (cleaned.length <= 16) {
          setCardNumber(cleaned.replace(/(\d{4})/g, '$1 ').trim());
        }
        validateField('cardNumber', cleaned);
        break;
        case 'name':
          // Allow only letters, spaces, and basic punctuation
          if (/^[A-Za-z\s'.,-]*$/.test(value) || value === '') {
            setName(value);
            validateField('name', value);
          }
          break;
    
        case 'email':
          setEmail(value);
          validateField('email', value);
          break;
    
        case 'state':
          const upperState = value.toUpperCase();
          setState(upperState);
          validateField('state', upperState);
          break;
    
        case 'postalCode':
          if (countryCode === 'CA') {
            const upperPostal = value.toUpperCase();
            setPostalCode(upperPostal);
            validateField('postalCode', upperPostal, countryCode);
          } else {
            setPostalCode(value);
            validateField('postalCode', value, countryCode);
          }
          break;
    
        case 'expiryMonth':
          const monthValue = value.replace(/\D/g, '');
          if (monthValue.length <= 2) {
            const month = parseInt(monthValue);
            if (!monthValue || (month >= 0 && month <= 12)) {
              setExpiryMonth(monthValue);
            }
          }
          break;
    
        case 'expiryYear':
          const yearValue = value.replace(/\D/g, '');
          if (yearValue.length <= 4) {
            setExpiryYear(yearValue);
          }
          break;
    
        case 'cvv':
          const cvvValue = value.replace(/\D/g, '');
          if (cvvValue.length <= 4) {
            setCvv(cvvValue);
          }
          break;
    
        case 'street':
          setStreet(value);
          break;
    
        case 'city':
          if (/^[A-Za-z\s'.,-]*$/.test(value) || value === '') {
            setCity(value);
          }
          break;
    
        default:
          break;
      }
    }
     
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    const isNameValid = validateField('name', name);
    const isEmailValid = validateField('email', email);
    const isCardValid = validateField('cardNumber', cardNumber);
    const isStateValid = validateField('state', state);
    const isPostalValid = validateField('postalCode', postalCode);

    if (!isNameValid || !isEmailValid || !isCardValid || !isStateValid || !isPostalValid) {
      setIsLoading(false);
      return;
    }

    try {
        const verifyCustomerUrl = buildUrl('verifyCustomer', queryLoginParam);
        const requestBody = {
          name,
          email,
          cardNumber: cardNumber.replace(/\D/g, ''),
          expiryMonth,
          expiryYear,
          cvv,
          postalCode,
          paymentMethodBillingAddress: {
            street,
            city,
            state: state.toUpperCase(), 
            countryCode
          },
          countryCode
        };
  
        const response = await postApi(verifyCustomerUrl, requestBody); 
        if (response && response.status === 'success') {
          if (response.message) {
            onVerificationSuccess(true);
            setStep(3);
          } else {
            throw new Error(response.message || 'Verification failed');
          }
        } else {
          throw new Error('Verification failed: Unexpected response from server');
        }
      } catch (error) {
        console.error('Verification error:', error);
        setError(error.message || 'An error occurred during verification');
        onVerificationSuccess(false);
      } finally {
        setIsLoading(false);
      }
    };
    
  if (!isOpen) return null;

  return (
    <div style={modalOverlayStyle}>
      <div style={modalContentStyle}>
        <div style={modalHeaderStyle}>
            <h2 style={modalTitleStyle}>Customer Verification</h2>
            <button onClick={onClose} style={closeButtonStyle}>X</button>
        </div>
        {step === 1 && (
          <div>
            <p>To participate in group wishes, we need to verify your account and set up a payment method.</p>
            <div style={buttonContainerStyle}>
              <button onClick={() => setStep(2)} style={buttonStyle}>Continue</button>
              <button onClick={onClose} style={secondaryButtonStyle}>Close</button>
            </div>
          </div>
        )}
        {step === 2 && (
          <form onSubmit={handleSubmit} style={formStyle}>
            <div className="form-field"> 
              <input type="text" placeholder="Full Name" value={name} onChange={handleInputChange} style={inputStyle} required/>
              {formErrors.name && <span style={errorStyle}>{formErrors.name}</span>}
            </div>
            <div className="form-field">
              <input type="email" placeholder="Email" value={email} onChange={handleInputChange} style={inputStyle} required/>
              {formErrors.email && <span style={errorStyle}>{formErrors.email}</span>}
            </div>
            <div className="form-field"> 
              <input type="text"placeholder="Card Number" value={cardNumber} onChange={handleInputChange} style={inputStyle} required/>
              {formErrors.cardNumber && <span style={errorStyle}>{formErrors.cardNumber}</span>}
            </div>
            <div style={rowStyle}>
              <div className="form-field" style={smallInputStyle}>
                <input type="text" placeholder="MM" value={expiryMonth} onChange={(e) => setExpiryMonth(e.target.value)} style={smallInputStyle} required />
              </div>
              <div className="form-field" style={smallInputStyle}> 
                <input type="text" placeholder="YYYY" value={expiryYear} onChange={(e) => setExpiryYear(e.target.value)} style={smallInputStyle} required/>
              </div>
              <div className="form-field" style={smallInputStyle}>
                <input type="text" placeholder="CVV" value={cvv} onChange={(e) => setCvv(e.target.value)} style={smallInputStyle} required/>
              </div>
            </div>
            <div className="form-field"> 
              <input type="text" placeholder="Postal Code" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} style={inputStyle} required/>
            </div>
            <div className="form-field"> 
              <input type="text" placeholder="Street" value={street} onChange={(e) => setStreet(e.target.value)} style={inputStyle} required/>
            </div>
            <div className="form-field"> 
              <input type="text" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} style={inputStyle} required/>              
            </div>
            <div className="form-field">               
              <select name="state" value={state} onChange={handleInputChange} style={inputStyle} required>
                <option value="">Select State/Province</option>
                {Object.entries(ALL_STATES).map(([code, name]) => (
                  <option key={code} value={code}>
                    {name} ({code})
                  </option>
                ))}
              </select>
              {formErrors.state && <span style={errorStyle}>{formErrors.state}</span>}
            </div>
            <div className="form-field"> 
              <input type="text" name="postalCode" placeholder={countryCode === 'US' ? "ZIP Code" : "Postal Code"} value={postalCode} onChange={handleInputChange} style={inputStyle} required/>
              {formErrors.postalCode && <span style={errorStyle}>{formErrors.postalCode}</span>}
            </div>
            <div style={agreementStyle}>
              <p>By proceeding, you agree to be charged based on your estimates if you meet the required criteria.
              You will be notified in advance of any charges.</p>
              <p style={{ marginTop: '10px' }}>Your payment information will be securely processed by Swirepay, our trusted third-party payment processor. 
              Your card details are encrypted and stored according to PCI DSS standards.</p>
            </div>

            {error && <p style={errorStyle}>{error}</p>}
            
            <div style={buttonContainerStyle}>
              <button type="submit" style={buttonStyle} disabled={isLoading}>
                {isLoading ? 'Verifying...' : 'Verify Account'}
              </button>
              <button type="button" onClick={onClose} style={secondaryButtonStyle}>Cancel</button>
            </div>
          </form>
        )}
        {step === 3 && (
          <div>
            <p>Verification successful! You can now participate in group wishes.</p>
            <button onClick={onClose} style={buttonStyle}>Close</button>
          </div>
        )}
      </div>
    </div>  
  );
};


const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const modalContentStyle = {
  backgroundColor: style.Color.colorLightBeige,
  borderRadius: '8px',
  padding: '20px',
  width: '90%', 
maxWidth: '500px',
  maxHeight: '90vh',
  overflowY: 'auto',
};

const modalTitleStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  color: style.Color.colorRoyalBlueText,
  marginBottom: '20px',
  borderRadius: '8px',
};

const formStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  borderRadius: '8px',
}; 

const inputStyle = {
  padding: '10px',
  borderRadius: '8px',
  border: `1px solid ${style.Color.colorBlueGrey}`,
  fontSize: '16px',
};

const modalHeaderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    borderRadius: '8px',
  };

const closeButtonStyle = {
    backgroundColor: 'transparent',
    border: 'none',
    color: style.Color.colorDarkGrey,
    fontSize: '20px',
    cursor: 'pointer',
    marginLeft: '20px',
    borderRadius: '8px',
  };

const smallInputStyle = {
  ...inputStyle,
  width: '40%',
};

const rowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
};

const buttonContainerStyle = {
    display: 'flex', 
    marginTop: '20px',
    marginLeft: '25%',
    borderRadius: '8px',
  };

const buttonStyle = {
  backgroundColor: style.Color.colorPurple,
  color: style.Color.colorWhite,
  border: 'none',
  borderRadius: '8px',
  padding: '12px',
  fontSize: '16px',
  fontWeight: 'bold',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
};

const secondaryButtonStyle = {
    ...buttonStyle,  
    marginLeft: '30px',
  };
  
const agreementStyle = {
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
};

const errorStyle = {
  color: style.Color.colorVibrantRed,
  fontSize: '14px',
};


export default VerificationModal;
import * as style from "../../../GlobalStyles";
import React, { useState } from 'react';

const CreateTermsAndConditionsModal = ({ isOpen, onClose, onAgree }) => {
  const [agreed, setAgreed] = useState(false);

  const termsContent = `1. Overview
   To ensure a positive user experience and compliance with applicable laws and regulations, BacksApp has established the BacksApp GroupWish Policy. This policy applies to all GroupWishes created by users on BacksApp.

   As a user of BacksApp, you are responsible for ensuring that your GroupWishes comply with all applicable laws, regulations, industry standards, and the BacksApp GroupWish Policy. Please note that the BacksApp GroupWish Policy is not intended to serve as legal advice. If you have any questions regarding relevant laws or regulations, it is recommended that you consult a legal advisor.

   BacksApp will periodically update the GroupWish Policy. Please regularly review the latest version to ensure that your GroupWishes comply with the most up-to-date guidelines.

2. Basic Guidelines for GroupWishes
   2.a GroupWishes must have a clear and specific purpose, and users must provide accurate details about the products or services they wish to purchase.
   
   2.b Transparency: All GroupWishes must be transparent, providing clear information on the product, the supplier (if known), estimated costs, and any additional fees (e.g., shipping, taxes).
   
   2.c Non-Misleading: GroupWishes must not contain any misleading or deceptive information that could confuse or deceive other users.
   
   2.d Compliance with Laws: All GroupWishes must comply with applicable laws, regulations, and industry standards in the users' respective countries or jurisdictions.
   
   2.e No Offensive Content: GroupWishes must not include content that could cause offense, including but not limited to content related to race, nationality, age, disability, sexual orientation, gender, religion, or belief.
   
   2.f Intellectual Property: GroupWishes must not infringe on the intellectual property rights, privacy rights, or other legal rights of third parties.

3. Approval Process for GroupWishes
   3.a Review by BacksApp: All GroupWishes will undergo a review process by the BacksApp team. During this review, the details provided by the user will be assessed for accuracy, compliance, and feasibility.
   
   3.b Approval or Rejection: After review, BacksApp may approve or reject the GroupWish. If a GroupWish is approved, it will be made available to other users for participation. If rejected, the user will be notified with a reason for the rejection.
   
   3.c No Editing Post-Approval: Once a GroupWish has been approved by BacksApp, the details of the GroupWish, including product specifications, pricing, and other relevant information, cannot be edited by the user. Any necessary changes will require the creation of a new GroupWish and a new approval process.

4. Prohibited Content in GroupWishes
   4.a Illegal Products: GroupWishes must not involve products or services that are illegal or prohibited in any jurisdiction where the GroupWish participants are located.
   
   4.b Offensive Content: GroupWishes must not contain content that is offensive, discriminatory, or harmful to others.
   
   4.c Misleading Claims: GroupWishes must not make false or exaggerated claims about the product or service being purchased.
   
   4.d Violent or Explicit Content: GroupWishes must not include any violent, explicit, or otherwise inappropriate content.

5. Restricted Content in GroupWishes
   5.a Certain Products: Certain products may be restricted or require additional documentation for approval. For example, products that are regulated, such as medical devices, pharmaceuticals, or alcohol, may require proof of compliance with local laws and regulations.
   
   5.b Geographical Restrictions: GroupWishes involving products that are restricted in certain regions can only be created if the product is legally permitted in the participants' jurisdictions.

6. Intellectual Property and Third-Party Rights
   6.a Respect for IP Rights: GroupWishes must not infringe on the intellectual property rights of others. Users must ensure that they have the necessary rights or permissions to include specific products or trademarks in their GroupWish.
   
   6.b Reporting Violations: If you believe your intellectual property rights have been violated by a GroupWish, please contact BacksApp through the appropriate channels.
   
7. Liability for Violations
   7.a User Responsibility: Users are responsible for ensuring that their GroupWishes comply with all applicable laws, regulations, and the BacksApp GroupWish Policy.
   
   7.b Consequences for Violations: BacksApp reserves the right to remove or cancel any GroupWish that violates the GroupWish Policy or applicable laws. Repeated or severe violations may result in penalties, including the suspension or termination of the user's account.
   
   7.c Dispute Resolution: BacksApp encourages users to resolve disputes amicably. However, in cases of unresolved disputes, BacksApp reserves the right to make final decisions regarding the continuation or cancellation of a GroupWish.`;

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 className="modal-title">Terms and Conditions</h2>
        <div className="modal-body">
          {termsContent.split('\n').map((paragraph, index) => (
            <p key={index} className="terms-item">{paragraph}</p>
          ))}
        </div>
        <div className="modal-footer">
          <label className="checkbox-label">
            <input type="checkbox" checked={agreed} onChange={(e) => setAgreed(e.target.checked)}/>
            I agree to the Terms and Conditions
          </label>
          <div className="button-group">
            <button className="cancel-button" onClick={onClose}>Cancel</button>
            <button className="agree-button" onClick={onAgree} disabled={!agreed} >
              Agree
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = `
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .modal-content {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    max-width: 800px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
  }
  .modal-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: ${style.Color.colorRoyalBlueText};
  }
  .modal-body {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    color: ${style.Color.colorDarkGrey};
  }
  .terms-item {
    margin-bottom: 10px;
  }
  .modal-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: ${style.Color.colorPurple};
  }
  .checkbox-label {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 14px;
    color: ${style.Color.colorPurple};
  }
  .checkbox-label input {
    margin-right: 10px;
    color: ${style.Color.colorPurple};
  }
  .button-group {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .cancel-button, .agree-button {
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  .cancel-button {
    background-color: ${style.Color.colorPurpleBlue};
    color: ${style.Color.colorWhite}; 
    margin-right: 10px;
    border: none;
  }
  .agree-button {
    background-color: ${style.Color.colorLimeGreen};
    color: ${style.Color.colorPurpleBlue};;
    border: none;
  }
  .agree-button:disabled {
    background-color: ${style.Color.colorMint};
    cursor: not-allowed;
    color: ${style.Color.colorWhite};
    border: none;
  }
`;

// Add styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default CreateTermsAndConditionsModal;
import { getAuthToken,getAuthHeaders, refreshAuthToken } from "../InitialGears/authLoginUtils";
import Config from "../../../config";


export const buildUrl = (endpoint, queryParams = '') => {
  return `${Config.apiUrl}${endpoint}${queryParams ? `?${queryParams}` : ''}`;
};

export const getApi = async (url, options = {}, maxRetries = 2) => {
  let retryCount = 0;
  
  while (retryCount <= maxRetries) {
    try {
      const headers = await getAuthHeaders();
      const response = await fetch(url, { 
        ...options, 
        headers: { ...headers, ...options.headers }
      });

      if (response.ok) {
        const data = await response.json();
        return { ok: true, data };
      }

      if (response.status === 423 || response.status === 401) {
        await refreshAuthToken();
        retryCount++;
        continue;
      }

      const errorData = await response.json().catch(() => ({}));
      return { ok: false, status: response.status, data: errorData };
    } catch (error) {
      if (retryCount === maxRetries) {
        return { ok: false, error: error.message };
      }
      retryCount++;
    }
  }
};

export const postApi = async (url, body, options = {}, maxRetries = 2, isFormData = false) => {
  let retryCount = 0;
  
  while (retryCount <= maxRetries) {
    try {
      const headers = await getAuthHeaders();
      
      if (isFormData) {
        delete headers['Content-Type'];
      }

      const requestOptions = {
        method: 'POST',
        headers: { ...headers, ...options.headers },
        mode: 'cors',
        credentials: 'omit',
        body: isFormData ? body : JSON.stringify(body),
        ...options
      };

      const response = await fetch(url, requestOptions);

      if (response.ok) {
        const data = await response.json();
        return { ok: true, data };
      }

      if (response.status === 423 || response.status === 401) {
        await refreshAuthToken();
        retryCount++;
        continue;
      }

      const errorData = await response.json().catch(() => ({}));
      return { ok: false, status: response.status, data: errorData };
    } catch (error) {
      if (retryCount === maxRetries) {
        return { ok: false, error: error.message };
      }
      retryCount++;
    }
  }
};
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../Common/InitialGears/AuthContext';
import { getApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import * as style from "../../../GlobalStyles";
import { TopMenuBar } from '../DashbordComponents/TopMenuBar';

const CreditDetailsPage = () => {
  const { queryLoginParam } = useAuth();
  const navigate = useNavigate();
  const [creditInfo, setCreditInfo] = useState({
    totalEarned: 0,
    redeemed: 0,
    balance: 0,
    upcoming: 0,
  });
  const [detailedSummary, setDetailedSummary] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('totalEarned');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDetailExpanded, setIsDetailExpanded] = useState(true);

  useEffect(() => { 
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const creditUrl = buildUrl('userCredit', queryLoginParam);
      const creditResponse = await getApi(creditUrl);
      
      if (creditResponse.ok) {
        setCreditInfo(creditResponse.data);
        
        // Fetch totalEarned details
        const totalEarnedUrl = buildUrl('totalEarned', queryLoginParam);
        const totalEarnedResponse = await getApi(totalEarnedUrl);
        
        if (totalEarnedResponse.ok) {
          setDetailedSummary(totalEarnedResponse.data);
        } else {
          setError('Failed to fetch total earned details. Please try again.');
        }
      } else {
        setError('Failed to fetch credit details. Please try again.');
      }
    } catch (error) {
      setError('An error occurred while fetching data.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategoryClick = (category) => {
    if (category !== 'balance') {
      setSelectedCategory(category);
      setIsDetailExpanded(true);
      if (category !== 'totalEarned') {
        fetchDetailedSummary(category);
      }
    }
  };

  const toggleDetailExpansion = () => {
    setIsDetailExpanded(!isDetailExpanded);
  };

  const fetchDetailedSummary = async (category) => {
    setIsLoading(true);
    setError(null);
    try {
      const summaryUrl = buildUrl(`${category}`, queryLoginParam);
      const response = await getApi(summaryUrl);
      
      if (response.ok) {
        setDetailedSummary(response.data);
      } else {
        setError('Failed to fetch detailed summary. Please try again.');
      }
    } catch (error) {
      setError('An error occurred while fetching detailed summary.');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div style={loadingStyle}>Loading credit details...</div>;
  }

  if (error) {
    return <div style={errorStyle}>{error}</div>;
  }

  return (
    <div>
        <TopMenuBar showBackButton={false} showHomeButton={true} onBackClick={() => navigate(-1)} title={'Details'} showMenuButton={true} showSearch={false} showAddress={false} /> 
        <div style={pageStyle}>
        <h1 style={titleStyle}>Credit Details</h1>
        
        <div style={infoBoxStyle}>
            <h2 style={subtitleStyle}>How to Earn Credits</h2>
            <ul style={listStyle}>
                <li>Invite a new user to BacksApp: Earn 100 points when they make their first purchase</li>
                <li>Refer a user to join a deal: Earn 0.5% of the deal value (max 50 points) when the deal lifecycle ends</li>
            </ul>
        </div>
        
        <div style={balanceStyle}>
          <span style={balanceLabelStyle}>Current Balance:</span>
          <span style={balanceValueStyle}>{creditInfo.balance} points</span>
        </div>

        <div style={creditSummaryStyle}>
          {Object.entries(creditInfo).map(([key, value]) => {
            if (key !== 'balance') {
              return (
                <div key={key} style={creditItemStyle} onClick={() => handleCategoryClick(key)}>
                  <h3 style={creditLabelStyle}>
                    {key === 'upcoming' ? (
                      <>
                        Upcoming Credits
                        <FontAwesomeIcon icon={faInfoCircle} style={infoIconStyle} title="Credits from ongoing deals that haven't completed their lifecycle yet" />
                      </>
                    ) : (
                      key.charAt(0).toUpperCase() + key.slice(1)
                    )}
                  </h3>
                  <p style={creditValueStyle}>{value} points</p>
                </div>
              );
            }
            return null;
          })}
        </div>

        <div style={noteStyle}>
            <p>Note: You can use up to 10% of your credit balance when joining a group wish.</p>
        </div>
        
        <div style={detailSummaryStyle}>
          <h2 style={subtitleStyle}>
            {selectedCategory.charAt(0).toUpperCase() + selectedCategory.slice(1)} Details
            <FontAwesomeIcon icon={isDetailExpanded ? faChevronUp : faChevronDown} style={expandIconStyle} onClick={toggleDetailExpansion}/>
          </h2>
          {isDetailExpanded && (
              <table style={tableStyle}>
                <thead>
                  <tr>
                    <th style={tableHeaderStyle}>Date</th>
                    {selectedCategory === 'redeemed' ? (
                      <>
                        <th style={tableHeaderStyle}>Purchase Item</th>
                        <th style={tableHeaderStyle}>Points Redeemed</th>
                      </>
                    ) : (
                      <>
                        <th style={tableHeaderStyle}>Referred User</th>
                        <th style={tableHeaderStyle}>Credit Allocated</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {detailedSummary.map((item, index) => (
                    <tr key={index} style={index % 2 === 0 ? tableRowEvenStyle : tableRowOddStyle}>
                      <td style={tableCellStyle}>{new Date(item.date).toLocaleDateString()}</td>
                      {selectedCategory === 'redeemed' ? (
                        <>
                          <td style={tableCellStyle}>{item.purchaseItem}</td>
                          <td style={tableCellStyle}>{item.pointsRedeemed} points</td>
                        </>
                      ) : (
                        <>
                          <td style={tableCellStyle}>{item.referredUser}</td>
                          <td style={tableCellStyle}>{item.creditAllocated} points</td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div> 
      </div>
    );
  };

 
const pageStyle = {
  maxWidth: '1000px',
  margin: '0 auto',
  marginTop: '20px',
  padding: '20px',
  backgroundColor: style.Color.colorLightBeige,
  borderRadius: '10px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
};

const titleStyle = {
  fontSize: '28px',
  fontWeight: 'bold',
  color: style.Color.colorRoyalBlueText,
  marginBottom: '20px',
  textAlign: 'center',
  borderRadius: '15px',
};

const infoBoxStyle = {
  backgroundColor: style.Color.colorLightYellow,
  border: `1px solid ${style.Color.colorYellow}`,
  borderRadius: '15px',
  padding: '15px',
  marginBottom: '20px',
};

const subtitleStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
  color: style.Color.colorDarkBlue,
  marginBottom: '10px',
  borderRadius: '15px',
};

const listStyle = {
  paddingLeft: '20px',
  color: style.Color.colorDarkGrey,
};

const balanceStyle = {
    backgroundColor: style.Color.colorPurpleBlue,
    borderRadius: '15px',
    padding: '15px',
    marginBottom: '20px',
    textAlign: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  };
  
  const balanceLabelStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
    color: style.Color.colorWhite,
    marginRight: '10px',
  };
  
  const balanceValueStyle = {
    fontSize: '28px',
    fontWeight: 'bold',
    color: style.Color.colorWhite,
  };

const creditSummaryStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  gap: '20px',
  marginBottom: '20px',
  borderRadius: '15px',
};

const creditItemStyle = {
  backgroundColor: style.Color.colorWhite,
  borderRadius: '15px',
  padding: '15px',
  textAlign: 'center',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
};

const creditLabelStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  color: style.Color.colorDarkGrey,
  marginBottom: '5px',
};

const creditValueStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  color: style.Color.colorPurple,
};

const infoIconStyle = {
  marginLeft: '5px',
  fontSize: '16px',
  color: style.Color.colorBlueGrey,
  cursor: 'help',
};

const noteStyle = {
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
  fontStyle: 'italic',
};

const loadingStyle = {
  textAlign: 'center',
  fontSize: '18px',
  color: style.Color.colorDarkGrey,
  marginTop: '50px',
};

const errorStyle = {
  textAlign: 'center',
  fontSize: '18px',
  color: style.Color.colorVibrantRed,
  marginTop: '50px',
};

const detailSummaryStyle = {
    marginTop: '20px',
    backgroundColor: style.Color.colorWhite,
    borderRadius: '15px',
    padding: '15px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  };
  
  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '10px',
  };
  
  const tableHeaderStyle = {
    backgroundColor: style.Color.colorLightGrey,
    color: style.Color.colorDarkGrey,
    padding: '10px',
    textAlign: 'left',
  };
  
  const tableRowEvenStyle = {
    backgroundColor: style.Color.colorWhite,
  };
  
  const tableRowOddStyle = {
    backgroundColor: style.Color.colorLightBeige,
  };
  
  const tableCellStyle = {
    padding: '10px',
    borderBottom: `1px solid ${style.Color.colorLightGrey}`,
  };
  
  const expandIconStyle = {
    marginLeft: '10px',
    cursor: 'pointer',
  };
  

export default CreditDetailsPage;
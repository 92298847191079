import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faShippingFast, faExclamationTriangle, faDollarSign, faShippingFast as faShipping, faPercent,faCheck } from '@fortawesome/free-solid-svg-icons';
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import { useAuth } from '../../Common/InitialGears/AuthContext';
import { TopMenuBar } from '../DashbordComponents/TopMenuBar';
import * as style from "../../../GlobalStyles";

const statusOptions = [
  { value: "POOL_OPEN", label: "Open for pooling"},
  { value: "MIN_MET", label: "Minimum Met" },
  { value: "SEEKING_SELLER", label: "Seeking Seller" },
  { value: "DEAL_UNDER_DISCUSSION", label: "Deal Under Discussion" },
  { value: "PROPOSAL_REVIEW_READY", label: "Proposal Ready for Review" },
  { value: "PROPOSAL_AGREED", label: "Proposal Agreed" },
  { value: "PROPOSAL_ACCEPTED", label: "Proposal Accepted" },
  { value: "DEAL_ACTIVATED", label: "Deal activated" },
  { value: "PAYMENT_INITATED", label: "Payment Initated" },
  { value: "ALL_PAYMENTS_COMPLETED", label: "All Payments Completed" },
  { value: "ORDER_SHIPPED_FROM_MANUFACTURER", label: "Order Shipped from Manufacturer" },
  { value: "GOODS_AT_PORT", label: "Goods at port" },
  { value: "GOODS_CUSTOM_CLEARED", label: "Goods Custom Cleared" },
  { value: "INLAND_FRIEGHT", label: "Inland Frieght" },
  { value: "REPACKING", label: "Repacking" },
  { value: "SHIPPED_TO_DELIVERY", label: "Shipped to Delivery" },
  { value: "DELIVERING", label: "Delivering" },
  { value: "DEAL_COMPLETED", label: "Deal Completed" },
];

const editableStatusIndex = statusOptions.findIndex(status => status.value === "PROPOSAL_ACCEPTED");

const OrderProcessing = () => {
  const { groupWishId } = useParams();
  const { queryLoginParam } = useAuth();
  const navigate = useNavigate();
  const [groupWish, setGroupWish] = useState(null);
  const [groupWishUsers, setGroupWishUsers] = useState(null);
  const [currentStatus, setCurrentStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [closingOrderCount, setClosingOrderCount] = useState('');
  const [closingProductCost, setClosingProductCost] = useState('');
  const [closingShippingCost, setClosingShippingCost] = useState('');
  const [closingBacksAppCharges, setClosingBacksAppCharges] = useState('');
  const [selectedPriceRange, setSelectedPriceRange] = useState(null);

  useEffect(() => {
    fetchGroupWishDetails();
  }, [groupWishId]);

  const fetchGroupWishDetails = async () => {
    setIsLoading(true);
    try {
      const url = buildUrl(`groupWishesOrderProcessing/${groupWishId}/`, queryLoginParam);
      const response = await getApi(url);
      if (response) { 
        setGroupWish(response.data.group_wish);
        setGroupWishUsers(response.data);
        setCurrentStatus(response.data.group_wish.group_wish_status); 
        setClosingOrderCount(response.data.closing_order_count || '');
        setClosingProductCost(response.data.closing_product_cost || '');
        setClosingShippingCost(response.data.closing_shipping_cost || '');
        setClosingBacksAppCharges(response.data.closing_backsapp_charges || '');
        if (response.data.selected_price_range !== undefined) {
            setSelectedPriceRange(response.data.selected_price_range);
          }
      } else {
        setErrorMessage('Failed to fetch group wish details');
      }
    } catch (error) {
      setErrorMessage('An error occurred while fetching group wish details');
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusChange = async (newStatus) => {
    if (selectedPriceRange === null) {
        setErrorMessage('Please select a price range before updating');
        return;
      } 
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    try {
      const url = buildUrl(`updateGroupWishOrderStatus/${groupWishId}/`, queryLoginParam);
      const response = await postApi(url, { 
        status: newStatus,
        closing_order_count: closingOrderCount,
        closing_product_cost: closingProductCost,
        closing_shipping_cost: closingShippingCost,
        closing_backsapp_charges: closingBacksAppCharges,
        selected_price_range: selectedPriceRange
      });
      if (response) {
        setCurrentStatus(newStatus);
        setSuccessMessage('Status, details, and price range updated successfully');
        fetchGroupWishDetails();
      } else {
        setErrorMessage('Failed to update status and details');
      }
    } catch (error) {
      setErrorMessage('An error occurred while updating status and details');
    } finally {
      setIsLoading(false);
    }
  };

  const isEditable = () => {
    const currentStatusIndex = statusOptions.findIndex(status => status.value === currentStatus);
    return currentStatusIndex <= editableStatusIndex;
  };

  const renderInputField = (label, value, setter) => {
    const editable = isEditable();
    return (
      <div className="input-field-container">
        <label className="input-label">{label}</label>
        <input type="number" value={value} onChange={(e) => setter(e.target.value)} className={`input-field ${!editable ? 'read-only' : ''}`} readOnly={!editable}/>
      </div>
    );
  };

  const calculateTotalQuantity = (users) => {
    return users.reduce((total, user) => total + user.quantity, 0);
  };

  const renderUserList = (users, title, icon, showDetails = true) => {
    const totalQuantity = calculateTotalQuantity(users);
    
    return (
      <div className="user-list-container">
        <h3 className="user-list-title">
          <FontAwesomeIcon icon={icon} /> {title}
        </h3>
        <p className="user-quantity">Total Users: {users.length}</p>
        <p className="user-quantity">Total Quantity: {totalQuantity}</p>
        {showDetails && users.length > 0 ? (
          <ul className="user-list">
            {users.map((user, index) => (
              <li key={index} className="user-item">
                <div className="user-details">
                  <span className="user-name">{`${user.user.first_name} ${user.user.last_name}`}</span>
                  <span className="user-email">{user.user.email}</span>
                </div>
                <span className="user-quantity">Qty: {user.quantity}</span>
              </li>
            ))}
          </ul>
        ) : (
          !showDetails && <p className="no-details"></p>
        )}
      </div>
    );
  };

  const handlePriceRangeSelection = (rangeId) => {
    setSelectedPriceRange(rangeId);
  };

  const renderPriceRanges = () => {
    if (!groupWish || !groupWish.price_ranges || groupWish.price_ranges.length === 0) {
      return null;
    }

    return (
      <div className="card">
        <div className="card-content">
          <h3 className="card-title">Select Price Ranges</h3>
          <div className="price-ranges-container">
            {groupWish.price_ranges.map((range) => (
              <div key={range.id} className={`price-range-item ${selectedPriceRange === range.id ? 'selected' : ''}`} onClick={() => handlePriceRangeSelection(range.id)}>                 
                <div className="price-range-header">
                  <input type="radio" name="priceRange" checked={selectedPriceRange === range.id} onChange={() => handlePriceRangeSelection(range.id)}/>
                  <FontAwesomeIcon icon={faUsers} /> 
                  <span>{range.price_range_start} - {range.price_range_end} orders</span>
                </div>
                <div className="price-range-details">
                  <div className="price-detail">
                    <FontAwesomeIcon icon={faDollarSign} />
                    <span>Price: ${range.range_price}</span>
                  </div>
                  <div className="price-detail">
                    <FontAwesomeIcon icon={faShipping} />
                    <span>Shipping: ${range.shipping_cost_for_range}</span>
                  </div>
                  {range.backsApp_service_charges && (
                    <div className="price-detail">
                      <FontAwesomeIcon icon={faPercent} />
                      <span>BacksApp Charges: ${range.backsApp_service_charges}</span>
                    </div>
                  )}
                </div>
                <div className="price-range-footer">
                  {range.negotiated ? "Negotiated" : "Not Negotiated"}
                </div>
                {selectedPriceRange === range.id && (
                  <div className="selected-indicator">
                    <FontAwesomeIcon icon={faCheck} />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <div className="loading-container"><div className="spinner"></div></div>;
  }

  

  if (!groupWish) {
    return <div className="error-message">Group wish not found</div>;
  }

  return (
    <>
    <TopMenuBar showBackButton={true} showHomeButton={true} onBackClick={() => navigate('/orderManagement')} title="Order Processing" showSearch={false} showAddress={false} showMenuButton={true}/>
    <div className="order-processing-container">            
      <div className="content-wrapper">        
            <div className="left-column">
            <div className="card product-info-card">
                <div className="card-content">
                <h2 className="card-name-title">{groupWish.product_name}</h2>
                {successMessage && <div className="success-message">{successMessage}</div>}
                    <div className="product-details">
                        <p><strong>Current Status:</strong> {currentStatus}</p>
                        <p><strong>Group Size:</strong> {groupWish.group_size_min}</p>
                        <p><strong>Expiry Date:</strong> {new Date(groupWish.expires_at).toLocaleDateString()}</p>
                    </div>
                </div>
            </div>
            <div className="card">
              <div className="card-content">
                <h3 className="card-title">Update Status and Details</h3>
                <select value={currentStatus} onChange={(e) => setCurrentStatus(e.target.value)} className="select-status">
                    {statusOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                    ))}
                </select>
                {renderPriceRanges()} 
                <button onClick={() => handleStatusChange(currentStatus)} className="update-button">
                    Update Status and Details
                </button>
                </div>
              </div>
            </div>
            <div className="right-column">
            {renderUserList(groupWishUsers.verified_users, "Verified Users", faUsers)}
            {renderUserList(groupWishUsers.unverified_users, "Unverified Users", faExclamationTriangle)}
            {renderUserList(groupWishUsers.all_users, "All Group Wish Buddies", faShippingFast,false)}
            </div> 
      </div>
    </div> 
    </>   
  );  
};

const styles = `
  .order-processing-container {
    font-family: Arial, sans-serif;
    background-color: ${style.Color.colorLightBeige};
    min-height: 100vh;
  }

  .content-wrapper {
    display: flex;
    gap: 20px;
    padding: 20px;
  }

  .left-column {
    flex: 2;
  }

  .right-column {
    flex: 1;
  }

  .card {
    background-color: ${style.Color.colorWhite};
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .card-content {
    padding: 20px;
  }

  .card-name-title {
    font-size: 24px;
    font-weight: bold;
    color: ${style.Color.colorPurple};
    margin-bottom: 15px;
  }

  .message-container {
    position: fixed;
    top: 100;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    padding-top: 40px;
    z-index: 999;
  }

  .success-message {
    color: #4CAF50;
    font-size: 16px;
    text-align: center;
    margin: 0;
  }

  .error-message {
    color: #F44336;
    font-size: 16px;
    text-align: center;
    margin: 0;
  }
  .card-title {
    font-size: 20px;
    font-weight: bold;
    color: ${style.Color.colorDarkBlue};
    margin-bottom: 15px;
  }

  .product-details p {
    margin-bottom: 10px;
  }

  .select-status {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid ${style.Color.colorLightGrey};
    border-radius: 4px;
    font-size: 16px;
  }

  .input-field-container {
    margin-bottom: 15px;
  }

  .input-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: ${style.Color.colorDarkBlue};
  }

  .input-field {
    width: 100%;
    padding: 10px;
    border: 1px solid ${style.Color.colorLightGrey};
    border-radius: 4px;
    font-size: 16px;
  }

  .input-field.read-only {
    background-color: ${style.Color.colorLightestBeige};
    color: ${style.Color.colorDarkGrey};
  }

  .update-button {
    background-color: ${style.Color.colorMintGreen};
    color: white;
    font-weight: bold;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .update-button:hover {
    background-color: ${style.Color.colorDarkMintGreen};
  }

  .success-message {
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
  }

  .error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid ${style.Color.colorPurple};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .user-list-container {
    background-color: ${style.Color.colorWhite};
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }

  .user-list-title {
    font-size: 18px;
    font-weight: bold;
    color: ${style.Color.colorDarkBlue};
    margin-bottom: 15px;
  }

  .user-list {
    list-style-type: none;
    padding: 0;
  }

  .user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid ${style.Color.colorLightGrey};
  }

  .user-details {
    display: flex;
    flex-direction: column;
  }

  .user-name {
    font-weight: bold;
    color: ${style.Color.colorDarkBlue};
  }

  .user-address {
    font-size: 14px;
    color: ${style.Color.colorDarkGrey};
  }

  .user-quantity {
    font-weight: bold;
    color: ${style.Color.colorPurple};
  }

  .no-users {
    color: ${style.Color.colorDarkGrey};
    font-style: italic;
  }

  .price-ranges-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  .price-range-item {
    background-color: ${style.Color.colorLightestBeige};
    border-radius: 8px;
    padding: 15px;
    width: calc(50% - 7.5px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .price-range-header {
    font-weight: bold;
    margin-bottom: 10px;
    color: ${style.Color.colorDarkBlue};
  }

  .price-range-details {
    margin-bottom: 10px;
  }

  .price-detail {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .price-detail svg {
    margin-right: 10px;
    color: ${style.Color.colorMintGreen};
  }

  .price-range-footer {
    font-style: italic;
    color: ${style.Color.colorDarkGrey};
  }
`;

// Add styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default OrderProcessing;
import React, { useState, useEffect, useCallback , useRef} from 'react';
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import { useNavigate, useLocation } from 'react-router-dom'; 
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAuth } from '../../Common/InitialGears/AuthContext'; 
import * as style from "../../../GlobalStyles";   
import { faUpload,faTrash ,faPlus} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import {TopMenuBar} from '../DashbordComponents/TopMenuBar';
import DefinePreferences from './DefinePreferences';
import ApprovalModal from './ApprovalModalComponent';

const LabeledInput = ({ label, value, onChange, placeholder, type, multiline, style: propStyle, error }) => (
  <div style={labeledInputContainerStyle}>
    <label style={inputLabelStyle}>{label}</label>
    {multiline ? (
      <textarea value={value} onChange={(e) => onChange(e.target.value)} placeholder={placeholder} style={{...inputStyle, ...textareaStyle, ...(error ? inputErrorStyle : {}), ...propStyle}}/>
    ) : (
      <input type={type} value={value} onChange={(e) => onChange(e.target.value)} placeholder={placeholder} style={{...inputStyle, ...(error ? inputErrorStyle : {}), ...propStyle}}/>
    )}
    {error && <span style={errorTextStyle}>{error}</span>}
  </div>
);

const CreateGroupWish = () => { 
  const { queryLoginParam, idTokenLoggedIn ,isBacksAppManager } = useAuth();  
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate(); 
  let draftWish = location.state?.draftWish;  
  const createNew = location.state?.createNew || false;
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPreferencesModal, setShowPreferencesModal] = useState(false); 
  const [existingPreferences, setExistingPreferences] = useState([]);
  const [createdGroupId, setCreatedGroupId] = useState(null);
  const [categories, setCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [userVerified, setUserVerified] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [errors, setErrors] = useState({}); 
  const [priceRangesChanged, setPriceRangesChanged] = useState(false);
  const [imageChanged, setImageChanged] = useState(false);
  const [formRequestBody, setFormRequestBody]= useState('');
  const [formData, setFormData] = useState({
    productName: draftWish?.product_name || '',
    dealTitle: draftWish?.deal_title || '',
    listPrice: draftWish?.list_price?.toString() || '',
    description: draftWish?.description || '',
    singlePrice: draftWish?.list_price?.toString() || '',
    marketLink: draftWish?.store_seller || '',
    groupSizeMin: draftWish?.group_size_min?.toString() || '',
    storeSeller: draftWish?.store_seller || '',
    expiresAt: draftWish?.expires_at ? new Date(draftWish.expires_at) : null,
    note: '',
    priceRanges: draftWish?.price_ranges?.length > 0 
      ? draftWish.price_ranges.map(range => ({
        minQuantity: range.minQuantity?.toString() || '',
        maxQuantity: range.maxQuantity?.toString() || '',
        price: range.rangePrice?.toString() || '',
        shippingCost: range.shippingCost_for_range?.toString() || '',
        negotiated: range.negotiated || false,
        additionalComments: range.additional_comments || ''
        }))
      : [{ minQuantity: '', maxQuantity: '', price: '', shippingCost: '', negotiated: false, additionalComments: '' }]
  });

  const initialFormState = {
    productName: '',
    dealTitle: '',
    description: '',
    singlePrice: '',
    marketLink: '',
    storeSeller: '',
    groupSizeMin: '',
    expiresAt: null,
    note: '',
    priceRanges: [{ minQuantity: '', maxQuantity: '', price: '', shippingCost: '', negotiated: false, additionalComments: '' }]
  };
  
  const initialState = {
    formData: initialFormState,
    selectedCategory: '',
    selectedSubCategories: [],
    selectedImage: null,
    imagePreview: null,
    errors: {},
    isFormValid: false,
    createdGroupId: null,
    existingPreferences: [],
    successMessage: null,
    errorMessage: null,
  };

  const resetForm = () => {
    setFormData(initialFormState);
    setSelectedCategory('');
    setSelectedSubCategories([]);
    setSelectedImage(null);
    setImagePreview(null);
    setErrors({});
    setIsFormValid(false);
    setCreatedGroupId(null);
    setExistingPreferences([]);
    setSuccessMessage(null);
    setErrorMessage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  useEffect(() => { 
    if (createNew){ 
      resetForm();
    }
    fetchCategories();
    fetchSubCategories();
  }, [location]);

  useEffect(() => {
    if (draftWish && categories.length > 0) {
      if (draftWish.image) {
        setImagePreview(draftWish.image);
      }
      if (draftWish.category && draftWish.category.length > 0) {
        const categoryName = draftWish.category[0];
        const category = categories.find(cat => cat.name === categoryName);
        if (category) {
          setSelectedCategory(category.id.toString());
        }
      }
      if (draftWish.sub_category && draftWish.sub_category.length > 0) {
        setSelectedSubCategories(draftWish.sub_category.map(sc => sc.toString()));
      }
      setCreatedGroupId(draftWish.id);
      validateForm();
    }
  }, [draftWish, categories]);

  useEffect(() => { 
    if (selectedCategory && categories.length > 0 && allSubCategories.length > 0) { 
      const selectedCategoryData = categories.find(cat => 
        cat.id.toString() === selectedCategory || cat.name === selectedCategory
      );
      if (selectedCategoryData && selectedCategoryData.sub_category) {
        const filteredSubs = allSubCategories.filter(sub => 
          selectedCategoryData.sub_category.includes(sub.id)
        );
        setFilteredSubCategories(filteredSubs); 
      } else {
        setFilteredSubCategories([]);
      }
    }
  }, [selectedCategory, categories, allSubCategories]);

  useEffect(() => {
    if (draftWish ){
      if (draftWish.preferences_defined) {
      setExistingPreferences(draftWish.preferences_defined);
      }else if (draftWish.preferences_defined === undefined || draftWish.preferences_defined === null) { 
        setExistingPreferences([]); 
      } else { 
        setExistingPreferences([]); 
      }
    } else { 
      setExistingPreferences([]);
    }
  }, [draftWish]);

  const fetchCategories = async () => {
    setIsLoading(true);
    try {
      const categoriesUrl = buildUrl('getCategories', queryLoginParam);
      const response = await getApi(categoriesUrl);
      if (response.ok) {
        setCategories(response.data);
      }  
    } catch (error) {
     // console.error('Error fetching categories:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDraftWishes = useCallback(async () => {
    setIsLoading(true);
    try {
      setRefreshing(true);
      const draftWishesUrl = buildUrl('myGroupWishes', `${queryLoginParam}&type=draft`);
      const response = await getApi(draftWishesUrl);
      if (response.ok) {
        const data = response.data;
        setUserVerified(data.is_verified_user);
        // ... (handle draft wishes data)
      } else {
        if (response.status === 423) {
          setErrorMessage('Your session has expired. Please log in again.');
          navigate("/");
        }
      }
    } catch (error) {
      //console.error('Error fetching draft wishes:', error);
    } finally {
      setRefreshing(false);
      setIsLoading(false);
    }
  }, [queryLoginParam, navigate]);

  const fetchSubCategories = async () => {
    setIsLoading(true);
    try {
      const subCategoriesUrl = buildUrl('getSubCategories', queryLoginParam);
      const response = await getApi(subCategoriesUrl);
      if (response.ok) { 
        setAllSubCategories(response.data || []);
      } else {
        if (response.status === 426) {
          setErrorMessage('Your session has expired. Please log in again.');
          navigate("/");
        }
        setAllSubCategories([]);
      }
    } catch (error) { 
      setAllSubCategories([]);
    } finally {
      setIsLoading(false);
    }
  };


  const handleCategoryChange = (event) => {
    const categoryId = event.target.value;
    setSelectedCategory(categoryId);
    setSelectedSubCategories([]);
    const selectedCategoryData = categories.find(cat => 
      cat.id.toString() === categoryId || cat.name === categoryId
    );
    if (selectedCategoryData && selectedCategoryData.sub_category) {
      const filteredSubs = allSubCategories.filter(sub => 
        selectedCategoryData.sub_category.includes(sub.id)
      );
      setFilteredSubCategories(filteredSubs);
    } else {
      setFilteredSubCategories([]);
    }
  };

  const handleSubCategoryChange = (event) => {
    const value = Array.from(event.target.selectedOptions, option => option.value)
      .filter(v => v !== undefined && v !== null && v !== '');
    setSelectedSubCategories(value);
  };

  const handleDateChange = (date) => { 
    setFormData(prevData => {
      const updatedData = {
        ...prevData,
        expiresAt: date
      }; 
      return updatedData;
    });
  };

  const validateForm = useCallback(() => {
    let newErrors = {};
    let isValid = true;

    if (!formData.productName || formData.productName.length < 3) {
      newErrors.productName = 'Product name must be at least 3 characters';
      isValid = false;
    }

    if (!formData.dealTitle || !isValidURL(formData.dealTitle)) {
      newErrors.dealTitle = 'Please enter a valid URL for the deal';
      isValid = false;
    }

    if (formData.description && formData.description.length > 500) {
      newErrors.description = 'Description must be 500 characters or less';
      isValid = false;
    }

    if (!formData.groupSizeMin || parseInt(formData.groupSizeMin) < 1) {
      newErrors.groupSizeMin = 'Please enter a valid group size (minimum 1)';
      isValid = false;
    }

    if (formData.expiresAt && formData.expiresAt <= new Date()) {
      newErrors.expiresAt = 'Expiration date must be in the future';
      isValid = false;
    }

    // Validate price ranges
    let previousMax = 0;
    newErrors.priceRanges = [];
    formData.priceRanges.forEach((range, index) => {
      const min = parseInt(range.minQuantity);
      const max = parseInt(range.maxQuantity);
      const price = parseFloat(range.price);

      if (isNaN(min) || isNaN(price) || (max !== Infinity && isNaN(max))) {
        newErrors.priceRanges[index] = 'All fields must be valid numbers';
        isValid = false;
      } else if (min <= previousMax) {
        newErrors.priceRanges[index] = 'Min quantity must be greater than previous max';
        isValid = false;
      } else if (max !== Infinity && max <= min) {
        newErrors.priceRanges[index] = 'Max quantity must be greater than min quantity';
        isValid = false;
      } else if (price <= 0) {
        newErrors.priceRanges[index] = 'Price must be greater than 0';
        isValid = false;
      }

      previousMax = max;
    });

    setErrors(newErrors);
    setIsFormValid(isValid);
    return isValid;
  }, [formData]);

  const handleInputChange = (name, value) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handlePriceRangeChange = (index, field, value) => {
    setFormData(prevData => {
      const updatedRanges = [...prevData.priceRanges];
      updatedRanges[index] = { ...updatedRanges[index], [field]: value };
      setPriceRangesChanged(true);
      return { ...prevData, priceRanges: updatedRanges };
    });
  };

  const addPriceRange = () => {
    setFormData(prevData => ({
      ...prevData,
      priceRanges: [...prevData.priceRanges, { minQuantity: '', maxQuantity: '', price: '' , shippingCost: '', negotiated: false, additionalComments: '' }]
    }));
    setPriceRangesChanged(true);
  };

  const removePriceRange = (index) => {
    if (formData.priceRanges.length > 1) {
      setFormData(prevData => ({
        ...prevData,
        priceRanges: prevData.priceRanges.filter((_, i) => i !== index)
      }));
      setPriceRangesChanged(true);
    }
  };

  const isValidURL = (string) => {
    setSuccessMessage(null);
    setErrorMessage(null);
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setImageChanged(true);
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const removeImage = () => {
    setSelectedImage(null);
    setImagePreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setImageChanged(true);
  };

  const saveDraft = async () => { 
    setSuccessMessage("");
    setErrorMessage("");
    if (!validateForm()) {
      return;
    }    
    setIsLoading(true);   
    const grpWishURL = draftWish && draftWish.id
      ? buildUrl(`updateGroupWish/${draftWish.id}/`, queryLoginParam)
      : buildUrl('createGroupWish', queryLoginParam);
    
    let formDataToSend = new FormData();  
    
    const subCategoryData = selectedSubCategories.map(subCat => 
      typeof subCat === 'object' ? (subCat.id || subCat.name) : subCat
    );

    const requestBody = {
      productName: formData.productName,
      dealTitle: formData.dealTitle,
      description: formData.description,
      listPrice: formData.singlePrice ? parseFloat(formData.singlePrice) : undefined,
      storeSeller: formData.storeSeller,
      groupSizeMin: parseInt(formData.groupSizeMin) || null,
      expiresAt: formData.expiresAt ? formData.expiresAt.toISOString() : null,
      note: formData.note,
      priceRangesChanged: priceRangesChanged,
      imageChanged: imageChanged,
      category: selectedCategory,
      subCategory: JSON.stringify(subCategoryData),
      priceRanges: JSON.stringify(formData.priceRanges.map(range => ({
        minQuantity: range.minQuantity ? parseInt(range.minQuantity) : null,
        maxQuantity: range.maxQuantity ? parseInt(range.maxQuantity) : null,
        rangePrice: range.price ? parseFloat(range.price) : null,
        shippingCost_for_range: range.shippingCost ? parseFloat(range.shippingCost) : null,
        negotiated: range.negotiated,
        additional_comments: range.additionalComments
      })))
    }; 
       

    if (draftWish && draftWish.id) {
      requestBody.id = draftWish.id;
    }
  
    Object.keys(requestBody).forEach(key => {
      formDataToSend.append(key, requestBody[key]);
    });
  
    if (selectedImage) {
      formDataToSend.append('image', selectedImage);
    }

    if (imageChanged && selectedImage) {
      formDataToSend.append('image', selectedImage);
    }
      
    try {    
      let response;
      if (selectedImage) { 
        const formData = new FormData();
        formDataToSend.append('data', JSON.stringify(requestBody));
        formDataToSend.append('image', selectedImage); 
        response = await postApi(grpWishURL, formDataToSend, {}, 2, true);
      } else {  
        response = await postApi(grpWishURL, requestBody, {}, 2, false);
      }
     
      setFormData(prevData => ({
          ...prevData,
          ...response.data
        }));
      setCreatedGroupId(response.id);
      if (response.preferences_defined) {
          setExistingPreferences(response.preferences_defined);
        }
     
      setSuccessMessage(`Saved ${draftWish ? 'updates on ' : 'the draft for'} "${formData.productName}"`);        
      setPriceRangesChanged(false);
      setImageChanged(false);
      return response.data;             
    } catch (error) {   
      setErrorMessage('Failed to save the draft. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDraft = () => {       
    saveDraft();   
  };

  const handleApprove = async() => {
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
    let currentDraft = createdGroupId ? { id: createdGroupId } : null;
    if (!currentDraft) { 
      currentDraft = await saveDraft();
    }
    
    if (currentDraft && currentDraft.id) {
      setShowApprovalModal(true);
    } else {
      setErrorMessage('Failed to create or retrieve the draft. Please try again.');
    }
  };

  const handleApprovalSubmit = async (isVerified, verificationScore) => {
    setIsLoading(true);
    try {
      const approveURL = buildUrl(`approveGroupWish/${draftWish.id}/`, queryLoginParam);
      const requestBody = {
        isVerified,
        verificationScore
      };
      const response = await postApi(approveURL, requestBody);
       
      if (response) {
        setSuccessMessage('Group wish approved successfully');
        fetchDraftWishes();
      } else {
        setErrorMessage('Failed to approve group wish');
      }
    } catch (error) { 
      setErrorMessage('An error occurred while approving the group wish');
    } finally {
      setIsLoading(false);
      setShowApprovalModal(false);
    }
  };

  const getGroupsExistingPreferences = async (groupId) => {    
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");
    try {
      const groupsExistingPreferencesURL = buildUrl(`getGroupsExistingPreferences/${groupId}`, queryLoginParam);
      const response = await getApi(groupsExistingPreferencesURL);
      if (response.ok) {
        const responseData = response.data;
        if (responseData.preferences_defined) {
          setExistingPreferences(responseData.preferences_defined);
        }
      }  
    } catch (error) {
     // console.error('Error getting group wish\'s existing preferences:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleSubmit = async () => {
    if (validateForm()) {
       
      setShowPreferencesModal(true);
      
    } else {
      alert('Please fill all required fields correctly before proceeding.');
    }
  };

  const handlePreferenceSubmit = (response) => { 
    if (response && response.preferences) {
      setExistingPreferences(response.preferences);
      setSuccessMessage("Preferences have been successfully set!");
    } else {
      setErrorMessage(response.error);
    }
    setShowPreferencesModal(false);
  };

  return (     
      <div  >
        <TopMenuBar showBackButton={false} showHomeButton={true} viewDrafts={true} onBackClick={() => navigate('/')} title={"Create"} showSearch={false} showAddress={false} /> 
        <div style={contentStyle}>
          {isLoading ? (
            <div style={loadingContainerStyle}>
              <div style={loaderStyle}></div>
            </div>
          ) : (
            <>
              {(successMessage || errorMessage) && (
                <div style={{...messageContainerStyle,backgroundColor: successMessage ? '#d4edda' : '#f8d7da', color: successMessage ? '#155724' : '#721c24',}}>
                  <p>{successMessage || errorMessage}</p>
                </div>
              )}
              <div style={formContainerStyle}>
                <section style={sectionStyle}>
                  <div style={inputGroup}> 
                  <h3 style={sectionTitleStyle}>Basic Information</h3>                  
                  </div>
                  
                  <LabeledInput label="Product Name" value={formData.productName} onChange={(value) => handleInputChange('productName', value)} placeholder="Enter product name"  error={errors.productName}/>
                  <LabeledInput label="Deal Link" value={formData.dealTitle}  onChange={(value) => handleInputChange('dealTitle', value)}  placeholder="Enter deal link"  error={errors.dealTitle}/>
                </section>
                <div style={imageAndMarketDetailsContainerStyle}>
                  <section style={marketDetailsSectionStyle}>
                    <h3 style={sectionTitleStyle}>Market Details</h3>
                    <div style={inputGroup}>
                      <LabeledInput label="Market Price/Piece"  value={formData.singlePrice} onChange={(value) => handleInputChange('singlePrice', value)} placeholder="Enter single item price"  type="number"  error={errors.singlePrice}/>
                      <LabeledInput label="Compare" value={formData.marketLink}  onChange={(value) => handleInputChange('marketLink', value)} placeholder="Enter Comparable Link"  type="text"  error={errors.marketLink}/>
                    </div>
                  </section>

                <section style={groupWishImageSectionStyle}>
                  <h3 style={sectionTitleStyle}>Group Wish Image</h3>
                  <div style={imageUploadContainerStyle}>
                    {imagePreview ? (
                      <div style={imagePreviewContainerStyle}>
                        <img src={imagePreview} alt="Preview" style={imagePreviewStyle} />
                        <button onClick={removeImage} style={removeImageButtonStyle}>Remove Image</button>
                      </div>
                    ) : (
                      <div style={uploadPromptStyle} onClick={handleImageClick}>
                        <FontAwesomeIcon icon={faUpload} size="2x" />
                        <p>Click to upload an image</p>
                      </div>
                    )}
                    <input type="file"  ref={fileInputRef} style={{ display: 'none' }}  onChange={handleImageChange}  accept="image/*"/>
                  </div>
                </section>
              </div>
  
                <section style={sectionStyle}>
                  <h3 style={sectionTitleStyle}>Price Ranges</h3>
                  <div style={priceRangeHeaderStyle}>
                    <span style={columnHeaderStyle1}>Min Qty</span>
                    <span style={columnHeaderStyle2}>Max Qty</span>
                    <span style={columnHeaderStyle3}>Price</span>
                    <span style={columnHeaderStyle4}>Shipping</span>
                    <span style={columnHeaderStyle5}>Actions</span>
                  </div>
                  {formData.priceRanges.map((range, index) => (
                    <div key={index} style={priceRangeContainerStyle}>
                      <div style={priceRangeRowStyle}>
                        <input style={priceRangeInputStyle} type="number" value={range.minQuantity} onChange={(e) => handlePriceRangeChange(index, 'minQuantity', e.target.value)} placeholder="Min"/>
                        <input style={priceRangeInputStyle} type="number" value={range.maxQuantity} onChange={(e) => handlePriceRangeChange(index, 'maxQuantity', e.target.value)} placeholder="Max"/>
                        <input style={priceRangeInputStyle} type="number" value={range.price} onChange={(e) => handlePriceRangeChange(index, 'price', e.target.value)} placeholder="Price"/>
                        <input style={priceRangeInputStyle} type="number" value={range.shippingCost} onChange={(e) => handlePriceRangeChange(index, 'shippingCost', e.target.value)} placeholder="Shipping"/>
                        <button style={removeButtonStyle} onClick={() => removePriceRange(index)}>
                          <FontAwesomeIcon icon={faTrash} style={removeIconStyle} />
                        </button>
                      </div>
                    </div>
                  ))}
                  <button style={addRangeButtonStyle} onClick={addPriceRange}>
                    <FontAwesomeIcon icon={faPlus} /> Add Price Range
                  </button>
                </section>
  
                <section style={sectionStyle}>
                  <h3 style={sectionTitleStyle}>Additional Details</h3>
                  <div style={inputGroup}>
                    <div style={labeledInputContainerStyle}>
                      <label style={inputLabelStyle}>Expires At</label>
                      <DatePicker selected={formData.expiresAt} onChange={handleDateChange} minDate={new Date()} placeholderText="Select expiration date" style={inputStyle} />
                      {errors.expiresAt && <span style={errorTextStyle}>{errors.expiresAt}</span>}
                    </div>
                    <LabeledInput label="Note" value={formData.note} onChange={(value) => handleInputChange('note', value)} placeholder="Enter any additional notes" multiline style={{ height: '100px' }}/>
                  </div>
                  <LabeledInput label="Description (Optional)" value={formData.description}  onChange={(value) => handleInputChange('description', value)}  placeholder="Enter description"  multiline style={{ height: '100px' }}  error={errors.description}/>
                  <LabeledInput label="Store/Seller" value={formData.storeSeller}  onChange={(value) => handleInputChange('storeSeller', value)} placeholder="Enter store or seller name" />
                  <LabeledInput label="Minimum Group Size" value={formData.groupSizeMin} onChange={(value) => handleInputChange('groupSizeMin', value)} placeholder="Enter minimum group size" type="number" error={errors.groupSizeMin}/>
                </section>
                <section style={sectionStyle}>
                  <h3 style={sectionTitleStyle}>Categories</h3>
                  <div style={inputGroup}>
                    <div style={labeledInputContainerStyle}>
                      <label style={inputLabelStyle}>Category</label>
                      <select value={selectedCategory} onChange={handleCategoryChange} style={inputStyle}>                      
                        <option value="">Select a category</option>
                        {categories.map(category => (
                          <option key={category.id} value={category.id.toString()} >
                             {category?.name || 'Unknown Category'}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div style={labeledInputContainerStyle}>
                      <label style={inputLabelStyle}>Subcategories</label>
                      <select multiple value={selectedSubCategories} onChange={handleSubCategoryChange} style={{...inputStyle, height: '100px'}}>                       
                      {filteredSubCategories.map(subCategory => (
                        <option key={subCategory.id} value={subCategory.id.toString()}>
                          {subCategory.name || 'Unknown Subcategory'}
                        </option>
                      ))}
                      </select>
                    </div>
                  </div>
                </section>
                <div style={buttonContainerStyle}>
                  <button style={saveDraftButtonStyle} onClick={handleDraft}>
                    Save as Draft
                  </button>
                  <button style={{ ...setPreferenceButtonStyle,backgroundColor: isFormValid ? style.Color.colorMintGreen : '#bdc3c7', cursor: isFormValid ? 'pointer' : 'not-allowed',}} onClick={handleSubmit} disabled={!isFormValid}>
                    Set Preference
                  </button>
                  {isBacksAppManager && (
                    <button style={approveButtonStyle} onClick={handleApprove}>Approve</button>
                  )}
                </div>
                <p style={requiredFieldsNoteStyle}>* Required fields</p>
              </div>
            </>
          )}
        </div>
        {showPreferencesModal && (
          <DefinePreferences isOpen={showPreferencesModal} onClose={() => setShowPreferencesModal(false)} groupId={createdGroupId} existingPreferences={existingPreferences} onSubmit={handlePreferenceSubmit}/>
        )}
        {showApprovalModal && (
          <ApprovalModal isOpen={showApprovalModal} onClose={() => setShowApprovalModal(false)} onApprove={handleApprovalSubmit}/>
        )}
      </div>
    );
  };
  
  
const contentStyle = {
  width: '100%',
  padding: '20px', 
};

const formContainerStyle = {
  backgroundColor: style.Color.colorLightBeige,
  borderRadius: '8px',
  padding: '30px',
  marginBottom: '30px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
};

const sectionStyle = {
  backgroundColor: style.Color.colorWhite,
  width:"95%",
  margin: "1%",
  borderRadius: '8px',
  padding: '20px',
  marginBottom: '20px',
  border: '1px solid #e0e0e0',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
};

const sectionTitleStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
  marginBottom: '20px',
  color: style.Color.colorPurple, 
  paddingBottom: '10px',
};

const inputGroup = {
  display: 'flex',
  gap: '20px',
  flexDirection: 'row',
};

const labeledInputContainerStyle = {
  marginBottom: '20px',
  marginLeft: '5%',
  marginRight: '5%',
  flex: 1,
  width: '90%',
  alignItems: 'center',
};

const inputLabelStyle = {
  display: 'block',
  fontSize: '14px',
  fontWeight: '600',
  marginBottom: '8px',
  color: style.Color.colorRoyalBlueText,
};

const inputStyle = {
  width: '100%',
  padding: '12px',
  border: '1px solid #bdc3c7',
  borderRadius: '4px',
  fontSize: '14px',
  transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
  outline: 'none',
  color: style.Color.colorRoyalBlueText,
};

const textareaStyle = {
  minHeight: '100px',
  resize: 'vertical',
};

const inputErrorStyle = {
  borderColor: '#e74c3c',
  boxShadow: '0 0 0 2px rgba(231, 76, 60, 0.25)',
};

const errorTextStyle = {
  color: '#e74c3c',
  fontSize: '12px',
  marginTop: '5px',
};

const priceRangeRowStyle = {
  display: 'flex',
  gap: '10px',
  marginBottom: '10px',
  alignItems: 'center',
  width: '100%',
};

const priceRangeInputStyle = {
  ...inputStyle, 
  flex: 2,
  padding: '8px',
};

const removeButtonStyle = {
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  color: '#e74c3c',
  fontSize: '18px',
  transition: 'color 0.3s ease',
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
};

const removeIconStyle = {
  fontStyle: 'normal',
};

const addRangeButtonStyle = {
  backgroundColor: style.Color.colorLimeGreen,
  color: 'white',
  border: 'none',
  padding: '12px',
  borderRadius: '4px',
  cursor: 'pointer',
  fontWeight: 'bold',
  width: '50%',
  marginLeft: '25%',
  transition: 'background-color 0.3s ease',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
};

const loadingContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '200px',
};

const loaderStyle = {
  border: '4px solid #f3f3f3',
  borderTop: `4px solid ${style.Color.colorMintGreen}`,
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  animation: 'spin 1s linear infinite',
};

const messageContainerStyle = {
  padding: '15px',
  borderRadius: '4px',
  marginBottom: '20px',
  fontWeight: 'bold',
  textAlign: 'center',
};

const priceRangeContainerStyle = {
  marginBottom: '15px',
};

const priceRangeHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  fontWeight: 'bold',
  marginBottom: '10px',
  padding: '0 10px',
  color: style.Color.colorRoyalBlueText,
  width: '100%',
};
const columnHeaderStyle = {
  flex: 1,
  textAlign: 'center',
  padding: '0 5px',
};

const columnHeaderStyle1 = {
  ...columnHeaderStyle,
  flex: 2,
};

const columnHeaderStyle2 = {
  ...columnHeaderStyle,
  flex: 2,
};

const columnHeaderStyle3 = {
  ...columnHeaderStyle,
  flex: 2,
};

const columnHeaderStyle4 = {
  ...columnHeaderStyle,
  flex: 2,
};

const columnHeaderStyle5 = {
  ...columnHeaderStyle,
  flex: 1,
};
const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '25px',
  marginTop: '30px',
  width:'25%',
  marginLeft: '37%',
};

const saveDraftButtonStyle = {
  flex: 1,
  padding: '15px',
  borderRadius: '8px',
  backgroundColor: style.Color.colorDarkBlue,
  color: 'white',
  border: 'none',
  fontWeight: 'bold',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
  fontWeight: 'bold',
};

const setPreferenceButtonStyle = {
  flex: 1,
  padding: '15px',
  borderRadius: '8px',
  color: 'white',
  border: 'none',
  fontWeight: 'bold',
  backgroundColor: style.Color.colorDarkBlue,
  transition: 'background-color 0.3s ease',
  fontWeight: 'bold',
};

const approveButtonStyle = {
  flex: 1,
  padding: '15px',
  borderRadius: '8px',
  backgroundColor: style.Color.colorDarkBlue,
  color: 'white',
  border: 'none',
  fontWeight: 'bold',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease', 
  backgroundColor: style.Color.colorPurpleBlue,  
  fontWeight: 'bold',
};

const requiredFieldsNoteStyle = {
  fontSize: '12px',
  color: style.Color.colorDarkGrey,
  textAlign: 'center',
  marginTop: '20px',
};

const imageAndMarketDetailsContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: '20px',
  marginBottom: '20px',
};

const marketDetailsSectionStyle = {
  ...sectionStyle,
  flex: 1,
  marginBottom: 0,
};

const groupWishImageSectionStyle = {
  ...sectionStyle,
  flex: 1,
  marginBottom: 0,
};

const imageUploadContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '300px',
  width: '100%',
  border: '2px dashed #bdc3c7',
  borderRadius: '4px',
  cursor: 'pointer',
};

const imagePreviewContainerStyle = {
  position: 'relative',
  width: '100%',
  height: '100%',
};

const imagePreviewStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '4px',
};

const removeImageButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  padding: '5px 10px',
  cursor: 'pointer',
};

const uploadPromptStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#bdc3c7',
};
const keyframesStyle = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;


  export default CreateGroupWish; 
      
import React from 'react';
import { Loader2 } from 'lucide-react';

const LoadingSpinnerSection = ({ title }) => {
  return (
    <div className="w-full h-64 bg-white rounded-lg shadow-md p-6 flex flex-col items-center justify-center space-y-4">
      <h3 className="text-lg font-semibold text-gray-700">{title}</h3>
      <Loader2 className="w-12 h-12 text-blue-500 animate-spin" />
      <p className="text-sm text-gray-500">Loading {title}...</p>
    </div>
  );
};

export default LoadingSpinnerSection;
import * as style from "../../../GlobalStyles";
import React, { useState, useEffect,useMemo } from 'react';

const TermsAndConditionsModal = ({ isOpen, onClose, onAgree }) => {
  const [agreed, setAgreed] = useState(false);

  useEffect(() => {
    setAgreed(false);
  }, []);
  
  const termsContent = useMemo(() => [
    {
      title: "1. Group Wish Participation",
      content: [
        "1.a No Guarantee: Joining a Group Wish does not guarantee that the deal will be confirmed. We will notify you if we are unable to reach the required pool size.",
        "1.b Verified Users: Only verified users count towards the required pool size. Not all participants may be verified users."
      ]
    },
    {
      title: "2. Deal Closure and Order Placement",
      content: [
        "2.a Closure Date: On the group wish closure date (as mentioned in the description), deal order placement may or may not occur. Users will be notified of any discussions and before any payment is initiated by BacksApp.",
        "2.b Withdrawal: Users can withdraw from the wish at any time until the group is closed for ordering."
      ]
    },
    {
      title: "3. Shipment and Tracking",
      content: [
        "3.a Shipment Details: All shipment details will be shared within the Group Wish. Users can track their packages using this information."
      ]
    },
    {
      title: "4. Payment and Refunds",
      content: [
        "4.a Confirmed Payments: The deal will proceed only when the count of verified users making confirmed payments reaches the required pool size.",
        "4.b Refunds: If for any reason the deal cannot be completed, the full amount will be repaid on the same day."
      ]
    },
    {
      title: "5. Order Fulfillment",
      content: [
        "5.a Preferences: Orders will be made trying to accommodate most preferences. However, it may not be possible to fulfill all individual preferences."
      ]
    },
    {
      title: "6. User Responsibility",
      content: [
        "6.a Compliance: Users are responsible for ensuring that their participation complies with all applicable laws and regulations in their jurisdiction.",
        "6.b Disputes: BacksApp encourages users to resolve disputes amicably. In cases of unresolved disputes, BacksApp reserves the right to make final decisions."
      ]
    },
    {
      title: "7. Modifications to Terms",
      content: [
        "7.a Updates: BacksApp may periodically update these terms. Users are encouraged to review the latest version regularly."
      ]
    }
  ],[]);

  const handleAgree = () => {
    if (agreed) {
      onAgree();
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 className="modal-title">Terms & Conditions</h2>
        <div className="modal-body">
        {termsContent.map((section, index) => (
            <div key={index} className="terms-section">
              <h3 className="terms-title">{section.title}</h3>
              {section.content.map((item, itemIndex) => (
                <p key={itemIndex} className="terms-item">{item}</p>
              ))}
            </div>
          ))}
          </div>          
        <div className="modal-footer">
          <label className="checkbox-label">
            <input type="checkbox" checked={agreed} onChange={(e) => setAgreed(e.target.checked)}/>
            I agree to the Terms and Conditions
          </label>
          <div className="button-group">
            <button className="cancel-button" onClick={onClose}>Cancel</button>
            <button className="agree-button" onClick={() => { if (agreed) { onAgree();  onClose(); } }} disabled={!agreed} >
              Agree & Join
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = `
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${style.Color.colorLightBeige},
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;
  }
  .modal-content {
    background-color: ${style.Color.colorLightBeige},
    border-radius: 8px;
    padding: 20px;
    max-width: 600px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
  }
  .modal-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .modal-body {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  .terms-section {
    margin-bottom: 15px;
  }
  .terms-title {
    font-size: 18px;
    color: ${style.Color.colorRoyalBlueText};
    margin-bottom: 10px;
    font-weight: bold;
  }
  .terms-item {
    margin-bottom: 5px;
  }
  .terms-footer {
    font-weight: bold;
    margin-top: 15px;
  }
  .modal-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .checkbox-label {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 14px;
  }
  .checkbox-label input {
    margin-right: 10px;
  }
  .button-group {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .cancel-button, .agree-button {
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
  }
  .cancel-button {
    background-color: ${style.Color.colorPurple};
    color: white;
    border: 1px solid #ccc;
    margin-right: 10px;
  }
  .agree-button {
    background-color: ${style.Color.colorPurple};
    color: white;
    border: none;
  }
  .agree-button:disabled {
    background-color: ${style.Color.colorMintGreen};
    cursor: not-allowed;
  }
`;

// Add styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default TermsAndConditionsModal;
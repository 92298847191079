import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../Common/InitialGears/AuthContext'; 
import * as style from "../../../GlobalStyles";
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";

const PreferencesScreen = ({ isOpen, onClose, grp_wish_id, groupWishName, existingPreferences, quantity }) => {
  const { queryLoginParam } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { formData, draftWish } = location.state || {};
  const [preferences, setPreferences] = useState([]);
  const [preferenceGroups, setPreferenceGroups] = useState([]);
  const [customPreferences, setCustomPreferences] = useState([]);
  const [expandedPreference, setExpandedPreference] = useState(null);
  const [customPreference, setCustomPreference] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPreferences();
  }, []);

  useEffect(() => {
    if (preferences.length > 0) {
      initializePreferenceGroups();
    }
  }, [preferences, quantity]);

  const fetchPreferences = useCallback(async () => {
    try {
      setIsLoading(true); 
      const prefernceListUrl = buildUrl(`getGroupsPreferencesToJoin/${grp_wish_id}/`, queryLoginParam);
      const response = await getApi(prefernceListUrl);
      
      if (response.ok) { 
        if (response.data.preferences && Array.isArray(response.data.preferences)) {
          setPreferences(response.data.preferences);
        } else { 
          setError('Unexpected data format received');
          setPreferences([]);
        }
      } else {
        if (response.status === 423) { 
          setError('Your session has expired. Please log in again.');
          navigate("/");
        }
      }
    } catch (error) { 
      setError('Failed to fetch preferences. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [grp_wish_id, queryLoginParam, navigate]);

  const initializePreferenceGroups = () => {
    const initialGroup = {
      quantity: quantity,
      preferences: preferences.reduce((acc, pref) => {
        acc[pref.uuid] = null;
        return acc;
      }, {})
    };
    setPreferenceGroups([initialGroup]);
  };

  const handlePreferenceExpand = (uuid) => {
    setExpandedPreference(expandedPreference === uuid ? null : uuid);
  };

  const handlePreferenceValueSelect = (groupIndex, preferenceUuid, valueUuid) => {
    setPreferenceGroups(prevGroups => {
      const newGroups = [...prevGroups];
      newGroups[groupIndex].preferences[preferenceUuid] = valueUuid;
      return newGroups;
    });
  };

  const handleCustomPreferenceChange = (event) => {
    setCustomPreference(event.target.value);
  };

  const handleAddCustomPreference = () => {
    if (customPreference.trim() && customPreferences.length < 1) {
      setCustomPreferences([...customPreferences, { value: customPreference.trim() }]);
      setCustomPreference('');
    }
  };

  const handleDeleteCustomPreference = async (index) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this custom preference?");
    
    if (isConfirmed) {
      setIsLoading(true);
      try {
        const preferenceToDelete = customPreferences[index];
        const deleteUrl = buildUrl('deleteUsersPreference', queryLoginParam);
        const body = {
          grp_wish_id: grp_wish_id,
          preference_value: preferenceToDelete.value
        };
        const response = await postApi(deleteUrl, body);

        if (response.ok) {
          setCustomPreferences(customPreferences.filter((_, i) => i !== index));
          alert("Custom preference deleted successfully.");
        } else {
          throw new Error('Failed to delete custom preference');
        }
      } catch (error) { 
        alert("Failed to delete custom preference. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSplitGroup = (index) => {
    setPreferenceGroups(prevGroups => {
      const newGroups = [...prevGroups];
      const groupToSplit = newGroups[index];
      if (groupToSplit.quantity > 1) {
        newGroups[index] = { ...groupToSplit, quantity: groupToSplit.quantity - 1 };
        newGroups.splice(index + 1, 0, {
          quantity: 1,
          preferences: { ...groupToSplit.preferences }
        });
      }
      return newGroups;
    });
  };

  const handleMergeGroup = (index) => {
    setPreferenceGroups(prevGroups => {
      if (index < prevGroups.length - 1) {
        const newGroups = [...prevGroups];
        newGroups[index] = {
          quantity: newGroups[index].quantity + newGroups[index + 1].quantity,
          preferences: { ...newGroups[index].preferences }
        };
        newGroups.splice(index + 1, 1);
        return newGroups;
      }
      return prevGroups;
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const allPreferences = preferenceGroups.flatMap(group => 
      Object.entries(group.preferences).map(([preferenceUuid, valueUuid]) => {
        const preference = preferences.find(p => p.uuid === preferenceUuid);
        const value = preference?.values.find(v => v.uuid === valueUuid);
        if (!preference || !value) return null;
        return {
          preference_name: preference.name,
          preference_value: value.value,
          quantity: group.quantity
        };
      }).filter(Boolean)
    );

    const customPrefs = customPreferences.map(cp => ({
      preference_name: 'Custom',
      preference_value: cp.value,
      comments: cp.comments,
      quantity: quantity  
    }));

    try {
      let grpWishURL;
      if (existingPreferences) {
        grpWishURL = existingPreferences === "new"
          ? buildUrl('addUsersPreferences', queryLoginParam)
          : buildUrl('updateUsersPreference', queryLoginParam);
      } else {
        grpWishURL = draftWish && draftWish.id
          ? buildUrl(`updateGroupWish/${draftWish.id}`, queryLoginParam)
          : buildUrl('createGroupWish', queryLoginParam);
      }

      const requestBody = {
        preferences: [...allPreferences, ...customPrefs],
        grp_wish_id: grp_wish_id || formData,
        id: draftWish && draftWish.id ? draftWish.id : undefined
      };
      const response = await postApi(grpWishURL, requestBody);
       
      if (response) {
        onClose();
      } else {
        throw new Error(response.data.message || 'Failed to update preferences');
      }
    } catch (error) { 
      setError(error.message || 'An error occurred while updating preferences');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  const handleClose = () => {
    onClose(false);
  };

  if (isLoading) {
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="loading-container">
            <div className="spinner"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="preferences-modal-overlay">
      <div className="preferences-modal-content">
        <div className="preferences-modal-header">
          <h1 className="preferences-modal-title">Set Preferences: "{groupWishName}"</h1>
          <button onClick={handleClose} className="preferences-close-button">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="preferences-container">
        {preferenceGroups.map((group, groupIndex) => ( 
          <div>
            <h2>Preferences</h2>
              <div key={groupIndex} className="preference-group">
                <h3>Quantity: {group.quantity}</h3>
                {preferences.map((item) => (
                  <div key={item.uuid} className="preference-item">
                    <div className="preference-header" onClick={() => handlePreferenceExpand(item.uuid)}>
                      <span className="preference-title">{item.name}</span>
                      <span>{expandedPreference === item.uuid ? '▲' : '▼'}</span>
                    </div>
                    {group.preferences[item.uuid] && (
                      <div className="selected-preference">
                        Selected: {item.values.find(v => v.uuid === group.preferences[item.uuid])?.value}
                      </div>
                    )}
                    {expandedPreference === item.uuid && (
                      <div className="preference-values">
                        {item.values.map(value => (
                          <div key={value.uuid} className="preference-value" onClick={() => handlePreferenceValueSelect(groupIndex, item.uuid, value.uuid)}>
                            <span>{value.value}</span>
                            {group.preferences[item.uuid] === value.uuid && <span>✓</span>}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
                <div className="group-actions">
                  {group.quantity > 1 && (
                    <button onClick={() => handleSplitGroup(groupIndex)} className="split-button">
                      <FontAwesomeIcon icon={faPlusCircle} /> Split
                    </button>
                  )}
                  {groupIndex < preferenceGroups.length - 1 && (
                    <button onClick={() => handleMergeGroup(groupIndex)} className="merge-button">
                      <FontAwesomeIcon icon={faMinusCircle} /> Merge with Next
                    </button>
                  )}
                </div>
              </div>
            </div>
            ))}

          <div className="custom-preferences">
            <h2>Custom Preferences</h2>
            <input className="custom-preference-input" placeholder="Add a custom preference (max 50 chars)" value={customPreference} onChange={handleCustomPreferenceChange} onBlur={handleAddCustomPreference} maxLength={50}/>
            {customPreferences.map((cp, index) => (
              <div key={index} className="preference-item">
                <div className="preference-header">
                  <span>{cp.value}</span>
                  <button onClick={() => handleDeleteCustomPreference(index)}>Delete</button>
                </div>
              </div>
            ))}
          </div>

          <button className={`submit-button ${isSubmitting ? 'submit-button-disabled' : ''}`}  onClick={handleSubmit}  disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </div>
    </div>   
  );
};

const styles = `
.preferences-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.preferences-modal-content {
  background-color: ${style.Color.colorLightestBeige};
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
}
.preferences-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.preferences-modal-title {
  font-size: 24px;
  color: ${style.Color.colorRoyalBlueText};
}
.preferences-close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: ${style.Color.colorDarkGrey};
}
.preferences-container {
  background-color: ${style.Color.colorLightBeige};
  border-radius: 8px;
  padding: 15px;
  color: ${style.Color.colorThemeGrey};
  font-size: 14px;
}
.preference-item {
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}
.preference-header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background-color: ${style.Color.colorLightestBeige};  
  cursor: pointer;
  align-items: center;
  color: ${style.Color.colorPurple};
}
.preference-title {
  font-weight: bold;
  color: ${style.Color.colorPurple};
  background-color: ${style.Color.colorLightestBeige}; 
} 
.preference-value {
  padding: 5px 0;
  cursor: pointer;
  color: ${style.Color.colorRoyalBlueText};
  background-color: ${style.Color.colorWhite};
}
.selected-preference {
  padding: 10px;
  font-style: italic;
  font-weight: bold;
  color: ${style.Color.colorWhite};
  background-color: ${style.Color.colorPurple};
}
.custom-preferences {
  margin-top: 20px;
}
.custom-preference-input {
  width: 95%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 10px;
}
.submit-button {
  background-color: ${style.Color.colorMintGreen};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 65%;
  margin-left:15%;
  margin-top: 20px;
}
.submit-button-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.preference-group {
  background-color: ${style.Color.colorLightBeige};
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
}

.group-actions {
  margin-top: 10px;
}

.split-button, .merge-button {
  background-color: ${style.Color.colorMintGreen};
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}

.merge-button {
  background-color: ${style.Color.colorPurple};
}
`;

// Add styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
export default PreferencesScreen;
import React, { useState } from 'react';
import styled from 'styled-components';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../../../firebaseConfig';
import { Color } from '../../../GlobalStyles';
import spereImage from '../../../assets/images/Original_Login.png';
 

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  border-radius: 16px;
  padding: 24px;
  width: 70%;
  max-width: 400px;
  height: 90%;
  max-height: 1500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${spereImage});
    background-size: cover;
    background-position: center;
    z-index: -2;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //background: linear-gradient(to right, #C77B58, #B76E79, #C77B58);
    background: linear-gradient(
      to right,
      rgba(199, 123, 88, 0.1),
      rgba(183, 110, 121, 0.4),
      rgba(199, 123, 88, 0.2)
    );
    z-index: -1;
  }
`;



const TitleContainer = styled.div` 
  padding: 20px;
  border-radius: 12px;
  margin-top: 55%;
  width: 90%;
`;

const FormContainer = styled.div`
  padding: 20px;
  border-radius: 12px;
  margin-top: 10%;
  width: 90%;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  color: ${Color.colorLightBeige};
  margin-bottom: 8px;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 0.9rem;
  color: ${Color.colorLightBeige};
  margin-bottom: 16px;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin: 16px 0;
  padding: 12px 0;
  border: none;
  border-bottom: 1px solid ${Color.colorLightestBeige};
  font-size: 16px;
  background-color: transparent;
  color: ${Color.colorWhite};
  transition: border-color 0.3s ease;

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }

  &:focus {
    outline: none;
    border-bottom-color: ${Color.colorPurpleBlue};
  }
`;


const ErrorMessage = styled.div`
  color: ${Color.colorRed};
  margin-bottom: 8px;
  text-align: center;
  font-size: 12px;
`;

const SuccessMessage = styled.div`
  color: ${Color.colorGreen};
  margin-bottom: 8px;
  text-align: center;
  font-size: 12px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 3%;
  right: 5%;
  background: none;
  border: none;
  font-size: 40px;
  color: ${Color.colorWhite};
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: ${Color.colorPurpleBlue};
  }

  &:focus {
    outline: none;
  }
`;
const Links = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const Link = styled.a`
  color: ${Color.colorWhite};
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;

  &:hover {
    color: ${Color.colorPurpleBlue};
  }
`;

const ForgotPasswordModal = ({ onClose }) => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage('Password reset email sent. Please check your inbox.');
      setErrorMessage('');
    } catch (error) {
      console.error("Error sending password reset email: ", error);
      setErrorMessage(`Failed to send password reset email. Error: ${error.message}`);
      setSuccessMessage('');
    }
  };

  const handleResetPassword = async (e) => {
  }
  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <TitleContainer>
            <Title>BacksApp</Title>
            <Subtitle>Reset Password</Subtitle>            
          </TitleContainer>
        <Subtitle>Enter your email to receive a password reset link</Subtitle>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
        
        <Form onSubmit={handleSubmit}>
          <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required/> 
          <Links>
              <Link href="#" onClick={handleResetPassword}>Send Reset Link</Link> 
          </Links>
        </Form>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ForgotPasswordModal;
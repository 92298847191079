import React, { useState } from 'react';
import { Users, Heart, TrendingUp, ShoppingCart, ChevronRight, Globe, Phone, Mail } from 'lucide-react'; 
import ReferralRequestModal from './ReferralRequestModal';
import Footer from './Footer';

const AboutPage = () => {
    const [activeTab, setActiveTab] = useState('vision');
    const [showReferralModal, setShowReferralModal] = useState(false);

  const values = [
    {
      icon: Users,
      title: "Community First",
      description: "Building strong connections between buyers and sellers, creating a supportive ecosystem"
    },
    {
      icon: Heart,
      title: "Innovation Driven",
      description: "Leveraging AI technology to revolutionize the group buying experience"
    },
    {
      icon: TrendingUp,
      title: "Maximum Savings",
      description: "Enabling buyers to achieve the best possible prices through collective purchasing"
    },
    {
      icon: ShoppingCart,
      title: "Seamless Experience",
      description: "Providing innovative tools to manage cashflow and simplify the buying process"
    }
  ];

  const milestones = [
    { year: "2022", title: "Company Founded", description: "BacksApp was established with a vision to revolutionize group buying" },
    { year: "2023", title: "AI Integration", description: "Launched AI-powered matching system for buyers and sellers" },
    { year: "2024", title: "Global Expansion", description: "Extended our services to multiple countries" },
  ];

  const stats = [
    { value: "50K+", label: "Active Users" },
    { value: "₹100M+", label: "Total Savings" },
    { value: "1000+", label: "Partner Sellers" },
    { value: "98%", label: "Success Rate" }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Navigation Bar */}
      <nav className="fixed top-0 w-full bg-white/95 backdrop-blur-sm shadow-sm z-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center space-x-8">
              <a href="/" className="text-xl font-bold bg-gradient-to-r from-amber-600 to-rose-800 bg-clip-text text-transparent">
                BacksApp
              </a>
              <div className="hidden md:flex space-x-6">
                <a href="/" className="text-gray-600 hover:text-amber-600 transition-colors">Home</a>
                <a href="/about" className="text-amber-600 font-medium">About</a>
               
              </div>
            </div>
            <div className="flex items-center space-x-4"> 
              <a onClick={() => setShowReferralModal(true)} className="px-4 py-2 bg-gradient-to-r from-amber-600 to-rose-800 text-white rounded-lg hover:opacity-90 transition-all">
                Request to Join
              </a>              
            </div>
          </div>
        </div>
      </nav>
      
      {/* Render Referral Modal */}
      {showReferralModal && (
        <ReferralRequestModal onClose={() => setShowReferralModal(false)} />
      )}
    
      {/* Hero Section */}
      <section className="relative pt-32 pb-16 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-amber-100/20 to-rose-100/20" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
          <div className="text-center max-w-3xl mx-auto">
            <h1 className="text-5xl font-bold mb-6 bg-gradient-to-r from-amber-600 to-rose-800 bg-clip-text text-transparent">
              BacksApp by Infoply
            </h1>
            <p className="text-xl text-gray-600 mb-8 leading-relaxed">
              Revolutionizing group buying through AI-powered marketplace solutions
            </p>
            <div className="flex justify-center space-x-4">
              <button className="px-6 py-3 bg-gradient-to-r from-amber-600 to-rose-800 text-white rounded-lg hover:opacity-90 transition-all flex items-center space-x-2">
                <span>Learn More</span>
                <ChevronRight className="w-4 h-4" />
              </button>
              <button className="px-6 py-3 border border-amber-600 text-amber-600 rounded-lg hover:bg-amber-50 transition-all">
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Vision & Mission Section */}
      <section className="py-16 bg-white/80">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center space-x-4 mb-8">
            <button
              onClick={() => setActiveTab('vision')}
              className={`px-6 py-2 rounded-lg transition-all ${
                activeTab === 'vision' 
                  ? 'bg-gradient-to-r from-amber-600 to-rose-800 text-white' 
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              Our Vision
            </button>
            <button
              onClick={() => setActiveTab('mission')}
              className={`px-6 py-2 rounded-lg transition-all ${
                activeTab === 'mission' 
                  ? 'bg-gradient-to-r from-amber-600 to-rose-800 text-white' 
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              Our Mission
            </button>
          </div>
          
          <div className="bg-gradient-to-r from-amber-50 to-rose-50 rounded-lg shadow-xl p-8">
            <div className="max-w-3xl mx-auto text-center">
              {activeTab === 'vision' ? (
                <>
                  <h2 className="text-3xl font-semibold mb-6 text-amber-800">Our Vision</h2>
                  <p className="text-lg text-gray-700 leading-relaxed">
                    To create a world where group buying is accessible to everyone, 
                    fostering a community where buyers and sellers collaborate seamlessly 
                    to achieve mutual success.
                  </p>
                </>
              ) : (
                <>
                  <h2 className="text-3xl font-semibold mb-6 text-amber-800">Our Mission</h2>
                  <p className="text-lg text-gray-700 leading-relaxed">
                    At Infoply, we are building a revolutionary AI-powered marketplace for group buying. 
                    Our goal is to enable buyers to maximize savings and connect serious buyers with sellers, 
                    avoiding hefty marketing spending.
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Values Grid */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-semibold mb-12 text-center text-gray-800">Our Values</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {values.map((value, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 p-6">
                <value.icon className="h-12 w-12 text-amber-600 mb-4" />
                <h3 className="text-xl font-semibold mb-3 text-gray-800">{value.title}</h3>
                <p className="text-gray-600">{value.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="py-16 bg-gradient-to-r from-amber-600 via-rose-800 to-amber-500">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {stats.map((stat, index) => (
              <div key={index} className="text-center">
                <div className="text-4xl font-bold text-white mb-2">{stat.value}</div>
                <div className="text-amber-200">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-semibold mb-12 text-gray-800">Get in Touch</h2>
            <div className="grid grid-cols-1 md:grid-cols-1 gap-8">
              {[ 
                { icon: Mail, title: "Email Us", content: "info@backsapp.com" }
              ].map((item, index) => (
                <div key={index} className="bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 p-6 text-center">
                  <item.icon className="h-8 w-8 mx-auto text-amber-600 mb-4" />
                  <h3 className="font-semibold mb-2">{item.title}</h3>
                  <p className="text-gray-600">{item.content}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default AboutPage;
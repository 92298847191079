import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../Common/InitialGears/AuthContext';
import * as style from "../../../GlobalStyles";
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";

const DefinePreferences = ({ isOpen, onClose, groupId,existingPreferences = [], onSubmit}) => {
  const { queryLoginParam, idTokenLoggedIn } = useAuth();
  const [standardPreferences, setStandardPreferences] = useState([]);
  const [selectedPreferences, setSelectedPreferences] = useState([]);
  const [newPreference, setNewPreference] = useState({ name: '', values: [''], isMultiple: false });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null); 

   useEffect(() => {
    if (isOpen) {
      fetchStandardPreferences();
      if (existingPreferences.length > 0) {
        const formattedPreferences = existingPreferences.map(pref => ({
          name: pref.name,
          isMultiple: pref.is_multiple,
          isStandard: pref.is_standard,
          values: pref.values.map(value => {
            if (typeof value === 'object' && value.value) {
              return value.value;
            }
            return value;
          })
        })); 
        setSelectedPreferences(formattedPreferences);
      }
    }
  }, [isOpen, existingPreferences]);
  
  const fetchStandardPreferences = async () => {
    setIsLoading(true);
    try {
      const standardPreferencesUrl = buildUrl('getStandardPreferences', queryLoginParam);
      const response = await getApi(standardPreferencesUrl);
      
      const data=response.data; 
      const formattedData = data.map(pref => ({
        uuid: pref.uuid,
        name: pref.name,
        values: pref.values.map(v => v.value),
        isMultiple: pref.values.length > 1,
        isStandard: true,
        is_product_specific: pref.is_product_specific
      }));
      
      setStandardPreferences(formattedData);
    } catch (error) { 
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStandardPreferenceSelect = (preference) => {
    const formattedPreference = {
      ...preference,
      values: preference.values,
      uuid: preference.uuid 
    };
    setSelectedPreferences(prev => [...prev, formattedPreference]);
  };

  const handleRemovePreference = (index) => {
    setSelectedPreferences(prev => prev.filter((_, i) => i !== index));
  };

  const handleNewPreferenceChange = (e) => {
    setNewPreference(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleNewPreferenceValueChange = (index, value) => {
    setNewPreference(prev => {
      const newValues = [...prev.values];
      newValues[index] = value;
      return { ...prev, values: newValues };
    });
  };

  const handleAddNewPreferenceValue = () => {
    setNewPreference(prev => ({ ...prev, values: [...prev.values, ''] }));
  };

  const handleRemoveNewPreferenceValue = (index) => {
    setNewPreference(prev => {
      const newValues = prev.values.filter((_, i) => i !== index);
      return { ...prev, values: newValues };
    });
  };

  const handleAddNewPreference = () => {
    if (newPreference.name && newPreference.values.some(v => v.trim())) {
      setSelectedPreferences(prev => [...prev, { ...newPreference, isStandard: false }]);
      setNewPreference({ name: '', values: [''], isMultiple: false });
    }
  };

  const handleSubmit = async () => { 
    try {
      const formattedPreferences = selectedPreferences.map(pref => ({
        name: pref.name,
        values: pref.values,
        isMultiple: pref.isMultiple,
        isStandard: pref.isStandard,
        uuid: pref.uuid,  
        is_product_specific: pref.is_product_specific
      }));
      const setPreferencesUrl = buildUrl('setGroupPreferences', queryLoginParam);
      const response = await postApi(setPreferencesUrl, {
        groupId: groupId,
        preferences: formattedPreferences
      });
       
      if (response) {
        onSubmit(response.message);
        onClose();
      }
    } catch (error) { 
      setError(error.message);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title">Define Group Preferences</h1>
          <button onClick={onClose} className="close-button">
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        {isLoading ? (
          <div className="loading">Loading...</div>
        ) : error ? (
          <div className="error">{error}</div>
        ) : (
          <div className="preferences-container">
            <h3>Standard Preferences</h3>
            {standardPreferences.length > 0 ? (
              <select onChange={(e) => handleStandardPreferenceSelect(JSON.parse(e.target.value))} value="">
                <option value="">Select a standard preference</option>
                {standardPreferences.map((pref, index) => (
                  <option key={index} value={JSON.stringify(pref)}>
                    {pref.name}
                  </option>
                ))}
              </select>
            ) : (
              <p>No standard preferences available.</p>
            )}
            
            <h2>Add Custom Preference</h2>
            <div className="preference-value-input">
            <input className="cust-preference-name-input" type="text" name="name" value={newPreference.name} onChange={handleNewPreferenceChange} placeholder="Preference Name"/>
            <label>
              <input className="cust-preference-checkbox" type="checkbox" name="isMultiple" checked={newPreference.isMultiple} onChange={(e) => handleNewPreferenceChange({ target: { name: 'isMultiple', value: e.target.checked } })}/>
              Allows multiple values
            </label>
            </div>
            {newPreference.values.map((value, index) => (
              <div key={index} className="preference-value-input">
                <input type="text" value={value} onChange={(e) => handleNewPreferenceValueChange(index, e.target.value)} placeholder={`Value ${index + 1}`}/>
                <button onClick={() => handleRemoveNewPreferenceValue(index)} className="remove-button">
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            ))}
            <button onClick={handleAddNewPreferenceValue} className="add-value-button">
              <FontAwesomeIcon icon={faPlus} /> Add Value
            </button>
            <button onClick={handleAddNewPreference} className="add-preference-button">
              Add Custom Preference
            </button>
 
            {selectedPreferences.length > 0 && (
              <>
                <h2>Selected Preferences</h2>
                  {selectedPreferences.map((pref, index) => (
                    <div key={index} className="selected-preference">
                      <span>{pref.name} ({pref.isMultiple ? 'Multiple' : 'Single'} Select)</span>
                      <div className="preference-values">
                        {pref.values.map((value, valueIndex) => (
                          <div key={valueIndex} className="preference-value">
                            {value}
                          </div>
                        ))}
                      </div>
                      <button onClick={() => handleRemovePreference(index)} className="remove-button">
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  ))}
              </>
            )}


            <button onClick={handleSubmit} className="submit-button">
              Save Group Preferences
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = `
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: ${style.Color.colorLightBeige};
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${style.Color.colorLightBeige};
}

.modal-title {
  font-size: 24px;
  color: ${style.Color.colorRoyalBlueText};
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: ${style.Color.colorDarkGrey};
}

.preferences-container {
  color: ${style.Color.colorDarkGrey};
  background-color: ${style.Color.colorLightestBeige};
}

.preferences-container h2 {
  margin-top: 20px;
  color: ${style.Color.colorPurple};
}
.preferences-container h3 {
    margin-top: 20px;
    color: ${style.Color.colorRoyalBlueText};
  }

.preferences-container select,
.preferences-container input[type="text"] {
  width: 100%;
  padding: 18px;
  margin-bottom: 10px;
  border: 1px solid ${style.Color.colorLightBeige};
  border-radius: 8px;
  color: ${style.Color.colorDarkGrey};
}

.selected-preference {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: ${style.Color.colorLimeGreen};
  margin-bottom: 10px;
  border-radius: 4px;
}
.cust-preference-name-input {
  display: flex;  
  font-size: 12px;
  font-weight: bold; 
  align-items: center; 
  background-color: ${style.Color.colorWhite};
  margin-bottom: 10px;
  border-radius: 8px;
}
.cust-preference-checkbox{
  display: flex;  
  justify-content: space-between;
  align-items: center; 
  background-color: ${style.Color.colorWhite};
  margin-bottom: 10px;
  border-radius: 8px;
}
.remove-button,
.add-value-button,
.add-preference-button {
  background-color: ${style.Color.colorLimeGreen};
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 90px;
}

.remove-button {
  background-color: ${style.Color.colorRed};
}

.preference-value-input {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  max-width: 530px;
}

.preference-value-input input {
  flex-grow: 1;
  margin-right: 10px;
}

.submit-button {
  background-color: ${style.Color.colorPurple};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 50%;
  margin-top: 20px;
  margin-left: 30%;
}

.loading,
.error {
  text-align: center;
  padding: 20px;
  font-size: 18px;
}

.error {
  color: ${style.Color.colorRed};
}
.preference-values {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
}

.preference-value {
  background-color: ${style.Color.colorLightestBeige};
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 0.9em;
}
`;

// Add styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default DefinePreferences;
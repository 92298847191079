import React, { useState } from 'react';
import { ArrowLeft, ArrowRight } from 'lucide-react';

const TestimonialsSection = ({ testimonials }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const nextTestimonial = () => {
    setActiveIndex((prev) => (prev + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setActiveIndex((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <section className="py-16 bg-gradient-to-b from-amber-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-rose-800 mb-4">What Our Users Say</h2>
          <p className="text-xl text-amber-700/80">Join thousands of satisfied group buyers</p>
        </div>

        <div className="relative">
          <div className="overflow-hidden">
            <div className="flex transition-transform duration-500 ease-in-out"
              style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
              {testimonials.map((testimonial) => (
                <div key={testimonial.id} className="w-full flex-shrink-0 px-4">
                  <div className="bg-white rounded-xl p-8 shadow-lg">
                    <div className="flex items-center mb-6">
                      <div>
                        <h4 className="text-lg font-semibold text-gray-900">
                          {testimonial.name}
                        </h4>
                        <p className="text-gray-600">{testimonial.role}</p>
                      </div>
                    </div>
                    <p className="text-gray-700 text-lg leading-relaxed italic">
                      {testimonial.content}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-center mt-8 gap-4">
            <button className="p-2 rounded-full bg-white shadow-md hover:bg-amber-50 transition-colors duration-300" onClick={prevTestimonial}>
              <ArrowLeft className="w-6 h-6 text-amber-800" />
            </button>
            <button className="p-2 rounded-full bg-white shadow-md hover:bg-amber-50 transition-colors duration-300" onClick={nextTestimonial}>
              <ArrowRight className="w-6 h-6 text-amber-800" />
            </button>
          </div>

          <div className="flex justify-center mt-4 gap-2">
            {testimonials.map((_, index) => (
              <button key={index}
                className={`w-2 h-2 rounded-full transition-colors duration-300 ${
                  index === activeIndex ? 'bg-amber-600' : 'bg-amber-200'
                }`} onClick={() => setActiveIndex(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;


//<img src={testimonial.avatar} alt={testimonial.name} className="w-16 h-16 rounded-full mr-4 object-cover"/>
import React, { useState, useEffect,useRef,useCallback } from 'react';
import sceneImage from '../../assets/images/scene1.png';
import bannerImage from '../../assets/images/banner1.png';
import { Search, Users, Star, Clock, ChevronDown, ArrowLeft, Bell, User, ArrowRight, Heart, ShoppingCart, TrendingUp } from 'lucide-react';
import Config from "../../config"; 
import DealCard from "./DealsGrid";
import LoginModal from '../Common/InitialGears/Login';
import PhoneLoginModal from '../Common/InitialGears/PhoneLogin';
import Register from '../Common/InitialGears/Register';
import CategoriesGrid from './CategoriesGrid';
import HowItWorksSection from './HowItWorksSection';
import TestimonialsSection from './TestimonialsSection';
import ReferralRequestModal from './ReferralRequestModal'; 
import Footer from './Footer';

const styles = `
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-in {
  animation: slideIn 0.3s ease-out;
}

.fade-in-left {
  animation: fadeInLeft 0.5s ease-out forwards;
}

.fade-in-right {
  animation: fadeInRight 0.5s ease-out forwards;
}

.fade-in-up {
  animation: fadeInUp 0.5s ease-out forwards;
}

.header-visible {
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.header-hidden {
  transform: translateY(-100%);
  transition: transform 0.3s ease;
}

.scale-on-hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.scale-on-hover:hover {
  transform: scale(1.05);
}

.scale-on-click:active {
  transform: scale(0.95);
}

.delayed-animation {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.delayed-animation.visible {
  opacity: 1;
  transform: translateY(0);
}
`;

const colorStyles = {
    primary: {
      gradient: 'from-amber-600 via-rose-800 to-amber-500',
      text: 'text-amber-700',
      hover: 'hover:text-rose-500',
      background: 'bg-gradient-to-r from-amber-500 via-rose-500 to-amber-500',
      button: 'bg-gradient-to-r from-amber-600 to-rose-800 hover:from-amber-500 hover:to-rose-800',
      buttonText: 'text-white',
      secondaryButton: 'border-rose-300 text-amber-700 hover:bg-amber-50',
      icon: 'text-amber-600 group-hover:text-rose-500',
    }
  };

const LandingPage = () => {
  const [selectedCategory, setSelectedCategory] = useState('All Categories');
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [activeTestimonial, setActiveTestimonial] = useState(0);
  const [isIntersecting, setIsIntersecting] = useState({});
  const dealsRef = useRef(null); 
  const [categories, setCategories] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null); 
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isListOpen, setIsListOpen] = useState(false);
  const [deals, setDeals] = useState([]);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showPhoneLoginModal, setShowPhoneLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showReferralModal, setShowReferralModal] = useState(false);

  //Footer refs 
  const howItWorksRef = useRef(null);
  const testimonialsRef = useRef(null); 
  const categoriesRef = useRef(null); 

  // Smooth scroll handler
  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  // Footer navigation handler
  const handleFooterNavigation = (section) => {
    switch (section) { 
      case 'howItWorks':
        scrollToSection(howItWorksRef);
        break;
      case 'testimonials':
        scrollToSection(testimonialsRef);
        break;     
      default:
        break;
    }
  };

  //Modal handlers
  const handleCloseModals = () => {
    setShowLoginModal(false);
    setShowPhoneLoginModal(false);
    setShowRegisterModal(false);
  };

  const switchToPhoneLogin = () => {
    setShowLoginModal(false);
    setShowPhoneLoginModal(true);
  };

  const switchToEmailLogin = () => {
    setShowPhoneLoginModal(false);
    setShowLoginModal(true);
  };

  const switchToRegister = () => {
    setShowLoginModal(false);
    setShowPhoneLoginModal(false);
    setShowRegisterModal(true);
  };

  // Scroll-based header visibility
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsHeaderVisible(currentScrollY < lastScrollY || currentScrollY < 50);
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  const stats = [
    { value: '100%', label: 'Happy Buyers', icon: Users },
    { value: '40%', label: 'Avg. Savings', icon: TrendingUp },
    { value: 'World Class', label: 'Manufactures', icon: ShoppingCart },
    { value: 'Top', label: 'Rated Service', icon: Star }
  ];

  const calculateSavings = (listPrice, rangePrice) => {
    if (!listPrice || !rangePrice) return "0%";
    const savings = ((listPrice - rangePrice) / listPrice) * 100;
    return `${Math.round(savings)}%`;
  };
  
  const calculateRating = (averageRating, ratingCounts) => {
    if (averageRating) return averageRating;
    // Default to 4.5 if no ratings
    return 4.5;
  };
  
  const calculateTimeLeft = (expiresAt) => {
    if (!expiresAt) return "No deadline";
    const now = new Date();
    const expiry = new Date(expiresAt);
    const diffTime = Math.abs(expiry - now);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    return `${diffDays} days ${diffHours} hours`;
  };

  const fetchCategories = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try { 
      const categoriesUrl = `${Config.apiUrl}getCategories`;
      const headers = { 'Content-Type': 'application/json' };
      const response = await fetch(categoriesUrl, { headers });
      const data = await response.json();  

      if (Array.isArray(data)) {
        const newCategoriesList = data.map((category) => ({
          id: category.id,
          image: category.image_url,  // Changed from image object to direct URL
          name: category.name,
          target_user_type: category.target_user_type,  // Added this field
          description: category.description,
          is_active: category.is_active,
          sub_category: category.sub_category
        })); 
        
        setCategoriesList(newCategoriesList);
        setFilteredCategories(newCategoriesList);
        setCategories(['All Categories', ...data.map(category => category.name)]);
      } else {
        throw new Error('Invalid data format received from server');
      }
    } catch (error) { 
      setError('Failed to load categories. Please try again later.');
      setCategoriesList([]);
      setFilteredCategories([]);
      setCategories(['All Categories']);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchDeals = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try { 
      const dealsUrl = `${Config.apiUrl}getDeals`;
      const headers = { 'Content-Type': 'application/json' }; 
      const response = await fetch(dealsUrl, { headers });
      const data = await response.json(); 
           
      if (data.success && data.data) {
        const transformedDeals = data.data.map(item => {
          const wish = item.wish;
          const priceRanges = item.price_ranges.map(range => ({
            qty: range.min_quantity,
            price: range.price,
            saved: calculateSavings(wish.list_price, range.price)
          }));

          return {
            id: wish.id,
            name: wish.product_name,
            manufacturer: wish.store_seller || "Various Manufacturers",
            category: wish.category[0] || "Uncategorized",
            rating: calculateRating(item.averageRating, item.rating_counts),
            reviews: item.rating_counts.length,
            image: typeof wish.image === 'string' ? wish.image : wish.image?.url || '/api/placeholder/400/400',
            description: wish.description,
            currentBuyers: item.totalJoined,
            targetBuyers: wish.group_size_min,
            timeLeft: calculateTimeLeft(wish.expires_at),
            priceTiers: priceRanges
          };
        });

        setDeals(transformedDeals);
      }
    } catch (error) { 
      setError('Failed to load deals. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
    fetchDeals();
  }, [fetchCategories, fetchDeals]);

  const testimonials = [
    {
      id: 1,
      name: "Sarah Johnson",
      role: "Home Designer",
      content: "BacksApp helped me save 45% on bulk furniture orders for my clients. The group buying power is incredible!",
      avatar: "/api/placeholder/64/64"
    },
    {
      id: 2,
      name: "Michael Chen",
      role: "Car Enthusiast",
      content: "Found amazing deals on auto accessories. The community is supportive and the buying process is seamless.",
      avatar: "/api/placeholder/64/64"
    },
    {
      id: 3,
      name: "Emma Davis",
      role: "Restaurant Owner",
      content: "Transformed how I source equipment for my restaurant. The savings have been game-changing for my business.",
      avatar: "/api/placeholder/64/64"
    }
  ];

  const howItWorks = [
    {
        title: "Create Your Wish",
        description: "Tell us what you want to buy and we'll find others interested in the same product",
        icon: Heart
      },
    {
        title: "Join a Group Buy",
        description: "Browse active deals and join ones that interest you",
        icon: Users
      },
    {
      title: "Reach Target",
      description: "Once enough buyers join, the deal is activated",
      icon: TrendingUp
    },
    {
      title: "Save Big",
      description: "Enjoy wholesale prices on premium products",
      icon: ShoppingCart
    }
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsIntersecting(prev => ({
            ...prev,
            [entry.target.id]: entry.isIntersecting
          }));
        });
      },
      { threshold: 0.1 }
    );

    document.querySelectorAll('.delayed-animation').forEach((el) => {
      observer.observe(el);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
       <header className="fixed w-full bg-white/95 backdrop-blur-sm shadow-sm z-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className={`text-xl font-bold bg-gradient-to-r ${colorStyles.primary.gradient} bg-clip-text text-transparent`}>
              BacksApp
            </div>
            
            <nav className="flex items-center gap-4">              
              <button onClick={() => setShowLoginModal(true)} className={`flex items-center gap-2 px-6 py-2 rounded-lg ${colorStyles.primary.button} ${colorStyles.primary.buttonText} transition-all duration-300`}>
                Sign In/ Register
              </button>
            </nav>
          </div>
        </div>
      </header>
        {/* Login Modals */}
        {showLoginModal && (
            <LoginModal onClose={handleCloseModals} openPhoneLogin={switchToPhoneLogin} openRegisterModal={switchToRegister}/>
        )}
        {showPhoneLoginModal && (
            <PhoneLoginModal onClose={handleCloseModals} openLoginModal={switchToEmailLogin}/>
        )}

        {showRegisterModal && (
            <Register onClose={handleCloseModals} openLoginModal={switchToEmailLogin}/>
        )}
      {/* Hero Section*/}
      <section className="relative min-h-[50vh] flex items-center pt-16  overflow-hidden">
        <div className="absolute inset-0 opacity-100">
        <img src={sceneImage}  alt="Background scene"  className="absolute inset-0 w-full h-full object-cover bg-center"/>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="flex flex-col md:flex-row justify-between items-center gap-12">
                <div className="max-w-xl fade-in-left backdrop-blur-sm bg-white/10 p-8 rounded-lg border border-white/20">
                <button className="px-8 py-4 bg-white/70 rounded-lg font-medium hover:bg-white transition-all duration-300 hover:shadow-lg">
                <h1 className="font-serif text-4xl font-bold mb-6 leading-tight text-gray-700">
                    Group Buying Power<br />
                    for Every Purchase
                </h1>
                </button>
                <div className="flex flex-col items-end w-full">  
                    <p className="text-m mb-4 leading-relaxed font-light text-right">  
                        <span className="text-white" > 
                        Join forces with other buyers to unlock  
                        </span>
                        <span className="text-white block mt-2">  
                            wholesale prices on premium products <br />                            
                        </span>
                    </p>
                </div>
                <div className="flex  w-full mt-4"> 
                    <button className="px-8 py-4 bg-white/90 text-rose-800 rounded-lg font-medium hover:bg-white transition-all duration-300 hover:shadow-lg">
                    Save more together!
                    </button>
                     
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 fade-in-right">
              {stats.map((stat, index) => (
                <div key={index}
                className="bg-white/80 backdrop-blur-sm p-6 rounded-lg border border-amber-100/20 group hover:bg-white/90 transition-all duration-300 scale-on-hover"
              >
                <stat.icon className="h-8 w-8 text-amber-600 mb-3 group-hover:text-amber-700 transition-colors" />
                <div className="text-3xl font-bold text-amber-900 mb-1">{stat.value}</div>
                <div className="text-gray-700">{stat.label}</div>
              </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Add styles tag */}
      <style>{styles}</style>

      {/* How It Works Section */}
      <div ref={howItWorksRef}>
        <HowItWorksSection howItWorks={howItWorks} colorStyles={colorStyles} />
      </div>

      {/* Categories Section with Hover Effects */}
      <div ref={categoriesRef}>
      <CategoriesGrid categories={categoriesList} />
      </div>

      {/* Call to Action Banner */}
      <section className="relative py-20 overflow-hidden"> 
            <div className="absolute inset-0">
                <img src={bannerImage} alt="Banner background" className="w-full h-full object-cover"/> 
                    <div className="absolute inset-0" style={{background: 'linear-gradient(to right, rgba(140, 80, 80, 0.7), rgba(138, 109, 81, 0.7), rgba(203, 138, 81, 0.3), rgba(140, 80, 80, 0.7), rgba(203, 109, 81, 0.7), rgba(140, 80, 80, 0.7), rgba(140, 80, 80, 0.7))'}} />
            </div>

            {/* Content Layer */}
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="text-white max-w-xl fade-in-left">
                        <h2 className="text-3xl font-bold mb-4 text-white/text-rose-50">Double the Rewards, Double the Savings!</h2>
                        <p className="text-xl text-white/80 mb-8">
                            Join forces with other smart shoppers - refer friends to earn rewards and team up for wholesale prices.
                            Together, we save more!
                        </p>
                         
                    </div>
                                    
                    <div className="mt-8 md:mt-0" initial={{ opacity: 0, x: 50 }} whileInView={{ opacity: 1, x: 0 }} viewport={{ once: true }}>
                        <div className="bg-white/10 backdrop-blur-sm p-8 rounded-lg border border-white/20">
                            <h3 className="text-2xl font-semibold text-white mb-4">Need a Referral Code?</h3>
                            <p className="text-gray-200 mb-6">
                                Get exclusive access and start saving with group buying power today!
                            </p>
                            <button className="w-full px-6 py-4 bg-white text-rose-800  rounded-lg font-medium shadow-lg hover:shadow-xl transition-all duration-300" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} onClick={() => setShowReferralModal(true)}>
                                Request Referral Code
                            </button>
                        </div>
                        {showReferralModal && (
                            <ReferralRequestModal onClose={() => setShowReferralModal(false)} />
                        )}
                    </div>
                </div>
            </div>
        </section>

      {/* Testimonials Section */}
      <div ref={testimonialsRef}>
        <TestimonialsSection testimonials={testimonials} />
      </div>                       
      
      {/* Footer */}
      <Footer />                          
    </div>
);
};
                        
export default LandingPage; 
                                                                       
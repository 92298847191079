import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider} from './components/Common/InitialGears/AuthContext';
import HomePage from './components/WebPage/Home';
import AboutPage from './components/WebPage/AboutUs';
import TermsAndConditionsScreen from './components/WebPage/Terms&Conditions';
import PrivacyPolicyScreen from './components/WebPage/PrivacyPolicy';
import FAQSection from './components/WebPage/FAQ';
import LandingPage from './components/WebPage/LandingPage';
import LoginModal from './components/Common/InitialGears/Login';
import RegisterModal from './components/Common/InitialGears/Register'; 
import InvitesPage from './components/Buyers/Invites/Invites';
import Profiles from './components/Common/Profiles';
import ProtectedRoute from './ProtectedRoute';
import WishesPage from './components/Buyers/Wishes/Wishes';
import MyWishDetails from './components/Buyers/Wishes/MyWishes';
import TrendsDetailsPage from './components/Buyers/Wishes/TrendingDetails';
import AddWish from './components/Buyers/Wishes/AddWish';
import GroupWishLists from './components/Buyers/GroupWishes/GroupWishLists';
import GroupWishProgressScreen from './components/Buyers/GroupWishes/GroupWishProgressScreen';
import PreferencesScreen from './components/Buyers/GroupWishes/PreferenceScreen';
import CompletedGroupWishes from './components/Buyers/GroupWishes/CompletedGroupWishes';
import JoinedGroupWishes from './components/Buyers/GroupWishes/JoinedGroupWishes';
import CreateGroupWishes from './components/Buyers/GroupWishes/CreateGroupWish';
import DefinePreferencesScreen from './components/Buyers/GroupWishes/DefinePreferences';
import WishesDashboardPage from './components/Buyers/DashbordComponents/WishesDashboard';
import DraftGroupWishes from './components/Buyers/GroupWishes/DraftGroupWishes';
import NotificationScreen from './components/Common/Notifications/NotificationScreen';
import CreditDetailsPage from './components/Buyers/Credits/CreditDetails';
import UserSettingsPage from './components/Buyers/Settings/UserSettings';
import OrderManagement from './components/Buyers/Orders/OrderManagement';
import OrderProcessing from './components/Buyers/Orders/OrderProcessing';

function App() {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  const openLoginModal = () => {
    setShowLoginModal(true);
    setShowRegisterModal(false);
  };

  const openRegisterModal = () => {
    setShowRegisterModal(true);
    setShowLoginModal(false);
  };

  const closeModals = () => {
    setShowLoginModal(false);
    setShowRegisterModal(false);
  };

  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage openLoginModal={openLoginModal} openRegisterModal={openRegisterModal} />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicyScreen />} />
          <Route path="/terms" element={<TermsAndConditionsScreen/>} />
          <Route path="/FAQs" element={<FAQSection/>} />

          <Route path="/dashboard" element={<ProtectedRoute> <WishesDashboardPage /> </ProtectedRoute>} /> 
          <Route path="/invites/*" element={<ProtectedRoute> <InvitesPage /> </ProtectedRoute>} />
          <Route path="/profiles" element={<ProtectedRoute> <Profiles /> </ProtectedRoute>} />
          <Route path="/wishes" element={<ProtectedRoute> <WishesPage /> </ProtectedRoute>} />
          <Route path="/myWishes" element={<ProtectedRoute> <MyWishDetails /> </ProtectedRoute>} />
          <Route path="/trendingWishes" element={<ProtectedRoute> <TrendsDetailsPage /> </ProtectedRoute>} />
          <Route path="/addWish" element={<ProtectedRoute> <AddWish /> </ProtectedRoute>} />
          <Route path="/orderManagement" element={<ProtectedRoute> <OrderManagement /> </ProtectedRoute>} />
          <Route path="/orderProcessing/:groupWishId" element={<ProtectedRoute> <OrderProcessing /> </ProtectedRoute>} />
          <Route path="/groupWishList" element={<ProtectedRoute> <GroupWishLists /> </ProtectedRoute>} />
          <Route path="/groupWishes" element={<ProtectedRoute> <GroupWishLists /> </ProtectedRoute>} />
          <Route path="/groupWishProgress" element={<ProtectedRoute> <GroupWishProgressScreen /> </ProtectedRoute>} />
          <Route path="/preferenceList" element={<ProtectedRoute> <PreferencesScreen /> </ProtectedRoute>} />
          <Route path="/completedGroupWish" element={<ProtectedRoute> <CompletedGroupWishes /> </ProtectedRoute>} />
          <Route path="/joinedGroupWish" element={<ProtectedRoute> <JoinedGroupWishes /> </ProtectedRoute>} />
          <Route path="/createGroupWishes" element={<ProtectedRoute> <CreateGroupWishes /> </ProtectedRoute>} />
          <Route path="/definePreferences" element={<ProtectedRoute> <DefinePreferencesScreen /> </ProtectedRoute>} />
          <Route path="/draftGroupWishes" element={<ProtectedRoute> <DraftGroupWishes /> </ProtectedRoute>} />
          <Route path="/notifications" element={<ProtectedRoute> <NotificationScreen /> </ProtectedRoute>} />
          <Route path="/creditDetails" element={<ProtectedRoute> <CreditDetailsPage /> </ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute> <UserSettingsPage /> </ProtectedRoute>} />
        </Routes>

        {showLoginModal && (
          <LoginModal onClose={closeModals} openRegisterModal={openRegisterModal}/>
        )}
        
        {showRegisterModal && (
          <RegisterModal onClose={closeModals} openLoginModal={openLoginModal}/>
        )}
      </Router>
    </AuthProvider>
  );
}

export default App;
import React, { useState, useEffect }  from 'react';
import { useNavigate } from 'react-router-dom'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '../../Common/InitialGears/AuthContext'; 
import { 
  faSearch, 
  faShoppingCart, 
  faHome, 
  faArrowLeft, 
  faList, 
  faPlus, 
  faBell,
  faBars,
  faTimes,
  faUser,
  faCog,
  faSignOutAlt,
  faUserPlus,
  faStar
} from '@fortawesome/free-solid-svg-icons';
import { Color } from "../../../GlobalStyles";
import BacksAppsLogo from "../../../assets/images/BacksAppLogo.jpg"

export const TopMenuBar = ({ 
  showSearch, 
  title, 
  showBackButton, 
  onBackClick, 
  showHomeButton, 
  showUserName, 
  userName, 
  viewDrafts, 
  showNotifications,
  showMenuButton, 
  onLogout 
}) => {
  const navigate = useNavigate();
  const { firstName } = useAuth();  
  
  const handleHomeClick = () => {
    navigate('/dashboard');
  };

  const handleViewDrafts = () => {
    navigate('/draftGroupWishes');
  };

  const handleNewGrpWish = () => {  
    navigate('/createGroupWishes', { state: { createNew: true } });
  };

  const handleNotificationClick = () => { 
    navigate('/notifications');
  };
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const Drawer = ({ isOpen, onClose }) => {
    const drawerItems = [
      { icon: faHome, text: 'Dashboard', action: () => navigate('/dashboard') },
      { icon: faUserPlus, text: 'Invites', action: () => navigate('/invites') },
      { icon: faUser, text: 'Profile', action: () => navigate('/profiles') },
      { icon: faStar, text: 'Rewards', action: () => navigate('/creditDetails') },
      { icon: faCog, text: 'Settings', action: () => navigate('/settings') },
      { icon: faSignOutAlt, text: 'Logout', action: onLogout },
    ];

    return (
      <div style={{...styles.drawer, right: isOpen ? '0' : '-250px'}}>
        <button style={styles.drawerCloseButton} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        {drawerItems.map((item, index) => (
          <div key={index} style={styles.drawerItem} onClick={item.action}>
            <FontAwesomeIcon icon={item.icon} style={styles.drawerIcon} />
            <span>{item.text}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div style={styles.topBarContainer}>
      <div style={styles.topBar}>
        {showBackButton ? (
          <button onClick={onBackClick} style={styles.backButton}>
            <FontAwesomeIcon icon={faArrowLeft} style={styles.backIcon} />
          </button>
        ) : (
          <>
            {showHomeButton ? (
              <button onClick={handleHomeClick} style={styles.backButton}>
                <FontAwesomeIcon icon={faHome} style={styles.backIcon} />
              </button>
            ) : (
              <div style={styles.logo}>
                <img src={BacksAppsLogo} alt="BacksApp Logo" style={styles.logoImage} />
              </div>
            )}
          </>
        )}
        
        <div style={styles.titleContainer}>
          <div style={styles.title}>{title}</div>
        </div>
        
        <div style={styles.rightSection}>
          {showUserName && (
            <div style={styles.accountInfo}>
              <div style={styles.hello}>Hello, {firstName}</div>
              <div style={styles.accountText}>Account & Lists</div>
            </div>
          )}
          {showNotifications && (
            <button onClick={handleNotificationClick} style={styles.notificationButton}>
              <FontAwesomeIcon icon={faBell} style={styles.notificationIcon} />
            </button>
          )}
          {viewDrafts && (
            <div style={styles.buttonContainer}>
              <button onClick={handleNewGrpWish} style={styles.newGrpWishButtonStyle}>
                <FontAwesomeIcon icon={faPlus} /> New GrpWish
              </button>
              <button onClick={handleViewDrafts} style={styles.viewDraftsButtonStyle}>
                <FontAwesomeIcon icon={faList} /> View Drafts
              </button>
            </div>
          )}
          <div style={styles.cart}>
            <FontAwesomeIcon icon={faShoppingCart} style={styles.cartIcon} />
          </div>
          {showMenuButton && (
            <button onClick={toggleDrawer} style={styles.menuButton}>
              <FontAwesomeIcon icon={faBars} />
            </button>
          )}          
        </div>
      </div>
      {showSearch && (
        <div style={styles.secondaryBar}>
          <div style={styles.searchBar}>
            <select style={styles.searchCategory}>
              <option>All</option>
            </select>
            <input type="text" placeholder="Search .." style={styles.searchInput} />
            <button style={styles.searchButton}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </div>
      )}
      <Drawer isOpen={isDrawerOpen} onClose={toggleDrawer} />
    </div>
  );
}; 

const styles = {
  topBarContainer: {
    backgroundColor: Color.colorDarkBlueBlack,
    color: Color.colorWhite,
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 20px',
    position: 'relative',
  },
  logo: {
    width: '100px',
  },
  logoImage: {
    width: '100%',
    height: 'auto',
  },
  backButton: {
    background: 'none',
    border: 'none',
    color: Color.colorWhite,
    fontSize: '20px',
    cursor: 'pointer',
    marginRight: '10px',
  },

  buttonContainer: {
    display: 'flex',
    gap: '10px',
    marginLeft: 'auto',
  },

  newGrpWishButtonStyle: {
    padding: '8px',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    color: Color.colorWhite,
    border: '1px solid white',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
  },

  viewDraftsButtonStyle: {
    padding: '8px',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    color: 'white',
    border: '1px solid white',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
  },

  backIcon: {
    marginRight: '5px',
  }, 
  titleContainer: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  
  title: {
    fontWeight: 'bold',
    fontSize: '18px', // Adjust as needed
    color: Color.colorWhite,
  },
 
  logo: {
    width: '100px',
  },
  logoImage: {
    width: '100%',
    height: 'auto',
  },
  backButton: {
    background: 'none',
    border: 'none',
    color: Color.colorWhite,
    fontSize: '20px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  backIcon: {
    marginRight: '5px',
  }, 
  deliverTo: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '20px',
  },
  deliverToText: {
    fontSize: '12px',
    color: Color.colorOffWhite,
  },
  location: {
    fontWeight: 'bold',
  },
  searchBar: {
    display: 'flex',
    flex: 1,
    maxWidth: '800px',
    margin: '0 20px',
  },
  searchCategory: {
    padding: '10px',
    backgroundColor: Color.colorMintGreen,
    border: 'none',
    borderRadius: '4px 4px 4px 4px',
  },
  searchInput: {
    flex: 1,
    padding: '10px',
    fontSize: '16px',
    border: 'none',
  },
  searchButton: {
    padding: '10px 15px',
    backgroundColor: Color.colorMintGreen,
    color: Color.colorDarkBlueBlack,
    border: 'none',
    borderRadius: '0 4px 4px 0',
    cursor: 'pointer',
  },
  rightIcons: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  accountInfo: {
    textAlign: 'right',
  },
  hello: {
    fontSize: '12px',
  },
  accountText: {
    fontWeight: 'bold',
  },
  returnsOrders: {
    textAlign: 'center',
  },
  cart: {
    display: 'flex',
    alignItems: 'center',
  },
  cartIcon: {
    fontSize: '24px',
    marginRight: '25px',
  },
  cartText: {
    fontWeight: 'bold',
  },
  secondaryBar: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 5px',
    backgroundColor: Color.colorDarkBlue,
  },
  allButton: {
    display: 'flex',
    alignItems: 'center',
    background: 'none',
    border: 'none',
    color: Color.colorWhite,
    fontWeight: 'bold',
    cursor: 'pointer',
    marginRight: '20px',
  },
  menuIcon: {
    marginRight: '5px',
  },
  secondaryNav: {
    display: 'flex',
    gap: '15px',
  },
  navLink: {
    color: Color.colorWhite,
    textDecoration: 'none',
    fontSize: '14px',
  },
  icon: {
    marginRight: '5px',
  },
  rightSection: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  notificationButton: {
    background: 'none',
    border: 'none',
    color: Color.colorWhite,
    fontSize: '20px',
    cursor: 'pointer',
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  notificationIcon: {
    fontSize: '24px',
  },
  menuButton: {
    background: 'none',
    border: 'none',
    color: Color.colorWhite,
    fontSize: '24px',
    cursor: 'pointer',
  },
  drawer: {
    position: 'fixed',
    top: 0,
    width: '250px',
    height: '100%',
    backgroundColor: Color.colorDarkBlueBlack,
    transition: 'right 0.3s ease',
    zIndex: 1001,
    boxShadow: '-2px 0 5px rgba(0,0,0,0.5)',
    display: 'flex',
    flexDirection: 'column',
    padding: '60px 0 20px',
  },
  drawerCloseButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    color: Color.colorOffWhite,
    fontSize: '24px',
    cursor: 'pointer',
  },
  drawerItem: {
    padding: '15px 20px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: Color.colorOffWhite,
    transition: 'background-color 0.3s',
  },
  drawerIcon: {
    marginRight: '15px',
    width: '20px',
  },
};

export default TopMenuBar;
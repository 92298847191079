import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { TopMenuBar } from '../DashbordComponents/TopMenuBar';
import { useAuth } from '../../../components/Common/InitialGears/AuthContext'; 
import * as style from '../../../GlobalStyles';
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
  
const DraftGroupWishes = () => {
  const auth = useAuth();
  const { queryLoginParam, idTokenLoggedIn} = auth;  
  const navigate = useNavigate();
  const [draftWishes, setDraftWishes] = useState([]);
  const [filteredWishes, setFilteredWishes] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [filterProductType, setFilterProductType] = useState('');
  const [userVerified, setUserVerified] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
 

  const handleViewCreateGroupWish = () => {
    navigate('/createGroupWishes');
  };
  const fetchDraftWishes = useCallback(async () => {
    setIsLoading(true);
    try {
      setRefreshing(true);
      const draftWishesUrl = buildUrl('myGroupWishes', `${queryLoginParam}&type=draft`);
      const response = await getApi(draftWishesUrl);
      
      if (response.ok) {
        const data = response.data;
        setUserVerified(data.is_verified_user);
        if (data.draft_group_wishes) {
          setDraftWishes(data.draft_group_wishes);
          setFilteredWishes(data.draft_group_wishes);
          setSearchQuery('');
          setFilterCategory('');
          setFilterProductType('');
        }
      } else {
        if (response.status === 423) {
          setErrorMessage('Your session has expired. Please log in again.');
          navigate("/");
        }
      }
    } catch (error) {
      console.error('Error fetching draft wishes:', error);
    } finally {
      setRefreshing(false);
      setIsLoading(false);
    }
  }, [queryLoginParam, navigate]);
 
  useEffect(() => {
    fetchDraftWishes();
  }, [fetchDraftWishes]);

  useEffect(() => {
    let timer;
    if (successMessage) {
      timer = setTimeout(() => {
        setSuccessMessage('');
      }, 7000); // 7 seconds
    }
    return () => clearTimeout(timer);
  }, [successMessage]);

  const filterWishes = useCallback((query, category, productType) => {
    if (!Array.isArray(draftWishes)) {
      setFilteredWishes([]);
      return;
    }

    const normalize = (str) => str.toLowerCase().replace(/[^a-z0-9]/g, '');
    const partialMatch = (full, partial) => {
      if (!full || !partial) return false;
      const normalizedFull = normalize(full);
      const normalizedPartial = normalize(partial);
      return normalizedFull.includes(normalizedPartial);
    };

    const filtered = draftWishes.filter(wish => {
      const matchesQuery = !query ||
        partialMatch(wish.deal_title, query) ||
        (wish.category && wish.category.some(cat => partialMatch(cat, query))) ||
        (wish.product_type && wish.product_type.some(type => partialMatch(type, query)));

      const matchesCategory = !category ||
        (wish.category && wish.category.some(cat => partialMatch(cat, category)));

      const matchesProductType = !productType ||
        (wish.product_type && wish.product_type.some(type => partialMatch(type, productType)));

      return matchesQuery && matchesCategory && matchesProductType;
    });
    setFilteredWishes(filtered);
  }, [draftWishes]);

  useEffect(() => {
    filterWishes(searchQuery, filterCategory, filterProductType);
  }, [draftWishes, searchQuery, filterCategory, filterProductType, filterWishes]);

  const handleSearch = (event) => {
    const query = event.target.value.trim();
    setSearchQuery(query);
    filterWishes(query, filterCategory, filterProductType);
  };

  const handleCategoryFilter = (event) => {
    const category = event.target.value.trim();
    setFilterCategory(category);
    filterWishes(searchQuery, category, filterProductType);
  };

  const handleProductTypeFilter = (event) => {
    const productType = event.target.value.trim();
    setFilterProductType(productType);
    filterWishes(searchQuery, filterCategory, productType);
  };

  const handleResumeEdit = (wish) => {
    navigate('/createGroupWishes', { state: { draftWish: wish } });
  };

  const handleReadyToShare = async (wish) => {
    setIsLoading(true); 
    try {
        const headers = { 'Authorization': idTokenLoggedIn, 'Content-Type': 'application/json' };
        const readyShareURL = buildUrl(`submitGrpWish/${wish.id}`, queryLoginParam);
        const response = await postApi(readyShareURL, {});      
        if (response.ok) {
          // Handle successful submission
          fetchDraftWishes(); // Refresh the list
        } else {
          // Handle error
          setErrorMessage('Failed to submit for approval. Please try again.');
        }
      } catch (error) {
        console.error('Error submitting for approval:', error);
        setErrorMessage('An error occurred. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

  const handleDelete = useCallback((wish) => {
    setSuccessMessage("");
    setErrorMessage("");
    if (window.confirm("Are you sure you want to delete this group wish?")) {
      const deleteWish = async () => {
        try { 
          const deleteURL = buildUrl(`deleteGroupWish/${wish.id}/`, queryLoginParam);
          const response = await postApi(deleteURL, {});
          
          if (response) {
            fetchDraftWishes();
            setSuccessMessage(`${wish.product_name} deleted`);
          } else {            
              setErrorMessage('Failed to delete the group wish. Please try again.');
          }
        } catch (error) {
          console.error('Error deleting group wish:', error);
          setErrorMessage('An error occurred while deleting the group wish. Please try again.');
        }
      };
      deleteWish();
    }
  }, [fetchDraftWishes, queryLoginParam, navigate]);

  const renderWishItem = (wish) => {
    const hasPreferences = wish.user_preferences && wish.user_preferences.length > 0;

    return (
      <div key={wish.uuid} style={wishItemStyle}  >
        {wish.image ? (
          <img src={wish.image} alt={wish.product_name} style={wishImageStyle} />
        ) : (
          <div style={{...wishImageStyle, ...placeholderImageStyle}}>
            <FontAwesomeIcon icon="image" size="3x" color="#ccc" />
          </div>
        )}
        <div style={wishContentStyle}>
          <h3 style={wishTitleStyle}>{wish.product_name}</h3>
          <p style={wishInfoStyle}>Price: ${wish.sale_price} (Save {wish.discount_percentage}%)</p>
          <p style={wishInfoStyle}>Seller: {wish.store_seller}</p>
          <p style={wishInfoStyle}>Group Size: {wish.group_size_min}</p>
          <div style={buttonContainerStyle}>
            <button style={resumeButtonStyle} onClick={() => handleResumeEdit(wish)}>Resume Edit</button>
            {hasPreferences && userVerified && (
              <button style={resumeButtonStyle} onClick={() => handleReadyToShare(wish)}>Ready to Share</button>
            )}
            
            <button style={deleteButtonStyle} onClick={() => handleDelete(wish)}>
              <FontAwesomeIcon icon={faTrash} size="sm" />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
    <TopMenuBar 
        showBackButton={true} 
        onBackClick={() => navigate(-1)} 
        title="Group Wishes in Draft" 
        showSearch={false} 
        showAddress={false} 
      />
    <div style={containerStyle}>
      
      
      <div style={contentStyle}>
        {isLoading ? (
          <div style={loadingContainerStyle}>
            <div style={loaderStyle}></div>
          </div>
        ) : (
          <>
            {(successMessage || errorMessage) && (
              <div style={{
                ...messageContainerStyle,
                backgroundColor: successMessage ? '#d4edda' : '#f8d7da',
                color: successMessage ? '#155724' : '#721c24',
              }}>
                <p>{successMessage || errorMessage}</p>
              </div>
            )}
            <div style={formContainerStyle}>
            <div style={inputGroup}> 
              <input type="text" style={searchInputStyle} placeholder="Search deals..." value={searchQuery} onChange={handleSearch}/> 
              <button style={viewDraftsButtonStyle} onClick={handleViewCreateGroupWish}>
                <FontAwesomeIcon icon={faPlus} /> Add New
              </button>
            </div>
              
              <div style={filterContainerStyle}>
                <input
                  type="text"
                  style={filterInputStyle}
                  placeholder="Filter by category"
                  value={filterCategory}
                  onChange={handleCategoryFilter}
                />
                <input
                  type="text"
                  style={filterInputStyle}
                  placeholder="Filter by product type"
                  value={filterProductType}
                  onChange={handleProductTypeFilter}
                />
              </div>
              <div style={wishListStyle}>
                {filteredWishes.map(renderWishItem)}
                {filteredWishes.length === 0 && <p style={emptyTextStyle}>No draft wishes</p>}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
    </>
  );
};

// Styles
const containerStyle = {
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    backgroundColor: style.Color.colorWhite,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20px',
};

const contentStyle = {
  width: '100%',
  maxWidth:'1600px', 
};

const inputGroup = {
  display: 'flex',
  gap: '20px',
  flexDirection: 'row',
};

const formContainerStyle = {
  backgroundColor: style.Color.colorLightBeige,
  borderRadius: '8px',
  padding: '30px',
  marginBottom: '30px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
};

const viewDraftsButtonStyle = {
  flex: '1 0 auto', 
  marginLeft: '70%', 
  borderRadius: '8px',
  padding: '12px',
  backgroundColor: style.Color.colorGray,
  color: 'white',
  border: 'none',
  fontWeight: 'bold',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',  
  justifyContent: 'center', 
};

const searchInputStyle = {
  width: '150%',
  padding: '12px',
  border: '1px solid #bdc3c7',
  borderRadius: '8px',
  fontSize: '14px',
  marginBottom: '20px',
};

const filterContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',
};

const filterInputStyle = {
  width: '48%',
  padding: '12px',
  border: '1px solid #bdc3c7',
  borderRadius: '8px',
  fontSize: '14px',
};

const wishListStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
};

const wishItemStyle = {
  display: 'flex',
  backgroundColor: style.Color.colorWhite,
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
};

const wishImageStyle = {
  width: '100px',
  height: '100px',
  borderRadius: '8px',
  marginRight: '20px',
  objectFit: 'cover',
};

const placeholderImageStyle = {
  backgroundColor: '#f0f0f0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const wishContentStyle = {
  flex: 1,
};

const wishTitleStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  marginBottom: '10px',
  color: style.Color.colorRoyalBlueText,
};

const wishInfoStyle = {
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
  marginBottom: '5px',
};

const buttonContainerStyle = {
  display: 'flex',
  gap: '10px',
  marginTop: '15px',
};

const resumeButtonStyle = {
  padding: '8px 12px',
  backgroundColor: style.Color.colorMintGreen,
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '14px',
  fontWeight: 'bold',
};

const deleteButtonStyle = {
  padding: '8px 12px',
  backgroundColor: '#e74c3c',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
};

const approveButtonStyle = {
  padding: '8px 12px',
  backgroundColor: style.Color.colorLimeGreen,
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '14px',
  fontWeight: 'bold',
};

const loadingContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '200px',
};

const loaderStyle = {
  border: '4px solid #f3f3f3',
  borderTop: `4px solid ${style.Color.colorMintGreen}`,
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  animation: 'spin 1s linear infinite',
};

const messageContainerStyle = {
  padding: '15px',
  borderRadius: '4px',
  marginBottom: '20px',
  fontWeight: 'bold',
  textAlign: 'center', 
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '10px',
    borderRadius: '5px',
    position: 'fixed',
    top: '20px',
    right: '20px',
    zIndex: '1000',
};

const emptyTextStyle = {
  textAlign: 'center',
  color: style.Color.colorDarkGrey,
  fontSize: '16px',
  marginTop: '20px',
};

export default DraftGroupWishes;
import React, { createContext, useState, useContext, useCallback, useMemo, useEffect } from 'react';
import { debounce } from 'lodash';
import { getAuth, onAuthStateChanged, onIdTokenChanged, signOut } from 'firebase/auth';

const AuthContext = createContext();

const initialAuthState = {
  idTokenLoggedIn: null,
  countryCodeLoggedIn: null,
  phoneLoggedIn: null,
  emailLoggedIn: null,
  loginEmail: false,
  isBacksAppStaff: false,
  isBacksAppManager: false,
  userVerified: false,
  firstName: null,
  queryLoginParam: null,
  lastLoginTime: null,
  logInRequested: false,
  logOutRequested: false,
  user: null,
  verificationCooldownUntil: null,
  isMerchant: null,
  isMerchantVerified: null,
  company:null,
};

export const AuthProvider = ({ children }) => { 
  const [user, setUser] = useState(null);
  const [authState, setAuthState] = useState(initialAuthState);

  
  const updateAuthState = useCallback((newState) => {
    setAuthState(prevState => {
      const updatedState = { ...prevState, ...newState }; 
      return updatedState;
    });
  }, []);

  const clearLocalStorage = useCallback(async () => {
    if (authState.logOutRequested) {
      return;
    }
    
    try { 
      const auth = getAuth();
      await signOut(auth);  
      localStorage.clear();     
      
      setAuthState(initialAuthState); 
      setUser(null); 
    } catch (error) {
      console.error('Logout failed:', error);
    }
  }, [authState.logOutRequested, updateAuthState]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribeAuthState = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser);
        updateAuthState({ user: firebaseUser,emailLoggedIn: firebaseUser.email, logOutRequested: false, logInRequested: true });
      } else {
        setUser(null);
        setAuthState(initialAuthState);
      }
    }); 

    const unsubscribeIdToken = onIdTokenChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken(true);   
        updateAuthState({ 
          idTokenLoggedIn: token, 
          logOutRequested: false,
          emailLoggedIn: user.email,
          loginEmail: true
        });
      } else {
        updateAuthState({ idTokenLoggedIn: null });
      }
    });

    return () => {
      unsubscribeAuthState();
      unsubscribeIdToken();
    };
  }, [updateAuthState]);

  const refreshToken = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      try {
        const newToken = await user.getIdToken(true); // Force refresh
        updateAuthState({ idTokenLoggedIn: newToken });
        return newToken;
      } catch (error) {
        console.error('Token refresh failed:', error);
        return null;
      }
    }
    return null;
  };

  const debouncedRefreshToken = useMemo(
    () => debounce(refreshToken, 1000, { leading: true, trailing: false }),
    [refreshToken]
  );  

  const login = async (accessToken, uid, email, isBacksAppStaff,  isBacksAppManager,firstName, userVerified, isMerchant, isMerchantVerified, companyName) => {    
    localStorage.clear();
    const is_merchant_user = `loginEmail=true`;
    const merchant_email = `&email=${encodeURIComponent(email)}`;
    const queryParams = `${is_merchant_user}${merchant_email}`; 
  
    if (!authState.logOutRequested) {
      try {
        localStorage.setItem('auth_token', accessToken);
        localStorage.setItem('firebase_uid', uid);
        localStorage.setItem('merchant_email', email);
        localStorage.setItem('loginEmail', 'true');
        localStorage.setItem('queryParam', queryParams);
        localStorage.setItem('isBacksAppStaff', String(isBacksAppStaff));
        localStorage.setItem('firstName', firstName);
        localStorage.setItem('isBacksAppManager', String(isBacksAppManager));
        localStorage.setItem('userVerified', String(userVerified));
        localStorage.setItem('company', String(companyName));
        localStorage.setItem('isMerchant', String(isMerchant));
        localStorage.setItem('isMerchantVerified', String(isMerchantVerified));
  
        const auth = getAuth();
        const currentUser = auth.currentUser;
        setUser(currentUser);
  
        const newAuthState = {
          idTokenLoggedIn: accessToken,
          emailLoggedIn: email,
          loginEmail: true,
          isBacksAppStaff: Boolean(isBacksAppStaff),
          isBacksAppManager: Boolean(isBacksAppManager),
          userVerified: Boolean(userVerified),
          company:companyName,
          firstName: firstName,
          queryLoginParam: queryParams,
          logOutRequested: false,
          logInRequested: true,
          user: currentUser,
          isMerchant: Boolean(userVerified),
          isMerchantVerified: Boolean(userVerified)
        };
  
        updateAuthState(newAuthState);
  
        return true;
      } catch (error) {
        console.error('Login failed:', error);
        return false;
      }finally{
        checkVerificationCooldown();
      }
    }
  };

  const returningUser = async () => {
    try {
      const token = localStorage.getItem('auth_token');
      const countryCode = localStorage.getItem('countryCode');
      const phoneNumber = localStorage.getItem('phoneNumber');
      const email = localStorage.getItem('email');
      const loginEmail = localStorage.getItem('loginEmail') === 'true';
      const isBacksAppStaff = localStorage.getItem('isBacksAppStaff') === 'true';
      const isBacksAppManager = localStorage.getItem('isBacksAppManager') === 'true';
      const userVerified = localStorage.getItem('userVerified') === 'true';
      const isMerchant = localStorage.getItem('isMerchant') === 'true';
      const isMerchantVerified = localStorage.getItem('isMerchantVerified') === 'true';
      const company = localStorage.getItem('company');
      const queryParam = localStorage.getItem('queryParam');
      const firstName = localStorage.getItem('firstName'); 

      if (token) {
        const newToken = await refreshToken();
        if (newToken) {
          const newAuthState = {
            idTokenLoggedIn: newToken,
            countryCodeLoggedIn: countryCode,
            phoneLoggedIn: phoneNumber,
            emailLoggedIn: email,            
            loginEmail: loginEmail,
            isBacksAppStaff: isBacksAppStaff,
            isBacksAppManager: isBacksAppManager,
            userVerified:userVerified,
            isMerchant: isMerchant,
            isMerchantVerified: isMerchantVerified,
            firstName: firstName,
            queryLoginParam: queryParam,
            company:company,
          };
           
          updateAuthState(newAuthState);
          return true;
        }
      }
      return false;
    } catch (error) {
      console.error('Returning user check failed:', error);
      return false;
    }finally{
      checkVerificationCooldown();
    }
  };

  const setVerificationCooldown = useCallback(() => {
    const cooldownTime = new Date();
    cooldownTime.setHours(cooldownTime.getHours() + 4);
    localStorage.setItem('verificationCooldownUntil', cooldownTime.toISOString());
    updateAuthState({ verificationCooldownUntil: cooldownTime });
  }, [updateAuthState]);

  const checkVerificationCooldown = useCallback(() => {
    const cooldownUntil = localStorage.getItem('verificationCooldownUntil');
    if (cooldownUntil) {
      const cooldownTime = new Date(cooldownUntil);
      if (new Date() < cooldownTime) {
        updateAuthState({ verificationCooldownUntil: cooldownTime });
        return cooldownTime;
      } else {
        localStorage.removeItem('verificationCooldownUntil');
        updateAuthState({ verificationCooldownUntil: null });
      }
    }
    return null;
  }, [updateAuthState]);

  useEffect(() => {
    checkVerificationCooldown();
  }, [checkVerificationCooldown]);

  return (
    <AuthContext.Provider value={{ ...authState,user, login, clearLocalStorage, refreshToken: debouncedRefreshToken, returningUser, updateAuthState,checkVerificationCooldown }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Common/InitialGears/AuthContext';
import { getApi, buildUrl } from "../../Common/BackendCalls/apiUtils";

const useGroupWishFilters = () => {
    const navigate = useNavigate(); 
  const { queryLoginParam } = useAuth();

  const fetchHighestSavingsWishes = async () => {
    try {
      const url = buildUrl('getGroupWishesByHighestSavings', queryLoginParam);
      const response = await getApi(url);
      
      if (response.ok) {
        return response.data.wishes || [];
      } else {
        if (response.status === 423) {
          navigate("/");
        }
        throw new Error(response.data.message || 'Failed to fetch highest savings wishes');
      }
    } catch (error) {
      console.error('Error fetching highest savings wishes:', error);
      throw error;
    }
  };

  const fetchFastestApproachingWishes = async () => {
    try {
      const url = buildUrl('getGroupWishesByFastestApproaching', queryLoginParam);
      const response = await getApi(url);
      
      if (response.ok) {
        return response.data.wishes || [];
      } else {
        if (response.status === 423) {
          navigate("/");
        }
        throw new Error(response.data.message || 'Failed to fetch fastest approaching wishes');
      }
    } catch (error) {
      console.error('Error fetching fastest approaching wishes:', error);
      throw error;
    }
  };

  return { fetchHighestSavingsWishes, fetchFastestApproachingWishes };
};
 
export default useGroupWishFilters;
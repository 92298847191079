import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; 
import { useAuth } from '../../../components/Common/InitialGears/AuthContext';
import * as style from "../../../GlobalStyles";
import NewInvites from './NewInvites';
import SentInvites from './SentInvites';
import ReceivedInvites from './ReceivedInvites';
import Modal from './Modal'; 
import { FaEnvelope, FaPhone, FaShoppingCart } from 'react-icons/fa';
import { MdDashboard } from 'react-icons/md';

const InvitesPage = () => {
  const [isDashboardOpen, setIsDashboardOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('new');
  const navigate = useNavigate();
  const location = useLocation();
  const { queryLoginParam, idTokenLoggedIn } = useAuth();

  useEffect(() => {
    const path = location.pathname.split('/').pop();
    if (path === 'invites') {
      setIsDashboardOpen(true);
      setActiveTab('new');
    }
  }, [location]);

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.textContent = styles;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  const handleOpenDashboard = () => {
    setIsDashboardOpen(true);
    navigate('/invites');
  };

  const handleCloseDashboard = () => {
    setIsDashboardOpen(false);
    navigate(-1);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderActiveContent = () => {
    switch (activeTab) {
      case 'new':
        return <NewInvites />;
      case 'sent':
        return <SentInvites />;
      case 'received':
        return <ReceivedInvites />;
      default:
        return <NewInvites />;
    }
  };

  return (
    <div className="invites-page-container">
       

      <Modal isOpen={isDashboardOpen} onClose={handleCloseDashboard} title="Invites Dashboard">
        <div className="invites-dashboard">
          <div className="invites-menu">
            <button className={`menu-item ${activeTab === 'new' ? 'active' : ''}`}  onClick={() => handleTabChange('new')}>
            <FaEnvelope /> New Invite
            </button>
            <button  className={`menu-item ${activeTab === 'sent' ? 'active' : ''}`}  onClick={() => handleTabChange('sent')}>
            <FaPhone /> Sent Invites
            </button>
            <button className={`menu-item ${activeTab === 'received' ? 'active' : ''}`}  onClick={() => handleTabChange('received')}>
            <FaShoppingCart /> Received Invites
            </button>
          </div>
          <div className="invites-content">
            {renderActiveContent()}
          </div>
        </div>
      </Modal>
    </div>
  );
}

// CSS styles
const styles = `
  .invites-page-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }

  .open-dashboard-btn {
    padding: 10px 20px;
    background-color: ${style.Color.colorPurple};
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }

  .open-dashboard-btn:hover {
    background-color: ${style.Color.colorPurple};
  }

  .invites-dashboard {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .invites-menu {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid none;
    padding-bottom: 10px;
  }

  .menu-item {
    padding: 10px 20px;
    background-color: transparent;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    margin-right: 20px;
    background-color: ${style.Color.colorMintGreen};
  }

  .menu-item:hover {
    background-color: ${style.Color.colorMintGreen};
  }

  .menu-item.active {
    background-color: ${style.Color.colorPurple};
    color: white;
  }

  .invites-content {
    flex-grow: 1;
    overflow-y: auto; 
  }
`;

export default InvitesPage;
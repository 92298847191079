import React, { useState } from 'react';
import styled from 'styled-components';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Config from "../../../config"; 
import { Color } from '../../../GlobalStyles';
import spereImage from '../../../assets/images/Original_Login.png';
import ForgotPasswordModal from './ForgotPasswordModal';
import {setAuthToken} from './authLoginUtils';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  border-radius: 16px;
  padding: 24px;
  width: 70%;
  max-width: 400px;
  height: 90%;
  max-height: 1500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${spereImage});
    background-size: cover;
    background-position: center;
    z-index: -2;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //background: linear-gradient(to right, #C77B58, #B76E79, #C77B58);
    background: linear-gradient(
      to right,
      rgba(199, 123, 88, 0.1),
      rgba(183, 110, 121, 0.4),
      rgba(199, 123, 88, 0.2)
    );
    z-index: -1;
  }
`;


const TitleContainer = styled.div` 
  padding: 20px;
  border-radius: 12px;
  margin-top: 55%;
  width: 90%;
`;

const FormContainer = styled.div`
  padding: 20px;
  border-radius: 12px;
  margin-top: 10%;
  width: 90%;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  color: ${Color.colorLightBeige};
  margin-bottom: 8px;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 0.9rem;
  color: ${Color.colorLightBeige};
  margin-bottom: 16px;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin: 16px 0;
  padding: 12px 0;
  border: none;
  border-bottom: 1px solid ${Color.colorLightestBeige};
  font-size: 16px;
  background-color: transparent;
  color: ${Color.colorWhite};
  transition: border-color 0.3s ease;

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }

  &:focus {
    outline: none;
    border-bottom-color: ${Color.colorPurpleBlue};
  }
`;

const LoginText = styled.span`
  margin: 24px 0;
  margin-left: 45%;
  color: ${Color.colorWhite};
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
  display: inline-block;

  &:hover {
    color: ${Color.colorPurpleBlue};
  }
`;

const ErrorMessage = styled.div`
  color: ${Color.colorRed};
  margin-bottom: 8px;
  text-align: center;
  font-size: 12px;
`;

const Links = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const Link = styled.a`
  color: ${Color.colorWhite};
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;

  &:hover {
    color: ${Color.colorPurpleBlue};
  }
`;


const CloseButton = styled.button`
  position: absolute;
  top: 3%;
  right: 5%;
  background: none;
  border: none;
  font-size: 40px;
  color: ${Color.colorWhite};
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: ${Color.colorPurpleBlue};
  }

  &:focus {
    outline: none;
  }
`;

const LoginModal = ({ onClose, openPhoneLogin, openRegisterModal }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginMethod, setLoginMethod] = useState('email');
  const navigate = useNavigate();
  const { login } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const { knownUser, isBacksAppStaff, isBacksAppManager, firstName,userVerified ,isMerchant, isMerchantVerified, company} = await verifyKnownMerchant(email);
      
      if (knownUser) {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        const accessToken = await user.getIdToken();
        const uid = user.uid;
        if (!uid || !accessToken) {
          setErrorMessage("Something went wrong");
          return;
        }
        setAuthToken(accessToken);
        await setLoginParams(accessToken, uid, email, isBacksAppStaff, isBacksAppManager,  firstName, userVerified, isMerchant,isMerchantVerified, company);
        navigate('/dashboard');
      } else {
        setErrorMessage("User not recognized as a known merchant");
      }
    } catch (error) {  
      setErrorMessage(`Failed to sign in. Error: ${error.message}`);
    }
  };

  const setLoginParams = async (accessToken, uid, email, isBacksAppStaff,isBacksAppManager,  firstName, userVerified,isMerchant,isMerchantVerified, company) => {
    const success = await login(accessToken, uid, email, isBacksAppStaff,isBacksAppManager,   firstName,userVerified,isMerchant,isMerchantVerified, company);
    if (!success) {
      throw new Error("Failed to verify merchant");
    }
  };

  const verifyKnownMerchant = async (email) => { 
    const queryParams = `loginEmail=true&email=${encodeURIComponent(email)}`;
    const verifyKnownMerchant = `${Config.apiUrl}verifyKnownMerchant?${queryParams}`;
     
    try {
      const response = await fetch(verifyKnownMerchant, {
        headers: { 'Content-Type': 'application/json' }
      });
      if (!response.ok) { 
        if (response.status === 500) { 
          openRegisterModal();
        }
      } else {
          const data = await response.json();
           
          return {
            knownUser: data.user_exists,
            isBacksAppStaff: data.isBacksAppStaff,
            isBacksAppManager: data.isBacksAppManager,
            firstName: data.firstName,
            userVerified: data.userVerified,
            isMerchant: data.isMerchant,
            isMerchantVerified: data.isMerchantVerified,
            company: data.company,
          };
        }
    } catch (error) { 
      throw error;
    }
  };


  const handleRegisterClick = (e) => {
    e.preventDefault();
    onClose();
    openRegisterModal();
  };

  const handleForgotPasswordClick = (e) => {
    e.preventDefault();
    setShowForgotPassword(true);
  };

  return (
    <>
      <ModalOverlay onClick={onClose}>
        <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
          <TitleContainer>
            <Title>BacksApp</Title>
            <Subtitle>Login to your account</Subtitle>            
          </TitleContainer>
          <FormContainer>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            
            <Form onSubmit={handleLogin}>
              <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required/>
              <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required/>
              <LoginText onClick={handleLogin}>Login</LoginText>
            </Form>
            
            <Links>
              <Link href="#" onClick={handleRegisterClick}>Need an account? Register</Link>
              <Link href="#" onClick={handleForgotPasswordClick}>Forgot Password?</Link>
            </Links>

            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <Link href="#" onClick={openPhoneLogin}>
                Login with Phone Number
              </Link>
          </div>
          </FormContainer>
        </ModalContent>
      </ModalOverlay>
      {showForgotPassword && (
        <ForgotPasswordModal onClose={() => setShowForgotPassword(false)} />
      )}
    </>
  );
};

export default LoginModal;
const env = process.env.REACT_APP_ENV || 'qa';
 
const config = {
  dev: {
    apiUrl: process.env.REACT_APP_API_URL || 'https://qa-backsapp.uc.r.appspot.com/',
    googleMapsAPIKEY: 'AIzaSyAYC4TbcxsJQD-0XR_3lbrB-1Ca9TQAXYE',
    openaiKey:'sk-proj-RtnXHVMMTGcbKonLECxKT3BlbkFJ84FMdl9CowSnXJxlxFFq' ,
  },
  qa: {
    apiUrl: process.env.REACT_APP_API_URL || 'https://qa-backsapp.uc.r.appspot.com/',
    openaiKey:'sk-proj-RtnXHVMMTGcbKonLECxKT3BlbkFJ84FMdl9CowSnXJxlxFFq'  ,
  },
  production: {
    apiUrl: process.env.REACT_APP_API_URL || 'https://api.example.com/',
    openaiKey:'sk-proj-RtnXHVMMTGcbKonLECxKT3BlbkFJ84FMdl9CowSnXJxlxFFq'  ,
  }
}; 
export default config[env];
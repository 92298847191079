import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Config from "../../config"; 
import styles from './Profiles.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faUserSecret, faEnvelopeOpenText,faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../components/Common/InitialGears/AuthContext';   
import AddressSection from "./AddressSection"; 
import MultipleAddressesSection from './MultipleAddressSection';
import ProfileImage from '../../assets/images/boy.png';
import {TopMenuBar} from '../Buyers/DashbordComponents/TopMenuBar';
import { getApi, postApi, buildUrl } from "./BackendCalls/apiUtils";

const Profiles = () => {
  useAuth();
  const navigate = useNavigate();
  const { logout, queryLoginParam, idTokenLoggedIn } = useAuth(); 
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userInfoUpdateAllowed, setUserInfoUpdateAllowed] = useState(false);  

  const [address, setAddressData] = useState({}); 
  const [commData, setCommData] = useState({}); 
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [isImageLoading, setIsImageLoading] = useState(true); 
  const [expandedAddress, setExpandedAddress] = useState(false); 
  const [editCommMode, setEditCommMode] = useState(false); 
  const [primaryAddressId, setPrimaryAddressId] = useState(null);
  const [primaryAddress, setPrimaryAddress] = useState(null);
  const [otherAddresses, setOtherAddresses] = useState([]);
  const [isAddressLoading, setIsAddressLoading] = useState(true);
  const [isOtherAddressLoading, setIsOtherAddressLoading] = useState(true);  

  const [editFirstName, setEditFirstName] = useState(false);
  const [editMiddleName, setEditMiddleName] = useState(false);
  const [editLastName, setEditLastName] = useState(false);
  const [editUserName, setEditUserName] = useState(false);
  const [editEmail, setEditEmail] = useState(false);

  // User Name
  const [nameData, setNameData] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    user_name: ''
  });
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');

  // State variables for Communication 
  const [newEmail, setEmail] = useState(commData.email || ''); 
  const [editPhone, setEditPhone] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [showVerificationInput, setShowVerificationInput] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [tempPhoneNumber, setTempPhoneNumber] = useState('');
  const [verificationTimer, setVerificationTimer] = useState(0);
  const [canResend, setCanResend] = useState(false); 

  useEffect(() => { 
    fetchUpdateRestrictions(); 
    fetchProfileData();
    fetchProfilePictureUrl(); 
    fetchAddresses();
    setSuccessMessage("");
    setErrorMessage("");
  }, []);
 
  useEffect(() => {
    setFirstName(nameData.first_name || '');
    setMiddleName(nameData.middle_name || '');
    setLastName(nameData.last_name || '');
    setUsername(nameData.user_name || ''); 
  }, [nameData]);

  useEffect(() => {  
    setAddressData(address || '');
  }, [address]);

  useEffect(() => {  
    setEmail(commData.email || '');
  }, [commData]);
  
  useEffect(() => {
    let interval;
    if (verificationTimer > 0) {
      interval = setInterval(() => {
        setVerificationTimer((prev) => prev - 1);
      }, 1000);
    } else if (verificationSent) {
      setCanResend(true);
    }
    return () => clearInterval(interval);
  }, [verificationTimer]);
 
  const sendVerificationCode = async (phoneNum) => {
    setIsVerifying(true);
    setVerificationSent(false);
    setCanResend(false);
    try {
      const verifyUrl = `${Config.apiUrl}sendPhoneVerification?${queryLoginParam}`;
      const requestBody = { phone_number: phoneNum };
      const headers = { 'Content-Type': 'application/json', 'Authorization': idTokenLoggedIn };
      
      const response = await fetch(verifyUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestBody)
      });

      if (response.ok) {
        setVerificationSent(true);
        setShowVerificationInput(true);
        setVerificationTimer(60);  
        setSuccessMessage('Verification code sent! Please check your phone.');
      } else {
        throw new Error('Failed to send verification code');
      }
    } catch (error) {
      setErrorMessage('Failed to send verification code. Please try again.');
    } finally {
      setIsVerifying(false);
    }
  };

  const verifyCodeAndSavePhone = async () => {
    setIsVerifying(true);
    try {
      const verifyCodeUrl = `${Config.apiUrl}verifyPhone?${queryLoginParam}`;
      const requestBody = {
        phone_number: tempPhoneNumber,
        verification_code: verificationCode
      };
      const headers = { 'Content-Type': 'application/json', 'Authorization': idTokenLoggedIn };
      
      const response = await fetch(verifyCodeUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestBody)
      });

      if (response.ok) {
        // If verification successful, save the phone number
        await handlePhoneUpdate(tempPhoneNumber);
        setShowVerificationInput(false);
        setVerificationCode('');
        setVerificationSent(false);
        setSuccessMessage('Phone number verified and saved successfully!');
      } else {
        setErrorMessage('Invalid verification code. Please try again.');
      }
    } catch (error) {
      setErrorMessage('Failed to verify code. Please try again.');
    } finally {
      setIsVerifying(false);
    }
  };

  // Modified phone update handler
  const handlePhoneUpdate = async (verifiedPhone) => {
    setIsLoading(true);
    try {
      const updateUrl = `${Config.apiUrl}phone?${queryLoginParam}`;
      const requestBody = { phone_number: verifiedPhone };
      const headers = { 'Content-Type': 'application/json', 'Authorization': idTokenLoggedIn };
      
      const response = await fetch(updateUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestBody)
      });

      if (response.ok) {
        setPhoneNumber(verifiedPhone);
        setEditPhone(false);
        setSuccessMessage('Phone number updated successfully');
      } else {
        throw new Error('Failed to update phone number');
      }
    } catch (error) {
      setErrorMessage('Failed to save phone number. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle initial phone number submission
  const handlePhoneSubmit = (newPhone) => {
    if (validatePhoneNumber(newPhone)) {
      setTempPhoneNumber(newPhone);
      sendVerificationCode(newPhone);
    } else {
      setErrorMessage('Please enter a valid phone number');
    }
  };

  const fetchProfilePictureUrl = async () => {
    setIsImageLoading(true); 
    try {
      let profilePictureApiUrl = buildUrl(`profilePicture`,queryLoginParam);  
      const response = await getApi(profilePictureApiUrl ); 
      if (!response.ok) {
        if (response.status === 426) { 
          setErrorMessage('Your session has expired. Please log in again.');  
          navigate("/")
        } else {
          throw new Error('Failed to fetch profile picture');
        }
      }
      const blob = await response.blob(); 
      const imageUrl = URL.createObjectURL(blob); 
      setProfileImageUrl(imageUrl);
    } catch (error) { 
      setProfileImageUrl(ProfileImage);
    } finally {
      setIsImageLoading(false);
      setLoading(false);
    }
  };

  const fetchUpdateRestrictions = async () => {
    try {
      const updateAllowedChecksUrl = buildUrl(`userInfoUpdateAllowed`,queryLoginParam); 
      const response = await getApi(updateAllowedChecksUrl );
      
      if (response.ok) { 
        setUserInfoUpdateAllowed(response.userInfo_status_update_allowed);
      }
    } catch (error) {
      setErrorMessage('An error occurred while fetching user information update status');
    }
  };

  const fetchProfileData = async () => {
    fetchProfilePictureUrl();
    const apiUrls = {
      address: buildUrl(`address`,queryLoginParam),
      name: buildUrl(`name`,queryLoginParam), 
      communication: buildUrl(`userCommunication`,queryLoginParam), 
    }; 

    try {
      const [nameResponse, commResponse, phoneResponse] = await Promise.all([
        getApi(apiUrls.name),
        getApi(apiUrls.communication), 
      ]);
  
      if (nameResponse.ok) {
        const nameData = await nameResponse.json();
        setNameData(nameData);
        setFirstName(nameData.first_name || '');
        setMiddleName(nameData.middle_name || '');
        setLastName(nameData.last_name || '');
        setUsername(nameData.user_name || '');
      }
  
      if (commResponse.ok) {
        const commData = await commResponse.json();
        setCommData(commData);
        setEmail(commData.email || '');
        setPhoneNumber(commData.phone_number || '');
      } 
  
      if (!nameResponse.ok && nameResponse.status === 423) { 
        setErrorMessage('Your session has expired. Please log in again.');  
        navigate("/");
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try later");
    }
  };
  const validatePhoneNumber = (number) => { 
    const phoneRegex = /^\+?[\d\s-]{10,}$/;
    return phoneRegex.test(number);
  };

  const fetchAddresses = async () => {
    setIsAddressLoading(true);
    setIsOtherAddressLoading(true);
    try {
      const addressesUrl = `${Config.apiUrl}address?${queryLoginParam}`;
      const headers = { 'Content-Type': 'application/json', 'Authorization': idTokenLoggedIn };
      const response = await fetch(addressesUrl, {headers});
      const data = await response.json();
      if (response.ok){ 
        setPrimaryAddress(data.primary_address || null);
        setPrimaryAddressId(data.primary_address ? data.primary_address.id : null);
        if (Array.isArray(data.other_addresses)) {   
          setOtherAddresses(data.other_addresses); 
        } else { 
          setPrimaryAddress(null);
          setOtherAddresses([]);
          setPrimaryAddressId(null);
        }        
      }else{
        //
      }
    } catch (error) {
      setPrimaryAddress(null);
      setOtherAddresses([]);
    }finally{
      setIsAddressLoading(false);
      setIsOtherAddressLoading(false);
    }
  };
  
  const updateProfilePicture = async (file) => {
    setIsLoading(true);
    setIsLoading(true);
    try {
      const profilePictureApiUrl = buildUrl(`profilePicture`, queryLoginParam); 
      const formData = new FormData();
      formData.append("file", file);
      const headers = { 'Authorization': idTokenLoggedIn };
      const response = await fetch(profilePictureApiUrl, { 
        method: 'POST', 
        headers: headers, 
        body: formData 
      });  

      if (response.ok) {
        await fetchProfilePictureUrl();
        setSuccessMessage("Profile picture updated successfully");
      } else {         
        setErrorMessage("Failed to update profile picture. Please try again."); 
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try later");
    } finally {
      setIsLoading(false);
      setIsImageLoading(false);
    }
  };

  const openImagePicker = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (event) => {
      const file = event.target.files[0];
      if (file) {
        updateProfilePicture(file);
      }
    };
    input.click();
  };
 
  const handleFirstNameUpdate = async () => { 
    setEditFirstName(false);
    setIsLoading(true);
    try { 
      const requestBody = { first_name: firstName };  
      const updateUrl = buildUrl(`name`, queryLoginParam);
      const response = await postApi(updateUrl, requestBody);
      if (response.ok) {
        setNameData(prevState => ({ ...prevState, first_name: firstName }));
        fetchProfileData();
        setSuccessMessage('First Name Updated');
      } else {
        setErrorMessage("Something went wrong. Please try later"); 
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try later");
    } finally {
      setIsLoading(false);
    }
  };

  const handleMiddleNameUpdate = async () => { 
    setEditMiddleName(false);
    setIsLoading(true);
    try { 
      const updateUrl = buildUrl(`name`, queryLoginParam);
      const requestBody = { middle_name: middleName };
      const response = await postApi(updateUrl, requestBody);  
      if (response.ok) {
        setNameData(prevState => ({ ...prevState, middle_name: middleName }));
        fetchProfileData();
        setSuccessMessage('Middle Name Updated');
      } else {
        setErrorMessage("Something went wrong. Please try later"); 
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try later");
    } finally {
      setIsLoading(false);
    }
  };

  const handleLastNameUpdate = async () => { 
    setEditLastName(false);
    setIsLoading(true); 
    try {
      const updateUrl = buildUrl(`name`, queryLoginParam); 
      const requestBody = { last_name: lastName }; 
      const response = await postApi(updateUrl, requestBody);    
      if (response.ok) {
        setNameData(prevState => ({ ...prevState, last_name: lastName }));
        fetchProfileData();
        setSuccessMessage('Last Name Updated');
      } else {
        setErrorMessage("Something went wrong. Please try later"); 
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try later");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailUpdate = async () => {
    setEditCommMode(false); 
    setIsLoading(true); 
    try { 
      const requestBody = { email: newEmail };  
      const updateUrl = buildUrl(`updateEmail`, queryLoginParam);  
      const response = await postApi(updateUrl, requestBody);      
      if (response.ok) {
        await fetchProfileData();
        setSuccessMessage('Email Updated');
      } else {
        await fetchProfileData();
        setErrorMessage("Something went wrong. Please try later"); 
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try later"); 
      await fetchProfileData();
    } finally {
      setIsLoading(false);
    }
  }; 

  const handleAddressSave = async (newAddress, isPrimaryAddress) => { 
    setIsLoading(true); 
    const addressUrl = buildUrl(`address`, queryLoginParam);  
    const requestBody = {
      address1: newAddress.address1,
      address2: newAddress.address2,
      city: newAddress.city,
      state: newAddress.state,
      country: newAddress.country,
      postal_code: newAddress.postal_code,
      primary_residence: isPrimaryAddress,
    };
      
    try {
      const response = await postApi(addressUrl, requestBody);     
      
      if (response.ok) {
        const savedAddress = await response.json();
        if (isPrimaryAddress) {
          setPrimaryAddress(savedAddress);
          setPrimaryAddressId(savedAddress.id);
          // Move the previous primary address to otherAddresses if it exists
          if (primaryAddress) {
            setOtherAddresses(prevAddresses => [...prevAddresses, {...primaryAddress, primary_residence: false}]);
          }
        } else {
          setOtherAddresses(prevAddresses => [...prevAddresses, savedAddress]);
        }
        setSuccessMessage("Address updated");
        setExpandedAddress(false);
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.detail || "Something went wrong. Please try later");
      }
    } catch (error) { 
      setErrorMessage('Failed to update address. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.mainContainer}>
     <TopMenuBar showBackButton={false} showHomeButton={true} onBackClick={() => navigate(-1)} title='My Profile' showSearch={false} showAddress={false} /> 
    <div className={styles.profile_container}>
      <div className={styles.profile_content}>
                
        {successMessage && <div className={styles.success_message}>{successMessage}</div>}
        {errorMessage && <div className={styles.error_message}>{errorMessage}</div>}
        {isLoading && <div className={styles.loading}>Loading...</div>}
        
        <div className={styles.profile_sections}>
          <div className={styles.section}>
            <div className={styles.section_header}>
              <FontAwesomeIcon icon={faUserSecret} />
              <h2>Personal Information</h2>
            </div>
            <div className={styles.section_content}>
              <div className={styles.name_field}>
                <label>First Name:</label>
                {editFirstName ? (
                  <input value={firstName || ''} onChange={(e) => setFirstName(e.target.value)} onBlur= {handleFirstNameUpdate} autoFocus/>
                ) : (
                  <span onClick={() => setEditFirstName(true)}>
                    {nameData.first_name || "No name provided."}
                  </span>
                )}
              </div>
              <div className={styles.name_field}>
                <label>Middle Name:</label>
                {editMiddleName ? (
                  <input value={middleName} onChange={(e) => setMiddleName(e.target.value)}  onBlur={handleMiddleNameUpdate} autoFocus/>
                ) : (
                  <span onClick={() => setEditMiddleName(true)}>
                    {nameData.middle_name || "No name provided."}
                  </span>
                )}
              </div>
              <div className={styles.name_field}>
                <label>Last Name:</label>
                {editLastName ? (
                  <input value={lastName} onChange={(e) => setLastName(e.target.value)} onBlur={handleLastNameUpdate} autoFocus/>
                ) : (
                  <span onClick={() => setEditLastName(true)}>
                    {nameData.last_name || "No name provided."}
                  </span>
                )}
              </div>
              <div className={styles.name_field}>
                <label>User Name:</label>                 
                  <span onClick={() => setEditUserName(true)}>
                    {username || "No user name provided."}
                  </span>
                
              </div> 
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.section_header}>
              <FontAwesomeIcon icon={faEnvelopeOpenText} />
              <h2>Contact Information</h2>
            </div>
            <div className={styles.section_content}>
              <div className={styles.name_field}>
                <label>Email:</label>                
                  <span onClick={() => setEditEmail(true)}>
                    {commData.email || "No Email available"}
                  </span>            
              </div> 
              <div className={styles.name_field}>
                  <label>Phone:</label>
                  {editPhone ? (
                    <div className={styles.verification_container}>
                      <div className={styles.edit_field}>
                        <input
                          type="tel"
                          value={tempPhoneNumber}
                          onChange={(e) => setTempPhoneNumber(e.target.value)}
                          placeholder="Enter phone number"
                          disabled={showVerificationInput}
                        />
                        {!showVerificationInput && (
                          <button 
                            onClick={() => handlePhoneSubmit(tempPhoneNumber)}
                            disabled={isVerifying}
                            className={styles.verify_button}
                          >
                            Send Code
                          </button>
                        )}
                      </div>
                      
                      {showVerificationInput && (
                        <div className={styles.verification_section}>
                          <input
                            type="text"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                            placeholder="Enter verification code"
                            maxLength={6}
                            className={styles.verification_input}
                          />
                          <div className={styles.verification_actions}>
                            <button onClick={verifyCodeAndSavePhone} disabled={isVerifying || verificationCode.length !== 6} className={styles.verify_button}>
                              Verify & Save
                            </button>
                            {verificationTimer > 0 ? (
                              <span className={styles.timer}>Resend in {verificationTimer}s</span>
                            ) : (
                              <button
                                onClick={() => sendVerificationCode(tempPhoneNumber)}
                                disabled={!canResend}
                                className={styles.resend_button}
                              >
                                Resend Code
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                      <button
                        onClick={() => {
                          setEditPhone(false);
                          setShowVerificationInput(false);
                          setVerificationCode('');
                          setTempPhoneNumber('');
                        }}
                        className={styles.cancel_button}
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <span onClick={() => setEditPhone(true)}>
                      {phoneNumber || "No phone number available"}
                      <FontAwesomeIcon icon={faPencilAlt} className={styles.edit_icon} />
                    </span>
                  )}
                </div>
            </div>
          </div>
          
          <div className={styles.section}>
            <div className={styles.section_header}>
              <FontAwesomeIcon icon={faMapMarker} />
              <h2>Primary Address</h2>
            </div>
            {isAddressLoading ? (
              <div className={styles.loading}>Loading address...</div>
            ) : primaryAddress ? (
              <AddressSection address={primaryAddress} primaryAddress={true} handleAddressSave={(newAddress) => handleAddressSave(newAddress, true)}/>
            ) : (
              <div className={styles.no_address}>No primary address found.</div>
            )}
          </div>
                    
          
          <div className={styles.section}>
            <div className={styles.section_header}>
              <FontAwesomeIcon icon={faMapMarker} />
              <h2>Additional Addresses</h2>
            </div>
            {isOtherAddressLoading ? (
              <div className={styles.loading}>Loading address...</div>
            ) : otherAddresses ? (
              <MultipleAddressesSection 
              addresses={otherAddresses}
              primaryAddressId={primaryAddressId} 
              onAddNewAddress={(newAddress) => handleAddressSave(newAddress, false)}
            />
            ) : (
              <div className={styles.no_address}>No other address found.</div>
            )}
            
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};


export default Profiles;
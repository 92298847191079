import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faUsers, faSearch, faTimes, faImage, faClock, faFire, faFilter,faChevronLeft, faChevronRight  } from '@fortawesome/free-solid-svg-icons';
import { format, parseISO, formatDistanceToNow } from 'date-fns';
import Config from "../../../config"; 
import { useAuth } from '../../Common/InitialGears/AuthContext'; 
import * as style from "../../../GlobalStyles";
import DownRateSlider from './DownRateSlider'; 
import TermsAndConditionsModal from './JoiningTermsAndConditions';
import CreateTermsAndConditionsModal from './TermsOfUse';
import shootingStarImage from '../../../assets/images/shooting-star.png';
import meetingImage from '../../../assets/images/meeting.png';
import newImage from '../../../assets/images/new-employee.png';
import completedImage from '../../../assets/images/idealistic.png'; 
import MyWishesModal from "../Wishes/MyWishesModal"; 
import TrendingDetailsModal from '../Wishes/TrendingDetailsModal';
import trendingImage from '../../../assets/images/trending.png';
import newWishAIRequestImage from '../../../assets/images/think-out-of-the-box.png';
import cargoImage from '../../../assets/images/cargo.png';
import JoinedGroupWishes from './JoinedGroupWishes';
import JoinGroupWishModal from './JoinGroupWishModal';
import CompletedGroupWishModal from './CompletedGroupWishes';
import AddWishModal from '../Wishes/AddWish';
import useGroupWishFilters from './UseGroupWishFilters'; 
import {getAuthToken} from '../../Common/InitialGears/authLoginUtils';
import { getApi, postApi, buildUrl} from "../../Common/BackendCalls/apiUtils";
import LoadingSpinnerSection from "./LoadingSpinnerSection";

const GroupWishLists = ({ groupWishes }) => { 
  const { queryLoginParam, isBacksAppStaff } = useAuth();
  const navigate = useNavigate(); 
  const [filteredWishes, setFilteredWishes] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [filterProductType, setFilterProductType] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [selectedWish, setSelectedWish] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [showRating, setShowRating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCreateDraftModalVisible, setIsCreateDraftModalVisible] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [selectedWishForJoin, setSelectedWishForJoin] = useState(null); 
  const [showDraftTermsModal, setShowDraftTermsModal] = useState(false); 
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [rating, setRating] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1); 
  const itemsPerPage = 10;  
  const timeoutRef = useRef(null);  

  const [wishlistData, setWishlistData] = useState([]); 
  const [trendsData, setTrendsData] = useState([]); 
  const [existingWishes, setExistingWishes] = useState([]);
  const [wishesLoaded, setWishesLoaded] = useState(false);  
  const [showWishTrends, setShowWishTrends] = useState(false); 
  const [showMyWishesModal, setShowMyWishesModal] = useState(false);

  const [showJoinedGroupWishesModal, setShowJoinedGroupWishesModal] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState(false); 
  const [showCompletedModal, setShowCompletedModal] = useState(false); 
  const [showAddWishModal, setShowAddWishModal] = useState(false);

  const [userGroupWishes, setUserGroupWishes] = useState([]);
  const [connectionGroupWishes, setConnectionGroupWishes] = useState([]);
  const [filteredUserWishes, setFilteredUserWishes] = useState([]);
  const [filteredConnectionWishes, setFilteredConnectionWishes] = useState([]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [itemsCount, setItemsCount] = useState(0);
  const visibleItems = 1;
  const transitionDuration = 300;
  const autoScrollInterval = 5000;
  
  const [latestWishesLoaded, setLatestWishesLoaded] = useState(false); 
  const [recommendedWishesLoaded, setRecommendedWishesLoaded] = useState(false);
  const [yourGroupWishesLoaded, setYourGroupWishesLoaded] = useState(false); 
  
  const filterOptions = [
    { value: 'highestsavings', label: 'Highest Savings $$' },
    { value: 'fastapproaching', label: 'Fastest Approaching' },
  ];
  const { fetchHighestSavingsWishes, fetchFastestApproachingWishes } = useGroupWishFilters();

  useEffect(() => {
    const count = groupWishes.connectionGroupWishes.length; 
    setItemsCount(count);
    setCurrentIndex(0);  
  }, [groupWishes.connectionGroupWishes]);

  useEffect(() => {
    if (groupWishes.latestGroupWishes?.length > 0) {
      setLatestWishesLoaded(true);
    }
  }, [groupWishes.latestGroupWishes]);
  
  useEffect(() => {
    if (groupWishes.connectionGroupWishes?.length > 0) {
      setRecommendedWishesLoaded(true);
    }
  }, [groupWishes.connectionGroupWishes]);
  
  useEffect(() => {
    if (groupWishes.userGroupWishes?.length > 0) {
      setYourGroupWishesLoaded(true);
    }
  }, [groupWishes.userGroupWishes]);

  const resetLoadingStates = () => {
    setLatestWishesLoaded(false);
    setRecommendedWishesLoaded(false);
    setYourGroupWishesLoaded(false);
  };
 
   
  const handleNextItem = () => {  
    
    if (itemsCount > 0) {
      setCurrentIndex((prevIndex) => { 
        const safeIndex = isNaN(prevIndex) ? 0 : prevIndex;
        
        const newIndex = (safeIndex + 1) % itemsCount;  
        return isNaN(newIndex) ? 0 : newIndex;
      });
    } else {
       
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      handleNextItem();
    }, autoScrollInterval);

    return () => clearInterval(timer);
  }, [handleNextItem]);

  useEffect(() => {
    const timer = setInterval(() => {
      handleNextItem();
    }, autoScrollInterval);

    return () => clearInterval(timer);
  }, [currentIndex, connectionGroupWishes.length]);

  useEffect(() => {
    setFilteredWishes([...groupWishes.userGroupWishes, ...groupWishes.connectionGroupWishes]);
  }, [groupWishes]);
  
  const handleOrderManagementClick= () => { 
    navigate('/orderManagement');
  };

  const handleMyWishesClick = () => { 
    fetchWishListData(); 
    setShowMyWishesModal(true);
  };

  const handleWishTrendsClick = () => { 
    fetchWishTrends(); 
    setShowWishTrends(true); 
  };

  const handleCompletedClick = () => { 
    setShowCompletedModal(true);
  }

  const handleJoinedGroupWishesClick= () => { 
    setShowJoinedGroupWishesModal(true); 
  };

  const handleAddNewWishClick= () => { 
    setShowAddWishModal(true); 
  };

  const getSavingsColor = (percentage) => {
    if (percentage >= 50) return style.Color.colorRed;
    if (percentage >= 30) return style.Color.colorOrange;
    if (percentage >= 10) return style.Color.colorYellow;
    return style.Color.colorGreen;
  };

  const clearFilter = () => {
    setSelectedFilter('');
    setSearchQuery('');
    setIsSearching(false);
    setFilteredWishes([]);
    setUserGroupWishes([]); 
  };

  const handleFilterChange = async (event) => {
    const filterValue = event.target.value;
    setSelectedFilter(filterValue); 
    setIsLoading(true);

    try {
      let filteredWishes = [];
      if (filterValue === 'highestsavings') {
        const response = await fetchHighestSavingsWishes();
        filteredWishes = response || [];        
      } else if (filterValue === 'fastapproaching') {
        const response = await fetchFastestApproachingWishes();
        filteredWishes = response || [];
      } 
      setFilteredWishes(filteredWishes);
      setUserGroupWishes(filteredWishes);
      setConnectionGroupWishes([]);
      setCurrentPage(1);
    } catch (error) { 
      setErrorMessage('Failed to fetch filtered wishes. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const wishTypes = [
    ...(isBacksAppStaff === 'true' || isBacksAppStaff === true 
      ? [{ 
          title: 'Create/Edit New Wish Group', 
          icon: <img src={newImage} alt="My Wishes" style={iconImageStyle} />,
          onPress: () => setIsCreateDraftModalVisible(true),
          color: '#45B7D1' // Sky Blue
        },{
          title: 'Order Management',
          icon: <img src={cargoImage} alt="Orders" style={iconImageStyle} />,
          onPress: handleOrderManagementClick,
          color: '#DDA0DD' // Plum
        }] 
      : []
    ),
     
    { 
      title: 'Joined Groups', 
      icon: <img src={meetingImage} alt="My Wishes" style={iconImageStyle} />,
      onPress: handleJoinedGroupWishesClick,
      color: '#4ECDC4' // Turquoise
    },
    { 
      title: 'Completed Wishes', 
      icon: <img src={completedImage} alt="My Wishes" style={iconImageStyle} />,
      onPress: handleCompletedClick,
      color: '#FF6B6B' 
    }];
  
  const personalWishTypes = [
    {
      title: 'My Wishes',
      icon: <img src={shootingStarImage} alt="My Wishes" style={iconImageStyle} />,
      onPress: handleMyWishesClick,
      color: '#8393f0' // Light Salmon
    },
    {
      title: 'Wish Trends',
      icon: <img src={trendingImage} alt="Wish Trends" style={iconImageStyle} />,
      onPress: handleWishTrendsClick,
      color: '#98FB98' // Pale Green
    },
    {
      title: 'I have a new Wish',
      icon: <img src={newWishAIRequestImage} alt="New Wish" style={iconImageStyle} />,
      onPress: handleAddNewWishClick,
      color: '#DDA0DD' // Plum
    }
  ];
 
  useEffect(() => {
    if (searchQuery.length<1){
      setIsSearching(false);
    }
  },[searchQuery]);

  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      setIsSearching(false);
      setSearchResults([]);
      return;
    }

    setIsSearching(true);
    setIsLoading(true);
    setErrorMessage(null);

    try {
      const searchUrl = `${Config.apiUrl}searchInGroupWishes?${queryLoginParam}&q=${encodeURIComponent(searchQuery)}`;
       
      const response = await getApi(searchUrl, queryLoginParam);
       
      if (response && response.data && response.data.search_results) { 
        setSearchResults(response.data.search_results);
        setFilteredWishes(response.data.search_results);
        setIsSearching(true);
      } else {
        setSearchResults([]);
        setErrorMessage('No results found');
      } 
    } catch (error) {  
      setErrorMessage('Failed to search group wishes. Please try again.');
      setSearchResults([]);
    } finally {
      setIsLoading(false);     
    }
  };

  const filterWishes = useCallback((query, category, productName) => {
    const normalize = (str) => str.toLowerCase().replace(/[^a-z0-9]/g, '');
    const partialMatch = (full, partial) => {
      if (!full || !partial) return false;
      return normalize(full).includes(normalize(partial));
    };
  
    const filterWishList = (wishList) => {
      return wishList.filter(item => { 
        const wish = item.wish;
        const matchesQuery = !query ||
          partialMatch(wish.deal_title, query) ||
          partialMatch(wish.product_name, query) ||
          (wish.category && wish.category.some(cat => partialMatch(cat, query)));
    
        const matchesCategory = !category ||
          (wish.category && wish.category.some(cat => partialMatch(cat, category)));
    
        const matchesProductName = !productName || partialMatch(wish.product_name, productName);
    
        return matchesQuery && matchesCategory && matchesProductName;
      });
    };

    setFilteredUserWishes(filterWishList(userGroupWishes));
    setFilteredConnectionWishes(filterWishList(connectionGroupWishes));
  }, [userGroupWishes, connectionGroupWishes]);
 
  useEffect(() => {
    filterWishes(searchQuery, filterCategory, filterProductType);
  }, [userGroupWishes, connectionGroupWishes, searchQuery, filterCategory, filterProductType, filterWishes]);

  const handleRate = async (rating) => {
    setRating(rating);
    if (selectedWish) {
      try {
        const wishId = selectedWish.wish.id;
        await rateGroupWish(wishId, rating); 
         
      } catch (error) { 
        setErrorMessage('Failed to rate the wish. Please try again.');
      }
    }
  };

  const rateGroupWish = async (wishId, rating) => {
    setIsLoading(true); 
    let grpWishURL = buildUrl('rateGroupwish',queryLoginParam);     
    const requestBody = {
      grp_wish_id: wishId,
      is_upvote: rating >= 0,
      rating: rating
    };
    let token = await getAuthToken();
    if (!token) {
      throw new Error('No authentication token available');
    } 
    try {
      const grpWishResponse = postApi(grpWishURL, requestBody);

      if (!grpWishResponse) {
        throw new Error(`HTTP error! status: ${grpWishResponse.status}`);
      } 
    } catch (error) { 
      setErrorMessage('Failed to rate the wish. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleJoinGroup = async (item) => { 
    if (!item || !item.wish || !item.wish.id) { 
      setErrorMessage('Unable to join group. Invalid wish data.');
      return;
    }  
    setSelectedWishForJoin(item);
    setShowTermsModal(true);
  };

  const handleReJoinGroup = async (item) => { 
      try { 
        const reJoinUrl = buildUrl('reJoinGroupWish', `${queryLoginParam}`);
        let token = await getAuthToken();
        if (!token) {
          throw new Error('No authentication token available');
        } 
        const response = await postApi(reJoinUrl, { wish_id: item.id });          

        if (response.ok) { 
        } else {
          //console.error('Failed to rejoin from the group wish'); 
        }
      } catch (error) {
        //console.error('Error rejoining from group wish:', error); 
      }
    
  };

  const handleAgreeTerms = async () => {
    if (selectedWishForJoin) {
      setShowJoinModal(true); 
    }
  };

  const handleViewProgress = (item, joinedStatus) => {   
    const wishData = item.wish || item;
    navigate('/groupWishProgress', { state: { wish: wishData, progressData: item, joined: joinedStatus || false } }); 
  };

  const handleReferAFriend = (item) => { 
    navigate("/invites", { state: { grp_wish: item } });
  };

  const WishItem = ({ item,isRecommended }) => { 
    if (!item || !item.wish) return null;  
    const wish = item.wish;  
    const formatExpiryDate = (expiryDateString) => {
      if (!expiryDateString) return 'Date not available';
      try { 
        const expiryDate = parseISO(expiryDateString); 
        const formattedDate = format(expiryDate, "MMMdo,`yy h:mm aa");  
  
        return `${formattedDate}`;
      } catch (error) { 
        return 'Invalid date';
      }
    };
    
    const formatTimeRemaining = (expiryDateString) => {
      if (!expiryDateString) return '';
  
      try { 
        const expiryDate = parseISO(expiryDateString);  
        const timeRemaining = formatDistanceToNow(expiryDate, { addSuffix: false });
        return timeRemaining.includes('days') ? timeRemaining.split(' ')[0] : '1';
      } catch (error) { 
        return '';
      }
    };

    const calculateSavings = (price, marketPrice) => {
      if (isNaN(price) || isNaN(marketPrice) || marketPrice === 0) return { amount: 0, percentage: 0 };
      const savingsAmount = marketPrice - price;
      const savingsPercentage = ((savingsAmount / marketPrice) * 100).toFixed(2);
      return { amount: savingsAmount.toFixed(2), percentage: savingsPercentage };
    };
    
    const priceRanges = wish.price_ranges || [];
    const minPrice = priceRanges.length > 0 ? Math.min(...priceRanges.map(range => range.range_price || range.price)) : 0;
    const maxPrice = priceRanges.length > 0 ? Math.max(...priceRanges.map(range => range.range_price || range.price)) : 0;
    
    const marketPrice = parseFloat(wish.list_price) || 0;
    const minSavings = calculateSavings(maxPrice, marketPrice);
    const maxSavings = calculateSavings(minPrice, marketPrice);
    
    const renderPriceRange = () => {
      if (minPrice === maxPrice) {
        return `$${minPrice.toFixed(2)}`;
      }
      return `$${minPrice.toFixed(2)} - $${maxPrice.toFixed(2)}`;
    };
    
    const renderSavings = () => {
      if (minSavings.percentage === maxSavings.percentage) {
        return (
          <div style={savingsContainerStyle}>
            <div style={savingsRowStyle}>
              <FontAwesomeIcon icon={faFire} style={fireIconStyle} />
              <span style={savingsTextStyle}>
                Save up to ${minSavings.amount}
              </span>
            </div>
            <div style={savingsPercentageRowStyle}>
              ({minSavings.percentage}% off)
            </div>
          </div>
        );
      }
      return (
        <div style={savingsContainerStyle}>
          <div style={savingsRowStyle}>
            <FontAwesomeIcon icon={faFire} style={fireIconStyle} />
            <span style={savingsTextStyle}>
              Save ${minSavings.amount} - ${maxSavings.amount}
            </span>
          </div>
          <div style={savingsPercentageRowStyle}>
            ({minSavings.percentage}% - {maxSavings.percentage}% off)
          </div>
        </div>
      );
    };

    const renderActionButtons = () => {
      const status = item.group_wish_buddy_status;
      return (
        <div style={actionButtonsContainerStyle}>
          <button style={actionButton1Style} onClick={() => handleViewProgress(item)}>
            View
          </button>
          {status === null ? (
            <button style={actionButton2Style} onClick={() => handleJoinGroup(item)}>
              Join
            </button>
          ) : status === 'WITHDRAWN' ? (
            <button style={actionButton2Style} onClick={() => handleReJoinGroup(item)}>
              Rejoin
            </button>
          ) : null}
          <button style={actionButton3Style} onClick={() => handleReferAFriend(item)}>
            Refer
          </button>
        </div>
      );
    };

    return (
      <div style={wishItemStyle}>
      {isRecommended }
        <div style={wishImageContainerStyle}>
          {wish.image ? (
            <div style={imageWrapperStyle}>
              <img src={wish.image} alt={wish.product_name} style={wishImageStyle} />
            </div>
          ) : (
            <div style={placeholderImageStyle}>
              <FontAwesomeIcon icon={faImage} size="2x" color="#ccc" />
              <p>No image available</p>
            </div>
          )}
        </div>
        <div style={wishContentStyle}>
          <h3 style={wishTitleStyle}>{wish.product_name}</h3>
          <div style={timeInfoContainerStyle}>
          <div style={expiryDateContainerStyle}>
            <h4 style={wishTimeInfoStyle}>Valid until {formatExpiryDate(wish.expires_at)}</h4>
          </div>
          <div style={getTimeRemainingCircleStyle()}>
            <span style={timeRemainingNumberStyle}>{formatTimeRemaining(wish.expires_at)}</span>
            <span style={timeRemainingTextStyle}>{timeRemainingTextStyle === 1 ? 'Day ' : 'Days '}Left</span>
          </div>
        </div>
          <p style={wishInfoStyle}>Price: {renderPriceRange()}</p>
           {renderSavings()}
          <p style={wishInfoStyle}>Mimimum Orders: {wish.group_size_min || 'N/A'}</p> 
          <div style={scoreBoardStyle}>
          <span style={scoreTextStyle}>OverAll Rating </span>
            <FontAwesomeIcon icon={faStar} color="#FFD700" />
            <span style={scoreTextStyle}>{parseFloat(wish.verification_score || 0).toFixed(1)}</span>
          </div>          
        </div>
          {renderActionButtons()}
      </div>
    );
  };


  const renderFilterAndSearch = () => {
    return (
      <div style={searchAndFilterContainerStyle}>
        <div style={searchContainerStyle}>
          <input style={searchInputStyle} type="text"  placeholder="Search all deals..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} onKeyPress={handleSearchInputKeyPress}/>
          <button onClick={handleSearch} style={{...searchButtonStyle,cursor: isLoading ? 'not-allowed' : 'pointer',opacity: isLoading ? 0.7 : 1}} disabled={isLoading}>
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
        <div style={filterContainerStyle}>
          <FontAwesomeIcon icon={faFilter} style={filterIconStyle} />
          <select 
            style={filterSelectStyle} 
            value={selectedFilter} 
            onChange={handleFilterChange}
          >
            <option value="">Select Filter</option>
            {filterOptions.map(option => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
        {(selectedFilter || isSearching) && (
          <button onClick={clearFilter} style={clearFilterButtonStyle}>
            <FontAwesomeIcon icon={faTimes} /> Clear Filter
          </button>
        )}
      </div>
    );
  };

  const renderPagination = () => (
    <div style={paginationContainerStyle}>
      <button 
        style={paginationButtonStyle} 
        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} 
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <span style={pageInfoStyle}>Page {currentPage}</span>
      <button  style={paginationButtonStyle}  onClick={() => setCurrentPage(prev => prev + 1)}  disabled={currentPage * itemsPerPage >= groupWishes.userGroupWishes.length}>
        Next
      </button>
    </div>
  );


  const RecommendedWishItem = ({ item }) => {
    const wish = item.wish;    
    const calculateSavings = (price, marketPrice) => {
      if (isNaN(price) || isNaN(marketPrice) || marketPrice === 0) return { amount: 0, percentage: 0 };
      const savingsAmount = marketPrice - price;
      const savingsPercentage = ((savingsAmount / marketPrice) * 100).toFixed(2);
      return { amount: savingsAmount.toFixed(2), percentage: savingsPercentage };
    };
  
    const priceRanges = wish.price_ranges || [];
    const minPrice = priceRanges.length > 0 ? Math.min(...priceRanges.map(range => parseFloat(range.range_price) || 0)) : 0;
    const maxPrice = priceRanges.length > 0 ? Math.max(...priceRanges.map(range => parseFloat(range.range_price) || 0)) : 0;
    
    const shippingCost = priceRanges.length > 0 ? parseFloat(priceRanges[0].shipping_cost_for_range) || 0 : 0;
    const minTotalCost = minPrice + shippingCost + (minPrice * 0.1);
    const maxTotalCost = maxPrice + shippingCost + (maxPrice * 0.1);
  
    const marketPrice = parseFloat(wish.list_price) || 0;
    const minSavings = calculateSavings(maxTotalCost, marketPrice);
    const maxSavings = calculateSavings(minTotalCost, marketPrice);
  
    const renderPriceRange = () => {
      if (minPrice === maxPrice) {
        return `$${minPrice.toFixed(2)}`;
      }
      return `$${minPrice.toFixed(2)} - $${maxPrice.toFixed(2)}`;
    };
  
    const renderSavings = () => {
      if (priceRanges.length === 1 || minSavings.percentage === maxSavings.percentage) {
        return (
          <div style={savingsStyle}>
            <span style={{...savingsAmountStyle, color: getSavingsColor(parseFloat(minSavings.percentage))}}>
              ${minSavings.amount} 
            </span>
            <span style={{...savingsAmountStyle, color: getSavingsColor(parseFloat(minSavings.percentage))}}>
              ({minSavings.percentage}% off)
            </span>
          </div>
        );
      }
      return (
        <>
        <div style={savingsStyle}>
          <FontAwesomeIcon icon={faFire} style={fireIconStyle} />
          <span style={{...savingsAmountStyle, color: getSavingsColor(parseFloat(maxSavings.percentage))}}>
            ${minSavings.amount}-${maxSavings.amount}
          </span>
          </div>
          <div style={savingsStyle}>
          <span style={{...savingsPercentageStyle, color: getSavingsColor(parseFloat(maxSavings.percentage))}}>
          ({minSavings.percentage}%-{maxSavings.percentage}% off)
          </span>
        </div>
        </>
      );
    };
      
  useEffect(() => {
    if (isTransitioning) {
      const timer = setTimeout(() => setIsTransitioning(false), transitionDuration);
      return () => clearTimeout(timer);
    }
  }, [isTransitioning]);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      setIsTransitioning(true);
      setCurrentIndex((prevIndex) => (prevIndex + 1) % connectionGroupWishes.length);
    }, 5000);
  
    return () => {
      resetTimeout();
    };
  }, [currentIndex, connectionGroupWishes.length]);
  
  useEffect(() => {
    if (isTransitioning) {
      const timer = setTimeout(() => {
        setIsTransitioning(false);
      }, transitionDuration);
      return () => clearTimeout(timer);
    }
  }, [isTransitioning]);
   
    return (
      <div style={recommendedItemStyle} onClick={() => handleViewProgress(wish)}>
      <h3 style={recommendedItemTitleStyle}>{wish.product_name}</h3>
      <div style={recommendedItemContentStyle}>
        <img src={wish.image} alt={wish.product_name} style={recommendedItemImageStyle} />
        <div>
          <p style={recommendedItemPriceStyle}>Price: {renderPriceRange()}</p>
          {renderSavings()}
        </div>
      </div>
    </div>
  );
  };
  

  const CreateDraftOverlay = ({ isVisible, onClose, onCreateNew, onViewDrafts,setShowDraftTermsModal,termsAgreed,setTermsAgreed }) => { 
    if (!isVisible) return null;
    const handleCreateNew = () => {
      if (termsAgreed) {
        onCreateNew();
      } else {
        alert("Please agree to the Terms of Use before proceeding.");
      }
    };
  
    const handleViewDrafts = () => {
      if (termsAgreed) {
        onViewDrafts();
      } else {
        alert("Please agree to the Terms of Use before proceeding.");
      }
    };
  
    return (      
      <div style={overlayContainerStyle}>
        <div style={overlayContentStyle}>
          <div style={sameRowContainerStyle}>
            <h2 style={modalTitleStyle}>Create or View Drafts</h2>
            <button onClick={onClose} style={closeButtonStyle}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <p style={modalTextStyle}>Do you want to create a new wish or view your drafts?</p>
          
          <button style={modalButtonStyle} onClick={handleCreateNew}>
            Create New
          </button>
          <button style={modalButtonStyle} onClick={handleViewDrafts}>
            View Drafts
          </button>
          
          <div style={termsContainerStyle}>
            <label>
              <input type="checkbox" checked={termsAgreed} onChange={(e) => setTermsAgreed(e.target.checked)}/>
               I agree to the Terms of Use
            </label>
            <button style={termsLinkStyle} onClick={() => setShowDraftTermsModal(true)}>
              View Terms of Use
            </button>
          </div>
        </div>
      </div>       
    );
  };

  const LatestGroupWishItem = ({ wish }) => {
    const formatDate = (dateString) => {
      return format(parseISO(dateString), 'MMM d');
    };
  
    return (
      <div  style={latestWishContentStyle} >
        <div   onClick={() => handleViewProgress(wish)}>
        <div style={latestWishInfoStyle}>
          <h4 style={latestWishTitleStyle}>{wish.wish.product_name}</h4>
          <div style={hotnessIndicatorStyle(wish.wish.verification_score)}>
            <FontAwesomeIcon icon={faFire} />
            <span>{wish.wish.verification_score}%</span>
          </div>
          </div>
          <div style={latestWishInfoStyle}>
            <span><FontAwesomeIcon icon={faUsers} /> {wish.totalJoined}</span>
            <span><FontAwesomeIcon icon={faClock} /> {formatDate(wish.wish.created_at)}</span>
            <span><FontAwesomeIcon icon={faClock} /> {formatDate(wish.wish.created_at)}</span>
          </div>
          
        </div>
       
      </div>
    );
  };

  const fetchWishTrends = async () => { 
    try{
      let wishTrendsURL = buildUrl('getWishTrends', `${queryLoginParam}&limit=3`);
      let token = await getAuthToken();
      if (!token) {
        throw new Error('No authentication token available');
      } 
      const response = await getApi(wishTrendsURL); 

      if (response.ok && response.data && response.data.status === "success" && Array.isArray(response.data.data)) {
        const trendsArray = response.data.data.map(item => ({
          name: item.name,
          count: item.count
        }));
        setTrendsData(trendsArray);
      } else { 
        setTrendsData([]);
      }
    } catch (error) { 
      setTrendsData([]);
    }
};

  const fetchWishListData = async () => { 
    let wishURL = buildUrl('getMyWishes', `${queryLoginParam}&limit=5`);
    let token = await getAuthToken();
    if (!token) {
      throw new Error('No authentication token available');
    } 
    try {
      const response = await getApi(wishURL);
      let responseData= response.data;
      
      if (responseData) {
        setWishlistData(responseData.wish_lists);
        setExistingWishes(responseData.categories || []);
        setWishesLoaded(true);
      } else {
        setWishlistData({});
        setWishesLoaded(true);
        setExistingWishes([]);
      }
    } catch (error) {
      setErrorMessage('Oops, please re-try');
      setExistingWishes([]);
    } finally {
      setIsLoading(false);
      setWishesLoaded(true);
    }
  };

  const handleTermsAgreement = (agreed) => {
    setTermsAgreed(agreed);
    setShowDraftTermsModal(false);
  };
 
  const renderSearchResults = () => {
    if (isLoading) {
      return <p style={loadingStyle}>Searching...</p>;
    }

    if (errorMessage) {
      return <p style={errorStyle}>{errorMessage}</p>;
    }

    if (!searchResults || searchResults.length === 0) {
      return (
        <div style={noResultsContainerStyle}>
          <p style={noResultsStyle}>No results found for "{searchQuery}"</p>           
        </div>
      );
    }

    return (
      <>
        <div style={searchResultsHeaderStyle}>
          <h2 style={sectionTitleStyle}>Search Results for "{searchQuery}"</h2>         
        </div>
        <div style={wishListStyle}>
          {searchResults.map((item, index) => (
            <WishItem key={item.wish?.uuid || item.wish?.id || index} item={item} isRecommended={false}/>
          ))}
        </div>
      </>
    );
  };
   
  const handlePrevItem = () => {
    if (groupWishes.connectionGroupWishes.length > 0) {
      setCurrentIndex((prevIndex) => 
        prevIndex === 0 ? groupWishes.connectionGroupWishes.length - 1 : prevIndex - 1
      );
    }
  };

  const handleSearchInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const renderMainContent = () => {
    if (isSearching) {
      return renderSearchResults();
    }

    return (
      <>
        <div style={featuredSectionStyle}>
          <div style={featuredColumnStyle}>
            <div style={latestSectionStyle}>
              <h5 style={sectionTitleStyle}>Latest</h5>
              {!latestWishesLoaded ? (
              <LoadingSpinnerSection title="" />
            ) : (
              <div style={{ overflowY: 'auto', flex: 1 }}>
                {groupWishes.latestGroupWishes.map(wish => (
                  <LatestGroupWishItem key={wish.id} wish={wish} />
                ))}
              </div>
            )}
            </div>
          </div>
          <div style={featuredColumnStyle}>
            <div style={recommendedSectionStyle}>
              <h5 style={sectionTitleStyle}>Recommended</h5>
              {!recommendedWishesLoaded ? (
              <LoadingSpinnerSection title="" />
            ) : (
              itemsCount > 0 && (
                <div style={carouselContainerStyle}>
                  <button onClick={handlePrevItem} style={{...carouselButtonStyle, left: '10px'}}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                  <div style={carouselViewportStyle}>
                    <div style={{...recommendedCarouselStyle, transform: `translateX(-${currentIndex * 100}%)`, transition: 'transform 300ms ease-in-out',}}>
                      {groupWishes.connectionGroupWishes.map((wish, index) => (
                        <div key={`${wish.wish?.uuid || wish.wish?.id || index}-${index}`} style={carouselItemStyle}>
                          <RecommendedWishItem item={wish} />
                        </div>
                      ))}
                    </div>
                  </div>
                  <button onClick={handleNextItem} style={{...carouselButtonStyle, right: '10px'}}>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
              )
              )}
            </div>
          </div>
        </div>
        <h2 style={sectionTitleStyle}>Your Group Wishes</h2>
        {!yourGroupWishesLoaded ? (
          <LoadingSpinnerSection title="" />
        ) : (
          <>
          <div style={wishListStyle}>
            {groupWishes.userGroupWishes.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((wish, index) => (
              <WishItem key={wish.wish?.uuid || wish.wish?.id || index} item={wish} />
            ))}
          </div>
          {renderPagination()}  
          </>
        )}      
      </>
    );
  };

  return (
    <>
      <div style={containerStyle}>
        <div style={contentStyle}>
          <div style={buttonContainerStyle}>
            {wishTypes.map(({ title, icon, onPress, color }) => (
              <button key={title} style={wishTypeCardStyle(color)} onClick={onPress}>
                <div style={iconContainerStyle}>{icon}</div>
                <span style={cardTextStyle}>{title}</span>
              </button>
            ))}    
            {personalWishTypes.map(({ title, icon, onPress, color }) => (
              <button key={title} style={wishTypeCardStyle(color)} onClick={onPress}>
                <div style={iconContainerStyle}>{icon}</div>
                <span style={cardTextStyle}>{title}</span>
              </button>
            ))}  
          </div>          
          {renderFilterAndSearch()}
          {renderMainContent()}

 
        </div>
        {modalVisible && selectedWish && selectedWish.wish && (
          <div style={modalOverlayStyle}>
            <div style={modalContentStyle}>
              <div style={sameRowContainerStyle}>
                <h2 style={modalTitleStyle}>{selectedWish.wish.product_name}</h2>
                <button onClick={() => setModalVisible(false)} style={closeButtonStyle}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
              <p>Max Market Price: ${selectedWish.wish.list_price}</p>
              <p>Seller: {selectedWish.wish.store_seller}</p>
              <p>Minimum Group Size: {selectedWish.wish.group_size_min}</p>
              <div style={actionButtonsStyle}>
                <button style={actionButton2Style} onClick={() => handleJoinGroup(selectedWish)}> 
                  Join
                </button>
                <button style={actionButton1Style} onClick={() => setShowRating(true)}>
                  Rate
                </button>
              </div>
              {showRating && (
                <DownRateSlider onRate={handleRate} onClose={() => setShowRating(false)} onReport={() => { setShowRating(false); }} />
              )}
            </div>
          </div>
        )}
        <CreateDraftOverlay isVisible={isCreateDraftModalVisible} onClose={() => setIsCreateDraftModalVisible(false)}
          onCreateNew={() => {
            setIsCreateDraftModalVisible(false);
            navigate('/createGroupWishes');
          }}
          onViewDrafts={() => {
            setIsCreateDraftModalVisible(false);
            navigate('/draftGroupWishes');
          }}
          showDraftTermsModal={showDraftTermsModal} setShowDraftTermsModal={setShowDraftTermsModal} termsAgreed={termsAgreed} setTermsAgreed={setTermsAgreed}/>
        <TermsAndConditionsModal isOpen={showTermsModal} onClose={() => setShowTermsModal(false)} onAgree={handleAgreeTerms}/>
        {showDraftTermsModal && (
          <CreateTermsAndConditionsModal isOpen={showDraftTermsModal} onClose={() => setShowDraftTermsModal(false)} onAgree={handleTermsAgreement} />
        )}
        
        {showMyWishesModal && (
          <MyWishesModal wishlistData={wishlistData} onClose={() => setShowMyWishesModal(false)}/>
        )}
        {showWishTrends && (
          <TrendingDetailsModal trendsData={trendsData} onClose={() => setShowWishTrends(false)}/>
        )}
        {showJoinedGroupWishesModal && (
          <JoinedGroupWishes onClose={() => setShowJoinedGroupWishesModal(false)}/>
        )}
        <JoinGroupWishModal isOpen={showJoinModal} onClose={() => setShowJoinModal(false)} wish={selectedWishForJoin} />
        <CompletedGroupWishModal isOpen={showCompletedModal} onClose={() => setShowCompletedModal(false)} />
        <AddWishModal isOpen={showAddWishModal} onClose={() => setShowAddWishModal(false)} /> 
      </div>  
    </>
  )
};

// Styles
const savingsContainerStyle = {
  display: 'flex',
  flexDirection: 'column',   
  alignItems: 'flex-start',  
  marginBottom: '10px',
  padding: '5px 10px',
  backgroundColor: style.Color.colorLightBeige,
  borderRadius: '5px',
};

const savingsRowStyle = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
};

const fireIconStyle = {
  color: '#FF5722',
  marginRight: '10px',
  fontSize: '18px',
};

const savingsTextStyle = {
  fontWeight: 'bold',
  color: '#FF5722',
};

const savingsPercentageRowStyle = {
  marginTop: '5px',
  marginLeft: '28px', // Match the fireIcon + its margin
  color: '#FF5722',
  fontSize: '14px',
  fontWeight: 'bold',
};

const clearFilterButtonStyle = {
  backgroundColor: style.Color.colorLightGray,
  color: style.Color.colorDarkGrey,
  border: 'none',
  borderRadius: '5px',
  padding: '8px 15px',
  cursor: 'pointer',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  transition: 'background-color 0.3s ease',
  ':hover': {
    backgroundColor: style.Color.colorMediumGray,
  },
}; 

const loadingStyle = {
  textAlign: 'center',
  color: style.Color.colorDarkGrey,
  marginTop: '20px',
  fontSize: '16px',
  padding: '20px',
};

const errorStyle = {
  textAlign: 'center',
  color: style.Color.colorRed,
  marginTop: '20px',
  fontSize: '16px',
  padding: '20px',
};

const noResultsContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '15px',
  marginTop: '20px',
  padding: '20px',
};

const searchResultsHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
  padding: '10px 0',
};

const clearSearchButtonStyle = {
  backgroundColor: style.Color.colorLightGray,
  color: style.Color.colorDarkGrey,
  border: 'none',
  borderRadius: '5px',
  padding: '8px 15px',
  cursor: 'pointer',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  transition: 'all 0.3s ease',
  ':hover': {
    backgroundColor: style.Color.colorMediumGray,
    color: style.Color.colorDarkBlue,
  },
};

const carouselContainerStyle = {
  position: 'relative',
  width: '100%',
  height: '320px', 
  overflow: 'hidden',
};

const carouselViewportStyle = {
  width: '100%',
  height: '80%',
 // overflow: 'auto',
};

const recommendedCarouselStyle = {
  display: 'flex',
  height: '90%', 
};
 
const carouselItemStyle = {
  flex: '0 0 100%',
  width: '100%',
  height: '90%',
};

const sectionBaseStyle = {
  backgroundColor: style.Color.colorWhite,
  borderRadius: '10px',
  padding: '20px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  height: '70%',  
  display: 'flex',
  flexDirection: 'column',
};  

const latestSectionStyle = {
  ...sectionBaseStyle,
  boxShadow: `10px 10px 10px 2px ${style.Color.colorMintGreen}40`,
  border: `0px solid ${style.Color.colorMintGreen}`,
  position: 'relative', 
  overflow: 'hidden', 
  '::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    backgroundColor: style.Color.colorMintGreen,
  }
};

const recommendedItemStyle = {
  backgroundColor: style.Color.colorLightBeige,
  borderRadius: '12px',
  padding: '15px',
  display: 'flex',
  flexDirection: 'column',  
};

const recommendedItemTitleStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  color: style.Color.colorDarkBlue,
  marginBottom: '10px',
};

const recommendedItemContentStyle = {
  display: 'flex',
  flexDirection: 'row',  
  gap: '10px',
};

const recommendedItemImageStyle = {
  height: '150px',
  borderRadius: '8px',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'left',  
};

const recommendedItemPriceStyle = {
  fontSize: '14px',
  fontWeight: 'bold',
  color: style.Color.colorDarkGrey,
  marginTop: '10px',
  paddingLeft: '35px',
};

const carouselButtonStyle = {
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: 'transparent',
  border: 'none',
  fontSize: '24px',
  color: style.Color.colorDarkGrey,
  cursor: 'pointer',
}; 

const containerStyle = {
  fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  backgroundColor: style.Color.colorLightBeige,
  minHeight: '100vh',
  maxHeight: '1500vh',
  padding: '30px 20px 20px 10px',
  margin: '10px 10px 10px 0px', 
  background: style.Color.colorLightBeige,
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const contentStyle = {
  width: '100%',
  maxWidth: '1800px',
  boxSizing: 'border-box',
  padding: '20px',
};

const featuredSectionStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: '20px',
  margin: '20px 0', 
}; 

const featuredColumnStyle = {
  flex: 1,
  width: '50%',
  display: 'flex',
  flexDirection: 'column',
};

const recommendedSectionStyle = {
  ...sectionBaseStyle,
  boxShadow: `10px 10px 10px 2px ${style.Color.colorLimeGreen}40`,
  border: `0px solid ${style.Color.colorLimeGreen}`,
  position: 'relative',
  overflow: 'hidden', 
  '::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    backgroundColor: style.Color.colorLimeGreen,
  }
};

const sectionTitleStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
  color: style.Color.colorDarkGrey,
  marginBottom: '15px',
  alignSelf: 'flex-start',
}; 

const savingsStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  marginBottom: '2px',
  paddingLeft: '35px',
};

const savingsAmountStyle = {
  fontSize: '18px',
  fontWeight: 'bold', 
};

const savingsPercentageStyle = {
  fontSize: '14px', 
  paddingLeft: '15px',
}; 

const searchAndFilterContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '20px',
  marginBottom: '30px',
  padding: '15px',
  borderRadius: '8px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  backgroundColor: style.Color.colorWhite,
};

const paginationContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '20px',
  gap: '10px',
};

const paginationButtonStyle = {
  backgroundColor: 'transparent',
  color: style.Color.colorDarkBlue,
  border: 'none',
   
  padding: '8px 15px',
  cursor: 'pointer',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  
}; 

const latestWishContentStyle = {
  flex: 1,
  backgroundColor: style.Color.colorLightBeige,
  borderRadius: '8px',
  padding: '10px',
  marginBottom: '10px',
  height: '80%', 
  overflow: 'auto', 
};

const latestWishTitleStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  color: style.Color.colorDarkBlue,
  marginBottom: '5px',
};

const latestWishInfoStyle = {
  display: 'flex',
  gap: '15px',
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
};

const hotnessIndicatorStyle = (hotness) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  backgroundColor: `rgba(255, ${255 - hotness * 2}, 0, ${hotness / 100})`,
  color: style.Color.colorWhite,
  fontWeight: 'bold',
  fontSize: '14px',
  marginLeft: '35%',
  marginTop: '5%',
}); 

const pageInfoStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  color: style.Color.colorDarkGrey,
}; 

const wishTypeCardStyle = (color) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: style.Color.colorWhite,
  border: `0px solid ${style.Color.colorBaseBeige}`, 
  borderRadius: '15px',
  padding: '5px',
  margin: '25px',
  width: '200px',
  height: '100px',
  boxShadow: `10px 10px 10px 2px ${color}40`,
  transition: 'all 0.3s ease',
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  border: `0px solid ${color}`,
  '::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    backgroundColor: color,
  },
  ':hover': {
    transform: 'translateY(-5px)',
    boxShadow: `20px 20px 15px 15px ${color}60`,
  },
});

const iconContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  marginBottom: '10px',
};

const iconStyle = {
  fontSize: '24px',
};

const iconImageStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'contain',
};

const cardTextStyle = {
  fontSize: '14px',
  fontWeight: 'bold',
  color: style.Color.colorDarkGrey,
  textAlign: 'center',
  maxWidth: '90%',
};

const searchContainerStyle = {
  position: 'relative',
  flex: 1,
};

const searchButtonStyle = {
  position: 'absolute',
  right: '10px',
  top: '50%',
  transform: 'translateY(-50%)',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  fontSize: '20px',
  color: style.Color.colorDarkGrey,
};

const noResultsStyle = {
  textAlign: 'center',
  color: style.Color.colorDarkGrey,
  marginTop: '20px',
};
const searchInputStyle = {
  width: '80%',
  height: '40px',
  borderRadius: '8px',
  padding: '0 20px 0 45px',
  fontSize: '16px',
  border: `2px solid ${style.Color.colorMintGreen}`,
  transition: 'all 0.3s ease',
  ':focus': {
    outline: 'none',
    boxShadow: `0 0 10px ${style.Color.colorMintGreen}80`,
  },
};

const filterContainerStyle = { 
  position: 'relative',
  width: '250px',
};

const filterIconStyle = {
  position: 'absolute',
  left: '15px',
  top: '50%',
  transform: 'translateY(-50%)',
  color: style.Color.colorDarkGrey,
  zIndex: 1,
};

const filterSelectStyle = {
  width: '100%',
  height: '40px',
  borderRadius: '8px',
  padding: '0 0px 0 45px',
  fontSize: '16px',
  backgroundColor: style.Color.colorWhite,
  border: `2px solid ${style.Color.colorMintGreen}`, 
  color: style.Color.colorDarkGrey,
  '::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 10,
    right: 0,
    height: '4px',
    backgroundColor: `${style.Color.colorLimeGreen}`,
  },
  appearance: 'none',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  ':hover': {
    boxShadow: `0 0 10px ${style.Color.colorLimeGreen}60`,
  },
};
 
const buttonContainerStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '15px',
  padding: '20px 0',
};

const wishListStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
  gap: '25px',
};

const wishItemStyle = {
  display: 'flex',
  flexDirection: 'column', 
  backgroundColor: style.Color.colorWhite,
  borderRadius: '10px',
  padding: '20px',
  marginLeft: '10px',
  marginRight: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  height: '90%', 
  justifyContent: 'space-between',  
  boxSizing: 'border-box',
};
const timeInfoContainerStyle = {
  position: 'relative',
  marginBottom: '20px',
};

const expiryDateContainerStyle = { 
  borderRadius: '8px',
  padding: '10px 15px', 
};

const getTimeRemainingCircleStyle = () => ({
  position: 'absolute',
  top: '-30%',
  right: '-2%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  backgroundColor: style.Color.colorLimeGreen, 
  color: style.Color.colorGray,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  animation: 'pulse 2s infinite',
  zIndex: 1,
});

const timeRemainingNumberStyle = {
  fontSize: '12px', 
};

const timeRemainingTextStyle = {
  fontSize: '8px',  
};
const wishContentStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding:'10px 0',
};

const actionButtonsContainerStyle ={
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  marginTop: '15px',
};

const actionButton1Style = {
  backgroundColor: style.Color.colorDarkBlue,
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  padding: '8px 15px',
  cursor: 'pointer',
  fontWeight: 'bold',
  flex: 1,  
  maxWidth: '80px',  
};

const actionButton2Style = {
  backgroundColor: style.Color.colorLimeGreen, 
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  padding: '8px 15px',
  cursor: 'pointer',
  fontWeight: 'bold',
  flex: 1,  
  maxWidth: '80px',  
};

const actionButton3Style = {
  backgroundColor:style.Color.colorPurpleBlue, 
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  padding: '8px 15px',
  cursor: 'pointer',
  fontWeight: 'bold',
  flex: 1,  
  maxWidth: '80px',  
};

const wishImageContainerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '10px',
  overflow: 'hidden',
  marginBottom: '15px',
};

const imageWrapperStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center', 
};

const wishImageStyle = {
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'cover',
};

const placeholderImageStyle = {
  ...imageWrapperStyle,
  flexDirection: 'column',
};

const wishTitleStyle = {
  fontSize: '20px', 
  fontWeight: 'bold',
  marginBottom: '15px',
  color: style.Color.colorPurple,
};

const wishTimeInfoStyle = {
  fontSize: '14px', 
  color:  'white',  
  backgroundColor: style.Color.colorGray,
  padding: '8px 12px',
  borderRadius: '8px',
  marginBottom: '10px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  display: 'inline-block', 
  letterSpacing: '0.5px',
  border: '3px solid #98c2d9', 
  animation: 'pulse 2s infinite',
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.05)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
};

const wishInfoStyle = {
  fontSize: '14px',
  color: style.Color.colorPurple,
  marginBottom: '5px',
};

const scoreBoardStyle = {
  display: 'flex',
  alignItems: 'center',
  marginTop: '10px',
};

const scoreTextStyle = {
  fontWeight: 'bold',
  color: style.Color.colorGray,
}; 

const actionButtonsStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '10px',
  marginTop: '15px',
}; 

const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const modalContentStyle = {
  backgroundColor: style.Color.colorLightestBeige,
  borderRadius: '10px',
  padding: '20px',
  width: '80%',
  maxWidth: '500px',
};

const sameRowContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '15px',
};

const closeButtonStyle = {
  background: 'none',
  border: 'none',
  fontSize: '24px',
  cursor: 'pointer',
  color: style.Color.colorDarkGrey,
};

const overlayContainerStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const overlayContentStyle = {
  backgroundColor: style.Color.colorLightestBeige,
  borderRadius: '10px',
  padding: '20px',
  width: '80%',
  maxWidth: '400px',
};

const modalTitleStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
  color: style.Color.colorPurple,
};

const modalTextStyle = {
  marginBottom: '20px',
  color: style.Color.colorCoffeeBrown,
};

const modalButtonStyle = {
  ...actionButton1Style,
  width: '100%',
  marginBottom: '10px',
  marginLeft: '75px',
};

const termsContainerStyle = {
  color: style.Color.colorCoffeeBrown,
  marginTop: '20px',
};

const termsLinkStyle = {
  background: 'none',
  border: 'none',
  color: style.Color.colorPurpleBlue,
  textDecoration: 'underline',
  cursor: 'pointer',
}; 
 
export default GroupWishLists;
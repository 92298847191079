import React, { useState ,useEffect, useRef} from 'react';
import styled from 'styled-components';
import { auth } from '../../../firebaseConfig';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth"; 
import { getFirebaseAuth } from '../../../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { Color } from '../../../GlobalStyles';
import spereImage from '../../../assets/images/Original_Login.png';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  border-radius: 16px;
  padding: 24px;
  width: 70%;
  max-width: 400px;
  height: 90%;
  max-height: 1500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${spereImage});
    background-size: cover;
    background-position: center;
    z-index: -2;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //background: linear-gradient(to right, #C77B58, #B76E79, #C77B58);
    background: linear-gradient(
      to right,
      rgba(199, 123, 88, 0.1),
      rgba(183, 110, 121, 0.4),
      rgba(199, 123, 88, 0.2)
    );
    z-index: -1;
  }
`;

const TitleContainer = styled.div` 
  padding: 20px;
  border-radius: 12px;
  margin-top: 75%;
  width: 90%;
`;

const FormContainer = styled.div`
  padding: 20px;
  border-radius: 12px;
  margin-top: 40%;
  width: 90%;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  color: ${Color.colorLightBeige};
  margin-bottom: 8px;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 0.9rem;
  color: ${Color.colorLightBeige};
  margin-bottom: 16px;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`; 
const CountrySelect = styled.div`
  position: relative;
  display: inline-block;
`;

const CountryButton = styled.button`
  background: transparent;
  border: none;
  border-bottom: 1px solid ${Color.colorLightestBeige};
  color: ${Color.colorWhite};
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 100px;

  &:hover {
    border-bottom-color: ${Color.colorPurpleBlue};
  }

  img {
    width: 20px;
    height: 15px;
    object-fit: cover;
  }
`;

const CountryDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 8px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  min-width: 200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: ${props => props.$isOpen ? 'block' : 'none'};

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: ${Color.colorPurpleBlue};
    border-radius: 4px;
  }
`;


const CountryOption = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  color: ${Color.colorWhite};

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  img {
    width: 20px;
    height: 15px;
    object-fit: cover;
  }
`;

const PhoneInputGroup = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-end;
  margin: 16px 0;
`;
const Input = styled.input`
  margin: 16px 0;
  padding: 12px 0;
  border: none;
  border-bottom: 1px solid ${Color.colorLightestBeige};
  font-size: 16px;
  background-color: transparent;
  color: ${Color.colorWhite};
  transition: border-color 0.3s ease;

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }

  &:focus {
    outline: none;
    border-bottom-color: ${Color.colorPurpleBlue};
  }
`;

const ActionText = styled.button`
  margin: 24px 0;
  margin-left: 45%;
  color: ${Color.colorWhite};
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
  background: none;
  border: none;
  padding: 8px 16px;
  display: inline-block;

  &:hover {
    color: ${Color.colorPurpleBlue};
  }
`;

const ErrorMessage = styled.div`
  color: ${Color.colorRed};
  margin-bottom: 8px;
  text-align: center;
  font-size: 12px;
`;

const Links = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const Link = styled.a`
  color: ${Color.colorWhite};
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;

  &:hover {
    color: ${Color.colorPurpleBlue};
  }
`;


const CloseButton = styled.button`
  position: absolute;
  top: 3%;
  right: 5%;
  background: none;
  border: none;
  font-size: 40px;
  color: ${Color.colorWhite};
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: ${Color.colorPurpleBlue};
  }

  &:focus {
    outline: none;
  }
`;

const OTPContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const OTPInput = styled.input`
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid ${Color.colorLightestBeige};
  border-radius: 4px;
  background-color: transparent;
  color: ${Color.colorWhite};

  &:focus {
    outline: none;
    border-color: ${Color.colorPurpleBlue};
  }
`;

const PhoneLoginModal = ({ onClose, openLoginModal }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOTP] = useState(['', '', '', '', '', '']);
  const [showOTP, setShowOTP] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState({ code: 'US', callingCode: '+1', flag: 'https://flagcdn.com/us.svg' });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const authRef = useRef(null);

  useEffect(() => {
    authRef.current = getFirebaseAuth();
    clearRecaptcha();
    return () => {
      clearRecaptcha();
    };
  }, []);

  useEffect(() => {
    // Fetch countries data
    fetch("https://restcountries.com/v3.1/all")
      .then(response => response.json())
      .then(data => {
        const formattedCountries = data
          .map(country => ({
            code: country.cca2,
            name: country.name.common,
            callingCode: country.idd.root + (country.idd.suffixes?.[0] || ''),
            flag: `https://flagcdn.com/${country.cca2.toLowerCase()}.svg`
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
        setCountries(formattedCountries);
      })
      .catch(error => console.error('Error fetching countries:', error));
  }, []);
  
  useEffect(() => {
    // Initialize Firebase Auth on component mount
    const initAuth = async () => {
      try {
        authRef.current = await getFirebaseAuth();
        clearRecaptcha();
      } catch (error) {
        console.error('Error initializing auth:', error);
        setErrorMessage('Error initializing authentication');
      }
    };
    
    initAuth();
    
    return () => {
      clearRecaptcha();
    };
  }, []);

  const clearRecaptcha = () => {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
      window.recaptchaVerifier = null;
    }    
  };

  const formatPhoneNumber = (number) => {
    // Remove all non-numeric characters except + and convert multiple spaces to single space
    let cleaned = number.replace(/[^\d\s+]/g, '').replace(/\s+/g, ' ').trim();
    
    // If it doesn't start with +, check if it has a country code
    if (!cleaned.startsWith('+')) {
      // If it starts with multiple zeros or a single zero, remove them
      cleaned = cleaned.replace(/^0+/, '');
      
      // Add + if it's not there
      cleaned = '+' + cleaned;
    }
    
    return cleaned;
  };

  const validatePhoneNumber = (number) => {
    // Allow more flexible validation for international numbers
    // Must start with + followed by at least 7 digits
    const phoneRegex = /^\+\d{7,15}$/;
    return phoneRegex.test(number.replace(/\s/g, ''));
  };

  const setupRecaptcha = async () => {
    try {
      const auth = authRef.current;
      if (!auth) {
        throw new Error("Authentication not initialized");
      }

      clearRecaptcha();

      // Create a new div for reCAPTCHA
      const container = document.getElementById('recaptcha-container');
      if (container) {
        container.innerHTML = '<div id="recaptcha"></div>';
      }

      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', {
        size: 'invisible',
        callback: (response) => {
          console.log('reCAPTCHA verified:', response);
        },
        'expired-callback': () => {
          setErrorMessage('reCAPTCHA expired. Please try again.');
          clearRecaptcha();
        }
      });

      await window.recaptchaVerifier.render();
      return window.recaptchaVerifier;
    } catch (error) {
      console.error('reCAPTCHA setup error:', error);
      setErrorMessage('Error setting up verification. Please try again.');
      return null;
    }
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    // Allow only numbers, plus sign, and spaces during input
    if (/^[0-9+ ]*$/.test(value)) {
      setPhoneNumber(value);
      setErrorMessage('');
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (showOTP) {
      await handleVerifyOTP(e);
    } else {
      await handleSendOTP(e);
    }
  };

  const handleSendOTP = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    console.log('Sending OTP...'); 

    try {
      const auth = authRef.current;
      if (!auth) {
        throw new Error("Authentication not initialized");
      }
      if (!phoneNumber) {
        setErrorMessage('Please enter a phone number');
        return;
      }
  
      // Combine country code with phone number
      const cleanedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');
      const formattedNumber = `${selectedCountry.callingCode}${cleanedPhoneNumber}`;
      console.log('Formatted number:', formattedNumber); // Debug log

      const verifier = await setupRecaptcha();
      if (!verifier) {
        throw new Error('Failed to set up reCAPTCHA');
      }

      const confirmationResult = await signInWithPhoneNumber(auth, formattedNumber, verifier);
      window.confirmationResult = confirmationResult;
      setShowOTP(true);
      setErrorMessage('');
    } catch (error) {
      console.error('Send OTP error:', error);
      handleFirebaseError(error);
    }
  };

  const handleFirebaseError = (error) => {
    let message = 'Failed to send OTP. Please try again.';
    
    switch (error.code) {
      case 'auth/invalid-phone-number':
        message = 'Invalid phone number format. Please check the number.';
        break;
      case 'auth/invalid-app-credential':
        message = 'Please refresh the page and try again.';
        break;
      case 'auth/quota-exceeded':
        message = 'Too many attempts. Please try again later.';
        break;
      case 'auth/too-many-requests':
        message = 'Too many attempts. Please try again later.';
        break;
      default:
        message = error.message || 'An error occurred. Please try again.';
    }
    
    setErrorMessage(message);
    clearRecaptcha();
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    
    const otpValue = otp.join('');
    if (otpValue.length !== 6) {
      setErrorMessage('Please enter a valid 6-digit OTP');
      return;
    }

    try {
      if (!window.confirmationResult) {
        setErrorMessage('Session expired. Please request OTP again.');
        setShowOTP(false);
        return;
      }

      const result = await window.confirmationResult.confirm(otpValue);
      // User signed in successfully
      console.log('User signed in successfully');
      navigate('/dashboard');
    } catch (error) {
      console.error('Error verifying OTP:', error);
      if (error.code === 'auth/invalid-verification-code') {
        setErrorMessage('Invalid OTP. Please try again.');
      } else if (error.code === 'auth/code-expired') {
        setErrorMessage('OTP expired. Please request a new one.');
        setShowOTP(false);
      } else {
        setErrorMessage('Failed to verify OTP. Please try again.');
      }
    }
  };

  const handleOTPChange = (index, value) => {
    // Only allow numbers
    if (value && !/^\d*$/.test(value)) {
      return;
    }

    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    // Move focus to the next input or previous input on backspace
    if (value !== '' && index < 5) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    } else if (value === '' && index > 0) {
      const prevInput = document.getElementById(`otp-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <div id="recaptcha-container" style={{ position: 'absolute', top: '0', left: '0' }}></div>
        <TitleContainer>
          <Title>BacksApp</Title>
          <Subtitle>{showOTP ? 'Enter OTP' : 'Login with Phone'}</Subtitle>            
        </TitleContainer>
        <FormContainer>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          
          <Form onSubmit={handleFormSubmit}>
            {!showOTP ? (
              <>
                <PhoneInputGroup>
                  <CountrySelect>
                    <CountryButton type="button" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                      <img src={selectedCountry.flag} alt={selectedCountry.code} />
                      {selectedCountry.callingCode}
                    </CountryButton>
                    <CountryDropdown isOpen={isDropdownOpen}>
                      {countries.map((country) => (
                        <CountryOption
                          key={country.code}
                          onClick={() => {
                            setSelectedCountry(country);
                            setIsDropdownOpen(false);
                          }}
                        >
                          <img src={country.flag} alt={country.code} />
                          <span>{country.name} ({country.callingCode})</span>
                        </CountryOption>
                      ))}
                    </CountryDropdown>
                  </CountrySelect>
                  <Input
                    type="tel"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    placeholder="Phone Number"
                    required
                  />
                </PhoneInputGroup>
                <ActionText type="submit">Send OTP</ActionText>
              </>
            ) : (
              <>
                <OTPContainer>
                  {otp.map((digit, index) => (
                    <OTPInput 
                      key={index} 
                      id={`otp-${index}`} 
                      type="text" 
                      maxLength="1" 
                      value={digit} 
                      onChange={(e) => handleOTPChange(index, e.target.value)} 
                      required
                    />
                  ))}
                </OTPContainer>
                <ActionText type="submit">Verify OTP</ActionText>
              </>
            )}
          </Form>
          
          <Links>
            <Link href="#" onClick={openLoginModal} style={{ margin: '0 auto' }}>
              Login with Email
            </Link>
          </Links>
        </FormContainer>
      </ModalContent> 
    </ModalOverlay>
  );
};

export default PhoneLoginModal;
import React, { useState , useRef} from 'react';
import styled from 'styled-components';
import { createUserWithEmailAndPassword, PhoneAuthProvider, signInWithCredential } from "firebase/auth";
import { auth } from '../../../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../components/Common/InitialGears/AuthContext'; 
import { Color } from '../../../GlobalStyles';
import spereImage from '../../../assets/images/Original_Login.png';
import Config from '../../../config';
import { refreshAuthToken } from './authLoginUtils';


const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  border-radius: 16px;
  padding: 24px;
  width: 70%;
  max-width: 400px;
  height: 90%;
  max-height: 1500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${spereImage});
    background-size: cover;
    background-position: center;
    z-index: -2;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //background: linear-gradient(to right, #C77B58, #B76E79, #C77B58);
    background: linear-gradient(
      to right,
      rgba(199, 123, 88, 0.1),
      rgba(183, 110, 121, 0.4),
      rgba(199, 123, 88, 0.2)
    );
    z-index: -1;
  }
`;


const TitleContainer = styled.div` 
  padding: 20px;
  border-radius: 12px;
  margin-top: 75%;
  width: 90%;
`;

const FormContainer = styled.div`
  padding: 20px;
  border-radius: 12px;
  margin-top: 40%;
  width: 90%;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  color: ${Color.colorLightBeige};
  margin-bottom: 8px;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 0.9rem;
  color: ${Color.colorLightBeige};
  margin-bottom: 16px;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin: 16px 0;
  padding: 12px 0;
  border: none;
  border-bottom: 1px solid ${Color.colorLightestBeige};
  font-size: 16px;
  background-color: transparent;
  color: ${Color.colorWhite};
  transition: border-color 0.3s ease;

  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }

  &:focus {
    outline: none;
    border-bottom-color: ${Color.colorPurpleBlue};
  }
`;

const RegisterText = styled.span`
  margin: 24px 0;
  margin-left: 45%;
  color: ${Color.colorWhite};
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
  display: inline-block;

  &:hover {
    color: ${Color.colorPurpleBlue};
  }
`;

const ErrorMessage = styled.div`
  color: ${Color.colorRed};
  margin-bottom: 8px;
  text-align: center;
  font-size: 18px;
`;

const Links = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const Link = styled.a`
  color: ${Color.colorWhite};
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;

  &:hover {
    color: ${Color.colorPurpleBlue};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 3%;
  right: 5%;
  background: none;
  border: none;
  font-size: 40px;
  color: ${Color.colorWhite};
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: ${Color.colorPurpleBlue};
  }

  &:focus {
    outline: none;
  }
`;


const CodeBoxesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`;

const CodeBox = styled.input`
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid ${Color.colorLightestBeige};
  border-radius: 4px;
  background-color: transparent;
  color: ${Color.colorWhite};
  margin: 0 4px;

  &:focus {
    outline: none;
    border-color: ${Color.colorPurpleBlue};
  }
`;

const NextButton = styled.button`
  background: none;
  border: none;
  color: ${Color.colorWhite};
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s ease;
  margin-top: 20px;
  padding: 0;

  &:hover {
    color: ${Color.colorPurpleBlue};
  }

  &:disabled {
    color: ${Color.colorGray};
    cursor: not-allowed;
  }
`;

const ReferralCodeInput = ({ value, onChange }) => {
  const inputRefs = useRef([]);

  const handleChange = (index, e) => {
    const newValue = e.target.value;
    if (newValue.length <= 1 && /^\d*$/.test(newValue)) {
      const newCode = value.split('');
      newCode[index] = newValue;
      onChange(newCode.join(''));
      
      // Move to next input if a digit was entered
      if (newValue !== '' && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && value[index] === '' && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <CodeBoxesContainer>
      {[0, 1, 2, 3, 4, 5].map((index) => (
        <CodeBox key={index} ref={(el) => (inputRefs.current[index] = el)} type="text" maxLength="1" value={value[index] || ''} onChange={(e) => handleChange(index, e)} onKeyDown={(e) => handleKeyDown(index, e)} />
      ))}
    </CodeBoxesContainer>
  );
};
const OTPInput = styled.input`
  width: 40px;
  height: 40px;
  margin: 0 5px;
  text-align: center;
  font-size: 18px;
`;

const RegisterModal = ({ onClose, openLoginModal }) => {
  const [step, setStep] = useState(1);
  const [referralCode, setReferralCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  const otpInputRefs = useRef([]);

  const handleNextStep = () => {
    if (step === 1 || (step === 2 && isNameValid())) {
      setStep(step + 1);
    }
  };

  const isNameValid = () => {
    return firstName.length >= 3 && lastName.length >= 3;
  };

  const isValidInput = () => {
    return (email && password) || (phone && otp.join('').length === 6);
  };

  const handleSendVerificationCode = async () => {
    try {
      const phoneProvider = new PhoneAuthProvider(auth);
      const verificationId = await phoneProvider.verifyPhoneNumber(phone, 60);
      setVerificationId(verificationId);
      setIsOtpSent(true);
      setErrorMessage('');  
    } catch (error) { 
      setErrorMessage("Failed to send verification code. Please try again.");
    }
  };

  const handleOtpChange = (index, value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to next input if a digit was entered
      if (value !== '' && index < 5) {
        otpInputRefs.current[index + 1].focus();
      }
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!isValidInput()) {
      setErrorMessage("Either email and password or phone number and OTP are required.");
      return;
    }

    try {
      let emailUid, phoneUid;
      let userCredential;

      if (email && password) {
        userCredential = await createUserWithEmailAndPassword(auth, email, password);
      } else if (phone && otp.join('').length === 6) {
        const phoneCredential = PhoneAuthProvider.credential(verificationId, otp.join(''));
        userCredential = await signInWithCredential(auth, phoneCredential);
      }

      let idToken = await userCredential.user.getIdToken(); 
      const uid = userCredential.user.uid;
      const response = await registerNewUser(email, phone, idToken, emailUid, phoneUid);
      const responseData = await response.json(); 

      // Set login parameters after successful registration
      idToken = await refreshAuthToken(userCredential.user);
      const isBacksAppStaff = responseData.isBacksAppStaff || false;
      const isBacksAppManager = responseData.isBacksAppManager || false;
      const userVerified = responseData.userVerified || false;
      
      await setLoginParams(idToken, uid, email, isBacksAppStaff, isBacksAppManager, firstName,userVerified);

      navigate('/dashboard');
    } catch (error) { 
      setErrorMessage(error.message || "Failed to register. Please try again.");
      navigate('/');
    }
  };

  const setLoginParams = async (accessToken, uid, email, isBacksAppStaff, isBacksAppManager, firstName, userVerified) => {
    const success = await login(accessToken, uid, email, isBacksAppStaff, isBacksAppManager, firstName, userVerified);
    if (!success) {
      throw new Error("Failed to set login parameters");
    }
  };

  const registerNewUser = async (email, phone, idToken, emailUid, phoneUid) => {
    const body = JSON.stringify({
      phone_number: phone,
      country_code: "+1",
      first_name: firstName,
      last_name: lastName,
      email: email,
      referral_secret: referralCode,
      email_uid: emailUid,
      phone_uid: phoneUid,
    });

    const queryParams = `loginEmail=true`; 
    const registerNewUserUrl = `${Config.apiUrl}registerNewUser?${queryParams}`;
    
    try {
      const response = await fetch(registerNewUserUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': idToken
        },
        body: body,
        mode: 'cors',
        credentials: 'omit'
      });

      if (!response.ok) {
        if (response.status === 404) {
          throw new Error(`Referral Code Not acceptable`);
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return response;
    } catch (error) { 
      throw error;
    }
  };

  const handleLoginClick = (e) => {
    e.preventDefault();
    onClose();
    openLoginModal();
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Subtitle>Referral Code (optional)</Subtitle>
            <ReferralCodeInput
              value={referralCode}
              onChange={setReferralCode}
            />
            <NextButton onClick={handleNextStep}>Next</NextButton>
          </>
        );
      case 2:
        return (
          <>
            <Input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First Name"
              required
            />
            <Input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last Name"
              required
            />
            <NextButton onClick={handleNextStep} disabled={!isNameValid()}>Next</NextButton>
          </>
        );
      case 3:
        return (
          <>
            <Input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Phone Number"
            />
            {phone && !isOtpSent && (
              <NextButton onClick={handleSendVerificationCode}>Send OTP</NextButton>
            )}
            {isOtpSent && (
              <div>
                <Subtitle>Enter OTP</Subtitle>
                <div>
                  {otp.map((digit, index) => (
                    <OTPInput
                      key={index}
                      ref={(el) => (otpInputRefs.current[index] = el)}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                    />
                  ))}
                </div>
              </div>
            )}
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
            {email && (
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
            )}
            <RegisterText onClick={handleRegister} disabled={!isValidInput()}>Register</RegisterText>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <TitleContainer>
          <Title>BacksApp</Title>
          <Subtitle>Create your account</Subtitle>            
        </TitleContainer>
        <FormContainer>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          
          <Form onSubmit={(e) => e.preventDefault()}>
            {renderStep()}
          </Form>
          
          <Links>
            <Link href="#" onClick={handleLoginClick}>Already have an account? Login</Link>
          </Links>
        </FormContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default RegisterModal;
// components/common/FAQSection.js
import React, { useState } from 'react';
import { ChevronDown, ChevronUp, ChevronLeft, ChevronRight } from 'lucide-react';

const FAQSection = ({ category, questions, isOpen, toggleCategory }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const goToNextQuestion = (e) => {
    e.stopPropagation();
    setCurrentQuestionIndex((prev) => 
      prev === questions.length - 1 ? prev : prev + 1
    );
  };

  const goToPreviousQuestion = (e) => {
    e.stopPropagation();
    setCurrentQuestionIndex((prev) => 
      prev === 0 ? prev : prev - 1
    );
  };

  return (
    <div className="mb-6">
      <button
        onClick={() => toggleCategory(category)}
        className="w-full flex justify-between items-center text-left text-gray-300 hover:text-amber-400 transition-colors"
      >
        <span className="font-semibold">{category.title}</span>
        {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      </button>
      
      {isOpen && (
        <div className="mt-3">
          <div className="relative">
            <div className="min-h-[120px] text-gray-400">
              <div className="text-gray-300 mb-1 font-medium">
                {questions[currentQuestionIndex].q}
              </div>
              <div className="text-sm mt-2 transition-opacity duration-300">
                {questions[currentQuestionIndex].a}
              </div>
            </div>
            
            <div className="flex justify-between items-center mt-4">
              <button
                onClick={goToPreviousQuestion}
                disabled={currentQuestionIndex === 0}
                className={`p-1 rounded-full transition-colors ${
                  currentQuestionIndex === 0 
                    ? 'text-gray-600 cursor-not-allowed' 
                    : 'text-amber-400 hover:text-amber-300'
                }`}
              >
                <ChevronLeft size={20} />
              </button>
              
              <span className="text-sm text-gray-500">
                {currentQuestionIndex + 1} / {questions.length}
              </span>
              
              <button
                onClick={goToNextQuestion}
                disabled={currentQuestionIndex === questions.length - 1}
                className={`p-1 rounded-full transition-colors ${
                  currentQuestionIndex === questions.length - 1 
                    ? 'text-gray-600 cursor-not-allowed' 
                    : 'text-amber-400 hover:text-amber-300'
                }`}
              >
                <ChevronRight size={20} />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FAQSection;
import React from 'react';
import { Color } from "../../../GlobalStyles";
import closeImage from '../../../assets/images/cancel.png';
import { useNavigate } from "react-router-dom";

const TrendsModal = ({ trendsData, onClose }) => { 
  const navigate = useNavigate();
  const onMoreDetails = () => { 
  navigate('/trendingWishes')
};
  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <button onClick={onClose} style={styles.closeButton}>
          <img src={closeImage} alt="Close" style={styles.menuIcon} />
        </button>
        <h2 style={styles.myWishesTitle}>Wish Trends</h2>
        <div style={styles.scrollView}>
          {trendsData.map((item, index) => (
            <div key={index} style={styles.wishItem}>
              <span style={styles.wishItemTitle}>{item.name}</span>
              <span style={styles.wishItemCount}>{item.count}</span>
            </div>
          ))}
          <a href="#" onClick={(e) => {e.preventDefault();onMoreDetails();}} style={styles.moreDetailsLink}>
            <span style={styles.moreDetailsText}>  More Details</span>                
          </a> 
        </div>
      </div>
    </div>
  );
};

const styles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    position: 'relative',
    width: '80%',
    maxWidth: '600px',
    maxHeight: '80%',
    overflowY: 'auto',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
  },
  menuIcon: {
    width: '24px',
    height: '24px',
    objectFit: 'contain',
  },
  myWishesTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: Color.colorDarkGrey,
    marginBottom: '20px',
    textAlign: 'center',
  },
  scrollView: {
    maxHeight: 'calc(80vh - 100px)', // Adjust based on your needs
    overflowY: 'auto',
  },
  wishItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '15px',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: Color.colorCoolBeige,
    color: Color.colorRoyalBlueText,
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  wishItemTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    flex: 1,
  },
  wishItemCount: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: Color.colorRoyalBlueText,
    marginLeft: '10px',
  },
  moreDetailsLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '10px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  moreDetailsText: {
    color: Color.colorDarkBrown,
    fontSize: '16px',
    textDecoration: 'underline',
    textAlign: 'center',
  },
};

export default TrendsModal;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Shield, Lock, UserCheck, Book, Bell, Mail } from 'lucide-react';
import Footer from './Footer';

const PrivacyPolicyScreen = () => {
  const [agreed, setAgreed] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showReferralModal, setShowReferralModal] = useState(false);  

  const handleAgree = async () => {
    try {
      const response = await fetch('/api/acceptPrivacyPolicy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        if (response.status === 426) {
          window.location.href = '/login';
        }
        setErrorMessage('Oops, please re-try');
      } else {
        setAgreed(true);
        setSuccessMessage('Policy Agreement Updated');
      }
    } catch {
      setErrorMessage('Failed to accept the privacy policy. Please try again.');
    }
  };

  const sections = [
    {
      icon: Shield,
      title: "Introduction",
      content: "At BacksApp, we value your privacy and are committed to protecting your personal information. This privacy policy outlines how we collect, use, and safeguard your data when you use our app and engage in subscription agreements."
    },
    {
      icon: Lock,
      title: "How We Use Your Information",
      content: "The information we collect is used solely for the following purposes:",
      list: [
        "Processing your subscription payments",
        "Providing you with the services associated with your subscription",
        "Communicating with you about your subscription and related matters"
      ]
    },
    {
      icon: UserCheck,
      title: "Information We Collect",
      content: "When you agree to enter into a BacksApp's deal agreement within our app, we collect the following basic information:",
      list: [
        "Name",
        "Email address",
        "Payment details (processed by third-party payment providers)",
        "Other PII's as needed"
      ]
    },
    {
      icon: Shield,
      title: "Third-Party Services",
      content: "We utilize trusted third-party services for payment processing and underwriting. When you engage in a subscription transaction, you will be directed to provide your information directly to these third parties.",
      list: [
        "We do not have access to or control over the data you provide to them.",
        "Please note that these third parties have their own privacy policies, and we encourage you to review them to understand how they handle your information.",
        "We are not responsible for the privacy practices of these third parties."
      ]
    },
    {
      icon: Lock,
      title: "Data Security",
      content: "We take the security of your personal information seriously.",
      list: [
        "We implement industry-standard security measures to protect your data from unauthorized access, alteration, disclosure, or destruction.",
        "This includes the use of encryption, secure storage, and strict access controls.",
        "However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security."
      ]
    },
    {
      icon: Book,
      title: "Data Retention",
      content: "We retain your personal information only for as long as necessary to fulfill the purposes outlined in this privacy policy, unless a longer retention period is required or permitted by law.",
      list: [
        "Once your subscription is terminated or expires, we will securely delete your personal information from our records."
      ]
    },
    {
      icon: UserCheck,
      title: "Your Rights",
      content: "You have the right to access, update, or delete your personal information held by us.",
      list: [
        "If you wish to exercise any of these rights or have any questions regarding your data, please contact us using the information provided below."
      ]
    },
    {
      icon: Bell,
      title: "Changes to the Privacy Policy",
      content: "We may update this privacy policy from time to time.",
      list: [
        "We will notify you of any significant changes by posting the updated policy within the app or by sending you an email. The revised policy will be effective from the date specified in the update."
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Navigation Bar */}
      <nav className="fixed top-0 w-full bg-white/95 backdrop-blur-sm shadow-sm z-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center space-x-8">
              <Link to="/" className="text-xl font-bold bg-gradient-to-r from-amber-600 to-rose-800 bg-clip-text text-transparent">
                BacksApp
              </Link>
              <div className="hidden md:flex space-x-6">
                <Link to="/" className="text-gray-600 hover:text-amber-600 transition-colors">Home</Link>
                <Link to="/about" className="text-gray-600 hover:text-amber-600 transition-colors">About</Link>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <button onClick={() => setShowReferralModal(true)} className="px-4 py-2 bg-gradient-to-r from-amber-600 to-rose-800 text-white rounded-lg hover:opacity-90 transition-all">
                Request to Join
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="pt-32 pb-16">
        <div className="max-w-4xl mx-auto px-4">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-amber-600 to-rose-800 bg-clip-text text-transparent">
              Privacy Policy
            </h1>
            <p className="text-gray-600">Last updated: April 05, 2024</p>
          </div>

          {errorMessage && (
            <div className="bg-rose-50 border border-rose-200 text-rose-700 px-4 py-3 rounded-lg mb-6 fade-in-up">
              {errorMessage}
            </div>
          )}
          
          {successMessage && (
            <div className="bg-green-50 border border-green-200 text-green-700 px-4 py-3 rounded-lg mb-6 fade-in-up">
              {successMessage}
            </div>
          )}

          <button
            onClick={handleAgree}
            disabled={agreed}
            className={`w-full py-3 px-6 rounded-lg transition-all duration-300 mb-12 ${
              agreed 
                ? 'bg-green-500 hover:bg-green-600' 
                : 'bg-gradient-to-r from-amber-600 to-rose-800 hover:opacity-90'
            } text-white scale-on-hover`}
          >
            {agreed ? 'Agreed' : 'I Agree to Privacy Policy'}
          </button>

          <div className="space-y-12">
            {sections.map((section, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md hover:shadow-lg transition-duration-300 p-8 fade-in-up">
                <div className="flex items-start space-x-4">
                  <section.icon className="w-6 h-6 text-amber-600 flex-shrink-0 mt-1" />
                  <div className="flex-1">
                    <h2 className="text-xl font-semibold mb-4 text-gray-800">
                      {index + 1}. {section.title}
                    </h2>
                    <p className="text-gray-700 mb-4 leading-relaxed">
                      {section.content}
                    </p>
                    {section.list && (
                      <ul className="space-y-2 text-gray-700 pl-5 list-disc">
                        {section.list.map((item, i) => (
                          <li key={i} className="leading-relaxed">{item}</li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            ))}

            {/* Contact Section */}
            <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-duration-300 p-8 fade-in-up">
              <div className="flex items-start space-x-4">
                <Mail className="w-6 h-6 text-amber-600 flex-shrink-0 mt-1" />
                <div>
                  <h2 className="text-xl font-semibold mb-4 text-gray-800">9. Contact Us</h2>
                  <p className="text-gray-700 mb-4">
                    If you have any questions, concerns, or requests regarding this privacy policy or the 
                    handling of your personal information, please contact us at:
                  </p>
                  <address className="text-gray-700 not-italic">
                    Infoply Inc<br />
                    California, CA<br />
                    support@backsapp.com<br />
                    510-771-7866
                  </address>
                  <p className="text-gray-700 mt-4">
                    We are committed to addressing your inquiries and resolving any issues in a timely manner.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />

      <style>{`
        @keyframes fadeInUp {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .fade-in-up {
          animation: fadeInUp 0.5s ease-out forwards;
        }

        .scale-on-hover {
          transition: transform 0.3s ease;
        }

        .scale-on-hover:hover:not(:disabled) {
          transform: scale(1.02);
        }
      `}</style>
    </div>
  );
};

export default PrivacyPolicyScreen;
import React from 'react'; 
import { Color } from "../../../GlobalStyles"; 
import closeImage from '../../../assets/images/cancel.png';
import { useNavigate } from "react-router-dom";

const MyWishesModal = ({ wishlistData, onClose }) => {
  const navigate = useNavigate();

 const onMoreDetails = () => { 
    navigate('/myWishes')
  };
  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <button onClick={onClose} style={styles.closeButton}> 
          <img src={closeImage} style={styles.menuIcon} />
        </button>
        <h2 style={styles.myWishesTitle}>My Wishes</h2>
        {wishlistData.length > 0 ? (
          <ul style={styles.wishList}>
            {wishlistData.map((wish, index) => (
              <li key={index} style={styles.wishItem}>
                <span style={styles.wishCategory}>{wish.name}</span>
                <span style={styles.itemDate}>Wished on: {wish.date_wished}</span>                  
                <span>
                {Object.entries(wish.details).map(([key, value]) => (
                    <span key={key} style={styles.itemDescription}>{wish.description}</span>
                  ))}
                </span>                                
              </li>
            ))}
            <a href="#" onClick={(e) => {e.preventDefault();onMoreDetails();}} style={styles.moreDetailsLink}>
                    <span style={styles.moreDetailsText}>  More Details</span>                
                </a> 
          </ul>
        ) : (
          <p style={styles.noWishesMessage}>You haven't made any wishes yet.</p>
        )}
      </div>
    </div>
  );
};

const styles = {
    
  menuIcon: {
    fontSize: '20px',
    width: '24px',
    height: '24px',
    objectFit: 'contain',
  }, 
itemDescription : {
        fontSize: 14,
        marginBottom: 6,
        color: '#5b5b5b',
},
itemDate : {
        fontSize: 12,
        color: '#666',
        marginTop: 5,
},
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    position: 'relative',
    width: '80%',
    maxWidth: '600px',
    maxHeight: '80%',
    overflowY: 'auto',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
    color: Color.colorDarkGrey,
  },
  myWishesTitle: {
    fontSize: '24px',
    marginBottom: '20px',
    color: Color.colorRoyalBlueText,    
    textAlign: 'center',
  },
  wishList: {
    listStyle: 'none',
    padding: 0,
  },
  wishItem: {
    marginBottom: '15px',
    padding: '10px', 
    borderRadius: '4px',
    backgroundColor: Color.colorCoolBeige,
    padding: 16,
    borderRadius: 8,
    marginVertical: 8,
    marginHorizontal: 16,
    elevation: 2,
    shadowColor: Color.colorDarkGrey,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
  },
  wishCategory: {
    fontWeight: 'bold',
    display: 'block',
    marginBottom: '5px',
    color: '#24547f',
  },
  wishDetails: {
    color: '#666',
  },
  noWishesMessage: {
    color: '#666',
    fontStyle: 'italic',
  }, 
  moreDetailsLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 10,
  },
  moreDetailsText: {
    color: Color.colorDarkBrown,
    fontSize: 16,
    textDecoration: 'underline',
    textAlign: 'center',
  },
};

export default MyWishesModal;
import React, { useState, useEffect, useRef } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { loadGoogleMapsScript } from './googleMapsLoader';
import Config from '../../config'; 
import styles from './Profiles.module.css';

const AddressSection = ({ address, isPrimaryAddress,handleAddressSave,onCancel, isNewAddress , callFromProfiles, callFromEstimates}) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [localAddress, setLocalAddress] = useState(address);
    const [isLoading, setIsLoading] = useState(false);
    const [googlePlacesKey, setGooglePlacesKey] = useState(0);
    const googlePlacesRef = useRef(null);
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        loadGoogleMapsScript()
            .then(() => setScriptLoaded(true))
            .catch((error) => console.error('Error loading Google Maps script:', error));
    }, []);

    useEffect(() => {       
        setLocalAddress(address);
    }, [address]);

    useEffect(() => {
        if (!callFromProfiles) {
            setModalVisible(true);
        }
    }, [callFromProfiles]);

    const handleGooglePlacesSelect = async (data) => { 
        if (data && data.value && data.value.place_id) {
            const { description, place_id} = data.value; 
            const parts = description.split(', '); 

            setIsLoading(true);
            try {
                const placeDetails = await getPlaceDetails(place_id);                 
                const newAddress = extractAddressComponents(placeDetails); 

                setModalVisible(false);
                setLocalAddress(newAddress);
                handleAddressSave(newAddress);
            } catch (error) {
                console.error("Error fetching place details:", error); 
            } finally {
                setIsLoading(false);
                setGooglePlacesKey(prevKey => prevKey + 1);
            }
        } else {
            console.error("Invalid address details received from Google Places API"); 
        }
    };

    const getPlaceDetails = (placeId) => {
        return new Promise((resolve, reject) => {
            const service = new window.google.maps.places.PlacesService(document.createElement('div'));
            service.getDetails(
                { 
                    placeId: placeId,
                    fields: ['address_component']
                }, 
                (place, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        resolve(place);
                    } else {
                        reject(new Error(`Place details request failed: ${status}`));
                    }
                }
            );
        });
    };

    const extractAddressComponents = (place) => {
        const addressComponents = place.address_components;
        const newAddress = {
            address1: '',
            address2: '',
            city: '',
            state: '',
            postal_code: '',
            country: '',
            primary_residence: isPrimaryAddress,
        };

        for (let component of addressComponents) {
            const types = component.types;
            if (types.includes('street_number')) {
                newAddress.address1 = component.long_name + ' ';
            } else if (types.includes('route')) {
                newAddress.address1 += component.long_name;
            } else if (types.includes('locality')) {
                newAddress.city = component.long_name;
            } else if (types.includes('administrative_area_level_1')) {
                newAddress.state = component.short_name;
            } else if (types.includes('postal_code')) {
                newAddress.postal_code = component.long_name;
            } else if (types.includes('country')) {
                newAddress.country = component.long_name;
            }
        }

        return newAddress;
    };

    const openModal = () => {
        setModalVisible(true);
        setGooglePlacesKey(prevKey => prevKey + 1);
    };

    const formatAddress = (address) => {
        if (!address) return "No address provided.";
        
        const parts = [
            address.address1,
            address.address2,
            address.city,
            address.state,
            address.geocode,
            address.country
        ].filter(Boolean);

        return parts.join(", ");
    };

    return (
        <div className={styles.sectionContainer}>
            {callFromProfiles ? (
                <div className={styles.sectionContent}>
                    {isLoading ? (
                        <div className={styles.loadingContainer}>
                            <div>Loading...</div>
                            <p className={styles.loadingText}>Updating address...</p>
                        </div>
                    ) : (
                        <div className={styles.addressItem} onClick={() => setModalVisible(true)}>
                            <p className={styles.addressText}>                      
                                {formatAddress(localAddress)}
                            </p> 
                        </div>
                    )}
                </div>
            ):(
                <></>
                
            )}

            {modalVisible && scriptLoaded && (
                <div className={styles.modalContainer}>
                    <div className={styles.modalContent}>
                        <h2 className={styles.modalTitle}>Enter New Address</h2>
                        <button onClick={() => setModalVisible(false)}  className={styles.modalCloseButton} aria-label="Close">
                            ×
                        </button>
                        <GooglePlacesAutocomplete apiKey={Config.googleMapsAPIKEY}
                            selectProps={{
                                placeholder: 'Search for an address',
                                onChange: handleGooglePlacesSelect,
                            }} ref={googlePlacesRef}
                            placeholder="Search for an address"
                            keepResultsAfterBlur={true} onLoad={ref => ref.focus()}
                            query={{
                                key: Config.googleMapsAPIKEY,
                                language: 'en',
                            }}
                            fetchDetails={true}
                            styles={{
                                container: styles.googlePlacesContainer,
                                textInputContainer: styles.googlePlacesTextInputContainer,
                                textInput: styles.googlePlacesTextInput,
                                listView: styles.googlePlacesListView,
                            }} debounce={300}/>
                        {callFromProfiles && (
                            <button onClick={() => setModalVisible(false)} className={styles.closeButton}>
                                Close
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddressSection;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faLock } from '@fortawesome/free-solid-svg-icons';  
import * as style from "../../../GlobalStyles";
import { useAuth } from '../../../components/Common/InitialGears/AuthContext';

const RatingsDisplay = ({ ratings, totalRatings, averageRating }) => {
  const { userVerified } = useAuth();
  const stars = [5, 4, 3, 2, 1];
  const safeAverageRating = Math.min(Math.max(Number(averageRating) || 0, 0), 5);
  const safeTotalRatings = Math.max(Number(totalRatings) || 0, 0);

  const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      padding: '10px',
      backgroundColor: style.Color.colorLightestBeige,
      borderRadius: '10px',
      paddingBottom: '20px',
      marginBottom: '20px',
      position: 'relative'
    },
    overlayContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(243, 244, 246, 0.9)',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 10
    },
    overlayContent: {
      textAlign: 'center',
      padding: '16px'
    },
    lockIcon: {
      color: '#6B7280',
      fontSize: '24px',
      marginBottom: '8px'
    },
    overlayText: {
      color: '#4B5563',
      fontSize: '14px'
    },
    ratingRow: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '15px'
    },
    stars: {
      marginRight: '10px'
    },
    ratingText: {
      fontWeight: 'bold',
      fontSize: '18px',
      color: style.Color.colorRoyalBlueText
    },
    totalRatings: {
      color: style.Color.colorRoyalBlueText,
      fontSize: '14px',
      marginBottom: '10px'
    },
    ratingBar: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '15px'
    },
    starLabel: {
      width: '60px',
      textAlign: 'right',
      marginRight: '10px',
      fontSize: '14px',
      color: style.Color.colorRoyalBlueText
    },
    progressBar: {
      flexGrow: 1,
      height: '18px',
      backgroundColor: '#E7E7E7',
      marginRight: '10px',
      borderRadius: '4px',
      overflow: 'hidden'
    },
    progressFill: (width) => ({
      height: '100%',
      backgroundColor: '#FFA41C',
      width: `${width}%`
    }),
    percentage: {
      width: '30px',
      fontSize: '14px',
      color: style.Color.colorRoyalBlueText
    },
    verificationMessage: {
      backgroundColor: '#EFF6FF',
      borderRadius: '8px',
      padding: '12px',
      marginTop: '16px'
    },
    verificationText: {
      color: '#1E40AF',
      fontSize: '14px'
    }
  };

  return (
    <div className="relative">
      <div style={styles.container}>
        {!userVerified && (
          <div style={styles.overlayContainer}>
            <div style={styles.overlayContent}>
              <FontAwesomeIcon icon={faLock} style={styles.lockIcon} />
              <p style={styles.overlayText}>
                Please verify your account to rate
              </p>
            </div>
          </div>
        )}

        <div style={styles.ratingRow}>
          <span style={styles.stars}>
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon key={index} icon={faStar} color={index < Math.round(safeAverageRating) ? '#FFA41C' : '#c97d7d'}/>
            ))}
          </span>
          <span style={styles.ratingText}>
            {safeAverageRating.toFixed(1)} out of 5
          </span>
        </div>

        <p style={styles.totalRatings}>
          {safeTotalRatings} {safeTotalRatings === 1 ? 'rating' : 'ratings'} available
        </p>

        {stars.map(star => {
          const count = ratings[star] || 0;
          const percentage = safeTotalRatings > 0 ? (count / safeTotalRatings * 100) : 0;
          
          return (
            <div key={star} style={styles.ratingBar}>
              <span style={styles.starLabel}>{star} star</span>
              <div style={styles.progressBar}>
                <div style={styles.progressFill(percentage)} />
              </div>
              <span style={styles.percentage}>{count}</span>
            </div>
          );
        })}
      </div>

      {!userVerified && (
        <div style={styles.verificationMessage}>
          <p style={styles.verificationText}>
            Rating functionality is only available for verified users. Please verify your account to participate in ratings.
          </p>
        </div>
      )}
    </div>
  );
};

export default RatingsDisplay;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Shield, 
  Scale, 
  UserCheck, 
  AlertTriangle, 
  FileText, 
  Lock,
  Users,
  Ban,
  Globe,
  Mail,
  ScrollText,
  Gavel
} from 'lucide-react';
import Footer from './Footer';

const TermsAndConditionsScreen = () => {
  const [agreed, setAgreed] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showReferralModal, setShowReferralModal] = useState(false);

  const handleAgree = async () => {
    try {
      const response = await fetch('/api/acceptTerms', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        if (response.status === 426) {
          window.location.href = '/login';
        }
        setErrorMessage('Oops, please re-try');
      } else {
        setAgreed(true);
        setSuccessMessage('Terms Agreement Updated');
      }
    } catch {
      setErrorMessage('Failed to accept the terms and conditions. Please try again.');
    }
  };

  const sections = [
    {
      icon: Shield,
      title: "Overview",
      content: "BacksApp has established the GroupWish Policy to ensure a positive user experience and compliance with applicable laws and regulations. This policy applies to all GroupWishes created by users on BacksApp.",
      subsections: [
        "Users are responsible for ensuring GroupWish compliance with all applicable laws",
        "This policy is not legal advice - consult legal advisors for specific guidance",
        "Policy updates occur periodically - regular review recommended",
        "Users must acknowledge and agree to these terms before using the platform"
      ]
    },
    {
      icon: ScrollText,
      title: "Basic Guidelines for GroupWishes",
      content: "All GroupWishes must adhere to these fundamental guidelines:",
      subsections: [
        "Clear and specific purpose with accurate product details",
        "Complete transparency regarding costs, suppliers, and additional fees",
        "No misleading or deceptive information",
        "Compliance with local and international laws",
        "No offensive or discriminatory content",
        "Respect for intellectual property rights"
      ]
    },
    {
      icon: FileText,
      title: "Approval Process",
      content: "Every GroupWish undergoes a thorough review process:",
      subsections: [
        "Initial review by BacksApp team for compliance and feasibility",
        "Assessment of accuracy and legitimacy of provided information",
        "Approval or rejection notification with detailed reasoning",
        "No modifications allowed after approval - new submission required for changes",
        "Regular audits of active GroupWishes for continued compliance"
      ]
    },
    {
      icon: Ban,
      title: "Prohibited Content",
      content: "The following content is strictly prohibited:",
      subsections: [
        "Illegal products or services in any jurisdiction",
        "Offensive, discriminatory, or harmful content",
        "False or misleading product claims",
        "Violent or explicit material",
        "Content that violates platform guidelines",
        "Unauthorized intellectual property use"
      ]
    },
    {
      icon: AlertTriangle,
      title: "Restricted Content",
      content: "Certain content categories have specific restrictions:",
      subsections: [
        "Regulated products requiring additional documentation",
        "Age-restricted items with verification requirements",
        "Geographically restricted products",
        "Products requiring special licenses or permits",
        "Items subject to import/export regulations"
      ]
    },
    {
      icon: Scale,
      title: "User Rights and Responsibilities",
      content: "Users must understand their rights and obligations:",
      subsections: [
        "Accurate information provision requirement",
        "Responsibility for GroupWish compliance",
        "Obligation to report violations",
        "Account maintenance requirements",
        "Communication guidelines and expectations"
      ]
    },
    {
      icon: Lock,
      title: "Data Usage and Privacy",
      content: "Information handling and privacy guidelines:",
      subsections: [
        "Data collection and usage policies",
        "User information protection measures",
        "Third-party data sharing protocols",
        "Privacy rights and options",
        "Data retention and deletion policies"
      ]
    },
    {
      icon: Globe,
      title: "International Operations",
      content: "Guidelines for international transactions:",
      subsections: [
        "Cross-border transaction requirements",
        "Currency and payment processing",
        "International shipping regulations",
        "Customs and import considerations",
        "Regional compliance requirements"
      ]
    },
    {
      icon: Gavel,
      title: "Dispute Resolution",
      content: "Procedures for handling disputes:",
      subsections: [
        "Conflict resolution process",
        "Mediation procedures",
        "Appeals process",
        "Resolution timeframes",
        "Legal jurisdiction specifications"
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Navigation Bar */}
      <nav className="fixed top-0 w-full bg-white/95 backdrop-blur-sm shadow-sm z-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center space-x-8">
              <Link to="/" className="text-xl font-bold bg-gradient-to-r from-amber-600 to-rose-800 bg-clip-text text-transparent">
                BacksApp
              </Link>
              <div className="hidden md:flex space-x-6">
                <Link to="/" className="text-gray-600 hover:text-amber-600 transition-colors">Home</Link>
                <Link to="/about" className="text-gray-600 hover:text-amber-600 transition-colors">About</Link>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <button onClick={() => setShowReferralModal(true)} className="px-4 py-2 bg-gradient-to-r from-amber-600 to-rose-800 text-white rounded-lg hover:opacity-90 transition-all">
                Request to Join
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="pt-32 pb-16">
        <div className="max-w-4xl mx-auto px-4">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-amber-600 to-rose-800 bg-clip-text text-transparent">
              Terms and Conditions
            </h1>
            <p className="text-gray-600">Last updated: April 05, 2024</p>
          </div>

          {errorMessage && (
            <div className="bg-rose-50 border border-rose-200 text-rose-700 px-4 py-3 rounded-lg mb-6 fade-in-up">
              {errorMessage}
            </div>
          )}
          
          {successMessage && (
            <div className="bg-green-50 border border-green-200 text-green-700 px-4 py-3 rounded-lg mb-6 fade-in-up">
              {successMessage}
            </div>
          )}


          <div className="space-y-12">
            {sections.map((section, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md hover:shadow-lg transition-duration-300 p-8 fade-in-up">
                <div className="flex items-start space-x-4">
                  <section.icon className="w-6 h-6 text-amber-600 flex-shrink-0 mt-1" />
                  <div className="flex-1">
                    <h2 className="text-xl font-semibold mb-4 text-gray-800">
                      {index + 1}. {section.title}
                    </h2>
                    <p className="text-gray-700 mb-4 leading-relaxed">
                      {section.content}
                    </p>
                    <ul className="space-y-2 text-gray-700 pl-5 list-disc">
                      {section.subsections.map((item, i) => (
                        <li key={i} className="leading-relaxed">{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}

            {/* Contact Section */}
            <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-duration-300 p-8 fade-in-up">
              <div className="flex items-start space-x-4">
                <Mail className="w-6 h-6 text-amber-600 flex-shrink-0 mt-1" />
                <div>
                  <h2 className="text-xl font-semibold mb-4 text-gray-800">Contact Us</h2>
                  <p className="text-gray-700 mb-4">
                    For any questions or concerns about these terms, please contact us at:
                  </p>
                  <address className="text-gray-700 not-italic">
                    Infoply Inc<br />
                    California, CA<br />
                    support@backsapp.com<br />
                    510-771-7866
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />

      <style>{`
        @keyframes fadeInUp {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .fade-in-up {
          animation: fadeInUp 0.5s ease-out forwards;
        }

        .scale-on-hover {
          transition: transform 0.3s ease;
        }

        .scale-on-hover:hover:not(:disabled) {
          transform: scale(1.02);
        }
      `}</style>
    </div>
  );
};

export default TermsAndConditionsScreen;
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import AddressSection from "./AddressSection";
import * as GlobalStyles from "../../GlobalStyles"; 
import Config from "../../config";
import { useAuth } from '../../components/Common/InitialGears/AuthContext';  
import { getApi, postApi, buildUrl } from "./BackendCalls/apiUtils";

const MultipleAddressesSection = ({ addresses: initialAddresses, primaryAddressId, onAddNewAddress }) => { 
  const { queryLoginParam, idTokenLoggedIn } = useAuth();
  const [addresses, setAddresses] = useState(initialAddresses); 
  const [isAddingNewAddress, setIsAddingNewAddress] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddNewAddress = () => {
    setIsAddingNewAddress(true);
  };
 
  const handleCancel = () => {
    setIsAddingNewAddress(false);
  };

  const deleteAddress = async (addressId) => { 
    setErrorMessage(null);
    setSuccessMessage(null);
    setIsLoading(true);
    let addressUrl = `${Config.apiUrl}deleteAddress?${queryLoginParam}`;  
    const headers = {'Authorization': idTokenLoggedIn, 'Content-Type': 'application/json'};    
    const requestBody = JSON.stringify({address_id: addressId});
    try {
        const response = await fetch(addressUrl, { method: 'POST', headers: headers, body: requestBody });   
        if (response.ok) { 
            setSuccessMessage("Address deleted");
            setAddresses(prevAddresses => prevAddresses.filter(address => address.id !== addressId));
        } else {
          setErrorMessage("Something went wrong. Please try later"); 
        }
    } catch (error) { 
        setErrorMessage('Failed to delete address. Please try again.');
    } finally {
        setIsLoading(false);
    }
  }; 

  const handleSetPrimaryAddress = async (addressId) => {
    setErrorMessage(null);
    setSuccessMessage(null);
    setIsLoading(true);
    try {
      const setPrimaryUrl = buildUrl('setPrimaryAddress', queryLoginParam);
      const requestBody = { address_id: addressId };
      const response = await postApi(setPrimaryUrl, requestBody);
      
      if (response) { 
        setSuccessMessage("Primary address updated successfully"); 
      } else {
        setErrorMessage("Failed to update primary address. Please try again.");
      }
    } catch (error) {
      setErrorMessage("An error occurred while updating the primary address");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={styles.sectionContainer}>      
        <div style={styles.sectionContent}>
          {isLoading && <div>Loading...</div>}
          {successMessage && <div style={styles.successMessage}>{successMessage}</div>}
          {errorMessage && <div style={styles.errorMessage}>{errorMessage}</div>}
          
          {addresses.length > 0 ? (
            addresses.map((address) => (
              <div key={address.id} style={styles.addressItem}>
                <p style={styles.addressText}>
                  {address.address1}, {address.address2 && `${address.address2}, `}
                  {address.city}, {address.state} {address.postal_code}
                </p>
                <div style={styles.addressActions}>
                  {address.id === primaryAddressId ? (
                    <span style={styles.primaryLabel}>Primary</span>
                  ) : (
                    <button style={styles.setPrimaryButton} onClick={() => handleSetPrimaryAddress(address.id)}>
                      Set as Primary
                    </button>
                  )}
                  <button style={styles.deleteButton} onClick={() => deleteAddress(address.id)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p style={styles.noAddressText}>No additional addresses found.</p>
          )}
          
          {isAddingNewAddress ? (
            <AddressSection address={{}}  isPrimaryAddress={false} handleAddressSave={onAddNewAddress}  onCancel={handleCancel}  isNewAddress={true}/>
          ) : (
            <button style={styles.addButton} onClick={handleAddNewAddress}>
              + Add New Address
            </button>
          )}
        </div>
    </div>
  );
};


const styles = {
  sectionContainer: {
    marginBottom: '10px',
  },
  sectionHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    width: '50px',
    backgroundColor: '#f0f0f0',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  sectionIcon: {
    marginRight: '10px',
    color: GlobalStyles.Color.colorTabs,
  },
  sectionTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: 0,
    flexGrow: 1,
  },
  sectionContent: {
    maxHeight: '300px',
    width: '80%',
    overflowY: 'auto',
    padding: '10%',
  },
  addressItem: {
    marginBottom: '10px',
    padding: '10px',
    backgroundColor: '#ffffff',
    color: '#41547d',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  },
  addressText: {
    fontSize: '16px',
    margin: '0 0 5px 0',
  },
  addressActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '5px',
  },
  primaryLabel: {
    color: 'green',
    fontWeight: 'bold',
  },
  setPrimaryButton: {
    color: 'blue',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
  },
  deleteButton: {
    color: 'red',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
  },
  noAddressText: {
    fontSize: '16px',
    fontStyle: 'italic',
    marginBottom: '10px',
  },
  addButton: {
    backgroundColor: '#4CAF50',
    color: '#ffffff',
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
    marginTop: '10px',
  },
  successMessage: {
    backgroundColor: '#d4edda',
    color: '#155724',
    padding: '10px',
    borderRadius: '5px',
    marginBottom: '10px',
  },
  errorMessage: {
    backgroundColor: '#f8d7da',
    color: '#721c24',
    padding: '10px',
    borderRadius: '5px',
    marginBottom: '10px',
  },
};

export default MultipleAddressesSection;
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faPencilAlt, faFilter, faChevronDown, faChevronUp, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Config from "../../../config";
import { useAuth } from '../../Common/InitialGears/AuthContext'; 
import { Color } from "../../../GlobalStyles"; 
import closeImage from '../../../assets/images/cancel.png'; 
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";

const JoinedGroupWishes = ({  onClose }) => {
  const navigate = useNavigate();
  const { queryLoginParam, idTokenLoggedIn } = useAuth();
  const [joinedWishes, setJoinedWishes] = useState([]);
  const [filteredWishes, setFilteredWishes] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [filterProductType, setFilterProductType] = useState('');
  const [showPreferences, setShowPreferences] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [isWithdrawDialogOpen, setIsWithdrawDialogOpen] = useState(false);
  const [selectedWishToWithdraw, setSelectedWishToWithdraw] = useState(null);

  const fetchJoinedWishes = useCallback(async () => {
    try {
      setRefreshing(true);
      const joinedWishesUrl = buildUrl('myGroupWishes', `${queryLoginParam}&type=joined`);
      const response = await getApi(joinedWishesUrl);
      if (response.ok) {
        setJoinedWishes(response.data);
        setFilteredWishes(response.data);
      } else {
        console.error('Failed to fetch joined wishes:', response.data);
      }
    } catch (error) {
      console.error('Error fetching joined wishes:', error);
    } finally {
      setRefreshing(false);
    }
  }, [queryLoginParam]);

  useEffect(() => {
    fetchJoinedWishes();
  }, [fetchJoinedWishes]);

  const filterWishes = useCallback(() => {
    if (!Array.isArray(joinedWishes)) {
      setFilteredWishes([]);
      return;
    }

    const normalize = (str) => str.toLowerCase().replace(/[^a-z0-9]/g, '');
    const partialMatch = (full, partial) => {
      if (!full || !partial) return false;
      return normalize(full).includes(normalize(partial));
    };

    const filtered = joinedWishes.filter(item => {
      const wish = item.wish;
      const matchesQuery = !searchQuery ||
        partialMatch(wish.deal_title, searchQuery) ||
        partialMatch(wish.product_name, searchQuery) ||
        (wish.category && wish.category.some(cat => partialMatch(cat, searchQuery))) ||
        (wish.product_type && wish.product_type.some(type => partialMatch(type, searchQuery)));

      const matchesCategory = !filterCategory ||
        (wish.category && wish.category.some(cat => partialMatch(cat, filterCategory)));

      const matchesProductType = !filterProductType ||
        (wish.product_type && wish.product_type.some(type => partialMatch(type, filterProductType)));

      return matchesQuery && matchesCategory && matchesProductType;
    });

    setFilteredWishes(filtered);
  }, [joinedWishes, searchQuery, filterCategory, filterProductType]);

  useEffect(() => {
    filterWishes();
  }, [filterWishes, searchQuery, filterCategory, filterProductType]);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleCategoryFilter = (category) => {
    setFilterCategory(category);
  };

  const handleProductTypeFilter = (productType) => {
    setFilterProductType(productType);
  };

  const togglePreferences = (uuid) => {
    setShowPreferences(prev => ({ ...prev, [uuid]: !prev[uuid] }));
  };

  const handleEditPreferences = (item) => {
    navigate('/preferenceList', {
      state: {
        formData: item.wish, 
        existingPreferences: "edit",
        groupWishName: item.wish.product_name,
        grp_wish_id: item.wish.id,
      }      
    }); 
  };

  useEffect(() => {
    filterWishes(searchQuery, filterCategory, filterProductType);
  }, [joinedWishes, searchQuery, filterCategory, filterProductType, filterWishes]);

  const handleViewProgress = (item) => {
    navigate('/groupWishProgress', { state: { wish: item.wish, progressData: item } });
  }; 

  const handleReferAFriend = (item) => {    
    navigate("/invites", { state: { "grp_wish": item } }); 
  };

  const getPriceRange = (priceRanges) => {
    if (!priceRanges || priceRanges.length === 0) return 'Price not available';
    const prices = priceRanges.map(range => parseFloat(range.range_price));
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices); 
    return minPrice === maxPrice ? `$${minPrice.toFixed(2)}` : `$${minPrice.toFixed(2)} - $${maxPrice.toFixed(2)}`;
  };

  const handleWithdraw = async (item) => {
    setSelectedWishToWithdraw(item);
    setIsWithdrawDialogOpen(true);
  };

  const confirmWithdraw = async () => {
    if (selectedWishToWithdraw) {
      try {
        const withdrawUrl = `${Config.apiUrl}withdrawGroupWish?${queryLoginParam}`;
        const headers = { 'Authorization': idTokenLoggedIn, 'Content-Type': 'application/json' };
        const request = { wish_id: selectedWishToWithdraw.wish.id };
        const response = await postApi(withdrawUrl, request);          

        if (response.ok) { 
          setJoinedWishes(prevWishes => prevWishes.filter(w => w.uuid !== selectedWishToWithdraw.uuid));
          setFilteredWishes(prevFiltered => prevFiltered.filter(w => w.uuid !== selectedWishToWithdraw.uuid));
        } else {
          console.error('Failed to withdraw from the group wish'); 
        }
      } catch (error) {
        console.error('Error withdrawing from group wish:', error); 
      }
    }
    setIsWithdrawDialogOpen(false);
    setSelectedWishToWithdraw(null);
  };

  const cancelWithdraw = () => {
    setIsWithdrawDialogOpen(false);
    setSelectedWishToWithdraw(null);
  };

  const styles = {
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    },
    modalContent: {
      backgroundColor: Color.colorLightestBeige,
      borderRadius: '12px',
      padding: '24px',
      width: '90%',
      maxWidth: '800px',
      maxHeight: '90vh',
      overflowY: 'auto',
      position: 'relative',
    },
    closeButton: {
      position: 'absolute',
      top: '16px',
      right: '16px',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
    },
    menuIcon: {
      width: '24px',
      height: '24px',
    },
    title: {
      fontSize: '28px',
      fontWeight: 'bold',
      color: Color.colorDarkBlue,
      marginBottom: '24px',
      textAlign: 'center',
    },
    searchAndFilterContainer: {
      marginBottom: '24px',
    },
    input: {
      width: '100%',
      padding: '12px',
      fontSize: '16px',
      backgroundColor: 'white',
      border: `1px solid ${Color.colorBlueGrey}`,
      borderRadius: '8px',
      marginBottom: '12px',
    },
    filterToggle: {
      background: 'none',
      border: 'none',
      color: Color.colorDarkBlue,
      fontSize: '16px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '12px',
    },
    filterContainer: {
      display: 'flex',
      gap: '12px',
      marginBottom: '12px',
    },
    filterInput: {
      flex: 1,
      padding: '12px',
      fontSize: '14px',
      backgroundColor: 'white',
      border: `1px solid ${Color.colorBlueGrey}`,
      borderRadius: '8px',
    },
    wishList: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
      gap: '20px',
    },
    wishCard: {
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    wishImageContainer: {
      height: '100px',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f0f0f0',
    },
    wishImage: {
      width: '100%',
      height: '100%',
      objectFit: 'contain', 
    },
    wishDetails: {
      padding: '16px',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    preferencesContainer: {
      marginTop: 'auto',
      padding: '12px',
      backgroundColor: Color.colorLightestGrey,
      borderRadius: '8px',
    },
    buttonContainer: {
      display: 'flex',
      gap: '8px',
      marginTop: '12px',
    },
    button: {
      flex: 1,
      padding: '10px',
      backgroundColor: Color.colorMintGreen,
      color: 'white',
      border: 'none',
      borderRadius: '6px',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 'bold',
      height: '40px',  
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    preferencesButton: {
      width: '100%',
      padding: '10px',
      backgroundColor: 'transparent',
      color: Color.colorDarkBlue,
      border: 'none',
      borderRadius: '6px',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '40px', // Match the height of other buttons
    },
    wishTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: Color.colorDarkBlue,
      marginBottom: '8px',
    },
    wishInfo: {
      fontSize: '14px',
      color: Color.colorDarkGrey,
      marginBottom: '4px',
    },
    preferencesContainer: {
      marginTop: '12px',
      padding: '12px',
      backgroundColor: Color.colorLightestGrey,
      borderRadius: '8px',
    },
    preferencesHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      marginBottom: '8px',
    },
    preferencesTitle: {
      fontWeight: 'bold',
      color: Color.colorDarkBlue,
    },
    preferenceItem: {
      fontSize: '14px',
      color: Color.colorDarkGrey,
      marginBottom: '4px',
    },
    buttonContainer: {
      display: 'flex',
      gap: '8px',
      marginTop: '12px',
    },
    button: {
      flex: 1,
      padding: '10px',
      backgroundColor: Color.colorMintGreen,
      color: 'white',
      border: 'none',
      borderRadius: '6px',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    noWishesText: {
      textAlign: 'center',
      fontSize: '18px',
      color: Color.colorDarkGrey,
      marginTop: '24px',
    },
    priceRange: {
      fontSize: '14px', 
      color: Color.colorVibrantRed,
      marginBottom: '8px',
    },
    withdrawButton: {
      backgroundColor: Color.colorVibrantRed,
      color: 'white',
      border: 'none',
      borderRadius: '6px',
      padding: '10px',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      marginTop: '8px',
    },
    confirmationDialog: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      zIndex: 1001,
    },
    confirmationTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    confirmationDescription: {
      marginBottom: '20px',
    },
    confirmationButtons: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '10px',
    },
    cancelButton: {
      backgroundColor: Color.colorMintGreen,
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      padding: '8px 16px',
      cursor: 'pointer',
      fontWeight: 'bold',
    },
    confirmButton: {
      backgroundColor: Color.colorBaseBeige,
      color: Color.colorWhite,
      border: 'none',
      borderRadius: '4px',
      padding: '8px 16px',
      cursor: 'pointer',
      fontWeight: 'bold',
    },
  };

  return (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <button onClick={onClose} style={styles.closeButton}>
          <img src={closeImage} alt="Close" style={styles.menuIcon} />
        </button>
        <h1 style={styles.title}>Joined Group Wishes</h1>
        
        <div style={styles.searchAndFilterContainer}>
          <input
            style={styles.input}
            placeholder="Search deals..."
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <button style={styles.filterToggle} onClick={() => setShowFilters(!showFilters)}>
            <FontAwesomeIcon icon={faFilter} style={{ marginRight: '8px' }} />
            {showFilters ? 'Hide Filters' : 'Show Filters'}
          </button>
          {showFilters && (
            <div style={styles.filterContainer}>
              <input
                style={styles.filterInput}
                placeholder="Filter by category"
                value={filterCategory}
                onChange={(e) => handleCategoryFilter(e.target.value)}
              />
              <input
                style={styles.filterInput}
                placeholder="Filter by product type"
                value={filterProductType}
                onChange={(e) => handleProductTypeFilter(e.target.value)}
              />
            </div>
          )}
        </div>

        {filteredWishes.length === 0 ? (
          <div style={styles.noWishesText}>No joined wishes</div>
        ) : (
          <div style={styles.wishList}>
            {filteredWishes.map((item) => (
              <div key={item.uuid} style={styles.wishCard}>
                {item.wish.image ? (
                  <img src={item.wish.image} alt={item.wish.product_name} style={styles.wishImage} />
                ) : (
                  <div style={{...styles.wishImage, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f0f0f0'}}>
                    <FontAwesomeIcon icon={faImage} size="2x" color="#ccc" />
                  </div>
                )}
                <div style={styles.wishDetails}>
                  <div style={styles.wishTitle}>{item.wish.product_name}</div>
                  <div style={styles.priceRange}>
                    Price Range: {getPriceRange(item.wish.price_ranges)}
                  </div>

                  <div style={styles.wishInfo}>Original Price: ${item.wish.list_price}</div>
                  <div style={styles.wishInfo}>Group Size: {item.totalJoined}/{item.wish.group_size_min}</div>
                  <div style={styles.wishInfo}>Progress: {(item.progress * 100).toFixed(2)}%</div>
                  {item.wish.user_preferences && item.wish.user_preferences.length > 0 && (
                    <div style={styles.preferencesContainer}>
                      <div style={styles.preferencesHeader} onClick={() => togglePreferences(item.uuid)}>
                        <span style={styles.preferencesTitle}>My Preferences</span>
                        <FontAwesomeIcon icon={showPreferences[item.uuid] ? faChevronUp : faChevronDown} />
                      </div>
                      {showPreferences[item.uuid] && (
                        <div>
                          {item.wish.user_preferences.map((pref, index) => (
                            <div key={index} style={styles.preferenceItem}>
                              • {pref.preference_name}: {pref.preferenceValue}
                              {pref.comments && ` (${pref.comments})`}
                            </div>
                          ))}
                          <button onClick={() => handleEditPreferences(item)} style={{...styles.button, marginTop: '8px'}}>
                            <FontAwesomeIcon icon={faPencilAlt} style={{ marginRight: '4px' }} /> Edit
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  <div style={styles.buttonContainer}>
                    <button onClick={() => handleViewProgress(item)} style={styles.button}>
                      View Progress
                    </button>
                    <button onClick={() => handleReferAFriend(item)} style={styles.button}>
                      Refer a friend
                    </button>
                  </div>
                  <button onClick={() => handleWithdraw(item)} style={styles.withdrawButton}>
                    <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '4px' }} /> Withdraw
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {isWithdrawDialogOpen && (
      <div style={styles.confirmationDialog}>
        <div style={styles.confirmationTitle}>Confirm Withdrawal</div>
        <div style={styles.confirmationDescription}>
          Are you sure you want to withdraw? If you wish to join again, you may be waitlisted.
        </div>
        <div style={styles.confirmationButtons}>
          <button onClick={cancelWithdraw} style={styles.cancelButton}>Cancel</button>
          <button onClick={confirmWithdraw} style={styles.confirmButton}>Withdraw</button>
        </div>
      </div>
    )}
    </div>   
  );
};

export default JoinedGroupWishes;
import React from 'react';
import * as style from "../../../GlobalStyles";

const ApprovalModalStyles = `
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: ${style.Color.colorLightBeige};
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid none;
}

.modal-title {
  font-size: 24px;
  color: ${style.Color.colorRoyalBlueText};
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: ${style.Color.colorDarkGrey};
}

.approval-form {
  color: ${style.Color.colorDarkGrey};
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: ${style.Color.colorRoyalBlueText};
}

.form-group select,
.form-group input[type="number"] {
  width: 90%;
  margin-left: 5%;
  padding: 10px;
  border: 1px solid none;
  border-radius: 8px;
  font-size: 16px;
  color: ${style.Color.colorDarkGrey};
}

.approve-button {
  background-color: ${style.Color.colorPurple};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 70%;
  margin-left: 15%;
  transition: background-color 0.3s ease;
}

.approve-button:hover {
  background-color: ${style.Color.colorDarkBlue};
}

.error {
  color: ${style.Color.colorRed};
  font-size: 14px;
  margin-top: 5px;
}
`;

export default ApprovalModalStyles;
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import * as style from "../../../GlobalStyles";

const RatingSlider = ({ onRate, onClose }) => {
  const [thumbsDownRating, setThumbsDownRating] = useState(0);
  const [starRating, setStarRating] = useState(0);
  const [thumbsDownAnimation, setThumbsDownAnimation] = useState(0);
  const [starAnimation, setStarAnimation] = useState(0);

  const handleThumbsDown = useCallback((value) => {
    setThumbsDownRating(value);
    setStarRating(0);
    setThumbsDownAnimation(value * 20);
    setStarAnimation(0);
  }, []);

  const handleStarRating = useCallback((value) => {
    setStarRating(value);
    setThumbsDownRating(0);
    setStarAnimation(value * 20);
    setThumbsDownAnimation(0);
  }, []);

  useEffect(() => {
    let timer;
    if (thumbsDownRating > 0 || starRating > 0) {
      timer = setTimeout(() => {
        onRate(thumbsDownRating > 0 ? -thumbsDownRating : starRating);
        onClose();
      }, 1500);
    }
    return () => clearTimeout(timer);
  }, [thumbsDownRating, starRating, onRate, onClose]);

  const ratingValues = useMemo(() => [1, 2, 3, 4, 5], []);

  const renderRatingButtons = useCallback((type) => {
    const isThumbsDown = type === 'thumbs';
    const rating = isThumbsDown ? thumbsDownRating : starRating;
    const handleRating = isThumbsDown ? handleThumbsDown : handleStarRating;
    const icon = isThumbsDown ? faThumbsDown : faStar;
    const activeColor = isThumbsDown ? '#FF6347' : '#FFD700';

    return ratingValues.map((value) => (
      <button key={`${type}-${value}`} className="rate-button" onClick={() => handleRating(value)}>
        <FontAwesomeIcon icon={icon} color={rating >= value ? activeColor : '#D3D3D3'} />
      </button>
    ));
  }, [ratingValues, thumbsDownRating, starRating, handleThumbsDown, handleStarRating]);

  return (
    <div className="rating-slider-overlay">
      <div className="rating-slider-container">
        <button className="close-button" onClick={onClose}> 
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className="rating-title">Rate this Group Wish</h2>
        <div className="sliders-wrapper">
          
          <div className="slider-container">
            <div className="slider star-slider" style={{ width: `${starAnimation}%` }} />
            {renderRatingButtons('star')}
          </div>
        </div>
        <p className="instruction">
          {(thumbsDownRating > 0 || starRating > 0) ? 'Rating will be submitted shortly...' : 'Click to rate'}
        </p> 
      </div>
    </div>
  );
};
 

const styles = `
  .rating-slider-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .rating-slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${style.Color.colorLightBeige};
    padding: 30px;
    border-radius: 12px;
    position: relative;
    max-width: 90%;
    width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: ${style.Color.colorDarkGrey};
  }
  .rating-title {
    font-size: 24px;
    color: ${style.Color.colorRoyalBlueText};
    margin-bottom: 20px;
  }
  .sliders-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .slider-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    background-color: ${style.Color.colorLightestBeige};
    border-radius: 25px;
    overflow: hidden;
    position: relative;
  }
  .slider {
    position: absolute;
    height: 100%;
    transition: width 0.3s ease-in-out;
  }
  .thumbs-down-slider {
    background-color: rgba(255, 99, 71, 0.3);
  }
  .star-slider {
    background-color: rgba(255, 215, 0, 0.3);
  }
  .rate-button {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1;
    transition: transform 0.2s ease;
  }
  .rate-button:hover {
    transform: scale(1.1);
  }
  .instruction {
    margin-top: 20px;
    font-size: 16px;
    color: ${style.Color.colorDarkGrey};
  }
`;

// Add styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default RatingSlider;
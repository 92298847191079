import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom"; 
import { useAuth } from '../../Common/InitialGears/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faChartLine, faPlusCircle, faTachometerAlt, faSearch, faCheck, faPlus, faArrowLeft, faBars, faTimes, faHome, faUser, faCog, faSignOutAlt, faEnvelope, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Color } from "../../../GlobalStyles";
import CategoryItem from "../Wishes/CategoryItem";
import { TopMenuBar } from './TopMenuBar';
import GroupWishLists from "../GroupWishes/GroupWishLists"; 
import { getAuth, signOut } from "firebase/auth";
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";

library.add(faStar, faChartLine, faPlusCircle, faTachometerAlt, faSearch, faCheck, faPlus, faArrowLeft, faBars, faTimes, faHome, faUser, faCog, faSignOutAlt, faEnvelope, faUserPlus);

const WishesDashboardPage = () => {
  const navigate = useNavigate();
  const { queryLoginParam, idTokenLoggedIn,logOutRequested, firstName } = useAuth();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [wishlistData, setWishlistData] = useState([]);
  const [trendsData, setTrendsData] = useState([]);
  const [existingWishes, setExistingWishes] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [showStarFor, setShowStarFor] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [groupWishes, setGroupWishes] = useState({
    userGroupWishes: [],
    connectionGroupWishes: [],
    latestGroupWishes: []
  });
  const [isGroupWishesLoading, setIsGroupWishesLoading] = useState(true);

  const loadGroupWishes = useCallback(async () => {
    setIsGroupWishesLoading(true); 
    try { 
      const groupWishUrl = buildUrl('groupWishes', queryLoginParam); 
      const response = await getApi(groupWishUrl); 
       
      if (response && response.data) {
        const { user_group_wishes, connection_group_wishes, additional_group_wishes } = response.data;
        
        setGroupWishes({
          userGroupWishes: user_group_wishes || [],
          connectionGroupWishes: connection_group_wishes || [],
          latestGroupWishes: additional_group_wishes || []
        });
      } else { 
        setErrorMessage('Failed to load group wishes. Please try again later.');
      }
    } catch (error) { 
      setErrorMessage('Failed to load group wishes. Please try again later.');
    } finally {
      setIsGroupWishesLoading(false);
    }
  }, [queryLoginParam]);

  const fetchWishListData = useCallback(async () => { 
    const wishURL = buildUrl('getMyWishes', `${queryLoginParam}&limit=5`);
    try {
      const response = await getApi(wishURL); 
      const data=response.data;
      if (data && data.wish_lists) {
        setWishlistData(data.wish_lists);
        // Set existing wishes from the categories array in the response
        if (data.categories && Array.isArray(data.categories)) {
          setExistingWishes(data.categories);
        }
      } else {
        setWishlistData([]);
        setExistingWishes([]);
      }
    } catch (error) { 
      setErrorMessage('Oops, please re-try');
      setExistingWishes([]);
    }
  }, [queryLoginParam, getApi]);

  const fetchWishTrends = useCallback(async () => { 
    const wishTrendsURL = buildUrl('getWishTrends', `${queryLoginParam}&limit=3`);
    try {
      const data = await getApi(wishTrendsURL); 
      if (data) {
        setTrendsData(trendsData.map(item => ({ name: item.name, count: item.count })));
      }
    } catch (error) {
     // console.error('Error fetching wish trends:', error);
    }
  }, [queryLoginParam, getApi]);

  const fetchCategories = useCallback(async () => { 
    try {
      const categoriesUrl = buildUrl('getCategories', queryLoginParam);  
      const response = await getApi(categoriesUrl);  

      if (response.ok && Array.isArray(response.data)) {
      const newCategoriesList = response.data.map((category) => ({
        image: category.image_url.startsWith('http') 
          ? category.image_url 
          : `${process.env.REACT_APP_API_URL}${category.image_url}`,
        title: category.name,
      }));
       
      setCategoriesList(newCategoriesList);
      setFilteredCategories(newCategoriesList);
      setCategories(response.data.map((category) => category.name)); 
      } else {  
        setCategoriesList([]);
        setFilteredCategories([]);
        setCategories([]);
      }
    } catch (error) {  
      setCategoriesList([]);
      setFilteredCategories([]);
      setCategories([]);
    } finally {
      setIsLoading(false); 
    }
  }, [queryLoginParam, getApi]);

  const addtoWishList = async (category) => {
    setIsGroupWishesLoading(true);
    try { 
      const wishURL = buildUrl('addToMyWish', queryLoginParam);
      const requestBody = {
        category: category,
        manufacturer_name: '',
        seller_name: '',
        brand_name: '',
        product_name: '',
        wait_period: '',
        personal_use_or_professional: 'personal',
        additional_comments: '',
      };
      await postApi(wishURL, requestBody);
      await loadGroupWishes();
    } catch (error) { 
      setErrorMessage('Failed to add wish. Please try again.');
    } finally {
      setIsGroupWishesLoading(false);
    }
  };

  const getMyWishedCategories = async () => {
    setIsGroupWishesLoading(true);
    try { 
      const wishURL = buildUrl('getMyWishedCategories', queryLoginParam);      
      await getApi(wishURL, queryLoginParam);
       
    } catch (error) { 
      setErrorMessage('Failed to add wish. Please try again.');
    } finally {
      setIsGroupWishesLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!logOutRequested) {
        try { 
          await loadGroupWishes();
          const results = await Promise.allSettled([
            fetchWishListData(),
            fetchWishTrends(),
            fetchCategories(),
            getMyWishedCategories(),
          ]);                    
  
          const failedPromises = results.filter(result => result.status === 'rejected');
          if (failedPromises.length > 0) {
            setErrorMessage("Some data failed to load. Please refresh the page.");
          }
        } catch (error) {  
          setErrorMessage("An error occurred while loading data. Please try again.");
        } finally {
          setIsLoading(false); 
        }
      }
    };
  
    fetchData();
  }, [logOutRequested, loadGroupWishes,fetchWishListData, fetchWishTrends, fetchCategories]);

  const handleAddWish = async (title) => {
    setShowStarFor(title);
    try {
      await addtoWishList(title);
      setExistingWishes(prev => [...prev, title]);
    } catch (error) { 
      setErrorMessage('Failed to add wish. Please try again.');
    }
    setTimeout(() => setShowStarFor(null), 10000);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filteredData = categoriesList.filter(category =>
      category.title.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCategories(filteredData);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleLogout = useCallback(async () => { 
    try {
      localStorage.clear();
      sessionStorage.clear();
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      const auth = getAuth();
      await signOut(auth); 
      navigate('/');
    } catch (error) { 
      setErrorMessage('Failed to logout. Please try again.');
    }
  }, [navigate]);

  const handleRefreshData = useCallback(async () => {
    try {
      setIsGroupWishesLoading(true);
      await loadGroupWishes();  
    } catch (error) { 
      setErrorMessage('Failed to refresh group wishes. Please try again.');
    } finally {
      setIsGroupWishesLoading(false);
    }
  }, [loadGroupWishes]);

  const Drawer = ({ isOpen, onClose }) => {
    const drawerItems = [
      { icon: faHome, text: 'Dashboard', action: () => navigate('/dashboard') },
      { icon: faUserPlus, text: 'Invites', action: () => navigate('/invites') },
      { icon: faUser, text: 'Profile', action: () => navigate('/profiles') },
      { icon: faStar, text: 'Rewards', action: () => navigate('/creditDetails') },
      { icon: faCog, text: 'Settings', action: () => navigate('/settings') },
      { icon: faSignOutAlt, text: 'Logout', action: handleLogout },
    ];

    return (
      <div style={{...styles.drawer,right: isOpen ? '0' : '-250px',}}>
        <button style={styles.drawerCloseButton} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        {drawerItems.map((item, index) => (
          <div key={index} style={styles.drawerItem} onClick={item.action}>
            <FontAwesomeIcon icon={item.icon} style={styles.drawerIcon} />
            <span>{item.text}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div style={styles.mainContainer}>
      <TopMenuBar showUserName={true} userName={firstName} showNotifications={true}/>
      <div style={styles.container}>
        <div style={styles.leftSection}>
          <h2 style={styles.leftSectionTitle}>Wish Preferences</h2>
          {isLoading ? (
            <div style={styles.loadingContainer}>
              <div style={styles.spinner}></div>
            </div>
          ) : (
            <>
              <div style={styles.searchBox}>
                <input type="text" placeholder="Search" value={searchQuery}  onChange={(e) => handleSearch(e.target.value)} style={styles.searchInput}/>
                <button style={styles.searchButton}>
                  <FontAwesomeIcon icon="search" color={Color.colorBaseBeige}/>
                </button>
              </div>
              <div style={styles.categoriesContainer}>
                {filteredCategories.map((item, index) => (
                  <CategoryItem key={index} item={item} onAddWish={handleAddWish} existingWishes={existingWishes} onWishAdded={handleAddWish} />
                ))}
                <div style={styles.bottomPadding} />
              </div>
            </>
          )}
        </div>
        <div style={styles.rightSection}>
          <div style={styles.topRightMenu}>
            <button style={styles.menuButton} onClick={toggleDrawer}>
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
          <Drawer isOpen={isDrawerOpen} onClose={toggleDrawer} />
          <GroupWishLists groupWishes={groupWishes} isLoading={isGroupWishesLoading} onRefreshData={handleRefreshData}/>
        </div>
      </div>
    </div>
  );
};
 
  const styles = {
     mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '200vh',
    },
    container: {
      display: 'flex', 
      backgroundColor: Color.colorWhite,
    },  
    leftSection: {
      width: '20%', 
      height: 'calc(100vh - 20px)', 
      padding: '30px 20px',
      background: Color.colorLightBeige,
      //background: 'rgba(240, 239, 231, 0.85)',
      borderRadius: '10px',
      margin: '10px', 
      boxShadow: '2px 0 10px rgba(0,0,0,0.1)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflowY: 'hidden',
      position: 'sticky',
      top: '10px',
      minWidth: '250px',
    },
    leftSectionTitle: {
      fontSize: '24px',
      fontWeight: 'bold',
      color: Color.colorRoyalBlueText,
      marginBottom: '30px',
      textAlign: 'center',
    },
    rightSection: {
      width: '80%', 
      padding: '0px 0px',
      paddingLeft: '0px',
      overflowY: 'auto',
    },
    topRightMenu: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        zIndex: 1000,
      },
      menuButton: {
        background: 'none',
        border: 'none',
        color: Color.colorBaseBeige,
        fontSize: '24px',
        cursor: 'pointer',
      },
      drawer: {
        position: 'fixed',
        top: 0,
        width: '250px',
        height: '100%',
        backgroundColor: Color.colorDarkBlueBlack,
        transition: 'right 0.3s ease',
        zIndex: 1001,
        boxShadow: '-2px 0 5px rgba(0,0,0,0.5)',
        display: 'flex',
        flexDirection: 'column',
        padding: '60px 0 20px',
      },
      drawerCloseButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'none',
        border: 'none',
        color: Color.colorOffWhite,
        fontSize: '24px',
        cursor: 'pointer',
      },
      drawerItem: {
        padding: '15px 20px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: Color.colorOffWhite,
        transition: 'background-color 0.3s',
      },
      drawerIcon: {
        marginRight: '15px',
        width: '20px',
      },
    searchBox: {
      display: 'flex',
      marginBottom: '20px',
      width: '100%',
      maxWidth: '100%', 
      position: 'relative',  
    },
    searchInput: {
      flex: 1,
      padding: '10px',
      fontSize: '16px',
      border: '1px solid #ddd',
      borderRadius: '4px 0 0 4px',
      width: '100%',  
      minWidth: 0,
    },

    bottomPadding: { 
    paddingTop: "20%", 
    paddingBottom: "20%", 
    },
    searchButton: {
      padding: '3% 8%',
      backgroundColor: Color.colorCoolBeige,
      color: Color.colorBaseBeige,
      border: 'none',
      borderRadius: '0 4px 4px 0',
      cursor: 'pointer',
    },
    categoriesContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
      gap: '20px',
      width: '100%',
      overflowY: 'auto', // This enables scrolling
      maxHeight: 'calc(100vh - 200px)', // Adjust based on your layout
      paddingRight: '10px', 
    },
    categoryItem: {
      backgroundColor: Color.colorWhite,
      borderRadius: '8px',
      padding: '10px',
      textAlign: 'center',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      transition: 'transform 0.2s',
      cursor: 'pointer',
      height: '175px',
      ':hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 6px 12px rgba(0,0,0,0.15)',
      },
    },
    categoryImage: {
      width: '100px',
      height: '100px',
      objectFit: 'cover',
      borderRadius: '10%',
      marginBottom: '1px',
    },
    categoryTitle: {
      fontSize: '16px',
      fontWeight: 'bold',
      marginBottom: '10px',
      color: Color.colorRoyalBlueText,
    },
    checkIcon: {
      color: Color.colorBaseBeige,
      fontSize: '20px',
    },
    starIcon: {
      color: 'gold',
      fontSize: '20px',
    },
    addWishButton: {
      backgroundColor: Color.colorBaseBeige,
      color: 'white',
      border: 'none',
      borderRadius: '50%',
      width: '30px',
      height: '30px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    spinner: {
      border: '4px solid #f3f3f3',
      borderTop: '4px solid #3498db',
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      animation: 'spin 1s linear infinite',
    },
  };
  
  export default WishesDashboardPage;
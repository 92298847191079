import React, { useState } from 'react';
import styled from 'styled-components';
import { getApi, postApi, buildUrl } from "../Common/BackendCalls/apiUtils";
import { Color } from '../../GlobalStyles';
import { useAuth } from '../Common/InitialGears/AuthContext'; 
import Config from "../../config"; 

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  width: 90%;
  max-width: 50%;
  background: #ffffff;
  border-radius: 20px;
  padding: 40px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(to right, #C77B58, #B76E79, #C77B58);
  }
`;
const TitleContainer = styled.div`
  padding: 10px;
  border-radius: 12px;
  margin-top: 10%; 
  width: 100%;
  background: linear-gradient(145deg, #fff);
  box-shadow: 
    5px 5px 10px rgba(0, 0, 0, 0.2),
    -5px -5px 10px rgba(255, 255, 255, 0.05);
`;
const FormContainer = styled.div`
  padding: 20px;
  border-radius: 12px;
  margin-top: 5%; 
  width: 100%;
  background: linear-gradient(145deg, #fff );
  box-shadow: 
    5px 5px 10px rgba(0, 0, 0, 0.2),
    -5px -5px 10px rgba(255, 255, 255, 0.05);
`;
const Title = styled.h2`
font-size: 24px;
background: linear-gradient(to right, #C77B58, #B76E79);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-weight: 600;
margin-bottom: 8px;
text-align: center;
`;

const Subtitle = styled.p`
  font-size: 14px;
  color: #999696;
  margin-bottom: 32px;
  text-align: center;
  line-height: 1.5;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 16px;
  border: 1.5px solid #E5E5E5;
  border-radius: 10px;
  font-size: 16px;
  color: #1A1A1A;
  background: #FFFFFF;
  transition: all 0.2s ease;

  &:hover {
    border-color: #C77B58;
  }

  &:focus {
    outline: none;
    border-color: #B76E79;
    box-shadow: 0 0 0 4px rgba(203, 109, 81, 0.1);
  }

  &::placeholder {
    color: rgba(199, 123, 88, 0.6);
  }
`;

const OTPContainer = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  margin: 20px 0;
`;

const OTPInput = styled.input`
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  border: 1.5px solid #E5E5E5;
  border-radius: 10px;
  background: #FFFFFF;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #B76E79;
    box-shadow: 0 0 0 4px rgba(203, 109, 81, 0.1);
  }
`;

const Button = styled.button`
  padding: 14px 24px;
  background: linear-gradient(to right, #C77B58, #B76E79);
  color: white;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(203, 109, 81, 0.2);
    background: linear-gradient(to right, #B76E79, #C77B58);
  }

  &:active {
    transform: translateY(0);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 32px;
  height: 32px;
  border: none;
  background: transparent;
  color: #C77B58;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    color: #B76E79;
  }
`;

const ErrorMessage = styled.div`
  color: #FF4D4D;
  font-size: 14px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 6px;

  &::before {
    content: '⚠️';
    font-size: 12px;
  }
`;

const SuccessMessage = styled.div`
  text-align: center;
  color: #1A1A1A;
  font-size: 16px;
  line-height: 1.6;
  margin: 32px 0;

  strong {
    color: #C77B58;
    font-weight: 600;
  }
`;

const LoadingButton = styled(Button)`
  opacity: ${props => props.disabled ? 0.7 : 1};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;

const DisabledInput = styled(OTPInput)`
  opacity: ${props => props.disabled ? 0.7 : 1};
  cursor: ${props => props.disabled ? 'not-allowed' : 'text'};
`;

const ReferralRequestModal = ({ onClose }) => {
  const { queryLoginParam, idTokenLoggedIn ,isBacksAppManager } = useAuth();  
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  });
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [verificationError, setVerificationError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };


  const requestVerificationCode = async (phone) => { 
    const requestRefURL = buildUrl(`verificationCode`, queryLoginParam);
      const requestBody = {
        phone: phone,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email
      };
      
    try {
      const response = await postApi(requestRefURL, requestBody);
      if (!response.ok) {  
        throw new Error(response.error || 'Failed to send verification code');
      } 
      return response;
    } catch (error) { 
      throw error;
    }
  };


  const handleOTPChange = (index, value) => {
    if (value.length > 1) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Auto-focus next input
    if (value && index < 5) {
      const nextInput = document.querySelector(`input[name=otp-${index + 1}]`);
      if (nextInput) nextInput.focus();
    }
  };

  const handleSubmitDetails = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    
    try {
      // Basic validation
      if (!formData.firstName || !formData.lastName || !formData.phone || !formData.email) {
        setError('Please fill in all fields');
        return;
      }
  
      // Email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        setError('Please enter a valid email address');
        return;
      }
  
      // Phone validation
      const phoneRegex = /^\d{10}$/;
      if (!phoneRegex.test(formData.phone.replace(/\D/g, ''))) {
        setError('Please enter a valid 10-digit phone number');
        return;
      }
  
      await requestVerificationCode(formData.phone);
      setStep(2);
    } catch (error) {
      setError(error.message || 'Failed to send verification code');
    } finally {
      setIsLoading(false);
    }
  };

  const verifyOTP = async (phone, otpValue) => {
    const queryParams = new URLSearchParams({
      verifyVerificationCode: 'true',
      phone: phone,
      otp: otpValue,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email
    }).toString();
    
    const verifyEndpoint = `${Config.apiUrl}verifyVerificationCode?${queryParams}`;
    
    try {
      const response = await fetch(verifyEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: null,
        mode: 'cors',
        credentials: 'omit'
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.error || 'Verification failed');
      }
  
      return data;
    } catch (error) {
      throw error;
    }
  };


  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    setError('');
    setVerificationError('');
    setIsLoading(true);

    const otpValue = otp.join('');
    if (otpValue.length !== 6) {
      setError('Please enter a valid 6-digit OTP');
      setIsLoading(false);
      return;
    }

    try {
      const response = await verifyOTP(formData.phone, otpValue);
      
      if (response.status === 'SUCCESS') {
        setStep(3); // Success step
      } else if (response.status === 'INVALID_OTP') {
        setVerificationError('Invalid verification code. Please try again.');
      } else if (response.status === 'EXPIRED_OTP') {
        setVerificationError('Verification code has expired. Please request a new one.');
        // Optionally, you could add a button to request a new code
      } else {
        setVerificationError('Verification failed. Please try again later.');
      }
    } catch (error) {
      setVerificationError('Failed to verify code. Please try again.');
      console.error('OTP verification error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <TitleContainer>
          <Title>Request Referral Code</Title>
          <Subtitle>
            {step === 1 && "Fill in your details to request a referral code"}
            {step === 2 && "Enter the verification code sent to your phone"}
            {step === 3 && "Thank you for your interest!"}
          </Subtitle>
        </TitleContainer>

        <FormContainer>
          {error && <ErrorMessage>{error}</ErrorMessage>}

          {step === 1 && (
            <Form onSubmit={handleSubmitDetails}>
              <Input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                placeholder="First Name"
                disabled={isLoading}
              />
              <Input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                placeholder="Last Name"
                disabled={isLoading}
              />
              <Input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Phone Number"
                disabled={isLoading}
              />
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email Address"
                disabled={isLoading}
              />
             <LoadingButton type="submit" disabled={isLoading}>
              {isLoading ? 'Sending...' : 'Request Verification Code'}
            </LoadingButton>
            </Form>
          )}

          {step === 2 && (
            <Form onSubmit={handleVerifyOTP}>
              <OTPContainer>
                {otp.map((digit, index) => (
                  <DisabledInput
                    key={index}
                    type="text"
                    name={`otp-${index}`}
                    value={digit}
                    onChange={(e) => handleOTPChange(index, e.target.value)}
                    maxLength={1}
                    disabled={isLoading}
                  />
                ))}
              </OTPContainer>
              {verificationError && <ErrorMessage>{verificationError}</ErrorMessage>}
              <LoadingButton type="submit" disabled={isLoading}>
                {isLoading ? 'Verifying...' : 'Verify Code'}
              </LoadingButton>
            </Form>
          )}

        {step === 3 && (
          <SuccessMessage>
            Thank you for verifying your contact information! Your referral request has been received and is being processed. We'll notify you shortly with your referral code via email and SMS.
          </SuccessMessage>
        )}
        </FormContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ReferralRequestModal;
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import * as globalStyles from '../../../GlobalStyles';

const ReminderSettingScreen = ({
  onSaveReminder,
  onCancel,
  initialReminderTime = new Date(),
  initialTitle = '',
  notificationId,
}) => {
  const [title] = useState(initialTitle);
  const [content] = useState('');
  const [reminderTime, setReminderTime] = useState(initialReminderTime);

  const handleSaveReminder = () => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const message = {
      title,
      content,
      reminderTime: reminderTime.toISOString(),
      localTime: reminderTime,
      userTimezone,
      notificationId,
    };
    onSaveReminder(message);
  };

  return (
    <div style={styles.modalView}>
      <div style={globalStyles.sameRowContainer}>
        <h2 style={globalStyles.modalText}>Reminder for</h2>
        <button style={styles.closeButton} onClick={onCancel}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <div>
        <p style={globalStyles.modalText}>{title}</p>
      </div>
      
      <DatePicker
        selected={reminderTime}
        onChange={(date) => setReminderTime(date)}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={15}
        timeCaption="time"
        dateFormat="MMMM d, yyyy h:mm aa"
        customInput={<input style={styles.datePickerInput} />}
      />
      
      <button style={{...globalStyles.button, ...styles.saveButton}} onClick={handleSaveReminder}>
        Save Reminder
      </button>
    </div>
  );
};

const styles = {
  modalView: {
    margin: '10px',
    width: '85%',
    maxWidth: '500px',
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: '20px',
    alignItems: 'center',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
  },
  closeButton: {
    background: 'none',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
    color: globalStyles.Color.colorDarkGrey,
    alignSelf: 'flex-end',
  },
  datePickerInput: {
    padding: '10px',
    fontSize: '16px',
    border: `1px solid ${globalStyles.Color.colorMidBeige}`,
    borderRadius: '5px',
    width: '100%',
    marginBottom: '20px',
  },
  saveButton: {
    marginTop: '20px',
    width: '100%',
  },
};

export default ReminderSettingScreen;
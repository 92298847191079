import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faSearch, faCheckCircle, faQuestionCircle, faClock, faShoppingCart, faStar, faCloudUploadAlt, faCheckDouble ,faTimes} from '@fortawesome/free-solid-svg-icons';
import * as styles from '../../../GlobalStyles'; 
import { useAuth } from '../../../components/Common/InitialGears/AuthContext';  
import {TopMenuBar} from '../DashbordComponents/TopMenuBar';
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";

const MyWishDetails = () => { 
  const navigate = useNavigate();
  const { logout, queryLoginParam, idTokenLoggedIn } = useAuth();
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [wishlistData, setWishlistData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredWishes, setFilteredWishes] = useState([]);
  const [isRemoveReasonModalVisible, setIsRemoveReasonModalVisible] = useState(false);
  const [selectedWish, setSelectedWish] = useState(null);

  useEffect(() => {
    fetchWishListData();
  }, []);

  const fetchWishListData = async () => {
    setErrorMessage("");
    setSuccessMessage("");
    if (queryLoginParam.length === 0) {
      logout();
      return navigate('/landing');
    }
 
  let wishURL = buildUrl('getMyWishesDetails',queryLoginParam);     
    
  try {      
      const wishResponse = await getApi(wishURL);
      let responseData = wishResponse.data;
           
      if (Array.isArray(responseData.wish_lists)) {
        setWishlistData(responseData.wish_lists);
        setFilteredWishes(responseData.wish_lists);
      }
    } catch (error) {
      setErrorMessage("Something went wrong. Please try later");
    }
  };

  const handleSearch = (text) => {
    setErrorMessage(null);
    setSuccessMessage(null);
    setSearchQuery(text);
    const filtered = wishlistData.filter((wish) => {
      const searchableFields = ['category', 'product', 'brand', 'seller', 'manufacturer', 'additional_comments'];
      return searchableFields.some(field => 
        (wish[field] || '').toLowerCase().includes(text.toLowerCase())
      );
    });
    setFilteredWishes(filtered);
  };

  const renderWishStatus = (status) => {
    const statusConfig = {
      1: { icon: faCheckCircle, color: styles.Color.colorGreen },
      2: { text: `Trend: ${status}`, style: styles1.trendText },
      3: { icon: faQuestionCircle, color: styles.Color.colorLightestGrey },
      4: { icon: faClock, color: styles.Color.colorYellow },
      5: { icon: faShoppingCart, color: styles.Color.colorOrange },
      6: { icon: faStar, color: styles.Color.colorGolden },
      7: { icon: faCloudUploadAlt, color: styles.Color.colorMediumGrey },
      8: { icon: faCheckDouble, color: styles.Color.colorDarkGreen },
    };

    const config = statusConfig[status];
    if (!config) return null;

    if (config.icon) {
      return <FontAwesomeIcon icon={config.icon} style={{ color: config.color, fontSize: '1.2em' }} />;
    } else if (config.text) {
      return <span style={config.style}>{config.text}</span>;
    }
  };

  const handleRemoveWish = async (wishId, removeReason) => {
    setSuccessMessage("");
    setErrorMessage("");
    try {
      const removeURL = buildUrl('removeMyWish', queryLoginParam);
      const requestBody = {
        wishId: `${wishId}`,
        remove_reason: removeReason,
      };
  
      const data = await postApi(removeURL, requestBody); 
  
      setFilteredWishes((prevWishes) => prevWishes.filter((wish) => wish.uuid !== wishId));
      setSuccessMessage(data.message || "Your wish has been removed successfully");
    } catch (error) { 
      setErrorMessage(error.message || 'An error occurred while removing the wish. Please try again later.');
    } finally {
      setIsRemoveReasonModalVisible(false);
      setSelectedWish(null);
    }
  };

  return (
    <div style={styles.mainContainer}>
     <TopMenuBar showBackButton={true} onBackClick={() => navigate(-1)} title={"More about My Wishes"} showSearch={false} showAddress={false} /> 
    <div style={styles1.container}>
      
    <div style={styles1.content}>
        {successMessage && (
          <div style={styles1.successBanner}>
            <span>{successMessage}</span>
          </div>
        )}
        {errorMessage && (
          <div style={styles1.errorBanner}>
            <span>{errorMessage}</span>
          </div>
        )}

        <div style={styles1.searchContainer}>
          <FontAwesomeIcon icon={faSearch} style={styles1.searchIcon} />
          <input style={styles1.searchInput} placeholder="Search wishes..." value={searchQuery} onChange={(e) => handleSearch(e.target.value)}/>
        </div>

        {filteredWishes.length > 0 ? (
          <div style={styles1.wishGrid}>
            {filteredWishes.map((item, index) => (
              <div key={index} style={styles1.wishCard}>
                <div style={styles1.wishCardHeader}>
                  <span style={styles1.wishCardTitle}>{item.category}</span>
                  <div style={styles1.wishCardStatus}>
                    {renderWishStatus(item.status)}
                    <button onClick={() => { setSelectedWish(item); setIsRemoveReasonModalVisible(true);}} style={styles1.closeButton}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </button>
                     
                  </div>
                </div>
                <div style={styles1.wishCardBody}>
                  <p style={styles1.wishCardText}><strong>Product:</strong> {item.product}</p>
                  <p style={styles1.wishCardText}><strong>Brand:</strong> {item.brand}</p>
                  <p style={styles1.wishCardText}><strong>Seller:</strong> {item.seller}</p>
                  <p style={styles1.wishCardText}><strong>Manufacturer:</strong> {item.manufacturer}</p>
                  <p style={styles1.wishCardText}><strong>Added on:</strong> {item.added_date}</p>
                  {item.additional_comments && (
                    <p style={styles1.wishCardText}><strong>Comments:</strong> {item.additional_comments}</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p style={styles1.noResultsText}>No wishes found</p>
        )}
      </div>

      {isRemoveReasonModalVisible && (
        <RemoveReasonModal visible={isRemoveReasonModalVisible} onRequestClose={() => setIsRemoveReasonModalVisible(false)} handleRemoveWish={handleRemoveWish} wish={selectedWish}/>
      )}
    </div>
    </div>
  );
};

const RemoveReasonModal = ({ visible, onRequestClose, handleRemoveWish, wish }) => {
  const [selectedReason, setSelectedReason] = useState(null);
  
  const removeReasonOptions = [
    { label: 'No longer needed', value: 'NO_LONGER_NEEDED' },
    { label: 'Changed my mind', value: 'CHANGED_MY_MIND' },
    { label: 'Found a better option', value: 'FOUND_A_BETTER_OPTION' },
    { label: 'Other', value: 'Other' },
  ];

  const handleReasonSelect = (value) => {
    setSelectedReason(value);
  };
  
  const handleSubmit = () => {
    handleRemoveWish(wish.uuid, selectedReason);
  };
  
  if (!visible) return null;

  return (
    <div style={styles1.modalBackground}>
      <div style={styles1.modalContent}>
        <div style={styles1.modalHeader}>
          <h2 style={styles1.modalTitle}>Remove Wish</h2>
          <button onClick={onRequestClose} style={styles1.modalCloseButton}>
            <FontAwesomeIcon icon={faTimes} style={styles1.modalCloseIcon} />
          </button>
           
        </div>
        <div style={styles1.modalBody}>
          <p style={styles1.modalText}>Please share the reason for removing this wish:</p>
          {removeReasonOptions.map((option, index) => (
            <button key={index} style={{...styles1.reasonOption, ...(selectedReason === option.value ? styles1.selectedReason : {}), }} onClick={() => handleReasonSelect(option.value)}>
              {option.label}
            </button>
          ))}
          <button style={{...styles1.submitButton, ...(selectedReason ? {} : styles1.disabledButton), }} onClick={handleSubmit} disabled={!selectedReason}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const styles1 = {
  container: {
    backgroundColor: "#f0ece3",
    minHeight: '100vh',
    fontFamily: 'Arial, sans-serif',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    backgroundColor: styles.Color.colorLightBeige,
  },
  backButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    color: styles.Color.colorDarkGrey,
  },
  pageTitle: {
    fontSize: '24px',
    color: styles.Color.colorDarkGrey,
    margin: 0,
  },
  placeholder: {
    width: '50px',
  },
  content: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '20px',
  },
  successBanner: {
    backgroundColor: "#d4edda",
    color: "#155724",
    padding: '10px 20px',
    borderRadius: '4px',
    marginBottom: '20px',
  },
  errorBanner: {
    backgroundColor: "#f8d7da",
    color: "#721c24",
    padding: '10px 20px',
    borderRadius: '4px',
    marginBottom: '20px',
  },
  searchContainer: {
    position: 'relative',
    marginBottom: '20px',
  },
  searchIcon: {
    position: 'absolute',
    left: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    color: styles.Color.colorMediumGrey,
  },
  searchInput: {
    width: '100%',
    padding: '10px 10px 10px 40px',
    fontSize: '16px',
    border: `1px solid ${styles.Color.colorLightGrey}`,
    borderRadius: '4px',
  },
  wishGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '20px',
  },
  wishCard: {
    backgroundColor: styles.Color.colorLightestBeige,
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  wishCardHeader: {
    backgroundColor: styles.Color.colorBaseBeige,
    padding: '10px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wishCardTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: styles.Color.colorDarkGrey,
  },
  wishCardStatus: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  removeButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    color: styles.Color.colorMediumGrey,
    fontSize: '18px',
  },
  wishCardBody: {
    padding: '15px',
  },
  wishCardText: {
    margin: '5px 0',
    fontSize: '14px',
    color: styles.Color.colorDarkGrey,
  },
  noResultsText: {
    textAlign: 'center',
    color: styles.Color.colorMediumGrey,
    fontSize: '18px',
    marginTop: '40px',
  },
  modalBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: styles.Color.colorLightestBeige,
    borderRadius: '8px',
    width: '90%',
    maxWidth: '400px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px',
    borderBottom: `1px solid ${styles.Color.colorLightGrey}`,
  },
  modalTitle: {
    margin: 0,
    fontSize: '20px',
    color: styles.Color.colorDarkGrey,
  },
  modalCloseButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  modalCloseIcon: {
    width: '20px',
    height: '20px',
    color: styles.Color.colorDarkGrey,
  },
  modalBody: {
    padding: '20px',
  },
  modalText: {
    marginBottom: '15px',
    fontSize: '16px',
    color: styles.Color.colorDarkGrey,
  },
  reasonOption: {
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    background: 'none',
    border: `1px solid ${styles.Color.colorRoyalBlueText}`,
    borderRadius: '4px',
    textAlign: 'left',
    fontSize: '14px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    color: styles.Color.colorRoyalBlueText,
  },
  selectedReason: {
    backgroundColor: styles.Color.colorLightBeige,
    borderColor: styles.Color.colorBaseBeige,
  },
  closeButton: { 
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '20px',
    color: styles.Color.colorDarkGrey,
  },
  submitButton: {
    width: '100%',
    padding: '10px',
    backgroundColor: styles.Color.colorBaseBeige,
    color: styles.Color.colorLightestBeige,
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
  disabledButton: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
};

export default MyWishDetails;
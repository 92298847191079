import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faImage, faDollarSign, faChevronDown, faChevronUp, faUsers, faTimes, faCalculator } from '@fortawesome/free-solid-svg-icons';
import { PieChart, Pie, Cell,ResponsiveContainer, Sector } from 'recharts'; 
import {useAuth} from '../../../components/Common/InitialGears/AuthContext';    
import * as style from "../../../GlobalStyles";
import DownRateSlider from './DownRateSlider';
import AddressSection from '../../Common/AddressSection'; 
import { format, parseISO, formatDistanceToNow } from 'date-fns';
import RatingsDisplay from './RatingsDisplay';  
import {TopMenuBar} from '../DashbordComponents/TopMenuBar';
import TermsAndConditionsModal from './JoiningTermsAndConditions';
import JoinGroupWishModal from './JoinGroupWishModal';
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";

const GroupWishProgressScreen = () => { 
  const { queryLoginParam} = useAuth();
  const { userVerified } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { wish, progressData: initialProgressData, joined = true } = location.state || {};   
  const [isLoading, setIsLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showJoinModal, setShowJoinModal] = useState(false); 
  const [isJoined, setIsJoined] = useState(joined); 
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);  
  const [addresses, setAddresses] = useState([]);
  const [showAddressSection, setShowAddressSection] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false); 
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRange, setSelectedRange] = useState(null);
  const [showRating, setShowRating] = useState(false);
  const [progressData, setProgressData] = useState(initialProgressData);
  const [selectedWishForJoin, setSelectedWishForJoin] = useState(null); 
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const [buddyStatus, setBuddyStatus] = useState(progressData.group_wish_buddy_status || ''); 
  const [buttonsKey, setButtonsKey] = useState(0);
  const [formData, setFormData] = useState({
    to_zip: '',
    to_state: '',
    to_city: '',
    to_street: '',
    amount: '',
    product_tax_code: '',
  });

  const [modalData, setModalData] = useState({
    selectedRange: null,
    selectedQuantity: '',
    selectedAddress: null,
    calculatedCosts: null
  });

  let data = progressData;
  if (typeof progressData === 'string') {
    try {
      data = JSON.parse(progressData);
    } catch (error) {
      console.error("Error parsing progressData:", error);
    }
  }

  useEffect(() => {
    fetchAddresses();
  }, []);
 
  useEffect(() => {
    setButtonsKey(prevKey => prevKey + 1);
  }, [buddyStatus]);

  const fetchAddresses = useCallback(async () => {
    try {
      const addressesUrl = buildUrl('address', `${queryLoginParam}&type=completed`);
      const response = await getApi(addressesUrl);
      
      if (response.ok) {
        const data = response.data;
        const primaryAddress = data.primary_address ? [data.primary_address] : [];
        const otherAddresses = Array.isArray(data.other_addresses) ? data.other_addresses : [];

        setAddresses([...primaryAddress, ...otherAddresses]);
      } else { 
        setAddresses([]);
      }
    } catch (error) { 
      setAddresses([]);
    } finally {
      setIsLoading(false);
    }
  }, [queryLoginParam]);

  const getCalculatedSalesTax = useCallback(async (wishId, zipCode, to_state, quantity, priceRangeId) => {
    try {
      const salesTaxUrl = buildUrl('getCalculatedSalesTax', queryLoginParam);
      const requestBody = {
        group_wish_id: wishId, 
        price_range_id: priceRangeId,
        zipCode: zipCode,
        to_state: to_state,
        quantity: quantity,
      };
      const response = await postApi(salesTaxUrl, requestBody); 
      return response.tax_rate
    } catch (error) {
      console.error("Error fetching sales tax:", error); 
    }
  }, [queryLoginParam]);

  const handleRejoinGroup = useCallback(async () => {
    setIsLoading(true);
    try {
      const rejoinUrl = buildUrl('reJoinGroupWish', queryLoginParam);
      const requestBody = {
        grp_wish_id: wish.id
      };

      const response = await postApi(rejoinUrl, requestBody);

      if (response) {
        setIsJoined(true);
        setBuddyStatus('JOINED');
        setSuccessMessage('You have successfully rejoined the group!');
      } else {
        setErrorMessage('Failed to rejoin the group. Please try again.');
      }
    } catch (error) { 
      setErrorMessage('Failed to rejoin the group. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [wish.id, queryLoginParam]);

  const handleWithdrawFromGroup = useCallback(async () => {
    const confirmWithdraw = window.confirm(
      "Once you withdraw, you can only rejoin the waitlist and may or may not be added to the active order. Are you sure you want to withdraw?"
    );
  
    if (!confirmWithdraw) {
      return;  
    }
  
    setIsLoading(true);
    try {
      const withdrawUrl = buildUrl('withdrawGroupWish', queryLoginParam);
      const requestBody = {
        wish_id: wish.id
      };
  
      const response = await postApi(withdrawUrl, requestBody);
  
      if (response.ok) {
        setIsJoined(false);
        setBuddyStatus('WITHDRAWN');
        setSuccessMessage('You have successfully withdrawn from the group. If you wish to rejoin, you will be added to the waitlist.');         
      } else {
        setErrorMessage('Failed to withdraw from the group. Please try again.');
      }
    } catch (error) { 
      setErrorMessage('Failed to withdraw from the group. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [wish.id, queryLoginParam]);

  const handleAddressSave = useCallback(async (newAddress) => { 
    setIsLoading(true);
    const addressUrl = buildUrl('address', queryLoginParam);
    const requestBody = {
      address1: newAddress.address1,
      address2: newAddress.address2,
      city: newAddress.city,
      state: newAddress.state,
      country: newAddress.country,
      postal_code: newAddress.postal_code,
      primary_residence: false,
    };
     
    try {
      const response = await postApi(addressUrl, requestBody);

      if (response.ok) {
        setAddresses(prevAddresses => [...prevAddresses, response.data]);  
        setModalData(prevData => ({
          ...prevData,
          selectedAddress: response.data
        })); 
        setShowAddressSection(false);
        await fetchAddresses();
      } else {
        setErrorMessage('Failed to update address. Please try again.');
      }
    } catch (error) { 
      setErrorMessage('Failed to update address. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [queryLoginParam]);

  const rateGroupWish = useCallback(async (wishId, rating) => {
    setIsLoading(true);
    const grpWishURL = buildUrl('rateGroupwish', queryLoginParam);
    const requestBody = {
      grp_wish_id: wishId,
      is_upvote: rating >= 0,
      rating: rating
    };

    try {
      const response = await postApi(grpWishURL, requestBody);

      if (response.ok) {
        setProgressData(prevData => ({
          ...prevData,
          upvotes: response.data.upvotes || prevData.upvotes,
          downvotes: response.data.downvotes || prevData.downvotes,
          averageRating: response.data.averageRating || prevData.averageRating,
        }));
        setSuccessMessage('Rating submitted successfully!');
      } else {
        setErrorMessage('Failed to submit rating. Please try again.');
      }
    } catch (error) { 
      setErrorMessage('Failed to submit rating. Please try again.');
    } finally {
      setIsLoading(false);
      setShowRating(false);
    }
  }, [queryLoginParam]);
    
  const calculateCosts = async () => {
    setIsCalculating(true); 

    if (!wish) { 
      alert("Error: Group wish data is missing"); 
      setIsCalculating(false);
      return;
    }
    const currentModalData = modalData;
    let missingFields = [];
    if (!currentModalData) missingFields.push("quantity");
    if (!currentModalData) missingFields.push("address");

    if (missingFields.length > 0) {
      alert(`Please select ${missingFields.join(" and ")}`);
      setIsCalculating(false);
      return;
    }

    setIsCalculating(true); 

    const quantity = parseInt(currentModalData.selectedQuantity);
    const range = selectedRange;  
  
    if (!range) { 
      alert("No price range selected");
      setIsCalculating(false);
      return;
    }
  
    if (quantity < 0) { 
      alert(`Please enter a quantity between ${range.price_range_start} and ${range.price_range_end}`);
      setIsCalculating(false);
      return;
    } 
    try {
      const itemPrice = parseFloat(range.range_price);
      const importCost = (parseFloat(range.shipping_cost_for_range) || 0) * quantity;
      const subtotal = itemPrice * quantity;
       
      const zipCode = modalData.selectedAddress.postal_code;
      const to_state = modalData.selectedAddress.state;
      const salesTax = await getCalculatedSalesTax(wish.id, zipCode,  to_state, quantity, range.id); 
  
     let serviceCharges = 0;
     const backsAppCharges = range.backsApp_service_charges; 

      if (backsAppCharges !== null && backsAppCharges !== undefined) {
        const chargesValue = parseFloat(backsAppCharges);
        if (!isNaN(chargesValue)) {
          if (range.backsApp_service_charges_type === '%') { 
            serviceCharges = subtotal * (chargesValue / 100);
          } else { 
            serviceCharges = chargesValue * quantity;
          }
        }  
      }  
      let shippingCost = calculateShippingCost(quantity, modalData.selectedAddress); 
  
      if (isNaN(shippingCost)) {
        throw new Error("Invalid shipping cost calculated");
      }
      //shippingCost = shippingCost * ( quantity);
      const pre_total = subtotal + salesTax + shippingCost + importCost + serviceCharges;
      const paymentProcessingFee = pre_total * 0.05;
      const total = pre_total + paymentProcessingFee;
      const listPrice = parseFloat(wish.list_price);
      const totalListPrice = listPrice * quantity;
      const savings = totalListPrice - total;
      const savingsPercentage = (savings / totalListPrice) * 100;
  
      const formatNumber = (num) => (typeof num === 'number' && !isNaN(num)) ? num.toFixed(2) : '0.00';
  
      const calculatedCosts = {
        subtotal: formatNumber(subtotal),
        serviceCharges: formatNumber(serviceCharges),
        paymentProcessingFee: formatNumber(paymentProcessingFee),
        salesTax: formatNumber(salesTax),
        shippingCost: formatNumber(shippingCost),
        importCost: formatNumber(importCost),
        total: formatNumber(total),
        savings: formatNumber(savings),
        savingsPercentage: formatNumber(savingsPercentage)
      };
  
      setModalData(prevData => ({
        ...prevData,
        calculatedCosts: calculatedCosts
      }));
   
    } catch (error) { 
      alert("An error occurred while calculating costs. Please try again.");
    }finally {
      setIsCalculating(false);
    }
  
  };

  const handleAgreeTerms = async () => {
    if (selectedWishForJoin) {
      setShowJoinModal(true); 
    }
  };

  const handleJoinGroup = async (item) => { 
    if (!item || !item.id) { 
      setErrorMessage('Unable to join group. Invalid wish data.');
      return;
    }  
    setSelectedWishForJoin(item);
    setShowTermsModal(true);
  };

  const handleJoinSuccess = useCallback(() => {
    setIsJoined(true);
    setShowSuccessMessage(true);
    setShowJoinModal(false); 
    setTimeout(() => setShowSuccessMessage(false), 5000);
  }, []);
 
  const calculateShippingCost = (quantity, address) => {
    const baseShippingCost = 250;
    const costPerItem = 5;
    return Number(baseShippingCost + (quantity * costPerItem));
  };

  const safeProgressData = {
    totalJoined: data?.totalJoined != null ? Number(data.totalJoined) : 0,
    verifiedUsers: data?.verifiedUsers != null ? Number(data.verifiedUsers) : 0,
    unverifiedUsers: data?.unverifiedUsers != null ? Number(data.unverifiedUsers) : 0,
    upvotes: data?.upvotes != null ? Number(data.upvotes) : 0,
    downvotes: data?.downvotes != null ? Number(data.downvotes) : 0,
    progress: data?.progress != null ? Number(data.progress) : 0,
    averageRating: data?.averageRating != null ? Number(data.averageRating) : 0,
  };

  
  const pieChartData = [
    { name: 'Verified', value: safeProgressData.verifiedUsers },
    { name: 'Unverified', value: safeProgressData.unverifiedUsers },
  ].filter(item => item.value > 0);

  const renderDetailItem = (label, value, icon) => {
    if (!value) return null;
    return (
      <div className="detail-item">
        <div className="detail-icon-container">
          <FontAwesomeIcon icon={icon} size="lg" color={style.Color.colorPurple} />
        </div>
        <div className="detail-text-container">
          <div className="detail-label">{label}</div>
          <div className="detail-value">{value}</div>
        </div>
      </div>
    );
  };

  const renderDescription = (description) => {
    if (!description) return null;
    const maxLength = 150;
    const shouldShowMore = description.length > maxLength;

    return ( 
      <div className="description-container">
        <div className="description-content">
          {shouldShowMore && !showFullDescription
            ? (
              <>
                <p>{`${description.substring(0, maxLength)}...`}</p>
                <button className="show-more-button" onClick={() => setShowFullDescription(true)}>
                  <span>Show More</span>
                  <FontAwesomeIcon icon={faChevronDown} />
                </button>
              </>
            ) : (
              <>
                <p>{description}</p>
                {shouldShowMore && (
                  <button className="show-less-button" onClick={() => setShowFullDescription(false)}>
                    <span>Show Less</span>
                    <FontAwesomeIcon icon={faChevronUp} />
                  </button>
                )}
              </>
            )
          }
        </div>
      </div>
    );
  };

  const formatAddress = (address) => {
    if (!address) return 'No address selected';
    return `${address.address1}, ${address.city}, ${address.state} ${address.postal_code}`;
  };

  const CostItem = ({ label, value }) => (
    <div className="cost-item-container">
      <div className="cost-item-label">{label}:</div>
      <div className="cost-item-value">${value}</div>
    </div>
  );

  const resetModalData = () => {
    setModalData({
      selectedRange: null,
      selectedQuantity: '',
      selectedAddress: null,
      calculatedCosts: null
    });
  };
  
  const openCalculatorModal = (range) => {
    resetModalData();
    setSelectedRange(range);
    setModalVisible(true);
  };
  
  const handleModalClose = () => {
    setModalVisible(false);
    resetModalData();
  };

  const handleQuantityChange = (e) => {
    setModalData(prevData => ({
      ...prevData,
      selectedQuantity: e.target.value
    }));
  };

  const handleAddressChange = (e) => { 
    const value = e.target.value;
    if (value === 'add_new') { 
      setShowAddressSection(true);
    } else if (value !== '') {
      const selectedAddress = JSON.parse(value); 
      setModalData(prevData => ({
        ...prevData,
        selectedAddress: selectedAddress
      }));
      setShowAddressSection(false); 
    } else { 
      setModalData(prevData => ({
        ...prevData,
        selectedAddress: null
      }));
    }
  };

  const handleAddressAdded = (newAddress) => {
    setAddresses(prevAddresses => [...prevAddresses, newAddress]);
    setModalData(prevData => ({
      ...prevData,
      selectedAddress: newAddress
    }));
    setShowAddressModal(false);
    setShowAddressSection(false);
  };

  const calculateSavings = useCallback((rangePrice, shipping_cost_for_range) => {
    const singlePrice = parseFloat(rangePrice);
    const serviceCharges = singlePrice * 0.1;
    const importCost = parseFloat(shipping_cost_for_range) || 0;
    const listPrice = parseFloat(wish.list_price);
    const pre_totalSinglePrice = singlePrice + serviceCharges + importCost;
    const paymentProcessingFee = pre_totalSinglePrice * 0.05;
    const totalSinglePrice = pre_totalSinglePrice + paymentProcessingFee;

    if (isNaN(totalSinglePrice) || isNaN(listPrice) || totalSinglePrice <= 0 || listPrice <= 0) {
      return { amount: '0.00', percentage: '0.00' };
    }
    const savingsAmount = listPrice - totalSinglePrice;
    const savingsPercentage = (savingsAmount / listPrice) * 100;
    return {
      amount: savingsAmount.toFixed(2),
      percentage: savingsPercentage.toFixed(2)
    };
  }, [wish.list_price]);

  const renderPriceRanges = () => {
    if (!wish.price_ranges || wish.price_ranges.length === 0) return null;

    return (
      <div className="card">
        <div className="price-ranges-container">
          <h3 className="sub-card-title">Price Ranges</h3>
          <div>
            <p className="price-range-note-text">
              Product costs vary based on our collective order quantity. Typically, higher quantities lead to better pricing. For accurate individual costs, please use the calculator for each price range.
            </p>
          </div>
          <div className="price-range-table">
            <div className="price-range-header">
              <div className="column-heading">Orders</div>
              <div className="column-heading">Savings</div>
              <div className="column-heading">Calculate</div>
              <div className="column-heading">Price/Item</div>
              <div className="column-heading">Import Cost/Item</div>
            </div>
            {wish.price_ranges.map((range, index) => {
              const savings = calculateSavings(range.range_price, range.shipping_cost_for_range);
              return (
                <div key={index} className="price-range-row">
                  <div className="column">{`${range.price_range_start} - ${range.price_range_end}`}</div>
                  <div className="savings-column">
                    <div className="savings-percentage">{`${savings.percentage}%`}</div>
                    <div className="savings-amount">{`$${savings.amount}`}</div>
                  </div>
                  <div className="column">
                    <button className="calculate-button" onClick={() => openCalculatorModal(range)}>
                      <FontAwesomeIcon icon={faCalculator} size="lg" color="#89a96a" />
                    </button>
                  </div>
                  <div className="column">{`$${range.range_price}`}</div>
                  <div className="column">
                    {range.shipping_cost_for_range ? `$${range.shipping_cost_for_range}` : 'N/A'}
                  </div>
                </div>
              );
            })}
          </div>
          </div>
      </div>
    );
  };
  const COLORS = ['#4CAF50', '#FF9800', '#E91E63'];

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;

  return (
    <g>
      <Sector cx={cx} cy={cy} innerRadius={innerRadius}  outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill}/>
      <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 10} fill={fill}/>
      <text x={cx} y={cy} dy={-4} textAnchor="middle" fill={fill} style={{ fontSize: '16px', fontWeight: 'bold' }}>
        {payload.name}
      </text>
      <text x={cx} y={cy} dy={20} textAnchor="middle" fill="#333" style={{ fontSize: '14px' }}>
        {`${value} (${(percent * 100).toFixed(0)}%)`}
      </text>
    </g>
  );
};

const CustomPieChart = ({ data, totalRequired }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const totalValue = data.reduce((sum, entry) => sum + entry.value, 0);
  const remainingValue = Math.max(0, totalRequired - totalValue);

  const chartData = [
    ...data,
    { name: 'Remaining', value: remainingValue }
  ];

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie activeIndex={activeIndex} activeShape={renderActiveShape} data={chartData} cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#8884d8" dataKey="value" onMouseEnter={(_, index) => setActiveIndex(index)}>
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} 
              fill={index === chartData.length - 1 ? 'url(#remainingPattern)' : COLORS[index % COLORS.length]}
              stroke={index === chartData.length - 1 ? '#ccc' : 'none'} strokeWidth={index === chartData.length - 1 ? 2 : 0} />
          ))}
        </Pie>
        <defs>
          <pattern id="remainingPattern" patternUnits="userSpaceOnUse" width="4" height="4">
            <path d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2" style={{ stroke: '#ccc', strokeWidth: 1 }} />
          </pattern>
        </defs>
      </PieChart>
    </ResponsiveContainer>
  );
};

  const renderPieChart = () => (
    
    <div className="rightCard">
        <h3 className="sub-card-title">User Composition</h3>        
        <CustomPieChart data={pieChartData} totalRequired={wish.group_size_min} />
        <div className="legend">
          {pieChartData.map((entry, index) => (
            <div key={entry.name} className="legend-item">
              <span className="legend-color" style={{ backgroundColor: COLORS[index] }}></span>
              <span>{entry.name}: {entry.value}</span>
            </div>
          ))}
          <div className="legend-item">
            <span className="legend-color remaining"></span>
            <span>Pending: {Math.max(0, wish.group_size_min - safeProgressData.verifiedUsers)}</span>
          </div>
        </div>
        <div  >
          <p className="note-text">
          The pie chart illustrates the composition of users, indicating how many are verified and unverified. Only verified users are considered when calculating the overall minimum quantity needed to place orders. We strongly encourage you to verify your account by clicking the verification link. Additionally, please recommend that any friends joining the group complete their verification as well.
          </p>
        </div>
    </div>
  )

  const renderParticipation = () => (
      <div className="rightCard">
            <div className="card-content">
              <h3 className="card-title">Participation</h3>
              <div className="stats-row">
                <div className="stat-item">
                  <FontAwesomeIcon icon={faUsers} size="2x" color="#89a96a" />
                  <p className="stat-value">{safeProgressData.totalJoined}</p>
                  <p className="stat-label">Total Joined</p>
                </div>
                <div className="stat-item">
                  <FontAwesomeIcon icon={faUsers} size="2x" color="#89a96a" />
                  <p className="stat-value">{safeProgressData.verifiedUsers}</p>
                  <p className="stat-label">Verified {safeProgressData.verifiedUsers === 1 ? 'User' : 'Users'}</p>
                </div>
                <div className="stat-item">
                  <FontAwesomeIcon icon={faUsers} size="2x" color="#d9ae67" />
                  <p className="stat-value">{safeProgressData.unverifiedUsers}</p>
                  <p className="stat-label"> Unverified {safeProgressData.unverifiedUsers === 1 ? 'User' : 'Users'}</p>
                </div>
              </div>
            </div>
          </div>
  );

  const renderCalculatorModal = () => (
    <div className={`modal ${modalVisible ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={handleModalClose}></div>
      <div className="modal-content">
        <div className="calculator-modal-container">
          <button className="close-button" onClick={handleModalClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <h2 className="modal-title">Calculate Cost for Range</h2>
          <p className="range-info">
            This estimate calculates your individual cost if the total group order falls within:
          </p>
          <p className="range-info">
            Range: {selectedRange?.price_range_start} - {selectedRange?.price_range_end}
          </p>
          <div className="field">
            <label className="label">Quantity:</label>
            <div className="control">
              <input className="input" type="number" value={modalData.selectedQuantity} onChange={handleQuantityChange} placeholder={`Enter quantity`}/>
            </div>
          </div>
          <div className="field">
            <label className="label">Delivery Address:</label>
            <div className="control">
              <div className="select">
                <select value={modalData.selectedAddress ? JSON.stringify(modalData.selectedAddress) : ''} onChange={handleAddressChange}>
                  <option value="">Select an address</option>
                  {addresses.map((address, index) => (
                    <option key={index} value={JSON.stringify(address)}>
                      {formatAddress(address)}
                    </option>
                  ))}
                  <option value="add_new">+ Add New Address</option>
                </select>
              </div>
            </div>
          </div>
          {showAddressSection && (
            <AddressSection onAddressAdded={handleAddressAdded} address={""} callFromProfiles={false} handleAddressSave={handleAddressSave}/>
          )}
          <button className="button is-primary" onClick={calculateCosts}  disabled={isCalculating}>Calculate</button>
          {isCalculating && (
            <div className="spinner-overlay">
              <div className="spinner"></div>
            </div>
          )}
          {modalData.calculatedCosts && (
            <div className="estimate-container">
              <div className="estimate-header">
                <h3 className="estimate-header-text">
                  Estimate for <span className="estimate-highlight">{modalData.selectedQuantity}</span> items
                </h3>
              </div>
              <div className="estimate-content">
                <div className="estimate-address-container">
                  <p className="estimate-address-label">Delivery Address:</p>
                  <p className="estimate-address">{formatAddress(modalData.selectedAddress)}</p>
                </div>
                <div className="cost-breakdown">
                  <CostItem label="Subtotal:" value={modalData.calculatedCosts.subtotal} />
                  <CostItem label="Import Cost:" value={modalData.calculatedCosts.importCost} />
                  <CostItem label="Service Charge:" value={modalData.calculatedCosts.serviceCharges} />
                  <CostItem label="Sales Tax:" value={modalData.calculatedCosts.salesTax} />
                  <CostItem label="Shipping:" value={modalData.calculatedCosts.shippingCost} />
                </div>
                <div className="total-cost-container">
                  <div className="total-cost-label">Total:</div>
                  <div className="total-cost-value">${modalData.calculatedCosts.total}</div>
                </div>
                <div className="savings-container">
                  <div className="savings-label">You Save:</div>
                  <div className="savings-value">
                    ${modalData.calculatedCosts.savings} ({modalData.calculatedCosts.savingsPercentage}%)
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
  const formatExpiryDate = (expiryDateString) => {
    if (!expiryDateString) return 'Date not available';
    try { 
      const expiryDate = parseISO(expiryDateString); 
      const formattedDate = format(expiryDate, "MMMdo,`yy h:mm aa");  

      return `${formattedDate}`;
    } catch (error) { 
      return 'Invalid date';
    }
  };
  
  const formatTimeRemaining = (expiryDateString) => {
    if (!expiryDateString) return '';

    try { 
      const expiryDate = parseISO(expiryDateString);  
      const timeRemaining = formatDistanceToNow(expiryDate, { addSuffix: false });
      return timeRemaining.includes('days') ? timeRemaining.split(' ')[0] : '1';
    } catch (error) { 
      return '';
    }
  };
  
  const handleRate = async (rating) => {
    if (!userVerified) {
      setErrorMessage('Please verify your account to rate this group wish');
      return;
    }
    await rateGroupWish(wish.id, rating);
    setShowRating(false);
  };

  const parseRatingCounts = (ratingCountsString) => {
    const counts = {};
    if (Array.isArray(ratingCountsString)) {
      ratingCountsString.forEach(item => {
        const match = item.match(/(-?\d+) stars? (\d+) users?/);
        if (match) {
          const [, rating, count] = match;
          counts[parseInt(rating)] = parseInt(count);
        }
      });
    }
    return counts;
  };

  const ratingCounts = parseRatingCounts(progressData.rating_counts || []);
  const totalRatings = Object.values(ratingCounts).reduce((a, b) => a + b, 0);

  const handleReferAFriend = (item) => { 
    navigate("/invites", { state: { grp_wish: item } });
  };

  const renderButtons = useCallback(() => {
    if (buddyStatus === null || buddyStatus === 'WITHDRAWN') { 
      return (
        <button className="button join-button" onClick={() => handleJoinGroup(wish)}>
          {buddyStatus === 'WITHDRAWN' ? 'Rejoin Group' : 'Join Group'}
        </button>
      );
    } 
    return null;
  }, [buddyStatus, handleJoinGroup, handleWithdrawFromGroup, wish]);

  return (
    <div style={styles.mainContainer}>
     <TopMenuBar showBackButton={false} showHomeButton={true} showMenuButton={true} onBackClick={() => navigate(-1)} title={'Details'} showSearch={false} showAddress={false} /> 
      <div className="container">
        <div className="header" style={style.sameRowContainer}>                 
          {isLoading && (
            <div className="loading-container">
              <div className="spinner"></div>
            </div>
          )}
        </div>

        <div className="content-wrapper">
          <div className="left-column">
            <div className="card product-info-card">
              <div className="card-content">
                <h3 className="card-name-title">{wish.product_name}</h3>
                <div className="product-info-grid">
                  <div className="product-image-container">
                    {!imageError && wish.image ? (
                      <img src={wish.image} alt={wish.product_name} className="product-image" onError={(e) => { setImageError(true)}} />
                      ) : (
                        <div className="placeholder-image">
                          <FontAwesomeIcon icon={faImage} size="3x" color="#ccc" />
                          <p>Image not available</p>
                        </div>
                      )}
                  </div>
                  <div className="product-details">
                    <div className="time-info-container">
                      <div className="expiry-date-container">
                        <h4 className="wish-time-info">Valid until {formatExpiryDate(wish.expires_at)}</h4>
                      </div>
                      <div className="time-remaining-circle">
                        <span className="time-remaining-number">{formatTimeRemaining(wish.expires_at)}</span>
                        <span className="time-remaining-text">Day(s) Left</span>
                      </div>
                    </div>   
                    {renderDetailItem("Category", wish.category, faTag)}             
                    {renderDetailItem("Market Price Per Item", `$${wish.list_price}`, faDollarSign)} 
                    {renderDetailItem("Minimum Group Size", wish.group_size_min, faUsers)}        
                  </div>        
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-content" >
                <div style= {styles.sameRowContainer}>
                <p className="card-title" > 
                  Description 
                </p>
                </div>
                {renderDescription(wish.description)}
            </div>
            </div>  
            {renderPriceRanges()}  
            
            <div className="card">
                <div className="card-content">
                  <h3 className="card-title">Overall Progress</h3>
                  <div className="progress-bar">
                    <div className="progress-bar-fill" style={{ width: `${Math.round(safeProgressData.progress * 100)}%` }}></div>
                  </div>
                  <p className="progress-text">{`${Math.round(safeProgressData.progress * 100)}% Complete`}</p>                  
                </div>
              </div>
            </div>

          <div className="right-column">
            <div className="card price-ranges-card">
              <div className="card-content">                
                {renderPieChart()}
                {renderParticipation()}
                {<RatingsDisplay ratings={ratingCounts} totalRatings={totalRatings}  averageRating={safeProgressData.averageRating}/>}
                {(buddyStatus === 'RE_JOINED' || buddyStatus === 'JOINED') && (
                  <div className="rightCard">                 
                    <button className="button withdraw-button" onClick={handleWithdrawFromGroup}>
                      Withdraw
                    </button>                 
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="button-row">
          <button className="button invite-button" onClick={() => handleReferAFriend({wish})}>
            Refer
          </button>
          {renderButtons()}
          <button className="button rate-button" onClick={() => setShowRating(true)}  disabled={!userVerified} style={{opacity: userVerified ? 1 : 0.6, cursor: userVerified ? 'pointer' : 'not-allowed'}}>
            Rate
          </button>
        </div>

        {renderCalculatorModal()}
        {showRating && (
          <div className="modal is-active">
            <div className="modal-background" onClick={() => setShowRating(false)}></div>
            <div className="modal-content">
              <DownRateSlider onRate={handleRate} onClose={() => setShowRating(false)} onReport={() => {setShowRating(false); }} />
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={() => setShowRating(false)}></button>
          </div>
        )}
        <TermsAndConditionsModal isOpen={showTermsModal} onClose={() => setShowTermsModal(false)} onAgree={handleAgreeTerms}/>
        <JoinGroupWishModal isOpen={showJoinModal} onClose={() => setShowJoinModal(false)} wish={initialProgressData} onJoinSuccess={handleJoinSuccess}/>
      </div>
    </div>
  );
};



const styles = `
  .container { 
    width:90%;
    max-width: 1500px;
    margin: 40px 150px;
    padding: 2px;
    background-color: #F5F5F5;
  }  
  .success-message {
    background-color: #e6f3e6;
    color: #2e7d32;
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: bold;
  }
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    backgroundColor: style.Color.colorMintGreen,
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  .title {
    font-size: 24px;
    font-weight: bold;
    color: colo;
    margin-bottom: 5px;
  }
  .subtitle {
    font-size: 18px;
    color: ${style.Color.colorGray};
    text-align: center;
  }
  .content-wrapper {
    display: flex;
    gap: 20px;
  }
  .left-column {
    flex: 2;
    background-color: ${style.Color.colorLightBeige};
  }
  .right-column {
    flex: 1;
  }
  .card {
    background-color: ${style.Color.colorWhite};
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
  }
  .rightCard {
    background-color: ${style.Color.colorLightestBeige};
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  .card-content {
    padding: 10px;
  }
  .card-name-title{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px; 
    color: ${style.Color.colorPurple};  
  }
  .card-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px; 
    color: #003366;  
  }
  .product-info-card {
    background-color: ${style.Color.colorWhite};
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .product-info-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    align-items: start;
  }

  .product-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${style.Color.colorLightestBeige};
    border-radius: 8px;
    padding: 10px;
  }

  .product-image {
    max-width: 100%;
    max-height: 200px;
    object-fit: contain;
  }

  .product-details {
    display: flex;
    flex-direction: column;
    gap: 10px; 
  }

  .detail-item {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 3px;
    background-color: ${style.Color.colorWhite};
    border-radius: 8px;
  }

  .detail-icon-container {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${style.Color.colorWhite};
    border-radius: 50%;
  }

  .detail-text-container {
    flex: 1;
  }

  .detail-label {
    font-size: 14px;
    color: ${style.Color.colorDarkGrey};
    margin-bottom: 2px;
  }

  .detail-value {
    font-size: 16px;
    color: ${style.Color.colorRoyalBlueText};
    font-weight: bold;
  }

  .description-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  .description-container {
    margin-top: 10px;
  }
  .progress-bar {
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
  }
  .progress-bar-fill {
    height: 100%;
    background-color: #d9ae67;
  }
  .progress-text {
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
    color: ${style.Color.colorDarkGrey};
  }
  .stats-row {
    display: flex;
    justify-content: space-around;
  }
  .stat-item {
    text-align: center;
  }
  .stat-value {
    font-size: 18px;
    font-weight: bold;
    margin: 5px 0;
  }
  .stat-label {
    font-size: 14px;
    color: ${style.Color.colorDarkGrey};
  }
  .placeholder-chart-container {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 8px;
  }
  .no-data-text {
    font-size: 16px;
    color: ${style.Color.colorDarkGrey};
  }
  .button-row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 800px; 
  }
  .invite-button {
    background-color: ${style.Color.colorDarkBlue};
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    flex: 1;
    margin: 0px 10px;
    margin-left: 250px;
  }
  .rate-button {
    background-color: ${style.Color.colorPurpleBlue};
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    flex: 1;
    margin: 0 10px;
  }

  .join-button {
    background-color: ${style.Color.colorLimeGreen};
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    flex: 1;
    margin: 0 10px;
  }
  .rejoin-button {
    background-color: ${style.Color.colorLimeGreen};
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    flex: 1;
    margin: 0 10px;
  }
  .withdraw-button {
    font-size: 0.8em;
    background-color: ${style.Color.colorLimeGreen};
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px; 
    opacity: 0.7;  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .price-ranges-container {      
    padding: 20px;
  }
  .sub-card-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #003366;
    margin-left:5px;
  }
  .price-ranges-card {
    height: 100%;
    width: 100%;
  }
  .note-card {
    background-color: ${style.Color.colorCoolBeige}; 
    border-radius: 5px;
    margin-bottom: 1px; 
    margin-left:-12px;
    margin-right:2px;
    width: 107%;
  }
  .price-range-table {
    display: table;
    width: 100%;
    border-collapse: collapse;
  }
  .price-range-note-text {
    font-size: 14px;
    background-color: ${style.Color.colorLightBeige};
    color: ${style.Color.colorDarkBlue}; 
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    margin-left:10px;
    margin-right:10px;
  }

  .note-text {
    font-size: 14px;
    color: ${style.Color.colorDarkGrey};
  }
 
.price-range-row {
  display: table-row;
}

.price-range-header .column {
  font-weight: bold;
  background-color: #f5f5f5;
}

  .price-range-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
  .column-heading {
    flex: 1;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #555;
  }
  .price-range-row {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #eee;
  }
  .column {
    flex: 1;
    font-size: 14px;
    text-align: center;
    color: #333;
  }
  .savings-column {
    flex: 1;
    text-align: center;
  }
  .savings-percentage {
    font-size: 14px;
    font-weight: bold;
    color: ${style.Color.colorPurple};
  }
  .savings-amount {
    font-size: 12px;
    color: ${style.Color.colorPurple};
  }
  .calculate-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  .modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .modal.is-active {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border-radius: 8px;
    max-width: 90%;
    width:400px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  } 
  .calculator-modal-container {
    background-color: #ebe6d6;
    padding: 25px;
    border-radius: 8px;
    position: relative;
  }  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    color:  ${style.Color.colorPurple};
    cursor: pointer;
  }
  .modal-title {
    font-size: 22px;
    font-weight: bold;
    color: ${style.Color.colorPurple};
    margin-bottom: 15px;
    text-align: center;
  }
  .range-info {
    font-size: 16px;
    color: ${style.Color.colorDarkGrey};
    margin-bottom: 20px;
    text-align: center;
  }
  .field {
    margin-bottom: 20px;
  }
  .label {
    display: block;
    font-size: 16px;
    color: ${style.Color.colorDarkGrey};
    margin-bottom: 5px;
  }
  .select select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
  }
  .input {
    width: 95%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
  }
  .button.is-primary {
    background-color: ${style.Color.colorMintGreen};
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
  }
  .estimate-container {
    margin-top: 20px;
    border: 1px solid ${style.Color.colorPurple};;
    border-radius: 8px;
    overflow: hidden;
  }
  .estimate-header {
    background-color: white;
    padding: 10px;
  }
  .estimate-header-text {
    color: ${style.Color.colorPurple};
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 0;
  }
  .estimate-content {
    padding: 15px;
  }
  .estimate-address-container {
    margin-bottom: 10px;
  }
  .estimate-address-label {
    font-size: 14px;
    color: ${style.Color.colorDarkGrey};
    margin-bottom: 2px;
  }
  .estimate-address {
    font-size: 14px;
    color: ${style.Color.colorPurple};
    font-weight: bold;
  }
  .cost-breakdown {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
  }
  .cost-item-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .cost-item-label {
    font-size: 14px;
    color: ${style.Color.colorDarkGrey};
  }
  .cost-item-value {
    font-size: 14px;
    font-weight: bold;
    color: ${style.Color.colorDarkGrey};
  }
  .total-cost-container {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    border-top: 1px solid ${style.Color.colorMintGreen};
  }
  .total-cost-label {
    font-size: 16px;
    font-weight: bold;
    color: ${style.Color.colorDarkGrey};
  }
  .total-cost-value {
    font-size: 18px;
    font-weight: bold;
    color: ${style.Color.colorPurple};
  }
  .same-row-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .savings-container {
    margin-top: 10px;
    padding: 10px;
    background-color: ${style.Color.colorVibrantRed};
    border-radius: 8px;
    align-items: center;
  }

  .savings-label {
    font-size: 14px;
    color: ${style.Color.colorWhite};
    margin-bottom: 5px;
  }

  .savings-value {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: ${style.Color.colorWhite};
  }

  .time-info-container {
    position: relative;
    margin-bottom: 20px;
  }

  .expiry-date-container {
    border-radius: 8px;
    padding: 10px 15px;
  }

  .wish-time-info {
    font-size: 14px;
    color: #FF4500;
    background-color: #FFFFFF;
    padding: 8px 12px;
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: inline-block;
    letter-spacing: 0.5px;
    border: 2px solid #fc8f7c;
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }

  .time-remaining-circle {
    position: absolute;
    top: -10px;
    right: 305px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #FFFFFF;
    color: #FF4500;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
    animation: pulse 2s infinite;
    z-index: 1;
  }

  .time-remaining-number {
    font-size: 12px;
  }

  .time-remaining-text {
    font-size: 8px;
    padding-left: 8px;
  }
  .show-more-button {
    background-color: #FFFFFF;
  }
  .description-card {
    background-color: ${style.Color.colorWhite};
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .description-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
  }

  .description-container {
    margin-top: 10px;
  }

  .description-content p {
    font-size: 13px;
    line-height: 1.6;
    color: ${style.Color.colorDarkBlue};
    margin-bottom: 15px;
  }

  .show-more-button,
  .show-less-button {
    background: none;
    border: none;
    color: ${style.Color.colorMintGreen};
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    border-radius: 15px;
    transition: background-color 0.3s ease;
  }

  .show-more-button:hover,
  .show-less-button:hover {
    background-color: ${style.Color.colorLightestBeige};
  }
  .users-needed-text {
    margin-top: 10px;
    font-size: 14px;
    color: #d9ae67;
    font-weight: bold; 
  }

  .minimum-reached-text {
    margin-top: 10px;
    font-size: 14px;
    color: ${style.Color.colorDarkBlue};
    font-weight: bold;
  }
  .legend {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -2px;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
    font-size: 10px;
    color: ${style.Color.colorDarkBlue};
  }
  
  .legend-color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .legend-color.remaining {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="4" height="4">
                <path d="M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2" style="stroke:%23ccc;stroke-width:1"/></svg>');
  }

  .spinner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid ${style.Color.colorPurple};
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .button.is-primary.is-loading {
    color: transparent !important;
    pointer-events: none;
  }

  .button.is-primary.is-loading::after {
    border-color: transparent transparent #fff #fff !important;
  }
`;


// Add styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default GroupWishProgressScreen; 
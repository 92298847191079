import React, { useState, useEffect, useCallback } from 'react';
import { isRouteErrorResponse, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { format, parseISO } from 'date-fns'; 
import { useAuth } from '../../Common/InitialGears/AuthContext';
import * as style from "../../../GlobalStyles";
import AddressSection from '../../Common/AddressSection';
import PreferencesScreen from './PreferenceScreen';
import VerificationModal from '../Verification/VerificationModal';
import { getApi, postApi, buildUrl } from "../../Common/BackendCalls/apiUtils";

const JoinGroupWishModal = ({ isOpen, onClose, wish, onJoinSuccess }) => { 
  const { queryLoginParam, userVerified } = useAuth(); 
  const [quantity, setQuantity] = useState(1);
  const [referralCode, setReferralCode] = useState('');
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showAddressSection, setShowAddressSection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPreferences, setShowPreferences] = useState(false);  
  const [showVerificationWarning, setShowVerificationWarning] = useState(!userVerified);
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const [useRedeemCredit, setUseRedeemCredit] = useState(false);
  const [availableCredit, setAvailableCredit] = useState(0);

  useEffect(() => {
    setError(null);
    setIsLoading(true);
    if (isOpen) {
      setError(null);
      setIsLoading(true);
      fetchAddresses();
      fetchAvailableCredit();
      setShowVerificationWarning(!userVerified);
    }
  }, [isOpen, userVerified]);

  const fetchAddresses = useCallback(async () => {
    try {
      const addressesUrl = buildUrl('address', `${queryLoginParam}&type=completed`);
      const response = await getApi(addressesUrl);
      
      if (response.ok) {
        const data = response.data;
        const primaryAddress = data.primary_address ? [data.primary_address] : [];
        const otherAddresses = Array.isArray(data.other_addresses) ? data.other_addresses : [];

        setAddresses([...primaryAddress, ...otherAddresses]);
      } else { 
        setAddresses([]);
      }
    } catch (error) { 
      setAddresses([]);
    } finally {
      setIsLoading(false);
    }
  }, [queryLoginParam]);

  const formatAddress = (address) => {
    if (!address) return 'No address selected. Click Here to add new';
    return `${address.address1}${address.address2 ? ', ' + address.address2 : ''}, ${address.city}, ${address.state} ${address.postal_code}${address.isPrimary ? ' (Primary)' : ''}`;
  };

  const handleAddressChange = (e) => {
    const value = e.target.value;
    if (value === 'add_new') { 
      setShowAddressSection(true);
    } else { 
      setSelectedAddress(addresses.find(addr => addr.id && addr.id.toString() === value));
      setShowAddressSection(false);
    }
  };

  const handleAddressSave = useCallback(async (newAddress) => { 
    setIsLoading(true);
    const addressUrl = buildUrl('address', queryLoginParam);
    const requestBody = {
      address1: newAddress.address1,
      address2: newAddress.address2,
      city: newAddress.city,
      state: newAddress.state,
      country: newAddress.country,
      postal_code: newAddress.postal_code,
      primary_residence: false,
    };
     
    try {
      const response = await postApi(addressUrl, requestBody);     
      if (response) { 
        setAddresses(prevAddresses => [...prevAddresses, response.data]);  
        setSelectedAddress(response.data);
        setShowAddressSection(false);
        await fetchAddresses();
      } else {
        setError('Failed to update address. Please try again.');
      }
    } catch (error) {  
      setError('Failed to update address. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [queryLoginParam]);

  const handleAddressAdded = async (newAddress) => {
    setIsLoading(true);
    const addressUrl = buildUrl('address', queryLoginParam);
    const requestBody = {
      address1: newAddress.address1,
      address2: newAddress.address2,
      city: newAddress.city,
      state: newAddress.state,
      country: newAddress.country,
      postal_code: newAddress.postal_code,
      primary_residence: false,
    };
     
    try {
      const response = await postApi(addressUrl, requestBody);
      
      if (response) {
        setAddresses(prevAddresses => [...prevAddresses, response.data]);  
        setSelectedAddress(response.data);
        setShowAddressSection(false);
        await fetchAddresses();
      } else {
        setError('Failed to add address. Please try again.');
      }
    } catch (error) { 
      
      setError('Failed to add address. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAvailableCredit = useCallback(async () => {
    try {
      const creditUrl = buildUrl('userCredit', queryLoginParam);
      const response = await getApi(creditUrl);
      
      if (response.ok) {
        setAvailableCredit(response.data.availableCredit);
      } else { 
        setAvailableCredit(0);
      }
    } catch (error) { 
      setAvailableCredit(0);
    }
  }, [queryLoginParam]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    if (!selectedAddress) { 
      setError('Please select an address before joining');
      setIsLoading(false);
      return;
    }
    if (!userVerified) {  
      setShowVerificationWarning(true);
      setIsLoading(false); 
    }

    try {
      const joinGroupWishUrl = buildUrl('joinGroupWish', queryLoginParam);
      const response = await postApi(joinGroupWishUrl, {
        grp_wish_id: wish.wish.id,
        quantity: quantity,
        referral_code: referralCode,
        address_id: selectedAddress.id,
        use_redeem_credit: useRedeemCredit
      }); 
      setShowPreferences(true); 
    } catch (error) { 
      setError('Failed to join group wish. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };


  const handlePreferencesClose = (success) => {
    setShowPreferences(false);
    onClose(); 
    if (success && typeof onJoinSuccess === 'function') {
      onJoinSuccess();
    }
  };

  const formatExpiryDate = (expiryDateString) => {
    if (!expiryDateString) return 'Date not available';
    try { 
      const expiryDate = parseISO(expiryDateString); 
      return format(expiryDate, "MMMM do, yyyy 'at' h:mm aa");
    } catch (error) { 
      return 'Invalid date';
    }
  };

  const handleVerification = () => { 
   setIsVerificationModalOpen(true);
  };

  const handleVerificationSuccess = () => {  
    setShowVerificationWarning(false);
  };

  const handleContinueAnyway = () => {
    setShowVerificationWarning(false);
  };

  if (!isOpen) return null;
   
  if (showPreferences) { 
    return (
      <PreferencesScreen isOpen={showPreferences} onClose={handlePreferencesClose} grp_wish_id={wish.wish.id} groupWishName={wish.wish.product_name} existingPreferences="new"  quantity={quantity}/>
    );
  }
  return (
    <div style={modalOverlayStyle}>
      <div style={modalContentStyle}>
        <div style={modalHeaderStyle}>
          <h2 style={modalTitleStyle}>Join Group Wish</h2>
          <button onClick={onClose} style={closeButtonStyle}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div style={wishInfoStyle}>
          <h3 style={wishTitleStyle}>{wish.wish.product_name}</h3>
          <p style={wishExpiryStyle}>Valid until: {formatExpiryDate(wish.wish.expires_at)}</p>
        </div>
        <form onSubmit={handleSubmit} style={formStyle}>
          <div style={formFieldStyle}>
            <label style={labelStyle}>Quantity:</label>
            <input type="number" min="1" value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value))} style={inputStyle} required/>
          </div>
          <div style={formFieldStyle}>
            <label style={labelStyle}>Referral Code (optional):</label>
            <input type="text" value={referralCode} onChange={(e) => setReferralCode(e.target.value)} style={inputStyle} />
          </div>
          <div style={formFieldStyle}>
            <label style={labelStyle}>Delivery Address:</label>
            <select value={selectedAddress ? selectedAddress.id : ''} onChange={handleAddressChange} style={selectStyle} required>
              <option value="">Select an address</option>
              {addresses.map((address) => (
                address && address.id ? (
                  <option key={address.id} value={address.id}>
                    {formatAddress(address)}
                  </option>
                ) : null
              ))}
              <option value="add_new" onSelect={() => setShowAddressSection(true)}>+ Add New Address</option>
            </select>
            {showAddressSection && ( 
            <AddressSection onAddressAdded={handleAddressAdded} address={""} callFromProfiles={false} handleAddressSave={handleAddressSave}/>
          )}
          </div>
          {showVerificationWarning && (
            <div style={warningStyle}>
              <p>Your account is not verified. Your wish may not count towards the deal commencement.</p>
              <button type="button" onClick={handleVerification} style={verifyButtonStyle}>
                Verify Account
              </button>
              <button type="button" onClick={handleContinueAnyway} style={continueButtonStyle}>
                Continue Anyway
              </button>
            </div>
          )}
          <div style={formFieldStyle}>
            <label style={checkboxLabelStyle}>
              <input type="checkbox"  checked={useRedeemCredit} onChange={(e) => setUseRedeemCredit(e.target.checked)} style={checkboxStyle}/>
              Use Redeem Credit points (max 10% of total cost)
            </label>
          </div>
          <div style={creditLinkStyle}>
            <Link to="/creditDetails">View Credit Details</Link>
          </div>
          {error && <p style={errorStyle}>{error}</p>}
          <button type="submit" style={submitButtonStyle} disabled={isLoading}>
            {isLoading ? 'Joining...' : 'Join Group Wish'}
          </button>
        </form>
      </div>
      <button onClick={() => setIsVerificationModalOpen(true)}>Verify Account</button>
      <VerificationModal  isOpen={isVerificationModalOpen}  onClose={() => setIsVerificationModalOpen(false)}  onVerificationSuccess={handleVerificationSuccess}/>
    </div>
  );
};

// Styles
const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: '1000',
};

const modalContentStyle = {
  backgroundColor: style.Color.colorLightBeige,
  borderRadius: '10px',
  padding: '20px',
  width: '90%',
  maxWidth: '500px',
  maxHeight: '90vh',
  overflowY: 'auto',
};

const modalHeaderStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
};

const modalTitleStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  color: style.Color.colorRoyalBlueText,
  margin: 0,
};

const closeButtonStyle = {
  background: 'none',
  border: 'none',
  fontSize: '24px',
  cursor: 'pointer',
  color: style.Color.colorDarkGrey,
};

const wishInfoStyle = {
  marginBottom: '20px',
};

const wishTitleStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  marginBottom: '10px',
  color: style.Color.colorDarkBlue,
};

const wishExpiryStyle = {
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
};

const formStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
};

const formFieldStyle = {
  display: 'flex',
  flexDirection: 'column',
};

const labelStyle = {
  marginBottom: '5px',
  fontWeight: 'bold',
  color: style.Color.colorDarkGrey,
};

const inputStyle = {
  padding: '10px',
  borderRadius: '5px',
  border: `1px solid ${style.Color.colorBlueGrey}`,
  fontSize: '16px',
  color: style.Color.colorRoyalBlueText,
};

const selectStyle = {
  ...inputStyle,
  appearance: 'none',
  backgroundImage: 'url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E")',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 10px top 50%',
  backgroundSize: '12px auto',
  color: style.Color.colorRoyalBlueText,
};

const submitButtonStyle = {
  backgroundColor: style.Color.colorPurple,
  color: style.Color.colorWhite,
  border: 'none',
  borderRadius: '5px',
  padding: '12px',
  fontSize: '16px',
  fontWeight: 'bold',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
  ':hover': {
    backgroundColor: style.Color.colorMintGreen,
  },
};

const warningStyle = {
  backgroundColor: style.Color.colorLightYellow,
  border: `1px solid ${style.Color.colorYellow}`,
  borderRadius: '5px',
  padding: '10px',
  marginBottom: '15px',
};

const verifyButtonStyle = {
  backgroundColor: style.Color.colorGreen,
  color: style.Color.colorWhite,
  border: 'none',
  borderRadius: '5px',
  padding: '8px 12px',
  fontSize: '14px',
  fontWeight: 'bold',
  cursor: 'pointer',
  marginRight: '10px',
};

const continueButtonStyle = {
  backgroundColor: style.Color.colorLightGrey,
  color: style.Color.colorDarkGrey,
  border: 'none',
  borderRadius: '5px',
  padding: '8px 12px',
  fontSize: '14px',
  fontWeight: 'bold',
  cursor: 'pointer',
};

const errorStyle = {
  color: style.Color.colorVibrantRed,
  marginTop: '10px',
};

const checkboxLabelStyle = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
};

const checkboxStyle = {
  marginRight: '10px',
};

const creditLinkStyle = {
  marginTop: '10px',
  fontSize: '14px',
};

export default JoinGroupWishModal;
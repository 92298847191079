import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getApi, buildUrl } from "../../Common/BackendCalls/apiUtils";
import { useAuth } from '../../Common/InitialGears/AuthContext';
import { TopMenuBar } from '../DashbordComponents/TopMenuBar';
import * as style from "../../../GlobalStyles";

const OrderManagement = () => {
  const { queryLoginParam } = useAuth();
  const navigate = useNavigate();
  const [groupWishes, setGroupWishes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchGroupWishes();
  }, []);

  const fetchGroupWishes = async () => {
    setIsLoading(true);
    setErrorMessage("");
    try {
      const url = buildUrl('groupWishesOrderList', queryLoginParam);
      const response = await getApi(url);
        
      if (response) { 
        const wishes = response.data.group_wishes_for_order_processing || []; 
        setGroupWishes(wishes);
        if (wishes.length === 0) {
          setErrorMessage('No group wishes available for order processing.');
        }
      } else {
        setErrorMessage('Failed to fetch group wishes');
      }
    } catch (error) {
      setErrorMessage('An error occurred while fetching group wishes');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGroupWishClick = (groupWishId) => {
    navigate(`/orderProcessing/${groupWishId}`);
  };

  const renderGroupWishes = () => {
    return groupWishes.map((wishData) => {
      const wish = wishData.wish;
      return (
        <div key={wish.id} style={wishItemStyle} onClick={() => handleGroupWishClick(wish.id)}>
          <h3 style={wishTitleStyle}>{wish.product_name}</h3>
          <p style={wishCategoryStyle}>Category: {wish.category.join(', ')}</p>
          <p style={wishStatusStyle}>Status: {wish.group_wish_status || 'N/A'}</p>
          <p style={wishInfoStyle}>Group Size: {wish.group_size_min}</p>
          <p style={wishInfoStyle}>Expiry: {new Date(wish.expires_at).toLocaleDateString()}</p>
          <p style={wishInfoStyle}>Progress: {(wishData.progress * 100).toFixed(2)}%</p>
          <p style={wishInfoStyle}>Total Joined: {wishData.totalJoined}</p>
        </div>
      );
    });
  };

  return (
    <div style={containerStyle}>
      <TopMenuBar showHomeButton={true}  title="Order Management" showSearch={false} showAddress={false} showMenuButton={true} />
      <div style={contentStyle}>
        {isLoading ? (
          <div style={loadingStyle}>Loading...</div>
        ) : errorMessage ? (
          <div style={errorStyle}>{errorMessage}</div>
        ) : (
          <div style={listContainerStyle}>
            {renderGroupWishes()}
          </div>
        )}
      </div>
    </div>
  );
};

const containerStyle = {
  fontFamily: 'Arial, sans-serif',
  backgroundColor: style.Color.colorLightBeige,
  minHeight: '100vh',
};

const contentStyle = {
  padding: '20px',
};

const loadingStyle = {
  textAlign: 'center',
  fontSize: '18px',
  marginTop: '20px',
};

const errorStyle = {
  color: 'red',
  textAlign: 'center',
  fontSize: '18px',
  marginTop: '20px',
};

const listContainerStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(800px, 1fr))',
  gap: '20px',
};

const wishItemStyle = {
  backgroundColor: style.Color.colorWhite,
  borderRadius: '8px',
  padding: '15px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
  transition: 'transform 0.2s ease-in-out',
  ':hover': {
    transform: 'scale(1.03)',
  },
};

const wishTitleStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  marginBottom: '10px',
  color: style.Color.colorPurple,
};

const wishCategoryStyle = {
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
  marginLeft: '30px',
  marginBottom: '5px',
};

const wishStatusStyle = {
    fontSize: '14px',
    color: style.Color.colorDarkGrey,
    marginBottom: '5px',
    marginLeft: '30px',
  };

const wishInfoStyle = {
  fontSize: '14px',
  color: style.Color.colorDarkGrey,
  marginBottom: '5px',
  marginLeft: '30px',
};

export default OrderManagement;
import React, { useState, useEffect } from 'react';
import { useNavigate , useLocation} from 'react-router-dom';
import Config from "../../../config"; 
import { useAuth } from '../../../components/Common/InitialGears/AuthContext'; 
import * as style from "../../../GlobalStyles";  

const WebInvites = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { queryLoginParam, idTokenLoggedIn } = useAuth();
  const [contacts, setContacts] = useState([]);
  const [newContact, setNewContact] = useState({ name: '', email: '', phone: '' });
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [agreed, setAgreed] = useState(false);
  const [inviteResponse, setInviteResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [groupWish, setGroupWish] = useState(null);
  const [groupWishTitle, setGroupWishTitle] = useState(null);
  const [groupWishId, setGroupWishId] = useState(null);

  useEffect(() => { 
    const styleElement = document.createElement('style');
    styleElement.type = 'text/css';
    styleElement.innerHTML = styles;
    document.head.appendChild(styleElement); 
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);
  useEffect(() => { 
    if (location.state && location.state.grp_wish) {
      setGroupWish(location.state.grp_wish);
      setGroupWishTitle(location.state.grp_wish.wish.product_name);
      setGroupWishId(location.state.grp_wish.wish.id); 
    } 
  }, [location]);

  const handleTermsClick = () => {
    setAgreed(!agreed);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewContact(prev => ({ ...prev, [name]: value }));
  };

  const addContact = () => {
    if (newContact.name && (newContact.email || newContact.phone)) {
      setContacts(prev => [...prev, { ...newContact, id: Date.now() }]);
      setNewContact({ name: '', email: '', phone: '' });
    }
  };

  const toggleContactSelection = (contactId) => {
    setSelectedContacts(prev => 
      prev.includes(contactId)
        ? prev.filter(id => id !== contactId)
        : [...prev, contactId]
    );
  };

  const handleSendInvite = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    setIsLoading(true);

    const selectedContactData = contacts.filter(contact => selectedContacts.includes(contact.id));
    
    try {
      let genURL = `${Config.apiUrl}sendInvite?${queryLoginParam}`;
      
      const requestBody = {
        dealId: 0, 
        ...(groupWish && { groupWishId: groupWishId}),
        Contacts: selectedContactData.map(contact => ({
          givenName: contact.name,
          familyName: '',
          phoneNumbers: contact.phone ? [{ number: contact.phone }] : [],
          emailAddresses: contact.email ? [{ email: contact.email }] : []
        }))
      };

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': idTokenLoggedIn
      };

      const response = await fetch(genURL, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestBody),
        mode: 'cors',  
        credentials: 'omit' 
      }); 
      const responseData = await response.json();

      if (response.ok) {
        setInviteResponse(responseData);
        setSuccessMessage('Invites sent successfully!');
      } else {
        setErrorMessage('Failed to send invites. Please try again.');
      }
    } catch (error) {
      setErrorMessage(`Error while sending invites: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const renderInviteResults = () => {
    if (!inviteResponse) return null;

    return (
      <div className="invite-response">
        <h2>Invite Results</h2>
        {inviteResponse.invited_successfully.length > 0 && (
          <div className="response-category">
            <h3>Successfully Invited:</h3>
            <ul>
              {inviteResponse.invited_successfully.map((name, index) => (
                <li key={index}>{name}</li>
              ))}
            </ul>
          </div>
        )}
        {inviteResponse.already_using_backsapp.length > 0 && (
          <div className="response-category">
            <h3>Already Using BacksApp:</h3>
            <ul>
              {inviteResponse.already_using_backsapp.map((name, index) => (
                <li key={index}>{name}</li>
              ))}
            </ul>
          </div>
        )}
        {inviteResponse.already_in_group.length > 0 && (
          <div className="response-category">
            <h3>Already in Group:</h3>
            <ul>
              {inviteResponse.already_in_group.map((name, index) => (
                <li key={index}>{name}</li>
              ))}
            </ul>
          </div>
        )}
        {inviteResponse.stop_messages.length > 0 && (
          <div className="response-category">
            <h3>Opted Out of Messages:</h3>
            <ul>
              {inviteResponse.stop_messages.map((name, index) => (
                <li key={index}>{name}</li>
              ))}
            </ul>
          </div>
        )}
        {inviteResponse.invite_failed.length > 0 && (
          <div className="response-category">
            <h3>Invite Failed:</h3>
            <ul>
              {inviteResponse.invite_failed.map((name, index) => (
                <li key={index}>{name}</li>
              ))}
            </ul>
          </div>
        )}
        {inviteResponse.phone_not_valid.length > 0 && (
          <div className="response-category">
            <h3>Invalid Phone Numbers:</h3>
            <ul>
              {inviteResponse.phone_not_valid.map((name, index) => (
                <li key={index}>{name}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };

  return (
    <div style={styles.webInvitesContainer}>
      <h1>{groupWish ? `Invite to ${groupWishTitle}` : 'Invite Friends'}</h1>
      
      <div style={styles.addContactForm}>
        <input type="text" name="name" value={newContact.name} onChange={handleInputChange} placeholder="Name" style={styles.input}/>
        <input type="email" name="email" value={newContact.email} onChange={handleInputChange} placeholder="Email" style={styles.input}/>
        <input type="tel" name="phone" value={newContact.phone} onChange={handleInputChange} placeholder="Phone" style={styles.input}/>
        <button onClick={addContact} style={styles.button}>Add Contact</button>
      </div>

      <div style={styles.contactsList}>
        {contacts.map(contact => (
          <div key={contact.id} style={styles.contactItem}>
            <input type="checkbox" checked={selectedContacts.includes(contact.id)} onChange={() => toggleContactSelection(contact.id)}/>
            <span>{contact.name} - {contact.email || contact.phone}</span>
          </div>
        ))}
      </div>

      <div style={styles.termsContainer}>
        <div style={styles.termsRadioButtonContainer} onClick={handleTermsClick}>
          <span style={{...styles.checkbox, ...(agreed ? styles.checkedCheckbox : {})}}>
            {agreed ? '✓' : '×'}
          </span>
          <div style={styles.termsContent}>
            <p style={styles.inviteListHeading}>
              By inviting the listed contacts, I confirm I have their consent to send this SMS/Email invitation.
            </p>
            <p style={styles.agreeStatement}>
              I also agree to BacksApp's{' '}
            </p>
            <a href="https://www.backsapp.com/privacy-policy" target="_blank" rel="noopener noreferrer" style={styles.termsLink}>
              Privacy Policy
            </a>
            <a href="https://www.backsapp.com/terms-of-service" target="_blank" rel="noopener noreferrer" style={styles.termsLink}>
              Terms of Service
            </a>
          </div>
        </div>
      </div>

      <button onClick={handleSendInvite} disabled={!agreed || selectedContacts.length === 0 || isLoading} style={{...styles.button, ...((!agreed || selectedContacts.length === 0 || isLoading) ? styles.disabledButton : {})}}>
        {isLoading ? 'Sending...' : 'Send Invite'}
      </button>

      {errorMessage && <div style={styles.errorMessage}>{errorMessage}</div>}
      {successMessage && <div style={styles.successMessage}>{successMessage}</div>}
      
      {inviteResponse && (
        <div style={styles.inviteResponse}>
          <h2>Invite Results</h2>
          {Object.entries(inviteResponse).map(([category, names]) => {
            if (names.length > 0) {
              return (
                <div key={category} style={styles.responseCategory}>
                  <h3 style={styles.responseCategoryHeader}>{getCategoryTitle(category)}:</h3>
                  <ul style={styles.responseCategoryList}>
                    {names.map((name, index) => (
                      <li key={index} style={styles.responseCategoryItem}>{name}</li>
                    ))}
                  </ul>
                </div>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};

const getCategoryTitle = (category) => {
  const titles = {
    invited_successfully: "Successfully Invited",
    already_using_backsapp: "Already Using BacksApp",
    already_in_group: "Already in Group",
    stop_messages: "Opted Out of Messages",
    invite_failed: "Invite Failed",
    phone_not_valid: "Invalid Phone Numbers"
  };
  return titles[category] || category;
};


const styles = {
  webInvitesContainer: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: style.Color.colorLightBeige,
    borderRadius: '8px',
  },
  addContactForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '20px',
    padding: '10px',
    borderRadius: '8px',
  },
  input: {
    padding: '15px',
    border: '1px solid #ccc',
    borderRadius: '8px',
  },
  button: {
    padding: '15px',
    backgroundColor: style.Color.colorPurple,
    color: 'white',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    width: '100%',
  },
  disabledButton: {
    backgroundColor: style.Color.colorMintGreen,
    cursor: 'not-allowed',
  },
  contactsList: {
    marginBottom: '20px',
    padding: '15px',
  },
  contactItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '5px',
    padding: '15px',
  },
  termsContainer: {
    marginBottom: '20px',
    backgroundColor: style.Color.colorLightestBeige,
    padding: '15px',
    borderRadius: '8px',
  },
  termsRadioButtonContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    cursor: 'pointer',
    padding: '15px',
    paddingTop: '20px',
  },
  checkbox: {
    display: 'inline-block',
    width: '20px',
    height: '20px',
    border: `2px solid ${style.Color.colorPurple}`,
    borderRadius: '8px',
    textAlign: 'center',
    lineHeight: '18px',
    marginRight: '10px',
    color: style.Color.colorPurple,
    fontWeight: 'bold',
    padding: '15px',
  },
  checkedCheckbox: {
    backgroundColor: style.Color.colorPurple,
    color: 'white',
  },
  termsContent: {
    flex: 1,
  },
  inviteListHeading: {
    fontSize: '14px',
    color: style.Color.colorRoyalBlueText,
    marginBottom: '10px',
  },
  agreeStatement: {
    fontSize: '14px',
    color: style.Color.colorDarkBrown,
    marginBottom: '5px',
  },
  termsLink: {
    display: 'block',
    color: style.Color.colorDarkBlue,
    fontSize: '14px',
    fontWeight: 500,
    textDecoration: 'underline',
    marginTop: '5px',
  },
  errorMessage: {
    color: '#dc3545',
    marginTop: '10px',
  },
  successMessage: {
    color: '#28a745',
    marginTop: '10px',
  },
  inviteResponse: {
    marginTop: '20px',
    borderTop: '1px solid #ccc',
    padding: '20px',
    backgroundColor: style.Color.colorLimeGreen,
    borderRadius: '8px',
  },
  responseCategory: {
    marginBottom: '15px',
  },
  responseCategoryHeader: {
    fontSize: '16px',
    color: style.Color.colorRoyalBlueText,
    marginBottom: '5px',
    backgroundColor: style.Color.colorLimeGreen,
    paddingLeft: '20px',
  },
  responseCategoryList: {
    listStyleType: 'none',
    paddingLeft: '20px',
  },
  responseCategoryItem: {
    fontSize: '14px',
    color: style.Color.colorPurple,
    marginBottom: '3px',
    paddingLeft: '10px',
  },
};

export default WebInvites;

